// @flow

import moment from 'moment'
import type { VatRate } from 'types'
import { notNullAndUndefined } from 'utils'
import { EMPTY_ARRAY } from 'trivi-constants'

// 1 - Standard
// 2 - Out of VAT (cz: Mimo DPH)
// 3 - DoNotApplyVat (cz: Neuplatnit DPH)
// 4 - Reverse Charge

export function VatRateType_isReverseCharge(vatRateType?: number): boolean {
	return vatRateType === 4
}

export function VatRate_isReverseCharge(vatRate: VatRate): boolean {
	return VatRateType_isReverseCharge(vatRate.type)
}

export function findVatRateById(vatRates: ?Array<VatRate>, id?: ?string): VatRate | null {
	if (!vatRates || !vatRates.length || !id) return null
	return vatRates.find((rate: VatRate) => id === rate.id) || null
}

export function vatRateIsValid(vatRate: VatRate, direction: ?number, date: ?string, vatCountryType: ?number): boolean {
	const hasMatchedDate = date ? moment(date).isBetween(vatRate.validFrom, vatRate.validTo, 'day', '[]') : true
	const hasMatchedDirection = notNullAndUndefined(direction)
		? notNullAndUndefined(vatRate.accountingDocumentDirection)
			? vatRate.accountingDocumentDirection === direction
			: true
		: true
	const hasMatchedVatCountryType = notNullAndUndefined(vatCountryType)
		? notNullAndUndefined(vatRate.vatCountryType) && vatRate.vatCountryType === vatCountryType
		: true
	return hasMatchedDate && hasMatchedDirection && hasMatchedVatCountryType
}

export function filterVatRates(vatRates: ?Array<VatRate>, financialAccount: ?string): Array<VatRate> {
	if (!vatRates) return EMPTY_ARRAY
	const domesticVatRates = vatRates.filter((vatRate: VatRate) => 'CZ' === vatRate.countryCode)
	const foreignVatRates = vatRates.filter((vatRate: VatRate) => 'CZ' !== vatRate.countryCode)

	let foundVatRates = domesticVatRates.filter(
		(vatRate: VatRate) =>
			vatRate.financialAccountPrefix &&
			!!vatRate.financialAccountPrefix.length &&
			financialAccount &&
			financialAccount.startsWith(vatRate.financialAccountPrefix),
	)

	if (foundVatRates.length === 0) {
		foundVatRates = domesticVatRates.filter((vatRate: VatRate) => vatRate.financialAccountPrefix === null)
	}

	foundVatRates =
		foundVatRates &&
		foundVatRates.concat(domesticVatRates.filter((vatRate: VatRate) => vatRate.financialAccountPrefix === ''))
	return [...foundVatRates, ...foreignVatRates].sort((a: VatRate, b: VatRate) => Number(a.type) - Number(b.type))
}

export function isVatRateAvailable(vatRates: ?Array<VatRate>, id?: ?string): boolean {
	return !!findVatRateById(vatRates, id)
}

export function findSameVatRate(vatRates: Array<VatRate>, vatRate: ?VatRate): null | VatRate {
	if (!vatRate) return null

	// Find by value and type
	const found = vatRates.filter(
		(item: VatRate) => vatRate && item.vatRate === vatRate.vatRate && item.type === vatRate.type,
	)
	if (1 === found.length) return found[0] || null

	// Find by text
	return vatRates.find((item: VatRate) => vatRate && item.text === vatRate.text) || null
}
