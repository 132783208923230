/* @flow */
/** @jsx jsx */

import React, { Component } from 'react'
import { jsx } from '@emotion/core'
import { merge } from 'lodash-es'
import keycode from 'keycode'
import { autoTestId, type AutoTestProps } from 'utils/tests/autotest'
import EventListener from 'react-event-listener'
import { Transition } from 'react-transition-group'
import RenderToLayer from 'components/internal/RenderToLayer'
import Overlay from 'components/internal/Overlay'
import { colors, depths } from 'variables'

type Props = {|
	...AutoTestProps,
	/**
	 * Action buttons to display below the Dialog content (`children`).
	 * This property accepts either a React element, or an array of React elements.
	 */
	actions?: React$Element<any> | Array<React$Element<any>>,
	children?: any,
	/**
	 * Force the user to use one of the actions in the `Dialog`.
	 * Clicking outside the `Dialog` will not trigger the `onRequestClose`.
	 */
	modal?: boolean,
	/**
	 * Fired when the `Dialog` is requested to be closed by a click outside the `Dialog` or on the buttons.
	 *
	 * @param {bool} buttonClicked Determines whether a button click triggered this request.
	 */
	onRequestClose?: boolean => void,
	opaque?: boolean,
	/**
	 * Controls whether the Dialog is opened or not.
	 */
	open: boolean,
	/**
	 * The title to display on the `Dialog`.
	 */
	title?: string,
	style?: Object,
|}

export type State = {|
	isCssBlurSupported: boolean,
|}

declare var CSS: {|
	supports: (string, ?string) => boolean,
|}

class Dialog extends Component<Props, State> {
	static defaultProps = {
		modal: true,
	}

	state = {
		isCssBlurSupported:
			!!window.MSInputMethodContext && !!document.documentMode
				? false
				: CSS &&
				  CSS.supports &&
				  (CSS.supports('backdrop-filter', 'blur(9px)') || CSS.supports('-webkit-backdrop-filter', 'blur(9px)')),
	}

	componentDidMount() {
		if (true === this.props.open) {
			this.updateBlur(true)
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.open !== nextProps.open) {
			this.updateBlur(nextProps.open)
		}
	}

	componentWillUnmount() {
		this.updateBlur(false)
	}

	updateBlur = (open: boolean) => {
		if (this.state.isCssBlurSupported) return

		const root = document.getElementById('root')
		if (open) {
			root && root.setAttribute('class', 'blur')
		} else {
			root && root.removeAttribute('class')
		}
	}

	requestClose(buttonClicked: boolean) {
		if (!buttonClicked && this.props.modal) return
		this.props.onRequestClose && this.props.onRequestClose(!!buttonClicked)
	}

	handleClickOverlay = () => {
		this.requestClose(false)
	}

	handleKeyUp = (event: Event) => {
		keycode(event) === 'esc' && this.requestClose(false)
	}

	renderActions = () => {
		if (!React.Children.count(this.props.actions)) return
		const styles = getStyles(this.props, this.state)

		return <div css={styles.actionsContainer}>{React.Children.toArray(this.props.actions)}</div>
	}

	renderLayer = () => {
		const { children, open, title } = this.props
		const styles = getStyles(this.props, this.state)

		return (
			<div css={styles.root} {...autoTestId(this.props.autoTestId)}>
				<Overlay show={open} style={styles.overlay} onClick={this.handleClickOverlay} />
				{open && <EventListener target="window" onKeyUp={this.handleKeyUp} />}
				<Transition component="div" timeout={450} in={open}>
					{(status: any) => (
						<div css={styles.scroller}>
							<div css={{ ...styles.content, ...transitionStyles[status] }}>
								<div>
									{this.props.title && <h3 style={styles.title}>{title}</h3>}
									<div css={styles.body}>{children}</div>
									{this.renderActions()}
								</div>
							</div>
						</div>
					)}
				</Transition>
			</div>
		)
	}

	render() {
		return <RenderToLayer render={this.renderLayer} open useLayerForClickAway={false} />
	}
}

function getStyles(props: Props, state: State) {
	const { open } = props
	const gutter = 24

	const style = {
		root: {
			pointerEvents: 'all',
			position: 'fixed',
			zIndex: depths.dialog,
			top: 0,
			left: open ? 0 : -10000,
			width: '100%',
			height: '100%',
			transition: open ? 'left 0ms cubic-bezier(0.23, 1, 0.32, 1)' : 'left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms',
			overflow: 'auto',
		},
		scroller: {
			pointerEvents: 'all',
			position: 'absolute',
			width: '100%',
			minHeight: '100%',
			height: 'auto',
			display: 'flex',
			justifyContent: 'center',
			alignContent: 'center',
			alignItems: 'center',
		},
		content: {
			transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1)',
			position: 'relative',
			width: '75%',
			maxWidth: 640,
			zIndex: depths.dialog,
			padding: '70px 0',
			fontWeight: 'normal',
		},
		actionsContainer: {
			padding: 8,
			width: '100%',
			textAlign: 'center',
			marginTop: -1,
			paddingBottom: '35px',
		},
		overlay: {
			background: props.opaque || !state.isCssBlurSupported ? colors.gray200Faded97 : colors.grey200Faded90,
			zIndex: depths.dialogOverlay,
			backdropFilter: 'blur(9px)',
			WebkitBackdropFilter: 'blur(9px)',
			pointerEvents: 'none',
		},
		title: {
			margin: 0,
			padding: `${gutter}px ${gutter}px 20px ${gutter}px`,
			color: colors.black,
			fontSize: 24,
			lineHeight: '32px',
			fontWeight: 700,
			textAlign: 'center',
			marginBottom: -1,
		},
		body: {
			fontSize: 12,
			textAlign: 'left',
			fontWeight: 'normal',
			color: colors.black,
			padding: `${props.title ? 0 : gutter}px ${gutter}px ${gutter}px`,
		},
	}

	return merge(style, props.style)
}

const transitionStyles = {
	entering: {
		opacity: 0,
		transform: 'translate(0, -64px)',
	},
	entered: {
		opacity: 1,
		transform: 'translate(0, 0)',
	},
	exiting: {
		opacity: 0,
		transform: 'translate(0, -64px)',
	},
	exited: {
		opacity: 0,
		transform: 'translate(0, -64px)',
	},
}

export default Dialog
