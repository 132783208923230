/* @flow */

import React, { PureComponent } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import FullStar from 'components/svg-icons/toggle/star'
import StarBorder from 'components/svg-icons/toggle/star-border'
import { IconButton } from 'components'
import { colors } from 'variables'

type Props = {
	...WithTranslateProps,
	isFavorite?: boolean,
	onClick: (event: SyntheticEvent<HTMLElement>) => void,
}

class Star extends PureComponent<Props> {
	render() {
		return (
			<IconButton
				onClick={this.props.onClick}
				color={colors.orange}
				hoverColor={colors.orange600}
				size={24}
				tooltip={
					this.props.isFavorite
						? this.props.t('favorites.removeFromFavorites')
						: this.props.t('favorites.addToFavorites')
				}
			>
				{this.props.isFavorite ? <FullStar size={16} /> : <StarBorder size={16} />}
			</IconButton>
		)
	}
}

export default withTranslate(Star)
