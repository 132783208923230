// @flow
import i18next from 'i18next'
import moment from 'moment'
import type { AccountingDocumentLanguage, PortalLanguage } from 'types'
import Tracking from 'utils/tracking'

export { default as i18n } from './i18n'

export type PrintLanguage = 'cs-CZ' | 'en-US' | 'en-GB' | 'de-DE'

export const AVAILABLE_ACCOUNTING_DOCUMENT_LANGUAGES: Array<AccountingDocumentLanguage> = ['CS', 'en-US', 'en-GB', 'DE']
export const AVAILABLE_USER_LANGUAGES: Array<PortalLanguage> = ['CS', 'EN']
export const AVAILABLE_LOCALES: Array<string> = ['cs-CZ', 'en-US', 'de-DE']

export const getAppLanguage = (): PortalLanguage => {
	const languages: Array<string> = i18next.languages || []

	for (let i = 0; i < languages.length; i++) {
		const language = languages[i]
		const match = AVAILABLE_USER_LANGUAGES.find((item: PortalLanguage) => item === Locale_PortalLanguage(language))

		if (match) {
			return match
		}
	}

	return 'CS'
}

export const setAppLanguage = (appLanguage: ?PortalLanguage) => {
	if (appLanguage) {
		i18next.changeLanguage(PortalLanguage_Locale(appLanguage))
	} else {
		i18next.changeLanguage()
	}
}

export const setAppLocale = (locale: ?string) => {
	moment.locale(locale || PortalLanguage_Locale(getAppLanguage()))
}

export const getAppLocale = (): string => {
	return moment.locale()
}

// Get languages name
export const getPortalLanguageName = (lang: PortalLanguage): string => {
	return { CS: 'Čestina', EN: 'English' }[lang]
}

export const getLocaleName = (lang: string): ?string => {
	return { 'cs-CZ': 'České', 'en-US': 'American English', 'en-GB': 'British English', 'de-DE': 'Deutsch' }[lang]
}

export const getAccountingDocumentLanguageName = (lang: AccountingDocumentLanguage): string => {
	const CS = 'Čestina'
	const US = 'American English'
	const GB = 'British English'
	const DE = 'Deutsch'
	return {
		CS: CS,
		cs_CZ: CS,
		'cs-CZ': CS,
		EN: US,
		en_US: US,
		en_GB: GB,
		'en-US': US,
		'en-GB': GB,
		DE: DE,
		de_DE: DE,
		'de-DE': DE,
	}[lang]
}
//-----

// handling reference of whole react App to be able refresh on language change
export const __setAppComponent = (appComponent: any, setLocale?: boolean) => {
	i18next.on('languageChanged', (lng: string) => {
		const language = Locale_PortalLanguage(lng)
		language && Tracking.changeLanguage(language)
		appComponent.forceUpdate && appComponent.forceUpdate()
		setLocale && moment.locale(lng)
	})
}
//-----

// Converting
export const Locale_PortalLanguage = (localeString: string): ?PortalLanguage => {
	const lang: string = localeString
		.toString()
		.split('-')[0]
		.split('_')[0]
		.toUpperCase()

	return AVAILABLE_USER_LANGUAGES.find((item: PortalLanguage) => item === lang)
}

export const Locale_PrintLanguage = (localeString: string): ?PrintLanguage => {
	const lowered = localeString.toLowerCase()

	switch (lowered) {
		case 'en-us':
		case 'en_us':
			return 'en-US'
		case 'en-gb':
		case 'en_gb':
			return 'en-GB'
	}

	switch (lowered.split('-')[0].split('_')[0]) {
		case 'cs':
		case 'cz':
			return 'cs-CZ'
		case 'en':
			return 'en-US'
		case 'de':
			return 'de-DE'
		default:
			return null
	}
}

export const PortalLanguage_PrintLanguage = (portalLanguage: PortalLanguage): PrintLanguage => {
	return { CS: 'cs-CZ', EN: 'en-US' }[portalLanguage]
}

export const PortalLanguage_AccountingDocumentLanguage = (
	portalLanguage: PortalLanguage,
): AccountingDocumentLanguage => {
	return { CS: 'cs_CZ', EN: 'en_US', DE: 'de_DE' }[portalLanguage]
}

const PortalLanguage_Locale = (portalLanguage: PortalLanguage): string => {
	return portalLanguage.toLowerCase()
}

export const AccountingDocumentLanguage_PrintLanguage = (documentLang: AccountingDocumentLanguage): PrintLanguage => {
	const CS = 'cs-CZ'
	const US = 'en-US'
	const GB = 'en-GB'
	const DE = 'de-DE'
	return {
		CS: CS,
		cs_CZ: CS,
		'cs-CZ': CS,
		EN: US,
		en_US: US,
		'en-US': US,
		en_GB: GB,
		'en-GB': GB,
		DE: DE,
		de_DE: DE,
		'de-DE': DE,
	}[documentLang]
}

export const toAvailableAccountingDocumentLanguage = (
	language: ?AccountingDocumentLanguage,
): ?AccountingDocumentLanguage => {
	if (!language) {
		return language
	}

	if (AVAILABLE_ACCOUNTING_DOCUMENT_LANGUAGES.indexOf(language) >= 0) {
		return language
	}

	const CS = 'cs-CZ'
	const US = 'en-US'
	const GB = 'en-GB'
	const DE = 'de-DE'
	return {
		CS: CS,
		cs_CZ: CS,
		'cs-CZ': CS,
		EN: US,
		en_US: US,
		'en-US': US,
		DE: DE,
		de_DE: DE,
		'de-DE': DE,
		en_GB: GB,
		'en-GB': GB,
	}[language]
}

// export const String_UserLanguage = (strLang: string): ?UserLanguage => {
// 	return String_AccountingDocumentLanguage(strLang)
// }

//-----
