/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { Contact_AccountingDocumentContact } from 'types/convertor'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import ContactForm from '../../../containers/invoice-elements/contact/contact-form'
import ContactActions from './contact-actions'
import type {
	AccountingDocumentAssignedDirection,
	AccountingDocumentContact,
	Contact as ContactType,
	FormFieldErrorMessage,
} from 'types'
import ContactDetail from '../../../containers/invoice-elements/contact/contact-detail'
import { type EditType as FormEditType } from './contact-form'
import ContactSelect from '../../../containers/invoice-elements/contact/contact-select'
import { getTooltipErrorLabel, hasErrors } from 'helpers'
import PopupSection from 'components/PopupSection'
import Tooltip from 'components/tooltip'
import { colors } from 'variables'

export type Props = {|
	direction: AccountingDocumentAssignedDirection,
	contact: ?AccountingDocumentContact,
	loading?: boolean,
	readonly?: boolean,
	isSupplier?: boolean,
	public?: boolean,
	isMe?: boolean,
	view?: string,
	canEditContacts: boolean,
	canSeeContacts: boolean,
	errors: ?Array<FormFieldErrorMessage>,
	onChange?: (contact: AccountingDocumentContact) => Promise<void>,
	removeError: () => void,
|}

type State = {|
	showSearch: boolean,
	edit: ?FormEditType,
	searchCompany: ?string,
|}

type ComponentProps = {| ...Props, ...WithTranslateProps |}

class Contact extends Component<ComponentProps, State> {
	state = {
		showSearch: false,
		edit: null,
		searchCompany: null,
	}

	componentWillReceiveProps(nextProps: ComponentProps) {
		if (nextProps.view !== this.props.view) {
			this.setState({
				edit: null,
			})
		}
	}

	showSearch = () => {
		this.setState({ showSearch: true })
	}

	hideSearch = () => {
		this.setState({ showSearch: false })
	}

	openUpdateForm = () => {
		this.setState({ edit: 'UPDATE', showSearch: false })
	}

	openCreateForm = () => {
		this.setState({ edit: 'CREATE', showSearch: false })
	}

	openSearchForm = (value: string) => {
		this.setState({ edit: 'CREATE', searchCompany: value, showSearch: false })
	}

	closeForm = () => {
		this.setState({ edit: null, searchCompany: null })
	}

	onAccDocContactChange = (accDocContact: AccountingDocumentContact) => {
		if (accDocContact.contactId) {
			this.props.onChange && this.props.onChange(accDocContact)
			if (this.props.errors) this.props.removeError()
		}
		this.closeForm()
	}

	onContactChange = (contact: ?ContactType) => {
		if (contact && contact.addresses) {
			const accDocContact: AccountingDocumentContact = Contact_AccountingDocumentContact(contact)
			this.props.onChange && this.props.onChange(accDocContact)
		}
		this.closeForm()
	}

	render() {
		const { t, readonly, isSupplier, contact, isMe, canEditContacts, canSeeContacts, errors } = this.props
		const canEditContact = !readonly && canEditContacts
		const canSelectContact = !readonly && (canEditContacts || canSeeContacts)
		const areAnyErrors = hasErrors(errors)

		if (!canSeeContacts && !isMe)
			return (
				<div css={styles.root}>
					<div css={styles.empty}>{t('invoice.contact.cannotSeeContacts')}</div>
				</div>
			)

		return (
			<div css={areAnyErrors ? styles.error : styles.root}>
				<Tooltip label={getTooltipErrorLabel(errors, t)} disabled={!areAnyErrors}>
					{canSelectContact && (
						<ContactSelect
							isSupplier={!!isSupplier}
							show={this.state.showSearch || !contact}
							hideSearch={this.hideSearch}
							onChange={this.onAccDocContactChange}
							onAdd={this.openCreateForm}
							onSearchInAres={this.openSearchForm}
							autoFocus={!!contact}
							enableAddContact={canEditContact}
						/>
					)}
					<ContactDetail contact={contact} isMe={isMe} showLoader={canSelectContact} public={this.props.public} />
					{canSelectContact && !isMe && contact && (
						<ContactActions
							isSupplier={isSupplier}
							canChangeContact={canSelectContact}
							canEditContact={canEditContact}
							onChange={this.showSearch}
							onEdit={this.openUpdateForm}
						/>
					)}
				</Tooltip>
				{this.renderEditModal()}
			</div>
		)
	}

	renderEditModal() {
		const { t, contact } = this.props
		const inline = 'scans' === this.props.view
		const title = t(
			'invoice.contact.' +
				('UPDATE' === this.state.edit ? 'edit' : 'addNew') +
				(this.props.isSupplier ? 'Supplier' : 'Customer'),
		)

		return (
			this.state.edit && (
				<PopupSection
					open
					opaque
					title={title}
					onRequestClose={this.closeForm}
					target={inline ? document.getElementById('invoice-container') : undefined}
					notPopoverTarget
				>
					<div css={styles.popup}>
						<ContactForm
							searchCompany={this.state.searchCompany}
							contactId={contact && contact.contactId}
							onCancel={this.closeForm}
							onSave={this.onContactChange}
							editType={this.state.edit}
							inline={inline}
						/>
					</div>
				</PopupSection>
			)
		)
	}
}

const styles = {
	root: {
		position: 'relative',
		paddingTop: 14,
		paddingBottom: 12,
		margin: -1,
		zIndex: 1,
	},
	error: {
		position: 'relative',
		paddingTop: 14,
		paddingBottom: 12,
		margin: -1,
		zIndex: 1,
		backgroundColor: colors.redFaded4,
		border: `1px solid ${colors.red}`,
	},
	popup: {
		backgroundColor: 'white',
		height: '100%',
	},
	empty: {
		paddingTop: 3,
		paddingLeft: 15,
		paddingRight: 15,
	},
}

export default withTranslate(Contact)
