//@flow
import GTM from './gtm'
import Mixpanel, { type Ids, type DeletedReason } from './mixpanel'
import type { AppSection, UserType, UserProfile } from 'types'

function init() {
	Mixpanel.init()
}

function logout() {
	Mixpanel.enableTracking()
	setUserInfoReady()
}

function impersonate() {
	Mixpanel.disableTracking()
}

function authenticate() {
	Mixpanel.enableTracking()
}

function changeAppSection(section: AppSection) {
	GTM.changeAppSection(section)
}

function changeURL(url: string, sectionName: AppSection, ids: Ids) {
	Mixpanel.changeURL(url, sectionName, ids)
}

function changeUserProfile(userProfile: ?UserProfile) {
	const userId: ?string = (userProfile && userProfile.id) || null
	Mixpanel.changeUserProfile(userProfile)
	GTM.changeUserId(userId)
}

function changeUserType(userType: ?UserType) {
	GTM.changeUserType(userType)
	Mixpanel.changeUserType(userType)
}

function changeUserIsExtractor(isExtractor: boolean) {
	Mixpanel.changeUserIsExtractor(isExtractor)
}

function changeOrganizationId(organizationId: ?string) {
	GTM.changeOrganizationId(organizationId)
	Mixpanel.changeOrganizationId(organizationId)
}

function submitDemoContactForm(success: boolean) {
	if (success) {
		GTM.submitDemoContactForm()
	}
}

function changeMainAccountant(id: ?string) {
	GTM.changeMainAccountantId(id)
	Mixpanel.changeMainAccountantId(id)
}

function changePayrollAccountant(id: ?string) {
	GTM.changePayrollAccountantId(id)
	Mixpanel.changePayrollAccountantId(id)
}

function setUserInfoReady() {
	GTM.setUserInfoReady()
}

function changeLanguage(language: string) {
	GTM.changeLanguage(language)
}

function trackStateChangeToExtracting(accDocId: string, isQueue?: boolean) {
	Mixpanel.trackStateChangeToExtracting(accDocId, isQueue)
}

function trackStateChangeToExtracted(accDocId: string, isQueue?: boolean) {
	Mixpanel.trackStateChangeToExtracted(accDocId, isQueue)
}

function trackExtractingQueuePop(accDocId: string) {
	Mixpanel.trackExtractingQueuePop(accDocId)
}

function trackSentEmail(accDocId: string, recipientsCount: number) {
	Mixpanel.trackSentEmail(accDocId, recipientsCount)
}

function trackUploadedDocuments(count: number) {
	Mixpanel.trackUploadedDocuments(count)
}

function trackCreateBankOrder(bankCode: string) {
	Mixpanel.trackCreateBankOrder(bankCode)
}

function trackStateChange(accDocId: string, state: string, prevState?: ?string) {
	Mixpanel.trackStateChange(accDocId, state, prevState)
}

function trackStateChangeToFixing(accDocId: string) {
	Mixpanel.trackStateChangeToFixing(accDocId)
}

function trackStateChangeToProcessed(accDocId: string) {
	Mixpanel.trackStateChangeToProcessed(accDocId)
}

function trackStateChangeToNeedHelp(accDocId: string) {
	Mixpanel.trackStateChangeToNeedHelp(accDocId)
}

function trackAccDocReextract(accDocId: string, newAccDocId: string) {
	Mixpanel.trackAccDocReextract(accDocId, newAccDocId)
}

function trackAccDocDeleted(accDocId: string, reason: DeletedReason) {
	Mixpanel.trackAccDocDeleted(accDocId, reason)
}

function trackAccDocExtractingCanceled(accDocId: string) {
	Mixpanel.trackAccDocExtractingCanceled(accDocId)
}

export default {
	init,
	logout,
	impersonate,
	authenticate,
	changeAppSection,
	changeUserProfile,
	changeUserType,
	changeOrganizationId,
	changeUserIsExtractor,
	submitDemoContactForm,
	changeMainAccountant,
	changePayrollAccountant,
	setUserInfoReady,
	changeLanguage,
	trackUploadedDocuments,
	trackStateChangeToExtracting,
	trackStateChangeToExtracted,
	trackExtractingQueuePop,
	trackSentEmail,
	trackCreateBankOrder,
	trackStateChange,
	trackStateChangeToFixing,
	trackStateChangeToProcessed,
	trackStateChangeToNeedHelp,
	trackAccDocExtractingCanceled,
	trackAccDocReextract,
	trackAccDocDeleted,
	changeURL,
}
