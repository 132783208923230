// @flow

import type {
	AresContact,
	AvatarSize,
	InternalUser,
	MyAvatarGetResponse,
	OrganizationMembers,
	UserProfile,
	TermsItemResponse,
} from 'types'
import { sortOrganizations } from 'modules/organization/domain/organization'
import type { Action } from '../actions/action-types'
import { EMPTY_ARRAY } from 'trivi-constants'

type AvatarSet = {
	[key: AvatarSize]: {|
		original: MyAvatarGetResponse,
		crop: MyAvatarGetResponse,
	|},
}

const EMPTY_AVATAR_SET: AvatarSet = {
	'40': {
		original: null,
		crop: null,
	},
	'80': {
		original: null,
		crop: null,
	},
	'100': {
		original: null,
		crop: null,
	},
	'200': {
		original: null,
		crop: null,
	},
} //eslint-disable-line

// State nezávislý na organizationId! Sem nepatří nic, co by se měnilo se změnou organizace
export type State = {
	username?: string,
	splashScreenVisible: boolean,
	currentOrganizationId: ?string,
	me: {
		data: ?UserProfile,
		loading: boolean,
		avatar: AvatarSet,
	},
	organizations: {
		data: ?OrganizationMembers,
		loading: boolean,
	},
	changingPasswordComplete: boolean,
	aresContacts: {
		loading: boolean,
		data: ?Array<AresContact>,
	},
	internalUsers: {
		loading: boolean,
		data: ?Array<InternalUser>,
	},
	invitationInProgress: boolean,
	terms: {
		loading: boolean,
		data: ?TermsItemResponse,
	},
}

export const initialState: State = {
	splashScreenVisible: false,
	currentOrganizationId: null,
	me: {
		data: {
			settings: {
				displayAccountingDocumentPreviewBeforeProcessing: false,
			},
		},
		loading: false,
		avatar: EMPTY_AVATAR_SET,
	},
	organizations: {
		data: null,
		loading: false,
	},
	changingPasswordComplete: false,
	aresContacts: {
		loading: false,
		data: null,
	},
	internalUsers: {
		loading: false,
		data: null,
	},
	invitationInProgress: false,
	terms: {
		loading: false,
		data: null,
	},
}

export default (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case 'SWITCH_SPLASH_SCREEN':
			return {
				...state,
				splashScreenVisible: action.payload,
			}
		case 'LOGIN': {
			return {
				...state,
				username: action.username,
			}
		}

		case 'START_AUTHENTICATE': {
			return {
				...state,
				loading: true,
			}
		}

		case 'FINISH_AUTHENTICATE': {
			return {
				...state,
				loading: false,
				splashScreenVisible: action.serverError ? false : state.splashScreenVisible,
			}
		}

		case 'LOGOUT': {
			let newState = { ...state }
			delete newState.username
			return newState
		}

		case 'START_LOAD_BOOTSTRAP':
			return {
				...state,
				me: {
					...state.me,
					loading: true,
				},
				organizations: {
					...state.organizations,
					loading: true,
				},
			}
		case 'FINISH_LOAD_BOOTSTRAP':
			return {
				...state,
				me: {
					...state.me,
					loading: false,
					data: action.data && action.data.userProfile,
				},
				organizations: {
					...state.organizations,
					loading: false,
					data: action.data && action.data.organizations && sortOrganizations(action.data.organizations),
				},
				// terms: action.data && action.data.terms,
				terms: {
					loading: false,
					data: action.data && action.data.terms,
				},
			}

		case 'START_LOAD_ME': {
			return {
				...state,
				me: {
					...state.me,
					loading: true,
				},
			}
		}

		case 'FINISH_LOAD_ME': {
			return {
				...state,
				me: {
					...state.me,
					loading: false,
					data: action.me,
				},
			}
		}

		case 'START_UPDATING_ME': {
			return {
				...state,
				me: {
					...state.me,
					data: action.newMe,
				},
			}
		}

		case 'FINISH_UPDATING_ME': {
			return {
				...state,
				me: {
					...state.me,
					data: action.serverError ? action.oldMe : action.newMe,
				},
			}
		}

		case 'FINISH_CHANGING_MY_AVATAR': {
			const { avatar } = action
			if (avatar === undefined) {
				return state
			}
			return {
				...state,
				me: {
					...state.me,
					avatar: {
						'40': {
							original: avatar,
							crop: avatar,
						},
						'80': {
							original: avatar,
							crop: avatar,
						},
						'100': {
							original: avatar,
							crop: avatar,
						},
						'200': {
							original: avatar,
							crop: avatar,
						},
					},
				},
			}
		}

		case 'FINISH_LOADING_MY_AVATAR': {
			const { original, size, avatar } = action
			if (avatar === undefined) {
				return state
			}
			return {
				...state,
				me: {
					...state.me,
					avatar: {
						...state.me.avatar,
						[size]: {
							original: original ? avatar : state.me.avatar[size].original,
							crop: original ? state.me.avatar[size].crop : avatar,
						},
					},
				},
			}
		}

		case 'START_DELETING_MY_AVATAR': {
			return {
				...state,
				me: {
					...state.me,
					avatar: EMPTY_AVATAR_SET,
				},
			}
		}

		case 'FINISH_DELETING_MY_AVATAR': {
			return {
				...state,
				me: {
					...state.me,
					avatar: action.serverError ? action.oldAvatarSet : EMPTY_AVATAR_SET,
				},
			}
		}

		case 'START_LOAD_ORGANIZATIONS': {
			return {
				...state,
				organizations: {
					loading: true,
					data: state.organizations.data,
				},
			}
		}

		case 'FINISH_LOAD_ORGANIZATIONS': {
			return {
				...state,
				organizations: {
					loading: false,
					data: action.organizations ? sortOrganizations(action.organizations) : EMPTY_ARRAY,
				},
			}
		}

		case 'CHANGE_CURRENT_ORGANIZATION': {
			return {
				...state,
				currentOrganizationId: action.organizationId,
			}
		}

		case 'START_CHANGING_PASSWORD': {
			return {
				...state,
				loading: true,
				changingPasswordComplete: false,
			}
		}

		case 'FINISH_CHANGING_PASSWORD': {
			return {
				...state,
				loading: false,
				changingPasswordComplete: action.complete,
			}
		}

		case 'START_LOADING_ARES_CONTACTS': {
			return {
				...state,
				aresContacts: {
					...state.aresContacts,
					loading: true,
				},
			}
		}

		case 'FINISH_LOADING_ARES_CONTACTS': {
			return {
				...state,
				aresContacts: {
					data: action.aresContacts,
					loading: false,
				},
			}
		}

		case 'CLEAR_ARES_CONTACTS': {
			return {
				...state,
				aresContacts: {
					data: null,
					loading: false,
				},
			}
		}

		case 'FINISH_EDITING_INTERNAL_USER': {
			if (action.internalUser) {
				const u: InternalUser = action.internalUser
				const data: ?Array<InternalUser> =
					state.internalUsers.data &&
					state.internalUsers.data.map((user: InternalUser) => {
						return u.id === user.id ? u : user
					})

				return {
					...state,
					internalUsers: {
						...state.internalUsers,
						data,
					},
				}
			}
			return state
		}

		case 'START_LOAD_INTERNAL_USERS': {
			return {
				...state,
				internalUsers: {
					...state.internalUsers,
					loading: true,
				},
			}
		}

		case 'FINISH_LOAD_INTERNAL_USERS': {
			return {
				...state,
				internalUsers: {
					loading: false,
					data: action.internalUsers || state.internalUsers.data,
				},
			}
		}

		case 'START_PROCESS_INVITATION':
			return {
				...state,
				invitationInProgress: true,
			}

		case 'FINISH_PROCESS_INVITATION':
			return {
				...state,
				invitationInProgress: false,
			}

		case 'UPDATE_TERMS_START':
			return {
				...state,
				terms: {
					...state.terms,
					loading: true,
				},
			}

		case 'UPDATE_TERMS_FINISH':
			if (action.serverError) {
				return {
					...state,
					terms: {
						...state.terms,
						loading: false,
					},
				}
			}
			return {
				...state,
				terms: {
					loading: false,
					data: action.payload,
				},
			}

		default:
			return state
	}
}
