/* @flow */

import React, { Component } from 'react'
import { Tooltip } from 'components'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { CountryVariantSpecific } from 'types'

type Props = {|
	...WithTranslateProps,
	countryVariantSpecific: ?CountryVariantSpecific,
|}

class EetText extends Component<Props> {
	render() {
		const { t, countryVariantSpecific: cvs } = this.props
		const eetSent = cvs && cvs.cz && cvs.cz && cvs.cz.eet && cvs.cz.eet.bkp
		const eet = cvs && cvs.cz && cvs.cz.eet

		const eetLabel = eet && (
			<div>
				{eet.bkp && <div>BKP: {eet.bkp}</div>}
				{eet.fik && <div>FIK: {eet.fik}</div>}
				{eet.pkp && <div>PKP: {eet.pkp}</div>}
			</div>
		)

		if (eetSent) {
			return (
				<Tooltip label={eetLabel || ''} inline>
					{t('settings.eet.yes')}
				</Tooltip>
			)
		} else {
			return <span>{t('settings.eet.no')}</span>
		}
	}
}

export default withTranslate(EetText)
