//@flow

import type { Feature } from 'utils/features'

type UnknownFeature = 'unknownFeature'

export type FeatureId =
	| UnknownFeature
	| 'registration'
	| 'addOrganizationButton'
	| 'bankAccountStatementOptions'
	| 'accountingDocumentImport'
	| 'notifications'
	| 'permissionsTestTool'
	| 'billings'
	| 'notification-settings'

export const AVAILABLE_FEATURES: Array<Feature> = [
	{
		id: 'registration',
		name: 'Registrace nového účtu',
		description: 'Zobrazí odkaz na registraci na login stránce a zpřístupní stránku s registrací na adrese "/register"',
	},
	{
		id: 'addOrganizationButton',
		name: 'Add organization button in organization switcher',
		description: 'Schová nebo zobrazí tlačítko na vytvoření organizace v přepínátku organizací',
	},
	{
		id: 'bankAccountStatementOptions',
		name: 'Bank Account Statement Options',
		description: 'Schová/zobrazí odkaz k nastavení stahování výpisu z banky',
	},
	{
		id: 'accountingDocumentImport',
		name: 'Accounitng Document Import',
		description: 'Schová/zobrazí tlačítko na proklik na stránku s importem dokladů',
	},
	{
		id: 'notifications',
		name: 'Notifications',
		description: 'Sprístupni notifikácie',
	},
	{
		id: 'billings',
		name: 'Billings',
		description: 'Sprístupni billingy',
	},
	{
		id: 'permissionsTestTool',
		name: 'Permissions Test Tool',
		description: 'Otevře nástroj pro testování oprávnění',
	},
	{
		id: 'notification-settings',
		name: 'Notification Settings',
		description: 'Zobrazí nastavení notifikací',
	},
]
