/* @flow */

import 'whatwg-fetch'
import React from 'react'
import ReactDOM from 'react-dom'
import { Route } from 'react-router'
import { Provider } from 'react-redux'
import store, { history } from 'store'
import { __setAppComponent, i18n } from 'locales'
import { I18nextProvider } from 'react-i18next'
import { ConnectedRouter } from 'react-router-redux'
import PublicAccountingDocument from 'modules/accounting-document/pages/public-accounting-document'
import InvoiceNotFound from 'modules/common/pages/invoice-not-found'
import Tracking from 'app/tracking'

const router = (
	<Provider store={store}>
		<I18nextProvider i18n={i18n}>
			<div>
				<ConnectedRouter history={history}>
					<React.Fragment>
						<Route
							path="/accounting-documents/:uniqueId"
							render={() => ( // eslint-disable-line
								<div>
									<PublicAccountingDocument />
									<Tracking />
									<div id="render-to-layer" />
								</div>
							)}
						/>
						<Route path="/notFound" component={InvoiceNotFound} />
					</React.Fragment>
				</ConnectedRouter>
			</div>
		</I18nextProvider>
	</Provider>
)

const root = document.getElementById('root')

if (root) {
	const APP_REACT_COMPONENT = ReactDOM.render(router, root)
	__setAppComponent(APP_REACT_COMPONENT, true)
}
