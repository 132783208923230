/* @flow */

import type { State, AccountingDocumentAssignedType, AccountingDocumentDirection } from 'types'
import store from 'store'

declare var SHARE_HOST: string //global variable defined in webpack.config.js

function getOrganizationId() {
	const state: State = store.getState()
	return state.user.currentOrganizationId || 'error'
}

export function getUrlDirectionParam(direction?: ?AccountingDocumentDirection) {
	return {
		received: 'received',
		issued: 'issued',
		unknown: 'uploaded',
		__undefined__: 'received', // Default direction
	}[direction || '__undefined__']
}

export function showAccountingDocumentRoute(documentId: string, organizationID?: string) {
	return `/${organizationID || getOrganizationId()}/accounting-documents/${documentId}/detail`
}

export function createAccountingDocumentRoute(
	type: ?AccountingDocumentAssignedType,
	direction: string,
	organizationID?: string,
) {
	return `/${organizationID || getOrganizationId()}/accounting-documents/${direction}/${type || 'unknown'}/create`
}

export function editAccountingDocumentRoute(
	documentId: string,
	organizationID?: string,
	type: 'edit' | 'preview' | 'redir' = 'redir',
) {
	const suffix = type === 'edit' || type === 'preview' ? `/${type}` : '/'
	return `/${organizationID || getOrganizationId()}/accounting-documents/${documentId}${suffix}`
}

export function extractAccountingDocumentRoute(documentId: string, customOrganizationId?: string) {
	return `/${customOrganizationId || getOrganizationId()}/accounting-documents/${documentId}/extract`
}

export function extractDashboardAccountingDocumentRoute(documentId: string, organizationID?: string) {
	return `/${organizationID || getOrganizationId()}/accounting-documents/${documentId}/edit/queue`
}

export function uploadScanRoute() {
	return `/${getOrganizationId()}/accounting-documents/upload-scan`
}

export function accountingDocumentsRoute(direction?: ?AccountingDocumentDirection) {
	return `/${getOrganizationId()}/accounting-documents/${getUrlDirectionParam(direction)}`
}

export function organizationAccountingDocumentsRoute(organizationID: string, direction?: ?AccountingDocumentDirection) {
	return `/${organizationID}/accounting-documents/${getUrlDirectionParam(direction)}`
}

export function accountingDocumentsTemplatesRoute() {
	return `/${getOrganizationId()}/accounting-documents/templates`
}

export const accountingDocumentRoute = (documentId: string): string => {
	if (!documentId) throw new Error('Cannot get route of accounting document detail with AccountingDocumentID empty.')
	return `/${getOrganizationId()}/accounting-documents/${documentId}/edit`
}

export const accountingDocumentPublicRoute = (uniqueId: string): string => {
	if (!uniqueId) throw new Error('Cannot get route of accounting document public detail with uniqueId empty.')
	return `/accounting-documents/${uniqueId}`
}

export const accountingDocumentPublicShareableRoute = (uniqueId: string): string => {
	if (!uniqueId) throw new Error('Cannot get route of accounting document public detail with uniqueId empty.')
	return `${SHARE_HOST}/accounting-documents/${uniqueId}`
}

export function editTemplateRoute(templateId: string) {
	return `/${getOrganizationId()}/accounting-documents/templates/${templateId}/edit`
}

export function accountingDocumentImportRoute() {
	return `/${getOrganizationId()}/accounting-documents/import`
}

export function accountingDocumentImportDetailRoute(importId?: string) {
	return `/${getOrganizationId()}/accounting-documents/import/${importId || ''}`
}
