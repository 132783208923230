/* @flow */

import React, { Component } from 'react'
import type { AccountingDocumentPrintingInfo } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import TextField from 'components/TextField'
import styles from './printing-note.css'

type Props = {|
	...WithTranslateProps,
	readonly?: boolean,
	accountingDocumentId?: string,
	printing: ?AccountingDocumentPrintingInfo,
	changePrinting: (printing: ?AccountingDocumentPrintingInfo) => void,
|}

type State = {|
	lineItemsFooter: ?string,
|}

class PrintingNote extends Component<Props, State> {
	state: State = {
		lineItemsFooter: this.props.printing ? this.props.printing.lineItemsFooter : null,
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		this.setState({
			lineItemsFooter: nextProps.printing ? nextProps.printing.lineItemsFooter : this.state.lineItemsFooter,
		})
	}

	onLineItemsFooterChange = (event: SyntheticInputEvent<HTMLInputElement>, text: ?string) => {
		this.setState({ lineItemsFooter: text })
	}

	onLineItemsFooterBlur = () => {
		const { lineItemsFooter } = this.state
		const { printing } = this.props

		if (!printing || printing.lineItemsFooter !== lineItemsFooter) {
			this.props.changePrinting(
				Object.freeze({
					...(printing || {}),
					lineItemsFooter: lineItemsFooter || '',
				}),
			)
		}
	}

	onLineItemsFooterFocus = (ev: Object) => {
		ev.target.select()
	}

	render() {
		const { t, readonly } = this.props
		const { lineItemsFooter } = this.state

		if (readonly && !lineItemsFooter) {
			return null
		}

		return (
			<div className={styles.root}>
				<h3 className={styles.headline}>{t('invoice.notes')}</h3>
				<TextField
					multiLine
					fullWidth
					autoHeight
					inline
					tabIndex={-1}
					value={lineItemsFooter}
					hintText={t('invoice.addNewNoteHint')}
					disabled={readonly}
					onChange={this.onLineItemsFooterChange}
					onBlur={this.onLineItemsFooterBlur}
					onFocus={this.onLineItemsFooterFocus}
					name="printing-note"
					autoTestId="invoice-printing-note-input"
				/>
			</div>
		)
	}
}

export default withTranslate(PrintingNote)
