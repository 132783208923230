// @flow

import type { CountryVariantSpecific } from 'types'

export function isEetSent(cvs: ?CountryVariantSpecific) {
	return !!(cvs && cvs.cz && cvs.cz.eet && cvs.cz.eet.operation === 'send_eet')
}

export function isEetCancelled(cvs: ?CountryVariantSpecific) {
	return !!(cvs && cvs.cz && cvs.cz.eet && cvs.cz.eet.operation === 'cancel_eet')
}

export function isEetUsed(cvs: ?CountryVariantSpecific) {
	return !!(cvs && cvs.cz && cvs.cz.eet && cvs.cz.eet.use)
}

export function isEetUsedOrSent(cvs: ?CountryVariantSpecific) {
	return !isEetCancelled(cvs) && (isEetUsed(cvs) || isEetSent(cvs))
}

export function getEetFromCvs(cvs: ?CountryVariantSpecific) {
	return (cvs && cvs.cz && cvs.cz.eet) || null
}

export function getEetPropertyFromCvs(cvs: ?CountryVariantSpecific) {
	const eet = getEetFromCvs(cvs)
	return (property: string) => {
		return eet && eet[property]
	}
}
