/* @flow */

import React, { PureComponent } from 'react'
import type { AccountingDocumentConnection } from 'types'
import { CurrencyInput, IconButton, SelectField, MenuItem } from 'components'
import ContentClear from 'components/svg-icons/content/clear'
import EditAmountsModal from './edit-total-amounts'
import Info from 'components/svg-icons/action/info'
import { Type_AccountingDocumentName, ROUNDING_TYPES } from 'types/convertor'
import { autoTestId } from 'utils/tests/autotest'
import { formatToMoney } from 'utils/formatters'
import ConfirmDialog from 'components/confirm-dialog'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { colors } from 'variables'
import styles from './totals.css'

type Props = {|
	...WithTranslateProps,
	accountingDocumentId?: string,
	total: ?number,
	totalVatExcl: ?number,
	totalVatAmount: ?number,
	roundingAmount: ?number,
	connections: ?Array<AccountingDocumentConnection>,
	readonly?: boolean,
	currency?: string,
	domesticCurrency: ?string,
	isAccDocVatFree: boolean,
	isProcessed: boolean,
	roundingType: ?number,
	showRounding: ?boolean,
	onRoundingTypeChange: (roundingType: ?number) => void,
	onConnectionChange: (connection: AccountingDocumentConnection) => void,
	onRemoveConnection?: (connection: AccountingDocumentConnection, asRelatedDocument: boolean) => Promise<any>,
|}

type State = {
	connections: ?Array<AccountingDocumentConnection>,
	visibleConnectionModal: ?string,
}

class Totals extends PureComponent<Props, State> {
	state: State
	iconButtonInfo: any

	state = {
		connections: this.props.connections,
		visibleConnectionModal: null,
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		this.setState({
			connections: nextProps.connections,
		})
	}

	onConnectionChange = (newConnection: AccountingDocumentConnection, setVatAmountsToState: boolean = true) => {
		let connections: Array<AccountingDocumentConnection> = [...(this.state.connections || [])]
		const foundIndex: number = connections.findIndex(
			(c: AccountingDocumentConnection) =>
				c.connectedAccountingDocumentId === newConnection.connectedAccountingDocumentId,
		)
		if (foundIndex > -1) {
			connections[foundIndex] = Object.freeze({
				...newConnection,
				vatAmounts: setVatAmountsToState ? newConnection.vatAmounts : connections[foundIndex].vatAmounts,
			})
			this.setState({ connections })
		}
	}

	createOnRemoveConnection = (connection: AccountingDocumentConnection) => () => {
		const { onRemoveConnection, t } = this.props

		ConfirmDialog(t('dialogs.removeAttachmentQuestion'), {
			okLabel: t('dialogs.yesOption'),
			cancelLabel: t('dialogs.noOption'),
		}).then(() => {
			onRemoveConnection && onRemoveConnection(connection, false)
		})
	}

	showPricesEditModal = (connection: AccountingDocumentConnection) => () => {
		const visibleConnectionModal = this.state.visibleConnectionModal
		if (visibleConnectionModal === connection.connectedAccountingDocumentId) {
			this.setState({ visibleConnectionModal: null })
		} else {
			this.setState({ visibleConnectionModal: connection.connectedAccountingDocumentId })
		}
	}

	handleRequestClose = () => {
		this.setState({
			visibleConnectionModal: null,
		})
	}

	onConnectionTotalChange = (connection: AccountingDocumentConnection) => (
		event: SyntheticInputEvent<HTMLInputElement>,
		value: ?number,
	) => {
		this.onConnectionChange(
			Object.freeze({
				...connection,
				vatAmounts: value ? undefined : connection.vatAmounts,
				amount: value ? Math.abs(value) : undefined,
			}),
			false,
		)
	}

	onConnectionTotalBlur = (connection: AccountingDocumentConnection) => () => {
		this.props.onConnectionChange(
			Object.freeze({
				...connection,
				vatAmounts: undefined,
			}),
		)
	}

	setIconButtonInfo = (node: any) => (this.iconButtonInfo = node)

	renderConnections = () => {
		const { t } = this.props
		if (!Array.isArray(this.state.connections)) {
			return null
		}

		return this.state.connections.map((connection: AccountingDocumentConnection, index: number) => {
			const type = Type_AccountingDocumentName(connection.connectedAccountingDocumentType)
			const docLabel = connection.connectedAccountingDocumentNo || connection.connectedAccountingDocumentId
			return (
				<div key={'connection' + index} className="document-connection">
					<hr className={styles.hr} />
					<div className={styles.connection}>
						{type && (
							<div className={styles.leftConnection}>
								{t('invoice.totals.type')}
								{t('accountingDocument.types.' + type)}
								{` (${t('invoice.totals.documentNo')} ${docLabel || ''})`}
							</div>
						)}
						<div className={styles.rightConnection} id={'invoice-vat'}>
							<CurrencyInput
								value={-Math.abs(connection.amount || 0)}
								onChange={this.onConnectionTotalChange(connection)}
								onBlur={this.onConnectionTotalBlur(connection)}
								hintText="amount"
								disabled={!!this.props.readonly}
								right
								inline
								name="total"
							/>
						</div>
						{!this.props.isProcessed && (
							<div className={styles.clear}>
								<IconButton
									autoTestId="invoice-totals-remove"
									style={style.iconButton}
									onClick={this.createOnRemoveConnection(connection)}
									tooltip={t('attachments.removeAttachment')}
									hoverColor={colors.blue}
									size={24}
								>
									<ContentClear size={14} />
								</IconButton>
							</div>
						)}
						{connection.vatAmounts && connection.vatAmounts.length > 0 && (
							<div className={styles.info} ref={this.setIconButtonInfo}>
								<IconButton
									autoTestId="invoice-totals-show-prices"
									style={style.iconButton}
									onClick={this.showPricesEditModal(connection)}
									hoverColor={colors.blue}
									size={24}
								>
									<Info size={14} />
								</IconButton>
							</div>
						)}
						<EditAmountsModal
							open={this.state.visibleConnectionModal === connection.connectedAccountingDocumentId}
							anchorEl={this.iconButtonInfo}
							onRequestClose={this.handleRequestClose}
							connection={connection}
							onConnectionChange={this.onConnectionChange}
							onConnectionBlur={this.props.onConnectionChange}
							disabled={this.props.isProcessed}
						/>
					</div>
				</div>
			)
		})
	}

	onRoundingTypeChange = (ev: Event, index: number, value: number) => {
		this.props.onRoundingTypeChange(value)
	}

	renderRoundingSelector = () => {
		const { t } = this.props
		return (
			<div className={styles.select}>
				<SelectField
					disabled={!!this.props.readonly}
					value={this.props.roundingType}
					onChange={this.onRoundingTypeChange}
					autoTestId="invoice-totals-rounding-select"
					inline
				>
					{Object.entries(ROUNDING_TYPES).map(([type, name]: any) => (
						<MenuItem
							key={type}
							value={parseInt(type)}
							primaryText={t(`invoice.settings.booking.roundingTypes.${name}`)}
						/>
					))}
				</SelectField>
			</div>
		)
	}

	renderRounding = () => {
		const { t, showRounding, currency } = this.props
		if (!showRounding) {
			return null
		}
		return (
			<div className={styles.row}>
				<div className={styles.left}>{t('invoice.totals.rounding')}</div>
				{this.renderRoundingSelector()}
				<div className={styles.right} id={'invoice-rounding'} {...autoTestId('invoiceRounding')}>
					{formatToMoney(this.props.roundingAmount, {
						currency: currency,
					})}
				</div>
			</div>
		)
	}

	render() {
		const { t, isAccDocVatFree } = this.props
		return (
			<div className={styles.root}>
				{!isAccDocVatFree && (
					<div className={styles.row}>
						<div className={styles.left}>{t('invoice.totals.totalWithVat')}</div>
						<div className={styles.right} id={'invoice-total'} {...autoTestId('invoiceTotal')}>
							{formatToMoney(this.props.total, {
								currency: this.props.currency,
							})}
						</div>
					</div>
				)}
				{this.renderConnections()}
				<div>
					{!isAccDocVatFree && (
						<div>
							<hr className={styles.hr} />
							<div className={styles.row}>
								<div className={styles.left}>{t('invoice.totals.vatBase')}</div>
								<div className={styles.right} id={'invoice-vat-base'} {...autoTestId('invoiceVatBase')}>
									{formatToMoney(this.props.totalVatExcl, {
										currency: this.props.currency,
									})}
								</div>
							</div>
							{this.props.totalVatAmount !== null && (
								<div className={styles.row}>
									<div className={styles.left}>{t('invoice.totals.vat')}</div>
									<div className={styles.right} id={'invoice-vat'} {...autoTestId('invoiceVatAmount')}>
										{formatToMoney(this.props.totalVatAmount, {
											currency: this.props.domesticCurrency,
										})}
									</div>
								</div>
							)}
						</div>
					)}
					{this.renderRounding()}
					<hr className={styles.hr} />
				</div>

				<div className={styles.row}>
					<div className={styles.left}>
						{!isAccDocVatFree ? t('invoice.totals.totalWithVat') : t('invoice.totals.totalToPay')}
					</div>
					<div className={styles.total} id={'invoice-to-pay'} {...autoTestId('invoiceToPay')}>
						{formatToMoney(this.props.total, {
							currency: this.props.currency,
						})}
					</div>
				</div>
			</div>
		)
	}
}

const style = {
	iconButton: { padding: 5 },
}

export default withTranslate(Totals)
