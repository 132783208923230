// @flow

import React, { Component } from 'react'
import memoize from 'memoize-one'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type {
	AccountingDocumentVatRecapInfo,
	AccountingDocumentVatRecapLine,
	AccountingDocumentConnection,
} from 'types'
import { VAT_RECAP_CALCULATION_MODE_MANUAL } from 'modules/accounting-document/constants'
import { isVatRecapResetButtonVisible } from 'logic/accounting-document'
import Button from 'components/button'
import VatRecapLine from './vat-recap-line'
import { EMPTY_ARRAY } from 'trivi-constants'
import styles from './vat-recap.css'

type Props = {|
	...WithTranslateProps,
	vatRecapCalculationMode: ?number,
	vatRecapLines: ?Array<AccountingDocumentVatRecapLine>,
	total: ?number,
	totalVatExcl: ?number,
	connections: ?Array<AccountingDocumentConnection>,
	accountingDocumentId: string,
	readonly?: boolean,
	isLoading?: boolean,
	isDomestic?: boolean,
	lineItemsTotal: number,
	lineItemsTotalVatExcl: number,
	isAccDocVatFree?: boolean,
	currentUserIsInternal?: boolean,
	onChange: (vatRecap: AccountingDocumentVatRecapInfo) => void,
|}

type State = {
	vatRecapLines: Array<AccountingDocumentVatRecapLine>,
	editedByUser: boolean,
	editingField: ?string,
	editingFieldIndex: number,
}

class VatRecap extends Component<Props, State> {
	state: State = {
		vatRecapLines: this.props.vatRecapLines || [],
		editedByUser:
			this.props.vatRecapCalculationMode && this.props.vatRecapCalculationMode === VAT_RECAP_CALCULATION_MODE_MANUAL
				? true
				: false,
		editingField: null,
		editingFieldIndex: -1,
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		const { editingField, editingFieldIndex, vatRecapLines } = this.state
		this.setState({
			vatRecapLines: !nextProps.vatRecapLines
				? []
				: nextProps.vatRecapLines.map((line: AccountingDocumentVatRecapLine, i: number) => {
						if (i === editingFieldIndex && editingField) {
							return {
								...line,
								[editingField]: vatRecapLines[editingFieldIndex][editingField],
							}
						}
						return { ...line }
				  }),
			editedByUser:
				nextProps.vatRecapCalculationMode && nextProps.vatRecapCalculationMode === VAT_RECAP_CALCULATION_MODE_MANUAL
					? true
					: false,
		})
	}

	onFieldChange = (field: string, value: ?number, index: number) => {
		const vatRecapLines = this.state.vatRecapLines.slice()
		if (vatRecapLines[index]) {
			vatRecapLines[index] = { ...vatRecapLines[index], [field]: value || 0 }
			this.setState({
				vatRecapLines,
				editedByUser: true,
				editingField: field,
				editingFieldIndex: index,
			})
		}
	}

	onBlur = () => {
		if (!this.state.editedByUser) return

		this.setState({
			editingField: null,
			editingFieldIndex: -1,
		})

		this.props.onChange(
			getVatRecapInfo(
				this.props.isDomestic,
				this.state.vatRecapLines,
				this.props.lineItemsTotal,
				this.props.lineItemsTotalVatExcl,
				this.props.connections,
			),
		)
	}

	onReset = () => {
		this.props.onChange({
			vatRecapCalculationMode: 0,
			total: undefined,
			totalVatExcl: undefined,
			vatRecapLines: undefined,
		})
	}

	getVatRecapLines = memoize((lines: Array<AccountingDocumentVatRecapLine>) => {
		return lines.map((item: AccountingDocumentVatRecapLine, index: number) => {
			return (
				<VatRecapLine
					key={item.vatRate}
					item={item}
					index={index}
					disabled={!!(this.props.isLoading || this.props.readonly)}
					onFieldChange={this.onFieldChange}
					onBlur={this.onBlur}
				/>
			)
		})
	})

	renderVatRecapLines = () => {
		const lines = (Array.isArray(this.props.vatRecapLines) && this.props.vatRecapLines) || EMPTY_ARRAY
		return this.getVatRecapLines(lines)
	}

	renderResetButton = () => {
		return (
			<div className={styles.reset}>
				<Button
					tertiary
					onClick={this.onReset}
					labelText={this.props.t('invoice.vatRecapitulation.reset')}
					autoTestId="invoice-vat-recap-reset"
				/>
			</div>
		)
	}

	render() {
		const { t } = this.props
		const isReadonly = this.props.readonly || false
		const isUserInternal = this.props.currentUserIsInternal || false
		const isVatFree = this.props.isAccDocVatFree || false
		const isManual = !!(
			this.props.vatRecapCalculationMode && VAT_RECAP_CALCULATION_MODE_MANUAL === this.props.vatRecapCalculationMode
		)

		const showReset = isVatRecapResetButtonVisible({ isManual, isVatFree, isReadonly, isUserInternal })

		const showTable = !this.props.isAccDocVatFree

		return (
			<div className={styles.root}>
				{(showTable || showReset) && <h3>{t('invoice.vatRecapitulation.headline')}</h3>}
				{showTable && (
					<>
						<div className={styles.head}>
							<div className={styles.vatRate}>{t('invoice.vatRecapitulation.vatRate')}</div>
							<div className={styles.base}>{t('invoice.vatRecapitulation.vatBase')}</div>
							<div className={styles.vat}>{t('invoice.vatRecapitulation.vat')}</div>
							<div className={styles.total}>{t('invoice.vatRecapitulation.total')}</div>
						</div>
						<hr className={styles.hr} />
						{this.renderVatRecapLines()}
					</>
				)}
				{showReset && this.renderResetButton()}
			</div>
		)
	}
}

export function getVatRecapInfo(
	isDomestic?: boolean,
	vatRecapLines?: ?Array<AccountingDocumentVatRecapLine>,
	lineItemsTotal?: number = 0,
	lineItemsTotalVatExcl?: number = 0,
	// eslint-disable-next-line no-unused-vars
	connections: ?Array<AccountingDocumentConnection>,
): AccountingDocumentVatRecapInfo {
	let total: number = 0
	let totalVatExcl: number = 0

	if (vatRecapLines && isDomestic) {
		total = vatRecapLines.reduce((result: number, line: AccountingDocumentVatRecapLine) => {
			return result + line.total
		}, 0)
		totalVatExcl = vatRecapLines.reduce((result: number, line: AccountingDocumentVatRecapLine) => {
			return result + line.base
		}, 0)
	}

	let connectionsTotal = 0
	// Ruční odečítání total připojené zálohové faktury:
	// connectionsTotal = (connections || []).reduce((result: number, connection: AccountingDocumentConnection) => {
	// 	// Total z připojených dokladů se má odečítat pouze u zálohových faktur
	// 	if (connection.connectedAccountingDocumentType === ACC_DOC_TYPES.advance) {
	// 		return (
	// 			result +
	// 			(connection.vatAmounts || []).reduce((result: number, line: AccountingDocumentVatRecapLine) => {
	// 				return result + line.total
	// 			}, 0)
	// 		)
	// 	} else {
	// 		return result
	// 	}
	// }, 0)

	if (!isDomestic) {
		total = lineItemsTotal
		totalVatExcl = lineItemsTotalVatExcl
	}

	return {
		vatRecapCalculationMode: VAT_RECAP_CALCULATION_MODE_MANUAL,
		total: total - connectionsTotal,
		totalVatExcl,
		vatRecapLines: vatRecapLines || undefined,
	}
}

export default withTranslate(VatRecap)
