// @flow

import { toString } from 'lodash-es'
import type { SearchFilter } from 'types'
import { Sort } from '../types'
import type { Filter } from 'modules/data-grid/types'

export function createGridMappingKey(dataKey: string, sort?: Sort, filter?: Filter): string {
	const sortString = sort ? sort.toString() : ''

	let key = `${dataKey}/`

	if (sortString) {
		key += sortString
	}

	key += '/'

	if (filter) {
		key += filterToString(filter)
	}

	return key
}

export function filterToString(filter: Filter): string {
	const sortedFilter = [...filter].sort((a: SearchFilter, b: SearchFilter) => {
		if ((a.field || '') < (b.field || '')) {
			return -1
		} else if ((b.field || '') > (a.field || '')) {
			return 1
		} else {
			return 0
		}
	})

	const filterString = sortedFilter.reduce((accumulator: string, item: SearchFilter) => {
		let str: string = ''

		if (item.field) {
			str += `${item.field || ''}-`
		}

		if (item.value) {
			str += toString(item.value)
		}

		str += '-'

		if (item.valueFrom) {
			str += toString(item.valueFrom)
		}

		str += '-'

		if (item.valueTo) {
			str += toString(item.valueTo)
		}

		str += '-'

		if (item.valueContains) {
			str += toString(item.valueContains)
		}

		str += ';'

		return accumulator + str
	}, '')
	return filterString.substr(0, filterString.length - 1)
}
