/* @flow */

import React, { Component } from 'react'
import type { AccountingDocumentIssue, AccountingDocumentIssues } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import Issue from './issue'
import styles from './issues.css'

type Props = {|
	...WithTranslateProps,
	accountingDocumentId: string,
	issuesResponse: ?AccountingDocumentIssues,
	hideTitle?: boolean,
	autoload: () => void,
|}

class Issues extends Component<Props> {
	getSeverity = (severity: ?number) => {
		if (severity) {
			if (severity >= 100 && severity < 200) return 'info'
			if (severity >= 200 && severity < 300) return 'warn'
			if (severity >= 300) return 'error'
		}
		return 'warn'
	}

	issueMapper = (issue: AccountingDocumentIssue) => {
		return (
			<Issue key={`${issue.code || ''}${issue.accountingDocumentId || ''}`} severity={this.getSeverity(issue.severity)}>
				{this.props.t(['serverError.' + (issue.code || ''), issue.message || ''], issue.params)}
			</Issue>
		)
	}

	render() {
		const { issuesResponse, t } = this.props

		if (!issuesResponse) return null

		const connectedIssues = issuesResponse.connectedIssues || []
		const issues = issuesResponse.issues || []

		if (!(connectedIssues.length + issues.length)) return null

		return (
			<div className={styles.root}>
				{issues.length > 0 && (
					<div className={styles.section}>
						{!this.props.hideTitle && (
							<div className={styles.heading}>{t('accountingDocument.issues.issues-header')}</div>
						)}
						<div>{issues.map(this.issueMapper)}</div>
					</div>
				)}

				{connectedIssues.length > 0 && (
					<div className={styles.section}>
						{!this.props.hideTitle && (
							<div className={styles.heading}>{t('accountingDocument.issues.connected-issues-header')}</div>
						)}
						<div className={styles.issues}>{connectedIssues.map(this.issueMapper)}</div>
					</div>
				)}
			</div>
		)
	}
}
export default withTranslate(Issues)
