/* @flow */

import type {
	AccountingDocument,
	AccountingDocumentBankAccount,
	AccountingDocumentContact,
	AccountingDocumentDefaults,
	AccountingDocumentHeader,
	AccountingDocumentLineItem,
	AccountingDocumentNote,
	AccountingDocumentVatRecapLine,
	CashRegister,
	Contact,
	FinancialAccount,
	OrganizationAddress,
	Scan,
	ScanFile,
	Template,
	TemplateScheduler,
	Sequence,
	InternalUser,
	AccountingDocumentCategory,
	BankAccount,
	ContactAddress,
	ContactPerson,
	ContactBankAccount,
	SequencesData,
} from 'types'

import { getTodayIsoDateString, getTomorrowIsoDateString } from 'utils/formatters'

export const DEFAULT_EET_SETTINGS_PLACE: number = 1

type EmptyAccDoParams = {|
	vatCountryType?: number,
|}

export const emptyAccountingDocument = (params?: EmptyAccDoParams): AccountingDocument => {
	return {
		id: undefined,
		currency: 'CZK', //TODO - nejaka defaultni?
		vatCountryType: (params && params.vatCountryType) || 1, //TODO - jakej typ na defaultní hodnotu?
	}
}

export const emptyAccountingDocumentDefaults = (): AccountingDocumentDefaults => {
	return {
		currency: undefined,
	}
}

export const emptyFinancialAccount = (): FinancialAccount => {
	return {
		no: '',
		name: '',
	}
}

export const emptyAccountingDocumentContact = (): AccountingDocumentContact => {
	return Object.freeze({})
}

export const emptyAccountingDocumentLineItem = (): AccountingDocumentLineItem => {
	return {
		description: '',
		// vatRate: 0,
		unitPrice: 0,
		unitName: 'ks',
		qt: 1,
		// vatRateType: 1,
	}
}

export const emptyAccountingDocumentVatRecapLine = (): AccountingDocumentVatRecapLine => {
	return {
		vatRate: 1,
	}
}

export const emptyOrganizationAddress = (): OrganizationAddress => {
	return {
		street: '',
		city: '',
		zip: '',
		country: '',
	}
}

export const emptyAccountingDocumentBankAccount = (): AccountingDocumentBankAccount => {
	return {
		bankCode: '',
	}
}

export const emptyAccountingDocumentHeader = (): AccountingDocumentHeader => {
	return {
		issueDate: undefined,
	}
}

export const emptyTemplate = (): Template => {
	return {
		id: undefined,
	}
}

export const emptyContact = (): Contact => {
	return {
		id: undefined,
		// bankAccounts: [],
		// persons: [],
		// addresses: [],
	}
}

export const emptyTemplateScheduler = (): TemplateScheduler => {
	return {
		start: getTomorrowIsoDateString(),
		frequency: 3,
		useWorkflow: false,
	}
}

export const emptyAccountingDocumentNote = (): AccountingDocumentNote => {
	return {
		authorUniqueId: '',
		createdTime: getTodayIsoDateString(),
		updatedTime: getTodayIsoDateString(),
		type: 1,
	}
}

export const emptyScanWithFile = (scanId: number, scanFile: ScanFile, documentCategory: ?string): Scan => {
	return {
		id: scanId,
		documentCategory: documentCategory,
		files: [scanFile],
		branch: '',
		project: '',
		paymentType: '',
		cashRegisterId: '',
		customerInstructions: '',
		checked: false,
	}
}

export const emptyCashRegister = (): CashRegister => {
	return {
		name: '',
		currency: '',
		ownerUserId: 0,
	}
}

export const emptySequence = (): Sequence => {
	return {
		name: '',
		format: '',
		accountingDocumentType: 1,
		accountingDocumentDirection: 1,
		validFrom: '',
	}
}

export const emptyInternalUser = (): InternalUser => {
	return {
		id: undefined,
	}
}

export const emptyAccountingDocumentCategory = (): AccountingDocumentCategory => {
	return {
		id: undefined,
		name: '',
		originCountry: 'CZ',
	}
}

export const emptyBankAccount = (): BankAccount => {
	return {
		currency: '',
		accountNo: '',
		bankId: '',
	}
}

export const emptyContactAddress = (type?: 1 | 2): ContactAddress => {
	return {
		type: type || 1,
	}
}

export const emptyContactPerson = (): ContactPerson => {
	return {
		id: '',
	}
}

export const emptyContactBankAccount = (): ContactBankAccount => {
	return {
		name: '',
	}
}

export const emptySequencesData: SequencesData = {
	issued: {},
	received: {},
	unknown: {},
}
