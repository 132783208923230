// @flow

import React, { Component } from 'react'
import { includes as arrayIncludes } from 'lodash-es'
import type {
	AccountingDocument,
	AccountingDocumentLanguage,
	AccountingDocumentLineItem,
	FinancialAccount,
	GreenboxSuggestionResponse,
	Enum,
	FormFieldErrorContext,
	FormFieldErrorMessage,
	VatRate,
} from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import LineItem from '../../containers/invoice-elements/line-item'
import { emptyAccountingDocumentLineItem } from 'types/empty'
import TextField from 'components/TextField'
import styles from './line-items.css'
import type { ValidationMessageType, ValidationValueType } from 'wrappers/validate'
import { EMPTY_ARRAY, EMPTY_FUNCTION } from 'trivi-constants'
import { addSubPath, getTooltipErrorLabel } from 'helpers'
import AddLineItem from './add-line-item'
type Props = {|
	...WithTranslateProps,
	lineItems: Array<AccountingDocumentLineItem>,
	public?: boolean,
	readonly?: boolean,
	financialAccounts: Array<FinancialAccount>,
	favoriteFinancialAccountIds: Array<string>,
	favoriteLineItemIds: Array<string>,
	greenboxSuggestion: ?GreenboxSuggestionResponse,
	calculatingLineItemIds: Array<string>,
	availableProjects: Enum,
	availableBranches: Enum,
	currency?: string,
	language?: ?AccountingDocumentLanguage,
	lineItemsHeader?: ?string,
	accountingDocument?: ?AccountingDocument,
	lineItemsProcessing: boolean,
	addingLineItem?: boolean,
	isAccDocVatFree?: boolean,
	defaultLineItems?: Array<AccountingDocumentLineItem> | null,
	accountingDocumentId?: ?string,
	vatRates: null | Array<VatRate>,
	loadDefaultLineItems?: () => void,
	loadFavoriteLineItems?: () => void,
	loadFavoriteFinancialAccounts?: () => void,
	loadAccountingDocumentVatRates: (accountingDocumentId: string) => void,
	onLineItemsHeaderChange?: (lineItemsHeader: ?string, accountingDocument: ?AccountingDocument) => any,
	onLineItemFavorite: (id: string, isFavorite: boolean) => void,
	onFinancialAccountFavorite: (no: string, isFavorite: boolean) => void,
	onLineItemCreate: () => void,
	onLineItemRemove: (lineItem: AccountingDocumentLineItem, index: number) => void,
	onLineItemClone: (lineItem: AccountingDocumentLineItem) => void,
	onLineItemChange: (
		lineItem: AccountingDocumentLineItem,
		autocalc: boolean,
		index: number,
		accountingDocument: ?AccountingDocument,
	) => any,
	validationMessage?: ValidationMessageType,
	validationValue?: ValidationValueType,
	formFieldErrorContext?: FormFieldErrorContext,
	errors: ?Array<FormFieldErrorMessage>,
|}

type State = {|
	lineItemsHeader: ?string,
	expandedItemIndex: ?number,
|}

class LineItems extends Component<Props, State> {
	state: State = {
		lineItemsHeader: this.props.lineItemsHeader,
		expandedItemIndex: null,
	}

	componentDidMount() {
		if (!this.props.public) {
			this.props.loadFavoriteFinancialAccounts && this.props.loadFavoriteFinancialAccounts()
			this.props.loadFavoriteLineItems && this.props.loadFavoriteLineItems()
			this.props.loadDefaultLineItems && !this.props.defaultLineItems && this.props.loadDefaultLineItems()
			this.props.accountingDocumentId && this.props.loadAccountingDocumentVatRates(this.props.accountingDocumentId)
		}
	}

	UNSAFE_componentWillReceiveProps(newProps: Props) {
		this.setState({ lineItemsHeader: newProps.lineItemsHeader })
	}

	// Cached lineItem functions
	onChangeFns: { [itemIndex: number]: (lineItem: AccountingDocumentLineItem, autocalc: boolean) => void } = {}
	onRemoveFns: { [itemIndex: number]: () => void } = {}
	onCloneFns: { [itemIndex: number]: () => void } = {}

	getOnChange = (index: number): ((value: AccountingDocumentLineItem, autocalc: boolean) => void) => {
		if (!this.onChangeFns[index]) {
			this.onChangeFns[index] = (lineItem: AccountingDocumentLineItem, autocalc: boolean) => {
				this.props.onLineItemChange(lineItem, autocalc, index, this.props.accountingDocument)
			}
		}
		return this.onChangeFns[index]
	}

	getOnRemove = (index: number): (() => void) => {
		if (!this.onRemoveFns[index]) {
			this.onRemoveFns[index] = () => {
				this.props.onLineItemRemove(this.props.lineItems[index], index)
			}
		}
		return this.onRemoveFns[index]
	}

	getOnClone = (index: number): (() => void) => {
		if (!this.onCloneFns[index]) {
			this.onCloneFns[index] = () => {
				this.props.onLineItemClone(this.props.lineItems[index])
			}
		}
		return this.onCloneFns[index]
	}

	onHeaderChange = (event: SyntheticInputEvent<HTMLInputElement>, lineItemsHeader: ?string) => {
		this.setState({ lineItemsHeader })
	}

	onHeaderBlur = () => {
		if (this.state.lineItemsHeader != this.props.lineItemsHeader)
			this.props.onLineItemsHeaderChange &&
				this.props.onLineItemsHeaderChange(this.state.lineItemsHeader, this.props.accountingDocument)
	}

	onExpand = (expandedItemIndex: number) => {
		this.setState({ expandedItemIndex })
	}

	onExpandClose = () => {
		this.setState({ expandedItemIndex: null })
	}

	renderAddingLineItem = () => {
		if (!this.props.addingLineItem) {
			return null
		}
		return (
			<LineItem
				isCreating
				readonly
				value={emptyAccountingDocumentLineItem()}
				onChange={EMPTY_FUNCTION}
				onRemove={EMPTY_FUNCTION}
				onClone={EMPTY_FUNCTION}
				onExpand={EMPTY_FUNCTION}
				onExpandClose={EMPTY_FUNCTION}
				financialAccounts={EMPTY_ARRAY}
				onFinancialAccountFavorite={EMPTY_FUNCTION}
				onLineItemFavorite={EMPTY_FUNCTION}
				favoriteFinancialAccountIds={EMPTY_ARRAY}
				favoriteLineItemIds={EMPTY_ARRAY}
				greenboxSuggestion={null}
				availableProjects={EMPTY_ARRAY}
				availableBranches={EMPTY_ARRAY}
				isCalculating={false}
				currency={null}
				isAccDocVatFree={!!this.props.isAccDocVatFree}
				direction={null}
				index={0}
				validationMessage={this.props.validationMessage}
				validationValue={this.props.validationValue}
				isExpanded={false}
				vatRates={null}
			/>
		)
	}

	renderLineItems = () => {
		return (
			Array.isArray(this.props.lineItems) &&
			this.props.lineItems.map((item: AccountingDocumentLineItem, index: number) => {
				let formFieldErrorContext = addSubPath(this.props.formFieldErrorContext, item.id || `@${index}`)
				return (
					<div key={`${index}/${this.props.lineItems.length}`}>
						<LineItem
							index={index}
							value={item}
							onChange={this.getOnChange(index)}
							onRemove={this.getOnRemove(index)}
							onClone={this.getOnClone(index)}
							onExpand={this.onExpand}
							onExpandClose={this.onExpandClose}
							readonly={!!this.props.readonly}
							public={!!this.props.public}
							financialAccounts={this.props.financialAccounts}
							onLineItemFavorite={this.props.onLineItemFavorite}
							onFinancialAccountFavorite={this.props.onFinancialAccountFavorite}
							favoriteFinancialAccountIds={this.props.favoriteFinancialAccountIds}
							favoriteLineItemIds={this.props.favoriteLineItemIds}
							greenboxSuggestion={this.props.greenboxSuggestion}
							defaultLineItems={this.props.defaultLineItems}
							availableProjects={this.props.availableProjects}
							availableBranches={this.props.availableBranches}
							isCalculating={arrayIncludes(this.props.calculatingLineItemIds, item.id || '')}
							currency={this.props.currency}
							direction={this.props.accountingDocument && this.props.accountingDocument.direction}
							isAccDocVatFree={!!this.props.isAccDocVatFree}
							validationMessage={this.props.validationMessage}
							validationValue={this.props.validationValue}
							isExpanded={this.state.expandedItemIndex === index}
							formFieldErrorContext={formFieldErrorContext}
							accountingDocumentId={this.props.accountingDocumentId}
							accountingDocument={this.props.accountingDocument}
							vatRates={this.props.vatRates}
						/>
					</div>
				)
			})
		)
	}

	render() {
		const { t, readonly, onLineItemCreate, isAccDocVatFree, errors } = this.props
		return (
			<div className={isAccDocVatFree ? styles.vatFree : styles.normal} id={'line-items'} style={style.lineItems}>
				{!readonly || (readonly && this.state.lineItemsHeader) ? (
					<h3>
						<TextField
							inline
							fullWidth
							autoHeight
							multiLine
							value={this.state.lineItemsHeader}
							hintText={t('invoice.lineItems.headlineHint')}
							disabled={readonly}
							onChange={this.onHeaderChange}
							onBlur={this.onHeaderBlur}
							style={style.textField}
							name="line-items-header"
							autoTestId="line-items-header"
						/>
					</h3>
				) : (
					<div style={style.spacer} />
				)}
				<div className={styles.head}>
					<div className={styles.item}>{t('invoice.lineItems.item')}</div>
					<div className={styles.price}>{t('invoice.lineItems.unitPrice')}</div>
					{!isAccDocVatFree && <div className={styles.vatRate}>{t('invoice.lineItems.vat')}</div>}
					<div className={styles.quantity}>{t('invoice.lineItems.amount')}</div>
					<div className={styles.unit}>{t('invoice.lineItems.unit')}</div>
					<div className={styles.total}>{t('invoice.lineItems.total')}</div>
				</div>
				<hr className={styles.hr} />
				{this.renderLineItems()}
				{this.renderAddingLineItem()}
				{!readonly && <AddLineItem onClick={onLineItemCreate} error={getTooltipErrorLabel(errors, t)} />}
			</div>
		)
	}
}

const style = {
	lineItems: {
		position: 'relative',
	},
	textField: {
		minHeight: 24,
		lineHeight: '22px',
	},
	spacer: { height: 30 },
	new: {
		marginRight: 8,
		top: -1,
		position: 'relative',
	},
}

export default withTranslate(LineItems)
