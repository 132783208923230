/* @flow */

import { loadBase64Data, createXHR } from 'lib/apiHandler'
import type {
	AccessToken,
	ActionFunction,
	AvatarSize,
	BootstrapResponse,
	CommonAction,
	Dispatch,
	InternalUser,
	InvitationProcessResponse,
	OrganizationMember,
	SignUpRequest,
	State,
	UserAction,
	ContactMeAction,
	PortalLanguage,
	UserProfile,
	TermsItemResponse,
	OrganizationAction,
	Enumeration,
	SequencesAction,
	Sequence,
	OrganizationSettingsOpen,
	SettingsAction,
	BootstrapAction,
	BankAction,
	BankAccount,
} from 'types'
import { beginTask, endTask } from 'utils/loader'
import {
	bootstrap as bootstrapApi,
	czAres as czAresApi,
	internalUser as internalUserApi,
	internalUsers as internalUsersApi,
	internalUsersSignup as internalUsersSignupApi,
	me as meApi,
	myAvatar as myAvatarApi,
	myAvatarCrop as myAvatarCropApi,
	organizations as organizationsApi,
	processInvitation as processInvitationApi,
	resetPassword as resetPasswordApi,
	signUp as signUpApi,
	terms as termsApi,
} from 'modules/common/models/api-model'
import { hideSplashscreen, showSplashscreen } from 'utils/splashscreen'
import type { FinishLoadingAresContacts } from '../actions/action-types'
import type { InternalUsersSignupPostResponse } from 'modules/common/models/api-model'

import diff from 'json-patch-gen'
import { getLoginRoute } from 'modules/user/routing/routes'
import { getMyProfile, isInternalUsersLoading } from 'modules/user/selectors'
import { push } from 'react-router-redux'
import { setAppLocale, setAppLanguage } from 'locales'
import { token as tokenApi } from 'modules/common/models/auth-api-model'
import storage from 'utils/local-storage'
import { clearLastFetchedTimes } from 'utils/fetch-throttle'
import { getUserEnabledFeaturesAndPermissions, loadFeaturesAndPermissionsFromStorage } from '../domain/features'
import { AVAILABLE_FEATURES, AVAILABLE_PERMISSIONS } from 'utils/features'
import { loadAllVatRates } from 'modules/accounting-document/actions'
import { loadCashRegisters } from 'modules/cash-register/actions'
import { isTimeWithinLastMiliseconds } from 'modules/user/components/change-lang-for-not-logged-in'
import { loadOrganizationSettingsOss } from 'modules/settings/actions'
import Tracking from 'utils/tracking'

import { toggleForm as toggleContactMeForm } from 'modules/contact-me/actions'
import { EMPTY_ARRAY, VISITOR_LANGUAGE_NAME, VISITOR_LANGUAGE_MODIFICATION_TIME_NAME } from 'trivi-constants'
import { loadUsers } from 'modules/organization/actions'

export function removeInternalUser(userId: string): ActionFunction<UserAction> {
	return async (dispatch: Dispatch<UserAction>) => {
		dispatch({
			type: 'START_INTERNAL_USER_DELETE',
		})
		try {
			await internalUserApi.delete({ userId })
			return dispatch({
				type: 'FINISH_INTERNAL_USER_DELETE',
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_INTERNAL_USER_DELETE',
				serverError,
			})
		}
	}
}

export function createInternalUser(body: InternalUser): ActionFunction<UserAction> {
	return async (dispatch: Dispatch<UserAction>) => {
		dispatch({
			type: 'START_INTERNAL_CREATE',
			body,
		})
		try {
			const response: InternalUser = await internalUsersApi.post(body)
			return dispatch({
				type: 'FINISH_INTERNAL_CREATE',
				response,
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_INTERNAL_CREATE',
				response: null,
				serverError,
			})
		}
	}
}

export function signupInternalUser(body: SignUpRequest): ActionFunction<UserAction> {
	return async (dispatch: Dispatch<UserAction>) => {
		dispatch({
			type: 'START_INTERNAL_SIGNUP',
		})
		try {
			const response: InternalUsersSignupPostResponse = await internalUsersSignupApi.post(body)
			return dispatch({
				type: 'FINISH_INTERNAL_SIGNUP',
				response,
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_INTERNAL_SIGNUP',
				response: null,
				serverError,
			})
		}
	}
}

export function login(username: string, token: AccessToken): UserAction {
	storage.setAccessToken(token)
	return {
		type: 'LOGIN',
		username,
		token,
	}
}

export function logout(): ActionFunction<UserAction> {
	return async (dispatch: Dispatch<UserAction>) => {
		storage.removeAccessToken()
		clearLastFetchedTimes()
		window.Raven && window.Raven.setUserContext()
		const result = dispatch({
			type: 'LOGOUT',
		})

		result.then(() => {
			Tracking.logout()
		})

		return result
	}
}

export function refreshToken(refreshToken: string): ActionFunction<UserAction> {
	return async (dispatch: Dispatch<UserAction>): Promise<?UserAction> => {
		try {
			const result = await tokenApi.post({
				refreshToken: refreshToken,
			})
			storage.setAccessToken(result)
			return dispatch({
				type: 'REFRESH_TOKEN',
				token: result,
			})
		} catch (e) {
			dispatch(logout())
		}
	}
}

export function loadBootstrap(organizationId: ?string) {
	return async (dispatch: Dispatch<BootstrapAction>): Promise<UserAction> => {
		showSplashscreen()
		dispatch({
			type: 'START_LOAD_BOOTSTRAP',
		})
		try {
			// $FlowFixMe Čeká na opravu Swaggeru
			const data: BootstrapResponse = await bootstrapApi.get(organizationId ? { organizationId } : {})
			const userProfile = data.userProfile

			if (userProfile) {
				window.Raven &&
					window.Raven.setUserContext({
						id: userProfile.id,
						email: userProfile.email,
					})

				const loginScreenLanguage = sessionStorage.getItem(VISITOR_LANGUAGE_NAME)
				const loginScreenLanguageChanged = isTimeWithinLastMiliseconds(
					sessionStorage.getItem(VISITOR_LANGUAGE_MODIFICATION_TIME_NAME),
				)
				const hasUserLanguageChanged =
					loginScreenLanguageChanged && loginScreenLanguage && userProfile.language !== loginScreenLanguage

				if (!hasUserLanguageChanged) {
					setAppLanguage(userProfile.language)
				}
				setAppLocale(userProfile.locale)
			}

			const returnedOrganizationId = (data.organization && data.organization.id) || null

			if (returnedOrganizationId) {
				dispatch(changeCurrentOrganization(returnedOrganizationId))
			} else {
				dispatch(changeCurrentOrganization())
			}

			const loadUsersPromise = dispatch(loadUsers())
			// $FlowFixMe https://triviit.atlassian.net/browse/TWU-2593
			dispatch(setBankAccounts(data.bankAccountsInfoOpen && data.bankAccountsInfoOpen.bankAccounts))
			dispatch(setProjects(data.projects))
			dispatch(setBranches(data.branches))
			dispatch(setSequences(data.sequences))
			dispatch(setOrganizationSettingsOpen(data.organizationSettingsOpen))
			dispatch(loadAllVatRates())
			dispatch(loadCashRegisters())
			dispatch(loadOrganizationSettingsOss())

			const featuresAndPermissionsFromStorage: {
				[key: string]: Array<string>,
			} = loadFeaturesAndPermissionsFromStorage()

			dispatch({
				type: 'LOAD_FEATURES_AND_TEST_PERMISSIONS',
				payload: getUserEnabledFeaturesAndPermissions(
					data.featureFlags,
					featuresAndPermissionsFromStorage,
					AVAILABLE_FEATURES,
					AVAILABLE_PERMISSIONS,
				),
			})

			const finishBootstrapPromise = dispatch({
				type: 'FINISH_LOAD_BOOTSTRAP',
				data,
			})

			Promise.all([loadUsersPromise, finishBootstrapPromise]).then(() => {
				Tracking.setUserInfoReady()
			})

			return finishBootstrapPromise
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_LOAD_BOOTSTRAP',
				serverError,
			})
		} finally {
			hideSplashscreen()
		}
	}
}

function setBankAccounts(bankAccounts?: Array<BankAccount>): ActionFunction<BankAction> {
	return async (dispatch: Dispatch<BankAction>) => {
		dispatch({ type: 'FINISH_LOAD_BANK_ACCOUNTS', bankAccounts: bankAccounts || EMPTY_ARRAY })
	}
}

function setProjects(projects?: Array<Enumeration>): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>) => {
		dispatch({ type: 'FINISH_LOAD_PROJECTS', projects })
	}
}

function setBranches(branches?: Array<Enumeration>): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>) => {
		dispatch({ type: 'FINISH_LOAD_BRANCHES', branches })
	}
}

function setSequences(sequences?: Array<Sequence>): ActionFunction<SequencesAction> {
	return async (dispatch: Dispatch<SequencesAction>) => {
		dispatch({ type: 'FINISH_LOAD_SEQUENCES', sequences })
	}
}

export function setOrganizationSettingsOpen(
	organizationSettingsOpen: ?OrganizationSettingsOpen,
): ActionFunction<SettingsAction> {
	return async (dispatch: Dispatch<SettingsAction>) => {
		dispatch({ type: 'FINISH_LOADING_ORGANIZATION_SETTINGS_OPEN', organizationSettingsOpen })
	}
}

export function loadMe(): ActionFunction<UserAction> {
	return async (dispatch: Dispatch<UserAction>) => {
		dispatch({
			type: 'START_LOAD_ME',
		})
		try {
			const me: UserProfile = await meApi.get({})
			setAppLanguage(me.language)
			setAppLocale(me.locale)
			return dispatch({
				type: 'FINISH_LOAD_ME',
				me: me,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_LOAD_ME',
				me: null,
				serverError: error,
			})
		}
	}
}

export function updateMe(me: UserProfile): ActionFunction<UserAction> {
	return async (dispatch: Dispatch<UserAction>, getState: () => State) => {
		const state: State = getState()
		const oldMe: ?UserProfile = getMyProfile(state)
		if (!oldMe) {
			return
		}

		const patch = diff(oldMe, me)

		dispatch({
			type: 'START_UPDATING_ME',
			newMe: me,
		})

		try {
			const result: UserProfile = await meApi.patch(patch)
			setAppLanguage(result.language)
			setAppLocale(result.locale)
			return dispatch({
				type: 'FINISH_UPDATING_ME',
				newMe: result,
				oldMe,
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_UPDATING_ME',
				oldMe,
				newMe: null,
				serverError,
			})
		}
	}
}

export function updateMyLanguage(language: ?PortalLanguage): ActionFunction<UserAction> {
	return async (dispatch: Dispatch<UserAction>, getState: () => State) => {
		const state: State = getState()
		const oldMe: ?UserProfile = getMyProfile(state)
		if (!oldMe) {
			return
		}
		const newMe: UserProfile = Object.freeze({
			...oldMe,
			language: language || undefined,
		})
		return dispatch(updateMe(newMe))
	}
}

export function loadAllMyAvatars() {
	return async (dispatch: Dispatch<UserAction>) => {
		dispatch(loadMyAvatar('40', true))
		dispatch(loadMyAvatar('80', true))
		dispatch(loadMyAvatar('100', true))
		dispatch(loadMyAvatar('200', true))
		dispatch(loadMyAvatar('40', false))
		dispatch(loadMyAvatar('80', false))
		dispatch(loadMyAvatar('100', false))
		dispatch(loadMyAvatar('200', false))
	}
}

let loading = {}
const getLoadKey = (size: AvatarSize, original: boolean): string => {
	return '' + size + (original ? 'true' : 'false')
}

export function loadMyAvatar(size: AvatarSize, original: boolean) {
	return async (dispatch: Dispatch<UserAction>) => {
		const loadKey = getLoadKey(size, original)
		if (loading[loadKey] === true) return
		loading[loadKey] = true

		dispatch({
			type: 'START_LOADING_MY_AVATAR',
		})

		const path = !original ? 'users/me/avatar' : 'users/me/avatar/original'
		const params = { width: size, height: size }

		loadBase64Data(path, true, params)
			.then((avatar: string) => {
				loading[loadKey] = false
				dispatch({
					type: 'FINISH_LOADING_MY_AVATAR',
					original,
					avatar,
					size,
				})
			})
			.catch(() => {
				loading[loadKey] = true
				dispatch({
					type: 'FINISH_LOADING_MY_AVATAR',
					original,
					size,
				})
			})
	}
}

export function deleteMyAvatar() {
	return async (dispatch: Dispatch<UserAction>, getState: () => State) => {
		const state: State = getState()
		const oldAvatarSet = state.user.me.avatar

		dispatch({
			type: 'START_DELETING_MY_AVATAR',
		})

		try {
			await myAvatarApi.delete()
			return dispatch({
				type: 'FINISH_DELETING_MY_AVATAR',
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_DELETING_MY_AVATAR',
				oldAvatarSet,
				serverError,
			})
		}
	}
}

export function cropMyAvatar(left: number, top: number, height: number, width: number) {
	return async (dispatch: Dispatch<UserAction>) => {
		dispatch({
			type: 'START_CROPPING_MY_AVATAR',
		})

		left = parseInt(left)
		top = parseInt(top)
		height = parseInt(height)
		width = parseInt(width)

		try {
			await myAvatarCropApi.put({ left, top, height, width })
			await dispatch(loadAllMyAvatars())
			return dispatch({
				type: 'FINISH_CROPPING_MY_AVATAR',
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_CROPPING_MY_AVATAR',
				serverError,
			})
		}
	}
}

export function changeMyAvatar(file: File, localFilePath: string) {
	return async (dispatch: Dispatch<UserAction | CommonAction>) => {
		let upload = (resolve: Function, reject: Function) => {
			let formData = new FormData()
			formData.append('attachment', file)

			let xhr = createXHR('users/me/avatar', 'PUT', true)
			xhr.onload = (response: ProgressEvent) => {
				if (xhr.status === 200) {
					dispatch({
						type: 'FINISH_CHANGING_MY_AVATAR',
						avatar: localFilePath,
					})
					resolve(response)
				} else {
					dispatch({
						type: 'FINISH_CHANGING_MY_AVATAR',
					})
					dispatch({
						type: 'SET_NOTIFICATION',
						notification: {
							message: 'clientError.genericError',
							type: 'error',
						},
					})
					reject(null)
				}
			}
			xhr.send(formData)
		}
		return new Promise(upload)
	}
}

let loadingOrganizations = false
export function loadOrganizations(): ActionFunction<?UserAction> {
	return async (dispatch: Dispatch<UserAction>): Promise<?UserAction> => {
		let returnAction
		if (loadingOrganizations) {
			return
		}
		loadingOrganizations = true
		dispatch({
			type: 'START_LOAD_ORGANIZATIONS',
		})
		try {
			const organizations: Array<OrganizationMember> = await organizationsApi.get({})
			returnAction = dispatch({
				type: 'FINISH_LOAD_ORGANIZATIONS',
				organizations: organizations.map((organization: OrganizationMember) => {
					return {
						...organization,
						organizationId: (organization.organizationId || '').toString(),
					}
				}),
			})
		} catch (error) {
			returnAction = dispatch({
				type: 'FINISH_LOAD_ORGANIZATIONS',
				organizations: undefined,
				serverError: error,
			})
		} finally {
			loadingOrganizations = false
		}
		return returnAction
	}
}

export function changeCurrentOrganization(organizationId?: string): ActionFunction<UserAction> {
	return async (dispatch: Dispatch<UserAction>) => {
		let id: ?string = organizationId
		if (organizationId != null) {
			storage.set('organizationId', organizationId)
		} else {
			storage.remove('organizationId')
			id = null
		}
		return dispatch({
			type: 'CHANGE_CURRENT_ORGANIZATION',
			organizationId: id,
		})
	}
}

export function resetPassword(email: string): ActionFunction<UserAction | CommonAction> {
	return async (dispatch: Dispatch<UserAction | CommonAction>) => {
		dispatch({ type: 'START_CHANGING_PASSWORD' })
		try {
			await resetPasswordApi.post({ email })
			dispatch({
				type: 'FINISH_CHANGING_PASSWORD',
				complete: false,
			})
			dispatch({
				type: 'SET_NOTIFICATION',
				notification: {
					message: 'user.login.changeResetPassword',
					type: 'success',
				},
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_CHANGING_PASSWORD',
				complete: false,
				validationError: {
					...error,
					code: 'api0025',
					params: { fieldName: 'email' },
				},
			})
		}
	}
}

export function changePassword(newPassword: string, token: string): ActionFunction<UserAction> {
	return async (dispatch: Dispatch<UserAction>) => {
		dispatch({ type: 'START_CHANGING_PASSWORD' })
		try {
			await resetPasswordApi.put({ newPassword, token })
			dispatch(push(getLoginRoute()))
			dispatch({
				type: 'FINISH_CHANGING_PASSWORD',
				complete: true,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_CHANGING_PASSWORD',
				complete: false,
				serverError,
			})
		}
	}
}

export function authenticate(username: string, password: string) {
	return async (dispatch: Dispatch<UserAction | ContactMeAction>) => {
		dispatch({
			type: 'START_AUTHENTICATE',
		})

		const loaderId = 'authenticate'
		Tracking.authenticate()

		try {
			beginTask(loaderId)
			const token = await tokenApi.post({
				username,
				password,
			})
			dispatch({
				type: 'FINISH_AUTHENTICATE',
			})

			const organizationId: ?string = storage.get('organizationId')

			if (organizationId) {
				dispatch(changeCurrentOrganization(organizationId))
			}
			return dispatch(login(username, token)).then(() => {
				dispatch(toggleContactMeForm(true))
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_AUTHENTICATE',
				serverError,
			})
		} finally {
			endTask(loaderId)
		}
	}
}

export function switchSplashScreen(visible: boolean): UserAction {
	return {
		type: 'SWITCH_SPLASH_SCREEN',
		payload: visible,
	}
}

export function signUp(
	firstName: string,
	lastName: string,
	email: string,
	password?: string,
	invitationToken?: string,
): ActionFunction<UserAction> {
	return async (dispatch: Dispatch<UserAction>) => {
		dispatch({
			type: 'START_SIGN_UP',
		})

		try {
			const params =
				invitationToken && password
					? {
							firstName,
							lastName,
							invitationToken,
							password,
					  }
					: {
							firstName,
							lastName,
							email,
					  }

			await signUpApi.post(params)

			return dispatch({
				type: 'FINISH_SIGN_UP',
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_SIGN_UP',
				serverError,
			})
		}
	}
}

export function loadAresContacts(companyRegNo: string): ActionFunction<UserAction> {
	return async (dispatch: Dispatch<UserAction>): Promise<FinishLoadingAresContacts> => {
		dispatch({ type: 'START_LOADING_ARES_CONTACTS' })
		try {
			const resp = await czAresApi.post({ companyRegNo })
			return dispatch({
				type: 'FINISH_LOADING_ARES_CONTACTS',
				aresContacts: resp.aresContacts,
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_LOADING_ARES_CONTACTS',
				serverError,
				aresContacts: null,
			})
		}
	}
}

export function clearAresContacts() {
	return {
		type: 'CLEAR_ARES_CONTACTS',
	}
}

export function editInternalUser(userId: string, user: InternalUser): ActionFunction<UserAction> {
	return async (dispatch: Dispatch<UserAction>) => {
		dispatch({ type: 'START_EDITING_INTERNAL_USER' })
		try {
			const internalUser: InternalUser = await internalUserApi.put({ userId }, user)
			return dispatch({
				type: 'FINISH_EDITING_INTERNAL_USER',
				internalUser,
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_EDITING_INTERNAL_USER',
				serverError,
				internalUser: null,
			})
		}
	}
}

async function doLoadInternalUsers(dispatch: Dispatch<UserAction>) {
	try {
		const internalUsers: Array<InternalUser> = await internalUsersApi.get({})
		dispatch({
			type: 'FINISH_LOAD_INTERNAL_USERS',
			internalUsers,
		})
	} catch (error) {
		dispatch({
			type: 'FINISH_LOAD_INTERNAL_USERS',
			internalUsers: null,
			serverError: error,
		})
	}
}
let loadInternalUsersPromise = Promise.resolve()
export function loadInternalUsers(): ActionFunction<UserAction> {
	return async (dispatch: Dispatch<UserAction>, getState: () => State) => {
		if (isInternalUsersLoading(getState())) {
			return loadInternalUsersPromise
		}

		dispatch({
			type: 'START_LOAD_INTERNAL_USERS',
		})
		loadInternalUsersPromise = doLoadInternalUsers(dispatch)
		return loadInternalUsersPromise
	}
}

export function processInvitation(token: string): ActionFunction<UserAction> {
	return async (dispatch: Dispatch<UserAction>) => {
		dispatch({
			type: 'START_PROCESS_INVITATION',
		})
		try {
			const response: InvitationProcessResponse = await processInvitationApi.put({ token }, { acceptanceType: 1 })
			return dispatch({
				type: 'FINISH_PROCESS_INVITATION',
				response,
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_PROCESS_INVITATION',
				serverError: { code: 'custom0002', message: '' },
			})
		}
	}
}

export function authenticateAndProcessInvitation(username: string, password: string, token: string) {
	return async (dispatch: Dispatch<UserAction | ContactMeAction>) => {
		dispatch({
			type: 'START_PROCESS_INVITATION',
		})
		await dispatch(authenticate(username, password))
		try {
			const response: InvitationProcessResponse = await processInvitationApi.put({ token }, { acceptanceType: 1 })
			response && dispatch(changeCurrentOrganization(response.organizationId))
			return dispatch({
				type: 'FINISH_PROCESS_INVITATION',
				response,
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_PROCESS_INVITATION',
				serverError,
			})
		}
	}
}

export function agreeWithTerms(terms: TermsItemResponse) {
	return async (dispatch: Dispatch<UserAction>) => {
		dispatch({
			type: 'UPDATE_TERMS_START',
		})
		try {
			if (terms.termsUniqueId) {
				await termsApi.put({
					termsUniqueId: terms.termsUniqueId,
				})
				dispatch({
					type: 'UPDATE_TERMS_FINISH',
					payload: null,
				})
			}
		} catch (serverError) {
			dispatch({
				type: 'UPDATE_TERMS_FINISH',
				serverError,
			})
		}
	}
}
