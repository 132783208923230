/* @flow */

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import styles from './not-found.css'

type Props = {
	...WithTranslateProps,
	redirectToMainScreen: () => void,
}

class NotFound extends Component<Props> {
	render() {
		return (
			<div className={styles.root}>
				<div>
					<h1 className={styles.headline}>{this.props.t('application.invoiceNotFound.headline')}</h1>
					<div className={styles.image} />
				</div>
			</div>
		)
	}
}

export default withTranslate(NotFound)
