/* @flow */

import type {
	AccountingDocument,
	AccountingDocumentAction,
	AccountingDocumentActionListState,
	AccountingDocumentActions,
	AccountingDocumentListAction,
	AccountingDocumentReduxAction,
	AccountingDocumentScan,
	CommonAction,
	DashboardAction,
	Dispatch,
	State,
	UploadScanAction,
	SettingsAction,
	AccountingDocumentBankAccount,
	AccountingDocumentFromScanRequest,
	SequencesAction,
	CashRegister,
	IssueAction,
	FileDescription,
	TemplatesAction,
	FinishConnectAccountingDocumentConnectionAction,
	AccountingDocumentConnection,
	AccountingDocumentPayment,
} from 'types'
import {
	accountingDocumentsBulkAction,
	changeAccountingDocumentState,
	confirmCreditNote,
	countryVariantUpdate,
	createAccountingDocumentWithScans,
	forceProcess,
	loadAccountingDocument,
	loadAccountingDocumentActions,
	loadAccountingDocumentConnections,
	printAccountingDocument,
	removeAccountingDocument,
	sendAccountingDocument,
	updateAccountingDocumentPaymentInfo,
	createAccountingDocument,
	updateAccountingDocumentRelatedAccountingDocument,
	updateAccountingDocumentTotalAndTotalVatExcl,
	changeStateToProcessed,
	updateAccountingDocument,
	deleteAccountingDocumentScan,
	createTemplateFromAccountingDocument,
	connectAccountingDocumentConnection,
	resetAccountingDocumentsDirection,
} from 'modules/accounting-document/actions'
import { setNotification } from 'modules/common/actions/index'
import { editAccountingDocumentRoute, showAccountingDocumentRoute } from 'modules/accounting-document/routing/routes'
import { AccountingDocumentDirection_Number, AccountingDocumentType_Number } from 'types/convertor'
import type { FinishCreateAccountingDocumentWithScansAction } from 'modules/accounting-document/actions/upload-scan-action-types'
import { type Component, type ComponentType, type ElementConfig } from 'react'
import { getTodayIsoDateString, formatToIsoDateString } from 'utils/formatters'
import { connect } from 'react-redux'
import { userHasAccess } from 'permissions'
import { push, replace } from 'react-router-redux'
import { VAT_RECAP_CALCULATION_MODE_MANUAL, VAT_PAYMENT_TYPE_PAYER, VAT_PAYMENT_TYPE_FREE } from '../constants'
import { getConnectionFromDocument } from 'modules/accounting-document/domain/accounting-document'
import { createCreditNote } from 'modules/accounting-document/actions/accounting-document'
import { getAccDocToPay } from '../../../types/operations'
import { EMPTY_ARRAY } from 'trivi-constants'
import Tracking from 'utils/tracking'

type StateProps = {|
	availableActions: AccountingDocumentActionListState,
	canEditIssuedAccountingDocuments: boolean,
	canEditReceivedAccountingDocuments: boolean,
	canEditUnknownAccountingDocuments: boolean,
	canEditCashRegisters: boolean,
	canProcessAccountingDocuments: boolean,
	canRemoveIssuedAccountingDocuments: boolean,
	canRemoveReceivedAccountingDocuments: boolean,
	canRemoveUnknownAccountingDocuments: boolean,
	canSeeBankAccounts: boolean,
|}

function mapStateToProps(state: State): StateProps {
	return {
		availableActions: state.accountingDocument.accountingDocumentList.actions,
		canEditIssuedAccountingDocuments: userHasAccess(state, 'editIssuedAccountingDocuments'),
		canEditReceivedAccountingDocuments: userHasAccess(state, 'editReceivedAccountingDocuments'),
		canEditUnknownAccountingDocuments: userHasAccess(state, 'editUnknownAccountingDocuments'),
		canEditCashRegisters: userHasAccess(state, 'editCashRegisters'),
		canProcessAccountingDocuments: userHasAccess(state, 'processAccountingDocuments'),
		canRemoveIssuedAccountingDocuments: userHasAccess(state, 'removeIssuedAccountingDocuments'),
		canRemoveReceivedAccountingDocuments: userHasAccess(state, 'removeReceivedAccountingDocuments'),
		canRemoveUnknownAccountingDocuments: userHasAccess(state, 'removeUnknownAccountingDocuments'),
		canSeeBankAccounts: userHasAccess(state, 'seeBankAccounts'),
	}
}

type DispatchProps = {|
	loadAccountingDocument: (id: string) => void,
	goOnShowAccountingDocumentRoute: (id: string, goBackInHistory?: boolean, linkText?: string) => void,
	goOnEditAccountingDocumentRoute: (id: string) => void,
	printAccountingDocument: (id: string) => void,
	updateAccountingDocument: (
		oldAccountingDocument: AccountingDocument,
		newAccountingDocument: AccountingDocument,
	) => Promise<*>,
	removeAccountingDocument: (id: string) => Promise<*>,
	updateAccountingDocumentPaymentInfo: (id: string, paid: boolean) => Promise<*>,
	changeAccountingDocumentState: (id: string, state: string) => Promise<*>,
	loadAvailableActions: (accountingDocumentIds: Array<string>) => Promise<AccountingDocumentActions>,
	forceProcess: (accountingDocumentId: number) => Promise<*>,
	confirmCreditNote: (accountingDocumentId: string, value: { date: string, files: Array<FileDescription> }) => void,
	sendAccountingDocument: (accountingDocumentId: string, templateId: number, emails?: Array<string>) => void,
	bulkAction: (operation: string, ids: Array<string>, additionalData?: Object) => Promise<*>,
	sendEet: (accountingDocumentId: string) => Promise<*>,
	cancelEet: (accountingDocumentId: string) => Promise<*>,
	joinAccountingDocuments: (accountingDocuments: Array<AccountingDocument>) => Promise<*>,
	unJoinAccountingDocuments: (originalDoc: AccountingDocument, scans: Array<AccountingDocumentScan>) => Promise<*>,
	defaultOnAction: (action: string, row: AccountingDocument, next?: Function) => Promise<void>,
	reextractAccountingDocument: (accountingDocument: AccountingDocument, replacePath?: boolean) => Promise<void>,
	copyAccountingDocument: (accountingDocument: AccountingDocument, redirectToEdit?: boolean) => Promise<void>,
	createTaxAdvanceAccountingDocument: (accountingDocument: AccountingDocument) => Promise<*>,
	accDocCreateAccDoc: (cashRegister: ?CashRegister, accDoc: ?AccountingDocument) => Promise<*>,
	createCreditNote: (accDcc: AccountingDocument) => Promise<*>,
	createTemplate: (accountingDocumentId: string) => Promise<*>,
|}

type Action =
	| AccountingDocumentReduxAction
	| AccountingDocumentListAction
	| DashboardAction
	| CommonAction
	| UploadScanAction
	| AccountingDocumentAction
	| SettingsAction
	| SequencesAction
	| IssueAction
	| TemplatesAction

function mapDispatchToProps(dispatch: Dispatch<Action>): DispatchProps {
	const goOnShowAccountingDocumentRoute = (
		accountingDocumentId: string,
		goBackInHistory?: boolean,
		linkText?: string,
	): void => {
		dispatch(resetAccountingDocumentsDirection())
		dispatch(
			push(showAccountingDocumentRoute(accountingDocumentId), {
				link: linkText,
				goBack: !!goBackInHistory,
			}),
		)
	}

	const goOnEditAccountingDocumentRoute = (accountingDocumentId: string, replaceRoute?: boolean): void => {
		const route = editAccountingDocumentRoute(accountingDocumentId)
		const action = replaceRoute ? replace(route) : push(route)
		dispatch(action)
	}

	const _updateAccountingDocument = (
		oldAccountingDocument: AccountingDocument,
		newAccountingDocument: AccountingDocument,
	): Promise<*> => {
		return dispatch(updateAccountingDocument(oldAccountingDocument, newAccountingDocument))
	}

	const _removeAccountingDocument = async (accountingDocumentId: string): Promise<*> => {
		return await dispatch(removeAccountingDocument(accountingDocumentId))
	}

	const _printAccountingDocument = (accountingDocumentId: string): void => {
		dispatch(printAccountingDocument(accountingDocumentId))
	}

	const _updateAccountingDocumentPaymentInfo = (accountingDocumentId: string, paid: boolean): Promise<*> => {
		return dispatch(updateAccountingDocumentPaymentInfo(accountingDocumentId, paid))
	}

	const _changeAccountingDocumentState = (accountingDocumentId: string, state: string): Promise<*> => {
		return dispatch(changeAccountingDocumentState(accountingDocumentId, state))
	}

	const _forceProcess = (accountingDocumentId: number): Promise<*> => {
		return dispatch(forceProcess(accountingDocumentId))
	}

	const _sendEet = (accountingDocumentId: string) => {
		return dispatch(countryVariantUpdate(accountingDocumentId, { cz: { eet: { operation: 'send_eet' } } }))
	}

	const _cancelEet = (accountingDocumentId: string) => {
		return dispatch(countryVariantUpdate(accountingDocumentId, { cz: { eet: { operation: 'cancel_eet' } } }))
	}

	const _changeStateToProcessed = (accountingDocumentId: string) => {
		return dispatch(changeStateToProcessed(accountingDocumentId))
	}

	const _createTemplate = async (accountingDocumentId: string): Promise<*> => {
		return await dispatch(createTemplateFromAccountingDocument(accountingDocumentId))
	}

	return {
		goOnShowAccountingDocumentRoute,
		goOnEditAccountingDocumentRoute,
		printAccountingDocument: _printAccountingDocument,
		updateAccountingDocumentPaymentInfo: _updateAccountingDocumentPaymentInfo,
		changeAccountingDocumentState: _changeAccountingDocumentState,
		forceProcess: _forceProcess,
		sendEet: _sendEet,
		cancelEet: _cancelEet,
		updateAccountingDocument: _updateAccountingDocument,
		removeAccountingDocument: _removeAccountingDocument,
		createTemplate: _createTemplate,
		createCreditNote: async (accDoc: AccountingDocument) => {
			dispatch(createCreditNote(accDoc))
		},
		accDocCreateAccDoc: (cashRegister: ?CashRegister, accDoc: ?AccountingDocument) => {
			if (!cashRegister || !accDoc) {
				dispatch(setNotification({ type: 'error', message: 'accountingDocument.multiActions.noCashRegister' }, 2))
				return Promise.reject()
			}
			const { direction, id, paymentType } = accDoc
			if (direction !== 0 && direction !== 1) {
				dispatch(
					setNotification({ type: 'error', message: 'accountingDocument.multiActions.wrongDocumentDirection' }, 2),
				)
				return Promise.reject()
			}
			const invoiceType = AccountingDocumentType_Number('cash_receipt')
			return dispatch(
				createAccountingDocument({
					lineItems: [],
					currency: cashRegister.currency,
					issueDate: getTodayIsoDateString(),
					type: invoiceType,
					direction,
					cashRegisterId: cashRegister.id,
					paymentType: cashRegister ? 2 : paymentType,
				}),
			)
				.then(async (action: AccountingDocumentReduxAction) => {
					if (action.type === 'FINISH_CREATE_ACCOUNTING_DOCUMENT' && action.result && action.result.id) {
						const documentId = action.result && action.result.id ? action.result.id : ''
						const amount = accDoc ? getAccDocToPay(accDoc) : 0
						dispatch(updateAccountingDocumentRelatedAccountingDocument(documentId, id || ''))
						dispatch(updateAccountingDocumentTotalAndTotalVatExcl(documentId, amount))
						dispatch(
							push({
								pathname: editAccountingDocumentRoute(documentId),
								state: {
									isNew: true,
								},
							}),
						)
					}
				})
				.catch(() => {
					return Promise.reject()
				})
		},
		copyAccountingDocument: (accountingDocument: AccountingDocument, redirectToEdit?: boolean = true) => {
			const newAccountingDocument: AccountingDocument = {
				...accountingDocument,
				id: undefined,
				explicitNo: undefined,
				externalNo: undefined,
				state: 'Draft',
				variableSymbol: undefined,
			}
			if (
				accountingDocument.bankAccounts &&
				accountingDocument.direction === AccountingDocumentDirection_Number('received')
			) {
				newAccountingDocument.bankAccounts = accountingDocument.bankAccounts.map((a: AccountingDocumentBankAccount) =>
					Object.freeze({
						...a,
						bankAccountId: undefined,
					}),
				)
			}

			// V případě, že je doklad neplátcovský (2),
			// je třeba při kopírování vynechat VatRecap, VatRecapCalculationMode a Total,
			// aby se zajistilo, že se budou počítat automaticky (nechat je null).
			//
			// V případě, že je doklad plátcovský (1),
			// tak kopírovat VatRecap a Total jen v případě,
			// že je VatRecapCalculationMode = Manual.

			if (newAccountingDocument.vatPaymentMode === VAT_PAYMENT_TYPE_FREE) {
				newAccountingDocument.total = undefined
				newAccountingDocument.vatRecap = undefined
				newAccountingDocument.totalVatExcl = undefined
				newAccountingDocument.vatRecapCalculationMode = undefined
			} else if (newAccountingDocument.vatPaymentMode === VAT_PAYMENT_TYPE_PAYER) {
				if (accountingDocument.vatRecapCalculationMode !== VAT_RECAP_CALCULATION_MODE_MANUAL) {
					newAccountingDocument.total = undefined
					newAccountingDocument.vatRecap = undefined
					newAccountingDocument.totalVatExcl = undefined
				}
			}

			return dispatch(createAccountingDocument(newAccountingDocument)).then((resp: { result: ?AccountingDocument }) => {
				if (resp.result && resp.result.id && redirectToEdit) {
					goOnEditAccountingDocumentRoute(resp.result && resp.result.id ? resp.result.id : '')
				}
			})
		},
		createTaxAdvanceAccountingDocument: async (accountingDocument: AccountingDocument) => {
			const time =
				accountingDocument.payments && accountingDocument.payments.payments
					? accountingDocument.payments.payments.reduce((time: number, payment: AccountingDocumentPayment) => {
							return payment.date ? Math.max(time, new Date(payment.date).getTime()) : time
					  }, 0)
					: new Date()

			const date = time ? formatToIsoDateString(new Date(time)) : formatToIsoDateString(new Date())

			const newAccountingDocument: AccountingDocument = {
				...accountingDocument,
				id: undefined,
				explicitNo: undefined,
				externalNo: undefined,
				sequenceId: undefined,
				state: 'Draft',
				variableSymbol: undefined,
				type: 2,
				issueDate: date,
				dueDate: date,
				taxDate: date,
			}

			if (newAccountingDocument.vatPaymentMode === VAT_PAYMENT_TYPE_FREE) {
				newAccountingDocument.total = undefined
				newAccountingDocument.vatRecap = undefined
				newAccountingDocument.totalVatExcl = undefined
				newAccountingDocument.vatRecapCalculationMode = undefined
			} else if (newAccountingDocument.vatPaymentMode === VAT_PAYMENT_TYPE_PAYER) {
				if (accountingDocument.vatRecapCalculationMode !== VAT_RECAP_CALCULATION_MODE_MANUAL) {
					newAccountingDocument.total = undefined
					newAccountingDocument.vatRecap = undefined
					newAccountingDocument.totalVatExcl = undefined
				}
			}

			return dispatch(createAccountingDocument(newAccountingDocument)).then(
				async (resp: { result: ?AccountingDocument }) => {
					if (resp.result && resp.result.id) {
						const connection: AccountingDocumentConnection = getConnectionFromDocument(accountingDocument)
						const connectionResult: FinishConnectAccountingDocumentConnectionAction = await dispatch(
							connectAccountingDocumentConnection(resp.result && resp.result.id ? resp.result.id : '', connection),
						)

						if (connectionResult.serverError) {
							return
						}

						goOnEditAccountingDocumentRoute(resp.result && resp.result.id ? resp.result.id : '')
					}
				},
			)
		},
		loadAccountingDocument: (id: string) => {
			dispatch(loadAccountingDocument(id))
			dispatch(loadAccountingDocumentConnections(id))
		},
		loadAvailableActions: async (accountingDocumentIds: Array<string>): Promise<AccountingDocumentActions> => {
			const action: AccountingDocumentListAction = await dispatch(loadAccountingDocumentActions(accountingDocumentIds))
			if (action.type === 'FINISH_LOAD_ACCOUNTING_DOCUMENT_ACTIONS') {
				return action.actions || EMPTY_ARRAY
			}
			return EMPTY_ARRAY
		},
		confirmCreditNote: (accountingDocumentId: string, value: { date: string, files: Array<FileDescription> }) => {
			const fileId = value.files[0].fileId
			fileId && dispatch(confirmCreditNote(accountingDocumentId, value.date, fileId))
		},
		sendAccountingDocument: (accountingDocumentId: string, templateId: number, emails?: Array<string>) => {
			dispatch(sendAccountingDocument(accountingDocumentId, templateId, emails))
		},
		bulkAction: (operation: string, ids: Array<string>, additionalData?: Object = {}) =>
			dispatch(accountingDocumentsBulkAction(operation, ids, additionalData)),

		joinAccountingDocuments: async (documents: Array<AccountingDocument>) => {
			if (documents.length <= 1) {
				throw new Error('cant join 1 or less documents')
			}
			const data: Array<AccountingDocumentFromScanRequest> = []
			const newRequest: AccountingDocumentFromScanRequest = _mapAccountingDocumentToScanRequest(documents[0])

			for (let i = 1; i < documents.length; i++) {
				newRequest.files != null && newRequest.files.push(..._mapFileIds(documents[i].scans))
			}

			data.push(newRequest)

			const action: UploadScanAction = await dispatch(createAccountingDocumentWithScans(data))

			const promises: Array<Promise<*>> = []
			if (action.serverError == null) {
				documents.forEach(
					(doc: AccountingDocument) => doc.id != null && promises.push(dispatch(removeAccountingDocument(doc.id))),
				)
			}

			return Promise.all(promises)
		},
		unJoinAccountingDocuments: async (originalDoc: AccountingDocument, scans: Array<AccountingDocumentScan>) => {
			if (scans.length < 1) {
				throw new Error('cant un-join 0 documents')
			}

			const promises: Array<Promise<*>> = []
			const originalScansLength = originalDoc && originalDoc.scans && originalDoc.scans.length
			const endIndex = scans.length === originalScansLength ? scans.length - 1 : scans.length

			for (let i = 0; i < endIndex; i++) {
				const data: Array<AccountingDocumentFromScanRequest> = []
				const newRequest: AccountingDocumentFromScanRequest = _mapAccountingDocumentToScanRequest(originalDoc, true)
				const scan = scans[i]
				newRequest.files != null && newRequest.files.push(..._mapFileIds([scan]))
				data.push(newRequest)
				promises.push(dispatch(createAccountingDocumentWithScans(data)))
				if (originalDoc && scan.guid && originalDoc.id) {
					promises.push(dispatch(deleteAccountingDocumentScan(originalDoc.id, scan.guid)))
				}
			}

			return Promise.all(promises)
		},
		reextractAccountingDocument: async (doc: AccountingDocument, replacePath?: boolean) => {
			const request: AccountingDocumentFromScanRequest = _mapAccountingDocumentToScanRequest(doc)
			const resp: FinishCreateAccountingDocumentWithScansAction = await dispatch(
				createAccountingDocumentWithScans([request]),
			)

			if (!resp.serverError && resp.scans && resp.scans.length > 0) {
				const accDocId = doc.id || ''
				const newAccDocId = (resp.scans && resp.scans[0].accountingDocumentId) || ''
				Tracking.trackAccDocReextract(accDocId, newAccDocId)
				Tracking.trackAccDocDeleted(accDocId, 'reextract')
				_removeAccountingDocument(accDocId)
				goOnEditAccountingDocumentRoute(newAccDocId, replacePath)
			}
		},
		defaultOnAction: async (action: string, row: AccountingDocument, next?: Function) => {
			switch (action) {
				case 'accdoc_show':
					row.id && goOnShowAccountingDocumentRoute(row.id)
					break
				case 'accdoc_edit':
					row.id && goOnEditAccountingDocumentRoute(row.id)
					break
				case 'accdoc_print':
					row.id && _printAccountingDocument(row.id)
					break
				case 'accdoc_manually_paid':
					row.id && _updateAccountingDocumentPaymentInfo(row.id, true).then(next)
					break
				case 'accdoc_manually_unpaid':
					row.id && _updateAccountingDocumentPaymentInfo(row.id, false).then(next)
					break
				case 'accdoc_movewf_draft':
					row.id && _changeAccountingDocumentState(row.id, 'Draft').then(next)
					break
				case 'accdoc_movewf_approval':
					row.id && _changeAccountingDocumentState(row.id, 'Waiting for approval').then(next)
					break
				case 'accdoc_movewf_processed':
					row.id && _changeAccountingDocumentState(row.id, 'Processed').then(next)
					break
				case 'accdoc_movewf_fixing':
					row.id && _changeAccountingDocumentState(row.id, 'Fixing').then(next)
					break
				case 'accdoc_force_processing':
					row.id && _forceProcess(parseInt(row.id)).then(next)
					break
				case 'accdoc_send_eet':
					row.id && _sendEet(row.id).then(next)
					break
				case 'accdoc_cancel_eet':
					row.id && _cancelEet(row.id).then(next)
					break
				case 'accdoc_set_processing_state':
					row.id && _changeStateToProcessed(row.id).then(next)
					break
				default:
					console.warn(`action ${action} is not supported in wrapper with-accounting-document-actions`) //eslint-disable-line
			}
		},
	}
}

function _mapFileIds(scans: ?Array<AccountingDocumentScan>): Array<string> {
	return scans != null ? scans.map((scan: AccountingDocumentScan) => scan.fileId || '') : EMPTY_ARRAY
}

function _mapAccountingDocumentToScanRequest(
	doc: AccountingDocument,
	unJoin: ?boolean,
): AccountingDocumentFromScanRequest {
	return {
		files: unJoin ? [] : [..._mapFileIds(doc.scans)],
		branch: doc.preferredBranch,
		project: doc.preferredProject,
		paymentType: doc.paymentType,
		cashRegisterId: doc.cashRegisterId,
		customerInstructions: doc.customerInstructions,
		categoryId: doc.categoryId,
	}
}

export type Props = {| ...StateProps, ...DispatchProps |}

export default function withAccountingDocumentActions<P: any, C: ComponentType<P>>(
	WrappedComponent: C,
): Class<Component<$Diff<ElementConfig<C>, Props>, any>> {
	return connect(mapStateToProps, mapDispatchToProps)(WrappedComponent)
}
