/* @flow */
/** @jsx jsx */

import { PureComponent, type Node } from 'react'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import { autoTestId, passAutoTestId, type AutoTestProps } from 'utils/tests/autotest'
import Tooltip from 'components/tooltip'
import { colors } from 'variables'

type Props = {|
	...AutoTestProps,
	children: Node,
	circled?: boolean,
	circledWhenHovered?: boolean,
	color?: string,
	disabled?: boolean,
	hoverColor?: string,
	style?: ?Object,
	tooltip?: ?string,
	tooltipAlign?: 'bottom-center' // eslint-disable-line
		| 'bottom-right'
		| 'bottom-left'
		| 'center-left'
		| 'center-right'
		| 'top-center'
		| 'top-left'
		| 'top-left-center'
		| 'top-right'
		| 'top-right-center',
	tooltipArrowAlign?: 'bottom-center' | 'bottom-right' | 'bottom-left' | 'top-center' | 'center-left' | 'center-right',
	tooltipVisible?: boolean,
	size: number,
	onClick?: (SyntheticEvent<HTMLElement>) => any,
	onFocus?: (SyntheticInputEvent<HTMLInputElement>) => void,
	onBlur?: (SyntheticInputEvent<HTMLInputElement>) => void,
|}

class IconButton extends PureComponent<Props> {
	button = null

	static defaultProps = {
		size: 40,
	}

	handleBlur = (event: SyntheticInputEvent<HTMLInputElement>) => {
		this.props.onBlur && this.props.onBlur(event)
	}

	handleFocus = (event: SyntheticInputEvent<HTMLInputElement>) => {
		this.props.onFocus && this.props.onFocus(event)
	}

	focus = () => {
		this.button && this.button.focus()
	}

	bindButton = (element: any) => {
		this.button = element
	}

	getStyles = memoize(
		(disabled: ?boolean, circled: ?boolean, circledWhenHovered: ?boolean, size: number, style: ?Object) => {
			return {
				root: {
					boxSizing: 'border-box',
					overflow: 'visible',
					padding: 0,
					width: size,
					height: size,
					fontSize: 0,
					cursor: disabled ? 'default' : 'pointer',
					pointerEvents: disabled ? 'none' : 'all',
					borderWidth: circled || circledWhenHovered ? 1 : 0,
					borderStyle: 'solid',
					borderColor: circled ? colors.grey400 : 'transparent',
					backgroundColor: circled ? colors.white : 'transparent',
					boxShadow: circled ? '3px 3px 0 0 rgba(0,0,0,0.08)' : 'none',
					borderRadius: '50%',
					display: 'flex',
					justifyContent: 'space-around',
					alignContent: 'center',
					alignItems: 'center',
					svg: {
						fill: this.props.color || colors.black,
					},
					'&:hover': {
						borderColor: circled || circledWhenHovered ? colors.blue : 'transparent',
						backgroundColor: circled || circledWhenHovered ? colors.white : 'transparent',
						boxShadow: circled || circledWhenHovered ? '3px 3px 0 0 rgba(0,0,0,0.08)' : 'none',
						svg: {
							fill: this.props.hoverColor || colors.blue,
						},
					},
					...style,
				},
				wrapper: {
					display: 'inline-flex',
				},
			}
		},
	)

	render() {
		const styles = this.getStyles(
			this.props.disabled,
			this.props.circled,
			this.props.circledWhenHovered,
			this.props.size,
			this.props.style,
		)

		const elements = (
			<div
				ref={this.bindButton}
				css={styles.root}
				onBlur={this.handleBlur}
				onFocus={this.handleFocus}
				onClick={!this.props.disabled && this.props.onClick}
				{...autoTestId(this.props.autoTestId)}
			>
				{this.props.children}
			</div>
		)

		if (!this.props.tooltip) return elements

		return (
			<Tooltip
				label={this.props.tooltip}
				visible={this.props.hasOwnProperty('tooltipVisible') ? this.props.tooltipVisible : undefined}
				disabled={this.props.disabled}
				align={this.props.tooltipAlign}
				arrowAlign={this.props.tooltipArrowAlign}
				wrapperStyle={styles.wrapper}
				{...passAutoTestId(this.props.autoTestId, 'tooltip')}
				inline
			>
				{elements}
			</Tooltip>
		)
	}
}

export default IconButton
