// @flow

import React, { Component } from 'react'
import type { AccountingDocumentVatRecapLine } from 'types'
import { CurrencyInput } from 'components'
import { formatToPercentage } from 'utils/formatters'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { autoTestId } from 'utils/tests/autotest'
import styles from './vat-recap.css'

export type Props = {|
	...WithTranslateProps,
	index: number,
	item: AccountingDocumentVatRecapLine,
	disabled: boolean,
	onBlur: () => void,
	onFieldChange: (field: string, value: ?number, index: number) => void,
|}

class VatRecapLine extends Component<Props> {
	onFieldChangeBase = (event: SyntheticInputEvent<HTMLInputElement>, base: ?number) => {
		this.props.onFieldChange('base', base, this.props.index)
	}
	onFieldChangeVatAmount = (event: SyntheticInputEvent<HTMLInputElement>, vatAmount: ?number) => {
		this.props.onFieldChange('vatAmount', vatAmount, this.props.index)
	}
	onFieldChangeTotal = (event: SyntheticInputEvent<HTMLInputElement>, total: ?number) => {
		this.props.onFieldChange('total', total, this.props.index)
	}

	render = () => {
		const { t, item } = this.props
		return (
			<div key={this.props.index} className={styles.row} {...autoTestId('vatRecapItem')}>
				<div className={styles.vatRate} id={'recap-vat-rate'} {...autoTestId('recapVatRate')}>
					{formatToPercentage(item.vatRate)}
				</div>
				<div className={styles.base} id={'recap-base'}>
					<CurrencyInput
						value={item.base}
						onChange={this.onFieldChangeBase}
						onBlur={this.props.onBlur}
						hintText={t('invoice.vatRecapitulation.vatBaseHint')}
						disabled={this.props.disabled}
						autoTestId="recapBase"
						name="recapBase"
						autoWidth
						inline
						right
					/>
				</div>
				<div className={styles.vat} id={'recap-vat-amount'}>
					<CurrencyInput
						value={item.vatAmount}
						onChange={this.onFieldChangeVatAmount}
						onBlur={this.props.onBlur}
						hintText={t('invoice.vatRecapitulation.vatAmountHint')}
						disabled={this.props.disabled}
						autoTestId="recapAmount"
						name="recapAmount"
						autoWidth
						inline
						right
					/>
				</div>
				<div className={styles.total} id={'recap-total'}>
					<CurrencyInput
						value={item.total}
						onChange={this.onFieldChangeTotal}
						onBlur={this.props.onBlur}
						hintText={t('invoice.vatRecapitulation.vatTotalHint')}
						disabled={this.props.disabled}
						autoTestId="recapTotal"
						name="recapTotal"
						autoWidth
						inline
						right
					/>
				</div>
			</div>
		)
	}
}

export default withTranslate(VatRecapLine)
