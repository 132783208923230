import React, { Component } from 'react'

import AutoLockScrolling from './AutoLockScrolling'
import PropTypes from 'prop-types'
import { colors } from 'variables'

function getStyles(props) {
	const style = {
		root: {
			position: 'fixed',
			height: '100%',
			width: '100%',
			top: 0,
			left: '-100%',
			opacity: 0,
			backgroundColor: colors.grey,

			// Two ways to promote overlay to its own render layer
			willChange: 'opacity',
			transform: 'translateZ(0)',

			transition: 'left 0ms cubic-bezier(0.23, 1, 0.32, 1) 400ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1)',
		},
	}

	if (props.show) {
		Object.assign(style.root, {
			left: 0,
			opacity: 1,
			transition: 'left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
		})
	}

	return style
}

class Overlay extends Component {
	static propTypes = {
		autoLockScrolling: PropTypes.bool,
		show: PropTypes.bool.isRequired,
		/**
		 * Override the inline-styles of the root element.
		 */
		style: PropTypes.object,
	}

	static defaultProps = {
		autoLockScrolling: true,
		style: {},
	}

	setOpacity(opacity) {
		this.refs.overlay.style.opacity = opacity
	}

	render() {
		const { autoLockScrolling, show, style, ...other } = this.props

		const styles = getStyles(this.props)

		return (
			<div {...other} ref="overlay" style={Object.assign(styles.root, style)}>
				{autoLockScrolling && <AutoLockScrolling lock={show} />}
			</div>
		)
	}
}

export default Overlay
