/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import React from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { autoTestId } from 'utils/tests/autotest'
import PopupSection from 'components/PopupSection'
import Paper from 'components/Paper'
import colors from 'variables/colors.css'
import styles from './invoice.css'

export type Props = {|
	...WithTranslateProps,
	accountingDocumentId: string,
	color: ?string,
	title: string,
	Logo?: any,
	HeaderDates?: any,
	HeaderNo: any,
	HeaderOrderNo?: any,
	ContactSupplier?: any,
	ContactBuyer?: any,
	LineItems?: any,
	PaymentType?: any,
	PaymentDetails?: any,
	TotalsToPay?: any,
	BankAccounts?: any,
	VatRecap?: any,
	Totals?: any,
	StateChanger: any,
	Summary?: any,
	PrintingNote?: any,
	InvoiceSignature?: any,
	PaymentState?: any,
	QR?: any,
	confirmClose?: boolean,
	onRequestClose?: () => void,
|}

function Invoice(props: Props) {
	const color = props.color || colors.blue

	return (
		<PopupSection open notClosable={!props.onRequestClose} onRequestClose={props.onRequestClose}>
			<div>
				<div className={styles.scroller}>
					<div className={styles.public}>
						<div className={styles.invoiceContainer} {...autoTestId('document-container')}>
							<div className={styles.wideHeader}>
								<div className={styles.headerRow}>
									<div className={styles.headings} id={'issued-invoice-header'} {...autoTestId('document-header')}>
										<div>
											<div css={style.textField}>{props.title}</div>
											<div css={style.paymentState}>{props.PaymentState}</div>
										</div>
									</div>
									<div className={styles.actions}>{props.StateChanger}</div>
								</div>
							</div>
							<Paper rounded zDepth={3} className={styles.invoice} id={'invoice-container'}>
								<div className={styles.invoiceHeader}>
									{props.HeaderOrderNo}
									{props.HeaderNo}
								</div>
								{(props.HeaderOrderNo || props.HeaderNo) && <div className={styles.fullhr} />}
								<div className={styles.row}>
									{props.ContactSupplier && (
										<div className={styles.contact}>
											<div {...autoTestId('invoice-contact-left')}>
												<div className={styles.h3} {...autoTestId('left-title')}>
													{props.t('invoice.contact.supplier')}
												</div>
												<div className={styles.supplierContact}>
													<div className={styles.supplier}>{props.ContactSupplier}</div>
													{props.Logo}
												</div>
											</div>
										</div>
									)}

									{props.ContactBuyer && (
										<div className={styles.contact}>
											<div {...autoTestId('invoice-contact-right')}>
												<div className={styles.h3} {...autoTestId('right-title')}>
													{props.t('invoice.contact.custommer')}
												</div>
												<div className={styles.buyer}>
													{props.ContactBuyer && React.cloneElement(props.ContactBuyer, { view: 'default' })}
													<div className={styles.hr} />
													{props.HeaderDates}
												</div>
											</div>
										</div>
									)}
								</div>

								{(props.BankAccounts || props.PaymentType || props.TotalsToPay) && (
									<div className={styles.paymentRoot}>
										<div className={styles.paymentType}>{props.PaymentType}</div>
										<div className={styles.paymentSummary} style={{ borderColor: color }}>
											<div className={styles.paymentAccounts}>{props.BankAccounts}</div>
											<div className={styles.paymentPayment}>{props.PaymentDetails}</div>
											{props.TotalsToPay}
										</div>
									</div>
								)}

								{props.LineItems}

								<div className={styles.row}>
									<div className={styles.vatRecap}>
										{props.PrintingNote}
										{props.VatRecap}
									</div>
									<div className={styles.totals}>
										{props.Totals}
										{props.InvoiceSignature}
									</div>
								</div>
							</Paper>
						</div>

						<div className={styles.menu}>{props.QR}</div>
					</div>
				</div>
			</div>
		</PopupSection>
	)
}

const style = {
	direction: {
		display: 'block',
		height: 50,
		marginLeft: 10,
	},
	textField: {
		margin: 0,
		fontSize: 24,
		color: colors.black,
		fontWeight: 'normal',
		lineHeight: '40px',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	paymentState: {
		marginLeft: -4,
		display: 'flex',
	},
}

export default withTranslate(Invoice)
