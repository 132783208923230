/* @flow */
/** @jsx jsx */

import { createRef, Component } from 'react'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import type {
	AccountingDocument,
	AccountingDocumentLineItem,
	FinancialAccount,
	VatRate,
	Enum,
	EnumItem,
	GreenboxSuggestionResponse,
	GreenboxSuggestionLineItemResponse,
	MultiFormFieldMessages,
	FormFieldErrorContext,
	Tag,
} from 'types'
import { formatToMoney } from 'utils/formatters'
import { FinancialAccounts_numberExist } from 'types/operations'
import { getFinancialAccountsOptions } from 'modules/accounting-document/domain/financial-accounts'
import { Checkbox, CurrencyInput, IconButton, MenuItem, SelectField, TextField, NumberInput } from 'components'
import SelectNext, { type OptionType, type ValueType } from 'components/select-next'
import FavoriteSelect from 'components/favorite-select'
import FavoriteAutoComplete from 'components/favorite-auto-complete'
import { type FavoriteOptionType } from 'components/favorite-select/favorite-option'
import Copy from 'components/svg-icons/content/content-copy'
import Delete from 'components/svg-icons/action/delete'
import DropDownArrow from 'components/svg-icons/navigation/arrow-drop-down'
import { includes as arrayIncludes, isEqual } from 'lodash-es'
import { autoTestId } from 'utils/tests/autotest'
import { colors } from 'variables'
import { emptyAccountingDocumentLineItem } from '../../types/empty'
import styles from './line-item.css'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { REVERSE_CHARGE_CODES as reverseChargeCodes, ACCDOC_TAG_MAXLENGTH } from 'modules/accounting-document/constants'
import type { ValidationMessageType, ValidationValueType } from 'wrappers/validate'
import { EMPTY_ARRAY } from 'trivi-constants'
import { getTooltipErrorLabel, getFormFieldErrorsFromMultiErros, hasFormFieldErrorsInMultiErrors } from 'helpers'
import { filterVatRates, findSameVatRate, findVatRateById, isVatRateAvailable } from '../../domain/vat-rate'
import VatRateSelect from './vat-rate-select'

type FieldName = $Keys<AccountingDocumentLineItem>

const ZERO_PRICE_MIN_WIDTH = 31

const autocalcLoadingFields: Array<FieldName> = [
	'unitPrice',
	'unitPriceVatExcl',
	'total',
	'totalVatExcl',
	'vatValue',
	'qt',
	'vatRateId',
]

type Props = {|
	...WithTranslateProps,
	value: AccountingDocumentLineItem,
	public?: boolean,
	readonly?: boolean,
	financialAccounts: Array<FinancialAccount>,
	favoriteFinancialAccountIds: Array<string>,
	favoriteLineItemIds: Array<string>,
	greenboxSuggestion: ?GreenboxSuggestionResponse,
	availableProjects: Enum,
	availableBranches: Enum,
	isCalculating: boolean,
	currency: ?string,
	isCreating?: boolean,
	direction: ?number,
	index: number,
	isAccDocVatFree: boolean,
	validationMessage?: ValidationMessageType,
	validationValue?: ValidationValueType,
	formFieldErrorContext?: FormFieldErrorContext,
	defaultLineItems?: Array<AccountingDocumentLineItem> | null,
	accountingDocumentId?: ?string,
	accountingDocument?: ?AccountingDocument,
	vatRates: null | Array<VatRate>,
	onChange: (value: AccountingDocumentLineItem, autocalc: boolean) => void,
	onRemove: () => void,
	onClone: () => void,
	onExpand: (id: number) => void,
	onExpandClose: (id: number) => void,
	onFinancialAccountFavorite: (no: string, isFavorite: boolean) => void,
	onLineItemFavorite: (id: string, isFavorite: boolean) => void,
	createTag: (tag: Tag) => void,
	isExpanded: boolean,
	errors: ?MultiFormFieldMessages,
	removeError: (formFieldName: string) => void,
	searchLineItems?: (filter?: string) => void,
|}

type State = {
	expanded: boolean,
	autocalc: boolean,
	fieldState: {
		[fieldName: FieldName]: any,
	},
	editingField: ?string,
}

class LineItem extends Component<Props, State> {
	state: State
	changedField: ?FieldName
	lastCountingField: ?FieldName
	lastCountingValue: ?any
	lineItems: Array<AccountingDocumentLineItem> = []
	disableSearchFor: string | null = null

	unitPriceRef = createRef()
	totalRef = createRef()

	ERROR_ENABLED_FIELDS = ['vatRateId', 'description', 'finAccount']

	constructor(props: Props) {
		super(props)
		this.state = {
			expanded: false,
			autocalc: true,
			fieldState: this.createFieldState(props),
			editingField: null,
		}
		this.changedField = null
		this.lastCountingField = null
		this.lastCountingValue = null
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		const item: AccountingDocumentLineItem = nextProps.value || emptyAccountingDocumentLineItem()
		this.setState({
			fieldState: this.createFieldState(nextProps),
			expanded: nextProps.isExpanded,
		})
		const greenboxSuggestion = this.getGreenboxSuggestion(nextProps.greenboxSuggestion, nextProps.index)
		const oldGgreenboxSuggestion = this.getGreenboxSuggestion(this.props.greenboxSuggestion, this.props.index)

		if (
			!isGreenboxSuggestionEqual(oldGgreenboxSuggestion, greenboxSuggestion) && // Pokud se nápověda z rákosáka nezměnila, nevyplňovat nic
			item.description &&
			!item.finAccount &&
			greenboxSuggestion &&
			greenboxSuggestion.financialAccounts &&
			greenboxSuggestion.financialAccounts.length > 0
		) {
			const firstAccount = greenboxSuggestion.financialAccounts[0]
			const suggestedFinAccount = firstAccount.financialAccountNumber

			// [TWU-3250] Docházelo tady k zacyklení - i když byl napovězený účet z rákosáka nevalidní, předvyplnil se,
			// následoval blur a odeslala se úprava položky na BE. Z BE se vrátila chyba
			// a to opět vyvolalo předvyplnění položky z rákosáka. Nyní se předvyplní jen při změně.
			if (firstAccount.confidenceLevel === 1 && this.state.fieldState.finAccount !== suggestedFinAccount) {
				// [TWU-3250] Nepředvyplňovat, pokud číslo účtu neznáme
				if (FinancialAccounts_numberExist(nextProps.financialAccounts, suggestedFinAccount)) {
					this.changedField = 'finAccount'
					this.setState(
						{
							fieldState: { ...this.state.fieldState, finAccount: suggestedFinAccount },
							editingField: 'finAccount',
						},
						() => {
							this.onBlur()
						},
					)
				}
			}
		}
	}

	getGreenboxSuggestion = memoize((greenboxSuggestion: ?GreenboxSuggestionResponse, index: number) => {
		return greenboxSuggestion && greenboxSuggestion.lineItems && greenboxSuggestion.lineItems[index]
	})

	createFieldState(props: Props) {
		const item: AccountingDocumentLineItem = props.value || emptyAccountingDocumentLineItem()

		if (this.state && this.state.fieldState && this.state.editingField) {
			return {
				...item,
				// $FlowFixMe
				[this.state.editingField]: this.state.fieldState[this.state.editingField],
			}
		}

		return item
	}

	createItemFromState() {
		let lineItem: AccountingDocumentLineItem = emptyAccountingDocumentLineItem()

		const item = this.state.fieldState

		Object.keys(this.props.value).map((fieldName: FieldName) => {
			lineItem[fieldName] = item[fieldName]
		})

		//TODO hotfix, celý tenhle soubor je příšernej!!!
		Object.keys(this.state.fieldState).forEach((fieldName: any) => {
			lineItem[fieldName] = item[fieldName]
		})

		return lineItem
	}

	onBlur = () => {
		setTimeout(() => {
			if (this.changedField) {
				const stateItem = this.createItemFromState()

				let itemToSubmit: AccountingDocumentLineItem = Object.assign(emptyAccountingDocumentLineItem(), stateItem)

				//TODO udělat lepší odbočku
				if (!this.state.autocalc) {
					this.changedField = null
					itemToSubmit.vatValue = (itemToSubmit.total || 0) - (itemToSubmit.totalVatExcl || 0)
					this.props.onChange(itemToSubmit, false)
					return
				}

				if (this.changedField) {
					const changedValue = stateItem[this.changedField]

					const recountingFields: Array<FieldName> = [
						'unitPrice',
						'unitPriceVatExcl',
						'total',
						'totalVatExcl',
						'vatValue',
					]

					if (arrayIncludes(recountingFields, this.changedField)) {
						this.lastCountingField = this.changedField
						this.lastCountingValue = changedValue
						recountingFields.forEach((fieldName: FieldName) => {
							delete itemToSubmit[fieldName]
						})

						if (this.changedField) itemToSubmit[this.changedField] = (changedValue: any)
					} else if (this.changedField === 'qt' || this.changedField === 'vatRateId') {
						recountingFields.forEach((fieldName: FieldName) => {
							delete itemToSubmit[fieldName]
						})
						// No field was edited before
						if (!this.lastCountingField) {
							itemToSubmit.unitPriceVatExcl = stateItem.unitPriceVatExcl
						} else {
							if (this.lastCountingValue) itemToSubmit[this.lastCountingField] = this.lastCountingValue
						}
					}

					const autocalc: boolean = this.changedField ? arrayIncludes(autocalcLoadingFields, this.changedField) : false
					this.props.onChange(itemToSubmit, autocalc)
					if (
						this.changedField &&
						this.ERROR_ENABLED_FIELDS.includes(this.changedField) &&
						hasFormFieldErrorsInMultiErrors(this.props.errors, this.changedField)
					) {
						this.props.removeError(this.changedField)
					}
				}
			}

			this.changedField = null
			this.setState({ editingField: null })
		}, 0)
	}

	closeExpand = () => {
		this.setState({
			expanded: false,
		})
		this.props.onExpandClose(this.props.index)
	}

	openExpand = () => {
		this.setState({
			expanded: true,
		})
		this.props.onExpand(this.props.index)
	}

	onAutocalcCheck = (event: SyntheticInputEvent<HTMLInputElement>, autocalc: boolean) => {
		this.setState({ autocalc })
	}

	onVatDeductionCheck = (event: SyntheticInputEvent<HTMLInputElement>, vatDeduction: boolean) => {
		const stateItem = this.createItemFromState()
		const item: AccountingDocumentLineItem = Object.assign(emptyAccountingDocumentLineItem(), stateItem, {
			vatDeduction,
		})
		this.props.onChange(item, this.state.autocalc)
	}

	getAutoCompleteOptions = (enumeration: Enum, value: ?string) => {
		let options = enumeration.map((item: EnumItem) => ({ value: item.key, label: item.value }))

		if (value && !options.find((option: OptionType) => option.value === value)) {
			options.push({ value: value, label: value })
		}

		return options
	}

	renderExpandButton = (style: Object) => {
		if (this.props.isCreating || this.props.public) {
			return null
		}
		return (
			<div className={styles.expand} onClick={this.openExpand}>
				<div className={styles.expandInner}>
					<DropDownArrow style={style.expandIcon} viewBox={'2 4 20 20'} />
				</div>
			</div>
		)
	}

	onVatRateChange = (vatRate: VatRate | null) => {
		this.changedField = 'vatRateId'
		this.setState(
			{
				fieldState: { ...this.state.fieldState, ...this.getNewVatRateFieldValues(vatRate) },
				expanded: vatRate && vatRate.type === 4 ? true : this.state.expanded,
				editingField: 'vatRateId',
			},
			() => {
				this.onBlur()
			},
		)
	}

	getNewVatRateFieldValues = (vatRate: VatRate | null): {} => {
		const fieldsToChange = {}

		if (vatRate) {
			if (vatRate.vatRate !== undefined) {
				fieldsToChange.vatRate = vatRate.vatRate * 100
			}
			if (vatRate.type !== undefined) {
				fieldsToChange.vatRateType = vatRate.type
			}
			if (vatRate.id !== undefined) {
				fieldsToChange.vatRateId = vatRate.id
			}
			if (vatRate.type !== 4) {
				fieldsToChange.reverseChargeCode = null
			}
		} else {
			fieldsToChange.vatRate = null
			fieldsToChange.vatRateType = null
			fieldsToChange.vatRateId = null
			fieldsToChange.reverseChargeCode = null
		}

		return fieldsToChange
	}

	createTagIfNeeded = (value: ?string, isBranch: boolean = true) => {
		if (value == null || '' === value) return
		const tagType = isBranch ? 0 : 1
		const tagsOnServer = isBranch ? this.props.availableBranches : this.props.availableProjects
		const isTagOnServer = tagsOnServer.find((tag: EnumItem) => tag.key === value)

		if (!isTagOnServer) {
			this.props.createTag({
				code: value,
				name: value,
				type: tagType,
			})
		}
	}

	onBranchChange = (value: ValueType) => {
		const newBranch = value && !Array.isArray(value) ? value.value : null
		this.changedField = 'branch'

		this.createTagIfNeeded(newBranch)
		this.setState(
			{
				fieldState: { ...this.state.fieldState, branch: newBranch },
				editingField: 'branch',
			},
			() => {
				this.onBlur()
			},
		)
	}

	onProjectChange = (value: ValueType) => {
		const newProject = value && !Array.isArray(value) ? value.value : null
		this.changedField = 'project'

		this.createTagIfNeeded(newProject, false)

		this.setState(
			{
				fieldState: { ...this.state.fieldState, project: newProject },
				editingField: 'project',
			},
			() => {
				this.onBlur()
			},
		)
	}

	onUnitNameChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?string) => {
		this.changedField = 'unitName'
		this.setState({
			fieldState: { ...this.state.fieldState, unitName: value },
			editingField: 'unitName',
		})
	}

	onUnitPriceChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?number) => {
		this.changedField = 'unitPrice'
		this.setState({
			fieldState: { ...this.state.fieldState, unitPrice: value },
			editingField: 'unitPrice',
		})
	}

	onUnitPriceVatExclChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?number) => {
		this.changedField = 'unitPriceVatExcl'
		this.setState({
			fieldState: { ...this.state.fieldState, unitPriceVatExcl: value },
			editingField: 'unitPriceVatExcl',
		})
	}

	onTotalChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?number) => {
		this.changedField = 'total'
		this.setState({
			fieldState: { ...this.state.fieldState, total: value },
			editingField: 'total',
		})
	}

	onTotalVatExclChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?number) => {
		this.changedField = 'totalVatExcl'
		this.setState({
			fieldState: { ...this.state.fieldState, totalVatExcl: value },
			editingField: 'totalVatExcl',
		})
	}

	onQuantityChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?number) => {
		this.changedField = 'qt'
		this.setState({
			fieldState: { ...this.state.fieldState, qt: value },
			editingField: 'qt',
		})
	}

	onFinAccountChange = (value: FavoriteOptionType) => {
		const vatRate = findVatRateById(this.props.vatRates, this.state.fieldState.vatRateId)
		const newVatRates = filterVatRates(this.props.vatRates, value.value)
		const needNewVatRate = !isVatRateAvailable(newVatRates, this.state.fieldState.vatRateId)
		let vatRateFieldValues = {}

		if (needNewVatRate) {
			const newVatRate = needNewVatRate
				? findSameVatRate(newVatRates, {
						id: this.props.value.vatRateId,
						type: this.props.value.vatRateType,
						vatRate: this.props.value.vatRate,
						text: vatRate ? vatRate.text : undefined,
				  })
				: null
			vatRateFieldValues = this.getNewVatRateFieldValues(newVatRate)
		}

		this.changedField = 'finAccount'
		this.setState(
			{
				fieldState: { ...this.state.fieldState, finAccount: value.value, ...vatRateFieldValues },
				editingField: 'finAccount',
			},
			() => {
				this.onBlur()
			},
		)
	}

	onReverseChargeCodeChange = (event: SyntheticEvent<HTMLElement>, index: ?number, value: any) => {
		this.changedField = 'reverseChargeCode'
		this.setState(
			{
				fieldState: { ...this.state.fieldState, reverseChargeCode: value },
				editingField: 'reverseChargeCode',
			},
			() => {
				const stateItem = this.createItemFromState()
				this.props.onChange(stateItem, this.state.autocalc)
			},
		)
	}

	renderExpandedPart = (style: Object) => {
		if (!this.state.expanded || this.props.isCreating || this.props.public) {
			return null
		}
		const { t } = this.props
		const isReverseCharge = this.props.value.vatRateType === 4

		return (
			<div className={styles.expandedPart} open={this.state.expanded}>
				<div className={styles.expandedContainer}>
					{isReverseCharge && (
						<div className={styles.column}>
							<SelectField
								compact
								fullWidth
								nullable
								labelText={t('invoice.lineItems.reverseChargeLabel')}
								hintText={t('invoice.lineItems.reverseChargeHint')}
								autoTestId="invoice-classification-reverse-charge"
								disabled={this.props.readonly}
								value={this.state.fieldState.reverseChargeCode}
								onChange={this.onReverseChargeCodeChange}
							>
								{reverseChargeCodes.map((code: { code: string, name: string }) => (
									<MenuItem key={code.code} value={code.code} primaryText={code.name} />
								))}
							</SelectField>
						</div>
					)}
					<div className={styles.column}>
						<SelectNext
							compact
							isClearable
							isSearchable
							isCreatable
							label={t('invoice.lineItems.branchLabel')}
							autoTestId="invoice-line-item-branch-select"
							disabled={this.props.readonly}
							onChange={this.onBranchChange}
							options={this.getAutoCompleteOptions(this.props.availableBranches, this.state.fieldState.branch)}
							value={this.state.fieldState.branch}
							maxLength={ACCDOC_TAG_MAXLENGTH.branch}
						/>
					</div>
					<div className={styles.column}>
						<SelectNext
							compact
							isClearable
							isSearchable
							isCreatable
							label={t('invoice.lineItems.projectLabel')}
							autoTestId="invoice-line-item-project-select"
							disabled={this.props.readonly}
							onChange={this.onProjectChange}
							options={this.getAutoCompleteOptions(this.props.availableProjects, this.state.fieldState.project)}
							value={this.state.fieldState.project}
							maxLength={ACCDOC_TAG_MAXLENGTH.project}
						/>
					</div>
				</div>
				<div className={styles.checkboxesContainer}>
					<span className={styles.expandedCheckbox}>
						<Checkbox
							label={t('invoice.lineItems.autocalcLabel')}
							checked={this.state.autocalc}
							onCheck={this.onAutocalcCheck}
							disabled={this.props.readonly}
						/>
					</span>
					{this.props.direction !== 1 && (
						<span className={styles.expandedCheckbox}>
							<Checkbox
								label={t('invoice.lineItems.vatDeductionLabel')}
								checked={!!this.props.value.vatDeduction}
								onCheck={this.onVatDeductionCheck}
								disabled={this.props.readonly}
							/>
						</span>
					)}
				</div>
				<div className={styles.reduce} onClick={this.closeExpand}>
					<div className={styles.reduceInner}>
						<DropDownArrow style={style.reduceIcon} viewBox={'2 4 20 20'} />
					</div>
				</div>
			</div>
		)
	}

	renderSideButtons = (style: Object) => {
		if (this.props.readonly || this.props.public || this.state.expanded || this.props.isCreating) {
			return null
		}
		return (
			<div className={styles.icons}>
				<div>
					<IconButton
						style={style.sideButton}
						onClick={this.props.onClone}
						tooltip={this.props.t('invoice.lineItems.copyLineItem')}
						tooltipAlign={'center-right'}
						tooltipArrowAlign={'center-left'}
						autoTestId="invoice-line-item-copy"
						size={24}
					>
						<Copy size={14} color={colors.grey600} />
					</IconButton>
				</div>
				<div>
					<IconButton
						style={style.sideButton}
						onClick={this.props.onRemove}
						tooltip={this.props.t('invoice.lineItems.deleteLineItem')}
						autoTestId="invoice-line-item-delete"
						tooltipAlign={'center-right'}
						tooltipArrowAlign={'center-left'}
						size={24}
					>
						<Delete size={14} color={colors.grey600} />
					</IconButton>
				</div>
			</div>
		)
	}

	getValErrorId(id: string) {
		return this.props.index.toString() + '_' + id
	}

	onFavorite = (value: ?string, isFavorite: boolean) => {
		value && this.props.onFinancialAccountFavorite(value, isFavorite)
	}

	getFinAccountTooltip = memoize((accounts: Array<FavoriteOptionType>, finAccount: ?string) => {
		const account = accounts.find((account: FavoriteOptionType) => account.labelPrefix === finAccount)
		const name = (account && account.label) || ''
		return name.length > 20 ? name : null
	})

	getOptions = memoize((accounts: Array<FinancialAccount>, favorites: Array<string>, hinted: Array<string>) => {
		return getFinancialAccountsOptions(accounts, favorites, hinted, this.onFavorite)
	})

	focusUnitPrice = () => {
		this.unitPriceRef.current && this.unitPriceRef.current.focus()
	}

	focusTotal = () => {
		this.totalRef.current && this.totalRef.current.focus()
	}

	changeDescription = (value: string) => {
		this.changedField = 'description'
		this.setState(
			{
				fieldState: { ...this.state.fieldState, description: value },
				editingField: 'description',
			},
			() => {
				this.props.removeError('description')
				this.onBlur()
			},
		)
	}
	setNewLineItem = (value: FavoriteOptionType) => {
		const selectedLineItem =
			this.lineItems.find((item: AccountingDocumentLineItem) => item.id === value.value) ||
			(this.props.defaultLineItems &&
				this.props.defaultLineItems.find((item: AccountingDocumentLineItem) => item.id === value.value))

		const newLineItem = {
			...selectedLineItem,
			id: this.state.fieldState.id,
		}

		const hasAllValues = null != newLineItem.vatRate && null != newLineItem.vatRateType && null != newLineItem.vatRateId
		const isVatRateAvailable = findVatRateById(this.props.vatRates, newLineItem.vatRateId)

		if (!hasAllValues || !isVatRateAvailable) {
			delete newLineItem.vatRate
			delete newLineItem.vatRateId
			delete newLineItem.vatRateType
		}

		selectedLineItem && this.props.onChange(newLineItem, false)
	}

	onDescriptionChange = (value: FavoriteOptionType | string) => {
		if ('string' === typeof value) {
			this.changeDescription(value)
		} else {
			this.setNewLineItem(value)
		}
	}

	debounceLoadOptions = () => {
		let timeout
		return (filter: string) => {
			return new Promise((resolve: (options: Array<FavoriteOptionType>) => void) => {
				if (this.disableSearchFor && filter.startsWith(this.disableSearchFor)) {
					return resolve([])
				} else {
					this.disableSearchFor = null
				}
				const later = async () => {
					timeout = null
					const result = await this.loadOptions(filter)
					resolve(result)
				}
				timeout && clearTimeout(timeout)
				timeout = setTimeout(later, 250)
			})
		}
	}

	loadOptions = async (filter: string) => {
		if (!this.props.searchLineItems) return []
		const result = await this.props.searchLineItems(filter)
		if (!result || !result.lineItems || !result.lineItems.length) this.disableSearchFor = filter
		this.lineItems = result ? result.lineItems || [] : []
		return this.lineItemsToOptions(this.lineItems, this.props.favoriteLineItemIds)
	}

	getOptionText = (item: AccountingDocumentLineItem) => {
		const info = []
		!this.props.isAccDocVatFree && item.vatRate && info.push(`${item.vatRate} %`)
		undefined !== item.qt && item.unitName && info.push(`${item.qt} ${item.unitName}`)
		info.push(formatToMoney(item.total, { currency: this.props.currency }))
		return info.join(', ')
	}

	onLineItemFavorite = (id: ?string, isFavorite: boolean) => {
		id && this.props.onLineItemFavorite && this.props.onLineItemFavorite(id, isFavorite)
	}

	lineItemsToOptions = (lineItems: Array<AccountingDocumentLineItem>, favorites?: Array<string>) =>
		lineItems
			.map((item: AccountingDocumentLineItem) => {
				return {
					value: item.id,
					label: item.description,
					isFavorite: favorites && favorites.includes(item.id),
					onFavorite: this.onLineItemFavorite,
					rightText: this.getOptionText(item),
				}
			})
			.sort((item: FavoriteOptionType) => (item.isFavorite ? -1 : 1))

	getDefaultLineItems = memoize((lineItems?: Array<AccountingDocumentLineItem> | null, favorites?: Array<string>) =>
		lineItems ? this.lineItemsToOptions(lineItems, favorites) : null,
	)

	getFilteredVatRates = memoize((vatRates?: Array<VatRate> | null, financialAccount?: string): Array<VatRate> =>
		filterVatRates(vatRates, financialAccount),
	)

	render() {
		const { isAccDocVatFree, t, errors } = this.props
		const foundBranch = this.props.availableBranches.find((branch: EnumItem) => branch.key === this.props.value.branch)
		const foundProject = this.props.availableProjects.find(
			(project: EnumItem) => project.key === this.props.value.project,
		)
		const branch = (foundBranch && foundBranch.value) || this.props.value.branch
		const project = (foundProject && foundProject.value) || this.props.value.project
		const offset = this.props.index * 10

		const greenboxSuggestion = this.getGreenboxSuggestion(this.props.greenboxSuggestion, this.props.index)
		const hintedFinancialAccountIds = (greenboxSuggestion && greenboxSuggestion.financialAccountNumbers) || EMPTY_ARRAY
		const finAccountErrorId = this.getValErrorId('finAccount')
		const options = this.getOptions(
			this.props.financialAccounts,
			this.props.favoriteFinancialAccountIds,
			hintedFinancialAccountIds,
		)

		const descriptionErrors = getFormFieldErrorsFromMultiErros(errors, 'description')
		const finAccountErrors = getFormFieldErrorsFromMultiErros(errors, 'finAccount')
		const vatRateIdErrors = getFormFieldErrorsFromMultiErros(errors, 'vatRateId')
		const readonly = this.props.readonly || this.props.public

		return (
			<div
				ref="root"
				className={`${this.state.expanded ? styles.opened : styles.normal} ${isAccDocVatFree ? styles.vatFree : ''}`}
				{...autoTestId('lineItem')}
			>
				<div className={styles.inner}>
					<div className={styles.item}>
						<div css={style.description}>
							<FavoriteAutoComplete
								autoTestId="description"
								tabIndex={offset + 1}
								value={this.state.fieldState.description}
								disabled={readonly}
								onChange={this.onDescriptionChange}
								placeholder={t('invoice.lineItems.itemNameHint')}
								error={getTooltipErrorLabel(descriptionErrors, t)}
								/*
								Disbaled until OSS is ready
								loadOptions={this.debounceLoadOptions()}
								defaultOptions={this.getDefaultLineItems(this.props.defaultLineItems, this.props.favoriteLineItemIds)}
								*/
								multiline
								inline
							/>
						</div>
						{!this.props.public && (
							<div css={style.accountPicker}>
								<FavoriteSelect
									value={this.props.value && this.props.value.finAccount}
									onChange={this.onFinAccountChange}
									placeholder={t('invoice.lineItems.financialAccountHint')}
									options={options}
									error={
										getTooltipErrorLabel(finAccountErrors, t) ||
										(this.props.validationMessage &&
											this.props.validationMessage('finAccount') &&
											this.props.validationMessage(finAccountErrorId))
									}
									tabIndex={offset + 7}
									disabled={readonly}
									tooltip={this.getFinAccountTooltip(options, this.props.value.finAccount)}
									autoTestId="invoice-line-item-finaccount-select"
									inline
								/>
							</div>
						)}
						<div className={styles.projectBranchContainer}>
							{branch && <div className={styles.branch}>{branch}</div>}
							{project && <div className={styles.project}>{project}</div>}
						</div>
					</div>
					<div className={styles.price}>
						<CurrencyInput
							fullWidth
							right
							inline
							tabIndex={offset + 2}
							hintText={t('invoice.lineItems.priceHint')}
							disabled={this.props.isCalculating || readonly}
							value={this.state.fieldState.unitPriceVatExcl}
							onChange={this.onUnitPriceVatExclChange}
							onBlur={this.onBlur}
							autoTestId="unitPriceVatExcl"
							name="unitPriceVatExcl"
						/>
						{!isAccDocVatFree && (
							<div css={style.priceWrapper}>
								<span onClick={this.focusUnitPrice} css={style.withVatLabel}>
									{t('invoice.lineItems.withVatLabel')}
								</span>
								<CurrencyInput
									autoWidth
									right
									inline
									tabIndex={offset + 8}
									disabled={this.props.isCalculating || readonly}
									value={this.state.fieldState.unitPrice}
									onChange={this.onUnitPriceChange}
									onBlur={this.onBlur}
									autoTestId="unitPrice"
									name="unitPrice"
									minWidth={ZERO_PRICE_MIN_WIDTH}
									ref={this.unitPriceRef}
								/>
							</div>
						)}
					</div>

					{!isAccDocVatFree && !this.props.isCreating && (
						<div className={styles.vatRate}>
							<div css={style.vatRateSelector}>
								{this.props.public ? (
									<span css={style.publicVatRate}>
										{t(`invoice.lineItems.vatRateTypes.${this.props.value.vatRateType || 1}`, {
											vatRate: this.props.value.vatRate || 0,
										})}
									</span>
								) : (
									<VatRateSelect
										tabIndex={offset + 3}
										disabled={readonly}
										onChange={this.onVatRateChange}
										autoTestId="invoice-line-item-vatrate-select"
										error={getTooltipErrorLabel(vatRateIdErrors, t)}
										value={this.state.fieldState.vatRateId}
										vatRates={this.getFilteredVatRates(this.props.vatRates, this.props.value.finAccount)}
									/>
								)}
							</div>
						</div>
					)}

					<div className={styles.quantity}>
						<NumberInput
							name="qt"
							tabIndex={offset + 4}
							hintText={t('invoice.lineItems.amountHint')}
							disabled={readonly}
							inline
							autoTestId={'qt'}
							onBlur={this.onBlur}
							value={this.state.fieldState.qt}
							onChange={this.onQuantityChange}
						/>
					</div>
					<div className={styles.unit}>
						<TextField
							tabIndex={offset + 5}
							name="unitName"
							hintText={t('invoice.lineItems.unitNameHint')}
							disabled={readonly}
							inline
							style={style.unitName}
							onBlur={this.onBlur}
							value={this.state.fieldState.unitName}
							onChange={this.onUnitNameChange}
							autoTestId={'unitName'}
						/>
					</div>
					<div className={styles.total}>
						<CurrencyInput
							tabIndex={offset + 6}
							disabled={this.props.isCalculating || readonly}
							fullWidth
							value={this.state.fieldState.totalVatExcl}
							onChange={this.onTotalVatExclChange}
							onBlur={this.onBlur}
							autoTestId="totalVatExcl"
							right
							inline
							name="totalVatExcl"
						/>
						{!isAccDocVatFree && (
							<div css={style.priceWrapper}>
								<span onClick={this.focusTotal} css={style.withVatLabel}>
									{t('invoice.lineItems.withVatLabel')}
								</span>
								<CurrencyInput
									autoWidth
									right
									tabIndex={offset + 9}
									disabled={this.props.isCalculating || readonly}
									value={this.state.fieldState.total}
									onChange={this.onTotalChange}
									onBlur={this.onBlur}
									autoTestId="total"
									inline
									name="total"
									minWidth={ZERO_PRICE_MIN_WIDTH}
									ref={this.totalRef}
								/>
							</div>
						)}
						{this.renderSideButtons(style)}
					</div>
					{this.renderExpandButton(style)}
					{this.renderExpandedPart(style)}
				</div>
			</div>
		)
	}
}

const style = {
	publicVatRate: {
		marginLeft: 4,
		lineHeight: '24px',
	},
	expandIcon: {
		height: 13,
		width: 28,
		padding: 0,
	},
	reduceIcon: {
		height: 13,
		width: 28,
		padding: 0,
		transform: 'rotate(180deg) translateY(4px)',
	},
	sideButton: { padding: 5 },
	description: { marginLeft: -4 },
	accountPicker: { marginLeft: -4 },
	unitName: { height: 24, lineHeight: '24px' },
	withVatLabel: { paddingRight: 3, verticalAlign: 'middle', lineHeight: '24px' },
	priceWrapper: {
		display: 'flex',
		justifyContent: 'flex-end',
		textAlign: 'right',
	},
	vatRateSelector: { margin: '0 -4px' },
}

export default withTranslate(LineItem)

function isGreenboxSuggestionEqual(
	sugg1: ?GreenboxSuggestionLineItemResponse,
	sugg2: ?GreenboxSuggestionLineItemResponse,
): boolean {
	return isEqual(sugg1 && sugg1.financialAccountNumbers, sugg2 && sugg2.financialAccountNumbers)
}
