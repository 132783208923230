//@flow
import { find, pullAt } from 'lodash-es'
import type { PagerProps } from 'types'
export { Validator } from './validators'

export function log(...args: Array<any>) {
	console.log(...args) //eslint-disable-line no-console
}

export function genSeries(start: number, to: number) {
	let series = []
	for (let i = start; i < to + 1; i++) {
		series.push(i)
	}
	return series
}

export function combine<TA, TB, TResult>(
	aa: Array<TA>,
	ab: Array<TB>,
	comparator: (TA, TB) => boolean,
	combiner: (?TA, ?TB) => TResult,
): Array<TResult> {
	const result: Array<TResult> = []
	const rest: Array<TB> = [...ab]

	for (let ai = 0; ai < aa.length; ai++) {
		const a = aa[ai]
		const b = find(rest, (b: TB) => comparator(a, b))
		pullAt(rest, rest.indexOf(b))

		result.push(combiner(a, b))
	}

	for (let bi = 0; bi < rest.length; bi++) {
		const b = rest[bi]

		result.push(combiner(null, b))
	}

	return result
}

export function refererRoute() {
	if (
		window.previousLocation &&
		window.previousLocation.indexOf('/new') == -1 &&
		window.location.pathname != window.previousLocation
	) {
		return window.previousLocation
	} else {
		return null
	}
}

export function isNullOrWhitespace(input: ?string) {
	if (typeof input === 'undefined' || input == null) return true
	return input.replace(/\s/g, '').length < 1
}

export function getPagerIndexes(pagerProps: PagerProps): { indexFrom: number, indexTo: number } {
	const { currentPage, pageSize } = pagerProps

	const indexFrom: number = (currentPage - 1) * pageSize
	const indexTo: number = indexFrom + pageSize - 1
	return { indexFrom, indexTo }
}

export function compareStringValues(str1: ?string, str2: ?string): boolean {
	if (!str1 && !str2) {
		return !str1 === !str2
	} else {
		return str1 === str2
	}
}

export function compareNumberValues(number1: ?number, number2: ?number): boolean {
	return number1 === number2
}

export function getInitialsFromName(name: string): string {
	const names = name.split(' ')
	return names[0].substring(0, 1) + (names.length > 1 ? names[names.length - 1].substring(0, 1) : '')
}

export function notNullAndUndefined(value?: ?number): boolean {
	return value !== null && value !== undefined
}
