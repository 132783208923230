/* @flow */

import { connect } from 'react-redux'
import { withAutoload } from 'wrappers'
import { loadCountries } from 'modules/common/actions'
import { getCountryName } from 'modules/common/selectors'
import type { AutoTestProps } from 'utils/tests/autotest'
import type { AccountingDocumentContact, CommonAction, Dispatch, State } from 'types'
import ContactDetail from '../../../components/invoice-elements/contact/contact-detail'

export type OwnProps = {|
	contact: ?AccountingDocumentContact,
	public?: boolean,
	isMe?: boolean,
	showLoader?: boolean,
	...AutoTestProps,
|}

type StateProps = {|
	countryName: ?string,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	return {
		countryName: ownProps.public
			? ownProps.contact && ownProps.contact.country
			: getCountryName(state, ownProps.contact && ownProps.contact.country),
	}
}

type DispatchProps = {
	autoload: () => void,
}

const mapDispatchToProps = (dispatch: Dispatch<CommonAction>, ownProps: OwnProps): DispatchProps => {
	return {
		autoload: () => {
			!ownProps.public && dispatch(loadCountries())
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withAutoload(ContactDetail))
