// @flow

import {
	contactsSearch as contactsSearchApi,
	tasksSearch as tasksSearchApi,
	accountingDocumentSearch as accountingDocumentSearchApi,
	tagsSearch as tagsSearchApi,
	financialAccountsSearch as financialAccountsSearchApi,
	unmatchedPaymentsSearch as unmatchedPaymentsSearchApi,
	bankAccountPaymentOrdersSearch,
	bankAccountStatementsSearch,
} from 'modules/common/models/api-model'
import type { DataType } from '../types'
import type { AccountingDocumentSearchPostParams, SearchRequestGeneric, DataGridId } from 'types'
import { type MethodOptions } from 'lib/apiHandler'

// TODO export only until dataGridNext will be implemented everywhere (TWU-1507)
// this function has different order of params then generated method in api-model
export const accDocGridSearch = {
	post: async function(body: SearchRequestGeneric, options?: MethodOptions, params?: AccountingDocumentSearchPostParams) { //eslint-disable-line
		return accountingDocumentSearchApi.post(params || {}, body, options)
	},
}

export const apiCallFunctions: { [DataType]: { [string]: Function } } = {
	accountingDocuments: accDocGridSearch,
	contacts: contactsSearchApi,
	banks: contactsSearchApi,
	tasks: tasksSearchApi,
	tags: tagsSearchApi,
	financialAccounts: financialAccountsSearchApi,
	unmatchedPayments: unmatchedPaymentsSearchApi,
	paymentOrders: bankAccountPaymentOrdersSearch,
	statements: bankAccountStatementsSearch,
}

export const dataInitialState = {
	accountingDocuments: undefined,
	contacts: undefined,
	banks: undefined,
	tasks: undefined,
	tags: undefined,
	financialAccounts: undefined,
	unmatchedPayments: undefined,
	paymentOrders: undefined,
	statements: undefined,
}

export const apiCallQueryParams: { [DataGridId]: Object } = {
	accountingDocumentPicker: { scope: 'CA' },
}

export const FINANCIAL_ACCOUNTS_GRID_ID: DataGridId = 'financialAccounts'
export const FINANCIAL_ACCOUNTS_DATA_TYPE = 'financialAccounts'
