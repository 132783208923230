/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { getTooltipErrorLabel } from 'helpers'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { autoTestId, passAutoTestId } from 'utils/tests/autotest'
import type { FormFieldErrorMessage } from 'types'
import SelectField from 'components/select-field'
import MenuItem from 'components/menu-item'

export type Props = {|
	readonly?: boolean,
	paymentType: ?number,
	availablePaymentTypes: Array<{ type: number, name: string }>,
	errors: ?Array<FormFieldErrorMessage>,
	removeError: () => void,
	onPaymentTypeChange: (paymentType: number) => void,
|}

type ComponentProps = {|
	...WithTranslateProps,
	...Props,
|}

class PaymentTypeSelect extends Component<ComponentProps> {
	onPaymentTypeChange = (event: Event, index: number, paymentType: number) => {
		this.props.onPaymentTypeChange(paymentType)
		this.props.removeError()
	}

	render() {
		const { t, errors } = this.props

		return (
			<div {...autoTestId('paymentType')}>
				<h4 css={styles.headline}>{t('invoice.paymentType.headline')}</h4>
				<div css={styles.root}>
					<SelectField
						disabled={this.props.readonly}
						hintText={t('invoice.paymentType.hint')}
						onChange={this.onPaymentTypeChange}
						value={this.props.paymentType}
						clientError={getTooltipErrorLabel(errors, t)}
						{...passAutoTestId('paymentTypeMenu')}
						inline
					>
						{this.props.availablePaymentTypes.map((paymentType: { type: number, name: string }) => (
							<MenuItem key={paymentType.type} value={paymentType.type} primaryText={t(paymentType.name)} />
						))}
					</SelectField>
				</div>
			</div>
		)
	}
}

const styles = {
	root: {
		fontSize: 14,
		color: '#000000',
		fontWeight: 'bold',
		margin: '-2px 0 2px 0',
		padding: 0,
	},
	headline: {
		opacity: 0.8,
		fontSize: 10,
		color: '#000000',
		lineHeight: '12px',
		fontStyle: 'italic',
		fontWeight: 'normal',
		margin: 0,
	},
}

export default withTranslate(PaymentTypeSelect)
