/* @flow */

import { connect } from 'react-redux'
import PaymentDetails from 'modules/accounting-document/components/invoice-elements/payment-details'
import { updateAccountingDocumentDueDate, updateAccountingDocumentVariableSymbol } from '../../actions'
import type { State, AccountingDocument } from 'types'
import { getAccountingDocument } from 'modules/accounting-document/selectors'

export type OwnProps = {|
	accountingDocumentId: string,
	readonly?: boolean,
	readonlyDueDate?: ?boolean,
|}

type StateProps = {|
	dueDate: ?string,
	issueDate: ?string,
	variableSymbol: ?string,
	variableSymbolPreview: ?string,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const doc: ?AccountingDocument = getAccountingDocument(state, ownProps.accountingDocumentId)
	const { variableSymbolPreview, variableSymbol, issueDate, dueDate } = doc || {}
	return {
		variableSymbol,
		variableSymbolPreview,
		dueDate: dueDate,
		issueDate: issueDate,
	}
}

type DispatchProps = {|
	onDueDateChange: (dueDate: ?Date) => void,
	onVariableSymbolChange: (variableSymbol: ?string) => void,
|}

const mapDispatchToProps = (dispatch: any, ownProps: OwnProps): DispatchProps => {
	return {
		onDueDateChange: (dueDate: ?Date) => {
			dispatch(updateAccountingDocumentDueDate(ownProps.accountingDocumentId, dueDate))
		},
		onVariableSymbolChange: (variableSymbol: ?string) => {
			dispatch(updateAccountingDocumentVariableSymbol(ownProps.accountingDocumentId, variableSymbol))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails)
