// @flow

import moment from 'moment'
import type {
	AccountingDocument,
	AccountingDocumentType,
	AccountingDocumentDirection,
	AccountingDocumentLineItem,
	AccountingDocumentConnection,
	AccountingDocumentIntegrationResponse,
	State,
	Sequence,
	Dispatch,
	AccountingDocumentIntegrationDataCashbotResponse,
	OrganizationSettingsTaxPaymentHistory,
} from 'types'
import { VatRateName_VatRateType, PayerTaxType_PayerTaxName, AccountingDocumentType_Number } from 'types/convertor'
import { getAccountingDocumentSequences } from 'modules/accounting-document/selectors'
import { getPayerTaxType, getUseOnlyOneSequenceSetPerCashRegisterFlag } from 'modules/settings/selectors'
import { emptyAccountingDocumentLineItem } from 'types/empty'
import {
	AccountingDocumentTypeNumber_isCashRegister,
	AccountingDocument_isProcessed,
	AccountingDocument_isNonCashRegister,
	AccountingDocument_isReceived,
	AccountingDocument_isIssued,
} from 'types/operations'
import { accountingDocumentValidate as accountingDocumentValidateApi } from 'modules/common/models/api-model'
import { validateAccountingDocumentError } from 'modules/accounting-document/actions'
import { ACC_DOC_FILTER_NAME } from '../constants'

export function getDuePeriod(doc: AccountingDocument): number {
	if (!doc.dueDate || !doc.issueDate) {
		return 0
	}

	return moment(doc.dueDate).diff(moment(doc.issueDate), 'days')
}

export function getConnectionFromDocument(doc: AccountingDocument): AccountingDocumentConnection {
	return {
		connectedAccountingDocumentId: doc.id || '',
		connectedAccountingDocumentNo: doc.accountingDocumentNo,
		vatAmounts: !doc.total ? doc.vatRecap : undefined,
		amount: !doc.vatRecap || doc.vatRecap.length === 0 ? doc.total : undefined,
	}
}

export function isAccDocDomestic(accDoc: ?AccountingDocument): boolean {
	return accDoc ? accDoc.vatCountryType === VatRateName_VatRateType('domestic') : false
}

export function isCurrentOrganizationVatFree(state: State): boolean {
	const payerTaxType = getPayerTaxType(state)
	return PayerTaxType_PayerTaxName(payerTaxType) === 'VATfree'
}

export function AccDocs_numberById(accDocs: Array<AccountingDocument>, accDocId: string): ?string {
	const found: ?AccountingDocument = accDocs.find((accDoc: AccountingDocument) => accDoc.id === accDocId)
	return (found && found.accountingDocumentNo) || null
}

/*
Compares two AccountingDocumentLineItem objects
Returns true if all fields, which are present in both objects, has the same value
*/
export function AccountingDocumentLineItem_equalExistingValues(
	item1: AccountingDocumentLineItem,
	item2: AccountingDocumentLineItem,
): boolean {
	type FieldName = $Keys<AccountingDocumentLineItem>

	const FIELDS_TO_CHECK: Array<FieldName> = [
		// 'id',
		'description',
		'finAccount',
		'unitPrice',
		'unitName',
		'qt',
		'total',
		'totalVatExcl',
		'reverseChargeCode',
		'storeItemId',
		'unitPriceVatExcl',
		'vatValue',
		'vatRate',
		'vatRateType',
		'vatRateId',
		'branch',
		'project',
		'vatDeduction',
		'greenboxFinancialAccountStatus',
	]

	const someNotEqual = FIELDS_TO_CHECK.some((field: FieldName) => {
		return item1.hasOwnProperty(field) && item2.hasOwnProperty(field) && item1[field] !== item2[field]
	})

	return !someNotEqual
}

export function createEmptyLineItem(
	defaultVatRateId?: string,
	currentOrganizationIsVatFree?: boolean,
): AccountingDocumentLineItem {
	let item: AccountingDocumentLineItem = emptyAccountingDocumentLineItem()
	if (defaultVatRateId && !currentOrganizationIsVatFree) {
		item = { ...item, vatRateId: defaultVatRateId }
	}
	return item
}

export function useSimplifiedNumbering(type: ?AccountingDocumentType, state: State): ?boolean {
	return (
		type &&
		AccountingDocumentTypeNumber_isCashRegister(AccountingDocumentType_Number(type)) &&
		getUseOnlyOneSequenceSetPerCashRegisterFlag(state)
	)
}

export function getDefaultSequence(
	state: State,
	type: ?AccountingDocumentType,
	direction: ?AccountingDocumentDirection,
	cashRegisterId?: ?string,
	validityDate?: Date,
): ?Sequence {
	const sequences =
		getAccountingDocumentSequences(
			state,
			direction,
			type,
			cashRegisterId,
			validityDate || new Date(),
			!!useSimplifiedNumbering(type, state),
		) || []
	return sequences.length > 0 ? sequences[0] : null
}

export function getSentEmailsFromDocument(doc: AccountingDocument): ?Array<AccountingDocumentIntegrationResponse> {
	return (
		doc._integrations &&
		doc._integrations.integrations &&
		doc._integrations.integrations.filter((integration: AccountingDocumentIntegrationResponse) => {
			return 'send_email' === integration.type && 2 === integration.direction
		})
	)
}

export async function isAccDocValid(
	accountingDocumentId: string,
	state: string,
	dispatch: Dispatch<any>,
	muteErrors?: boolean,
): Promise<boolean> {
	try {
		await accountingDocumentValidateApi.put({ accountingDocumentId }, { state })
		return true
	} catch (e) {
		!muteErrors && dispatch(validateAccountingDocumentError(e, accountingDocumentId, state))
		return false
	}
}

export function isAccDocReadOnly(
	accountingDocument: AccountingDocument,
	internal: boolean,
	canEditIssuedAccountingDocuments: boolean,
	canEditReceivedAccountingDocuments: boolean,
): boolean {
	const isProcessed = (accountingDocument && AccountingDocument_isProcessed(accountingDocument)) || false
	const isReceived = (accountingDocument && AccountingDocument_isReceived(accountingDocument)) || false
	const isIssued = (accountingDocument && AccountingDocument_isIssued(accountingDocument)) || false
	const isNonCashRegister = (accountingDocument && AccountingDocument_isNonCashRegister(accountingDocument)) || false

	return (
		isProcessed ||
		(isNonCashRegister && isIssued && !canEditIssuedAccountingDocuments) ||
		(isNonCashRegister && isReceived && !canEditReceivedAccountingDocuments) ||
		// TWU-1542:
		// pokud nejsem interni, tak u prijateho nepokladniho dokladu (tzn. faktura, zaloha, ddz, opravy) se mi vzdy musi zobrazit pouze Nahled.
		(!internal && isReceived && isNonCashRegister)
	)
}

export function getAccDocIntegrations(accDoc: ?AccountingDocument): ?Array<AccountingDocumentIntegrationResponse> {
	return (accDoc && accDoc._integrations && accDoc._integrations.integrations) || undefined
}

export function getAccDocIntegrationsByType(
	accDoc: ?AccountingDocument,
	type: 'send_email' | 'cashbot',
): ?Array<AccountingDocumentIntegrationResponse> {
	const integrations = getAccDocIntegrations(accDoc)
	return (
		integrations &&
		integrations.filter((integration: AccountingDocumentIntegrationResponse) => {
			return integration.type === type
		})
	)
}

export function getAccDocCashbot(accDoc: ?AccountingDocument): ?AccountingDocumentIntegrationDataCashbotResponse {
	const integrations = getAccDocIntegrationsByType(accDoc, 'cashbot')
	const cashbot = integrations && integrations[0]
	return cashbot && cashbot.data && cashbot.data.cashbot
}

export function getAccDocCashbotId(accDoc: ?AccountingDocument): ?number {
	const cashbot = getAccDocCashbot(accDoc)
	const cashbotId: ?string = cashbot && cashbot.cashbotId
	return (cashbotId && Number.parseInt(cashbotId)) || null
}

export function wasTaxPaymentTypeInDate(
	taxPaymentHistory: ?Array<OrganizationSettingsTaxPaymentHistory>,
	taxPaymentType: number,
	date: Date,
): boolean {
	return (taxPaymentHistory || [])
		.filter((hist: OrganizationSettingsTaxPaymentHistory) => hist.taxPaymentType === taxPaymentType)
		.some((hist: OrganizationSettingsTaxPaymentHistory) => {
			const from = hist.dateFrom && new Date(hist.dateFrom)
			const to = hist.dateTo && new Date(hist.dateTo)
			return from && to && from <= date && date <= to
		})
}

export function isAccDocOverdue(accDoc: ?AccountingDocument): boolean {
	if (!accDoc || !accDoc.dueDate) return false
	return moment(accDoc.dueDate).isBefore(moment())
}

export function isAccountingDocumentExtractable(accDocState: ?string): boolean {
	return 'Extracting' === accDocState || 'Uploaded' === accDocState
}

export function getAccDocFilterName(direction?: ?AccountingDocumentDirection): string {
	if (!direction) return ''
	return `${ACC_DOC_FILTER_NAME}/${direction}`
}

export function deleteVatRateFromLineItem(item: AccountingDocumentLineItem): AccountingDocumentLineItem {
	delete item.vatRate
	delete item.vatRateId
	delete item.vatRateType
	return item
}
