/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { uniqBy } from 'lodash-es'
import memoize from 'memoize-one'
import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { autoTestId } from 'utils/tests/autotest'
import type { Sequence, AccountingDocumentType, FormFieldErrorMessage } from 'types'
import ArrowDropDown from 'components/svg-icons/navigation/arrow-drop-down'
import ArrowDropUp from 'components/svg-icons/navigation/arrow-drop-up'
import IconMenu from 'components/icon-menu'
import MenuItem from 'components/menu-item'
import MenuCategory from 'components/menu-category'
import Divider from 'components/divider'
import TextField from 'components/TextField'
import type { ValidationMessageType, ValidationValueType } from 'wrappers/validate'
import { MAX_DOCUMENT_NUMBER_LENGTH } from 'modules/accounting-document/constants'
import { getTooltipErrorLabel } from 'helpers'
import { colors } from 'variables'

type Props = {|
	...WithTranslateProps,
	accountingDocumentId: string,
	direction: 'issued' | 'received',
	type: AccountingDocumentType,
	disableExplicitNo?: boolean,
	readonly?: boolean,
	sequenceId: ?string,
	explicitNo: ?string,
	externalNo: ?string,
	accountingDocumentNo: ?string,
	accountingDocumentNoPreview: ?string,
	cashRegisterId: ?string,
	onExplicitNoChange: (explicitNo: string) => void,
	onSequenceIdChange: (sequenceId: string) => void,
	onExternalNoChange: (externalNo: string) => void,
	availableSequences: ?Array<Sequence>,
	isAllowedToChangeToSequence: boolean,
	validationMessage?: ValidationMessageType,
	validationValue?: ValidationValueType,
	isTemplate?: boolean,
	errors: ?Array<FormFieldErrorMessage>,
	removeError: () => void,
|}

type State = {|
	isOwnId: boolean,
	textValue: string,
	explicitNo: string,
	externalNo: ?string,
	sequenceName?: string,
	editingField: ?string,
|}

export const OWN_ID: string = '__OWN_ID__'

class InvoiceHeaderNo extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = this.getStateFromProps(this.props)
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		this.setState(this.getStateFromProps(nextProps))
	}

	getStateFromProps(props: Props): State {
		const { editingField } = this.state || {}

		const newState: State = {
			editingField: editingField || null,
			isOwnId: !props.sequenceId,
			explicitNo: props.explicitNo || '',
			externalNo: props.externalNo,
			textValue: this.getTextValueFromProps(props),
			sequenceName: this.getSequenceNameBySequenceId(props.availableSequences, props.sequenceId),
		}

		let state = {}
		if (editingField) {
			if (editingField === 'explicitNo') {
				state = {
					explicitNo: this.state.explicitNo,
					textValue: this.state.textValue,
				}
			} else {
				state = { [editingField]: this.state[editingField] }
			}
		}

		return Object.freeze({ ...newState, ...state })
	}

	getTextValueFromProps(props: Props): string {
		if (!props.isTemplate && props.sequenceId && props.accountingDocumentNo) {
			return props.accountingDocumentNo
		}
		if (!props.isTemplate && props.sequenceId && !props.accountingDocumentNo && props.accountingDocumentNoPreview) {
			return props.accountingDocumentNoPreview
		}
		if (!props.isTemplate && props.explicitNo) {
			return props.explicitNo
		}
		if (props.availableSequences) {
			return this.getTextValueBySequenceId(props.availableSequences, props.sequenceId)
		}
		return ''
	}

	getTextValueBySequenceId(sequences: ?Array<Sequence>, sequenceId: ?string): string {
		const found: ?Sequence = (sequences || []).find((s: Sequence) => {
			return s.id === sequenceId
		})
		return (found && found.exampleNo) || ''
	}

	getSequenceNameBySequenceId = (sequences: ?Array<Sequence>, sequenceId: ?string): string => {
		const found: ?Sequence = (sequences || []).find((s: Sequence) => {
			return s.id === sequenceId
		})
		return (found && found.name) || ''
	}

	onExplicitChange = (event: SyntheticInputEvent<HTMLInputElement>, explicitNo: ?string) => {
		if (explicitNo !== null && explicitNo !== undefined) {
			this.setState({
				explicitNo,
				textValue: explicitNo,
				editingField: 'explicitNo',
			})
		}
		this.props.removeError()
	}

	onExternalChange = (event: SyntheticInputEvent<HTMLInputElement>, externalNo: ?string) => {
		if (externalNo !== null && externalNo !== undefined) {
			this.setState({
				externalNo,
				editingField: 'externalNo',
			})
		}
		this.props.removeError()
	}

	onExplicitBlur = () => {
		this.setState({ editingField: null })
		if ((this.props.explicitNo || '') != this.state.explicitNo) {
			this.props.onExplicitNoChange(this.state.explicitNo)
		}
	}

	onExternalBlur = () => {
		this.setState({ editingField: null })
		if (this.props.externalNo != this.state.externalNo) {
			this.props.onExternalNoChange(this.state.externalNo || '')
		}
	}

	handleSequenceChange = (event: SyntheticEvent<HTMLElement>, sequenceId: string) => {
		if (sequenceId === OWN_ID) {
			this.setState({
				isOwnId: true,
				textValue: this.state.explicitNo,
				sequenceName: '',
			})
			this.props.onExplicitNoChange(this.state.explicitNo)
		} else {
			this.setState({
				isOwnId: false,
				textValue: this.getTextValueBySequenceId(this.props.availableSequences, sequenceId),
				sequenceName: this.getSequenceNameBySequenceId(this.props.availableSequences, sequenceId),
			})
			this.props.onSequenceIdChange(sequenceId)
		}
		this.props.removeError()
	}

	renderSequences = () => {
		let items: Array<?React$Element<typeof MenuItem | typeof Divider | typeof MenuCategory>> = []

		if (this.props.isAllowedToChangeToSequence && this.props.availableSequences) {
			items = this.props.availableSequences.map((item: Sequence) => (
				<MenuItem key={item.id} value={item.id} primaryText={item.name || item.exampleNo} />
			))
		}

		if (!this.props.disableExplicitNo) {
			items = [...items, <MenuItem key={OWN_ID} value={OWN_ID} primaryText={this.props.t('invoice.number.ownNo')} />]
		}

		return items
	}

	renderExternalNo = () => {
		const { t } = this.props
		const styles = this.getStyles(this.props.readonly)

		if (this.props.direction !== 'received') {
			return null
		}

		if (this.props.readonly && !this.state.externalNo) {
			return null
		}

		return (
			<div css={styles.external}>
				<span css={styles.externalLabel}>{t('invoice.number.externalNo')}&nbsp;</span>
				<TextField
					autoWidth
					inline
					name="externalNo"
					hintText={t('invoice.number.externalNoHint')}
					autoTestId="header-no-external-no"
					disabled={this.props.readonly}
					value={this.state.externalNo}
					onBlur={this.onExternalBlur}
					onChange={this.onExternalChange}
				/>
			</div>
		)
	}

	getStyles = memoize((readonly: ?boolean) => {
		return {
			root: {
				flex: '1 1 0',
			},
			select: {
				fontSize: 16,
				color: colors.black,
				letterSpacing: 0,
			},
			nos: {
				display: 'flex',
				justifyContent: 'flex-end',
				alignItems: 'flex-end',
			},
			label: {
				marginBottom: 2,
			},
			no: {
				flex: '0 0 auto',
				marginBottom: 2,
			},
			dropdown: {
				flex: '0 0 24px',
			},
			sequence: {
				fontSize: 12,
				color: colors.blackFaded80,
				letterSpacing: 0,
				fontWeight: 'normal',
				marginRight: readonly ? 0 : 26,
				lineHeight: '12px',
				minHeight: 12,
				textAlign: 'right',
			},
			external: {
				fontSize: 12,
				lineHeight: '20px',
				marginTop: -4,
				textAlign: 'right',
				marginRight: readonly ? 0 : 24,
			},
			externalLabel: {
				verticalAlign: 'bottom',
			},
		}
	})

	render() {
		const { accountingDocumentNo, disableExplicitNo, readonly, t, validationValue, validationMessage } = this.props
		const { isOwnId, sequenceName, textValue } = this.state
		const value: ?string = !readonly ? textValue : accountingDocumentNo
		const showNo = !readonly || (readonly && value)
		const styles = this.getStyles(readonly)
		const error =
			getTooltipErrorLabel(uniqBy(this.props.errors, 'code'), t) ||
			(validationMessage && validationMessage('sequenceId'))

		return (
			<div css={styles.root} id={'invoice-header'} {...autoTestId('documentNumber')}>
				{showNo && (
					<div css={styles.select}>
						{sequenceName && <div css={styles.sequence}>{sequenceName}</div>}
						<div css={styles.nos}>
							<span css={styles.label}>{t('invoice.number.documentNo')}&nbsp;</span>
							<span css={styles.no}>
								<TextField
									autoWidth
									inline
									name="explicitNo"
									autoTestId="header-no-explicit-no"
									disabled={!isOwnId || readonly || disableExplicitNo}
									value={validationValue == null ? value : validationValue('sequenceId', value)}
									onBlur={this.onExplicitBlur}
									onChange={this.onExplicitChange}
									hintText={t('invoice.number.ownNoHint')}
									maxLength={MAX_DOCUMENT_NUMBER_LENGTH}
									clientError={error}
								/>
							</span>
							{!readonly ? (
								<span css={styles.dropdown}>
									<IconMenu
										context
										icon={<ArrowDropDown size={20} />}
										closeIcon={<ArrowDropUp size={20} />}
										onChange={this.handleSequenceChange}
										disabled={readonly}
										autoTestId="invoice-header-no-sequences"
									>
										{this.renderSequences()}
									</IconMenu>
								</span>
							) : null}
						</div>
						{this.renderExternalNo()}
					</div>
				)}
			</div>
		)
	}
}

export default withTranslate(InvoiceHeaderNo)
