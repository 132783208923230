// @flow
/** @jsx jsx */

import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import ContentAdd from 'components/svg-icons/content/add'
import Avatar from 'components/avatar'
import Tooltip from 'components/tooltip'
import { colors } from 'variables'

type Props = {|
	...WithTranslateProps,
	error?: ?React$Element<any>,
	onClick: () => void,
|}

function LineItems(props: Props) {
	const style = getStyles(!!props.error)

	return (
		<Tooltip label={props.error}>
			<div id={'add-line-item'} css={style.add} onClick={props.onClick}>
				<div css={style.new}>
					<Avatar size={16} icon={<ContentAdd disabled />} backgroundColor={colors.orange} />
				</div>
				<div>{props.t('invoice.lineItems.addNewLineItem')}</div>
			</div>
		</Tooltip>
	)
}

const getStyles = memoize((error: boolean) => {
	return {
		new: {
			marginRight: 8,
		},
		add: {
			display: 'flex',
			marginTop: 17,
			padding: 20,
			borderWidth: 0.5,
			borderStyle: error ? 'solid' : 'dashed',
			borderColor: error ? colors.red : '#323232',
			background: error ? colors.redFaded4 : colors.white,
			borderRadius: 3,
			fontSize: 11,
			color: '#000000',
			fontWeight: 'bold',
			textAlign: 'center',
			cursor: 'pointer',
			alignItems: 'center',
			justifyContent: 'center',
			textTransform: 'uppercase',
			'&:hover': {
				background: 'rgba(0, 0, 30, 0.02)',
			},
		},
	}
})

export default withTranslate(LineItems)
