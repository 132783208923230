/* @flow */

import { connect } from 'react-redux'
import type { AccountingDocument, State } from 'types'
import { getPublicAccountingDocument, getPublicAccountingDocumentColor } from '../../selectors'
import { loadPublicAccountingDocument } from '../../actions'
import PublicInvoiceDocument from '../../components/invoices/public-invoice-document'

type StateProps = {|
	color: ?string,
	accountingDocument: ?AccountingDocument,
|}

function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
	return {
		accountingDocument: getPublicAccountingDocument(state, ownProps.uniqueId),
		color: getPublicAccountingDocumentColor(state, ownProps.uniqueId),
	}
}

type DispatchProps = {|
	loadPublicAccountingDocument: (uniqueId: string) => Promise<void>,
|}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
	return {
		loadPublicAccountingDocument: (uniqueId: string) => {
			return dispatch(loadPublicAccountingDocument(uniqueId))
		},
	}
}

type OwnProps = {|
	uniqueId: string,
|}

export default connect(mapStateToProps, mapDispatchToProps)(PublicInvoiceDocument)
