//@flow

import type { DashboardAction, GenericReport } from 'types'

//TODO: [TK] rename to report reducer

export type State = {
	reports: Array<GenericReport>,
	report: ?GenericReport,
	reportId: ?string,
}

export const initialState: State = {
	reports: [],
	report: null,
	reportId: null,
}

export default (state: State = initialState, action: DashboardAction): State => {
	switch (action.type) {
		case 'START_LOAD_REPORTS': {
			return {
				...state,
				//reports: [],
			}
		}
		case 'FINISH_LOAD_REPORTS': {
			return {
				...state,
				reports: [...(action.reports.genericReports || [])],
			}
		}
		case 'FAIL_LOAD_REPORTS': {
			return {
				...state,
				reports: [],
			}
		}
		case 'START_LOAD_REPORT': {
			return {
				...state,
				report: null,
				reportId: action.reportId,
			}
		}
		case 'FINISH_LOAD_REPORT': {
			return {
				...state,
				report: action.report,
			}
		}
	}

	return state
}
