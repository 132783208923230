/* @flow */

import React, { Component, type Node } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import Clear from 'components/svg-icons/content/clear'
import RenderToLayer from 'components/internal/RenderToLayer'
import styles from './PopupSection.css'
import { colors } from 'variables'

type Props = {|
	...WithTranslateProps,
	children: Node,
	className?: string,
	open?: boolean,
	opaque?: boolean,
	closeText?: string,
	notClosable?: boolean,
	notPopoverTarget: boolean,
	target?: ?Node,
	onRequestClose: (?string) => void,
	zIndex?: number,
|}

type State = {|
	isCssBlurSupported: boolean,
|}

class PopupSection extends Component<Props, State> {
	state = {
		isCssBlurSupported:
			!!window.MSInputMethodContext && !!document.documentMode
				? false
				: // $FlowFixMe (CSS is global object provided by browser)
				  CSS &&
				  CSS.supports &&
				  (CSS.supports('backdrop-filter', 'blur(9px)') || CSS.supports('-webkit-backdrop-filter', 'blur(9px)')),
	}

	componentDidMount() {
		if (true === this.props.open) {
			this.updateBlur(true)
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.open !== nextProps.open) {
			this.updateBlur(nextProps.open)
		}
	}

	componentWillUnmount() {
		this.updateBlur(false)
	}

	updateBlur = (open: ?boolean) => {
		if (this.state.isCssBlurSupported) return
		const element = document.getElementById('root')
		if (open) {
			element && element.setAttribute('class', 'blur')
		} else {
			element && element.removeAttribute('class')
		}
	}

	renderLayer = () => {
		if (!this.props.open) return null

		const attributes = {}
		if (!this.props.notPopoverTarget) attributes['data-popover-target'] = true

		return (
			<div className={this.props.opaque ? styles.opaque : styles.normal} {...attributes}>
				{!this.props.opaque && <div className={styles.background} />}
				{!this.props.opaque && <div className={styles.overlay} />}
				<div className={styles.container}>{this.props.children}</div>
				{!this.props.notClosable && (
					<div className={styles.close} onClick={this.handleCloseClick}>
						<div className={styles.closeIcon}>
							<Clear color={colors.black} hoverColor={colors.black} />
						</div>
						<div className={styles.closeText}>
							{this.props.closeText || this.props.t('application.closePopupSection')}
						</div>
					</div>
				)}
			</div>
		)
	}

	requestClose(reason: string) {
		if (this.props.onRequestClose) return this.props.onRequestClose(reason)
	}

	handleCloseClick = (event: SyntheticMouseEvent<HTMLElement>) => {
		event.preventDefault()
		this.requestClose('closeClick')
	}

	render() {
		return (
			<RenderToLayer
				target={this.props.target}
				ref="layer"
				open={this.props.open}
				useLayerForClickAway={false}
				render={this.renderLayer}
				zIndex={this.props.zIndex}
			/>
		)
	}
}

export default withTranslate(PopupSection)
