/* @flow */

import React, { Component } from 'react'
import memoize from 'memoize-one'
import { convertToDate } from 'utils/formatters'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import DatePicker from 'components/date-picker'
import VariableSymbolInput from 'components/variable-symbol-input'
import styles from './payment-details.css'

type Props = {|
	...WithTranslateProps,
	accountingDocumentId: string,
	issueDate: ?string,
	dueDate: ?string,
	readonly?: boolean,
	readonlyDueDate?: ?boolean,
	onDueDateChange: (dueDate: ?Date) => void,
	variableSymbol: ?string,
	variableSymbolPreview: ?string,
	onVariableSymbolChange: (variableSymbol: ?string) => void,
	isTemplate?: boolean,
|}

export type State = {|
	dueDate: ?Date,
	variableSymbol: ?string,
	editingField: ?string,
|}

class PaymentDetails extends Component<Props, State> {
	state: State = {
		dueDate: this.props.dueDate ? convertToDate(this.props.dueDate) : null,
		variableSymbol: this.props.variableSymbol,
		editingField: null,
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		const { editingField } = this.state
		this.setState({
			dueDate: editingField === 'dueDate' ? this.state.dueDate : this.getDueDate(nextProps.dueDate),
			variableSymbol: editingField === 'variableSymbol' ? this.state.variableSymbol : nextProps.variableSymbol,
		})
	}

	onVariableSymbolChange = (event: SyntheticInputEvent<HTMLInputElement>, variableSymbol: ?string) => {
		this.setState({ variableSymbol, editingField: 'variableSymbol' })
	}

	onDueDateChange = (event: ?SyntheticInputEvent<HTMLInputElement>, dueDate: ?Date) => {
		this.setState({ dueDate, editingField: 'dueDate' })
		this.props.onDueDateChange && this.props.onDueDateChange(dueDate)
		this.setState({ editingField: null })
	}

	onVariableSymbolBlur = () => {
		if (this.state.variableSymbol !== this.props.variableSymbol) {
			this.props.onVariableSymbolChange && this.props.onVariableSymbolChange(this.state.variableSymbol)
		}
		this.setState({ editingField: null })
	}

	getIssueDate = memoize((date: ?string) => {
		return date ? convertToDate(date) : null
	})

	getDueDate = memoize((date: ?string) => {
		return date ? convertToDate(date) : null
	})

	render() {
		const { t, readonly, readonlyDueDate, isTemplate } = this.props
		const { dueDate } = this.state
		return (
			<div className={styles.root}>
				<div className={styles.vs}>
					<div className={styles.header}>{t('invoice.variableSymbolLabel')}</div>
					<VariableSymbolInput
						name="variableSymbol"
						value={this.state.variableSymbol || ''}
						onChange={this.onVariableSymbolChange}
						onBlur={this.onVariableSymbolBlur}
						hintText={!readonly ? this.props.variableSymbolPreview || t('invoice.variableSymbolHint') : null}
						disabled={readonly}
						inline
						autoTestId="invoice-payment-details-variableSymbol"
					/>
				</div>
				<div className={styles.due}>
					<div className={styles.header}>{t('invoice.dueDateLabel')}</div>
					<div>
						<DatePicker
							value={dueDate}
							onChange={this.onDueDateChange}
							hintText={
								isTemplate
									? t('invoice.willBeGenerated')
									: readonly || !!readonlyDueDate
									? null
									: t('invoice.dueDateHint')
							}
							disabled={readonly || !!readonlyDueDate}
							minDate={this.getIssueDate(this.props.issueDate)}
							autoTestId="payment-details-dueDate"
							autoWidth
							inline
							required={!isTemplate}
						/>
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslate(PaymentDetails)
