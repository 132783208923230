// @flow

import moment, { type MomentInput } from 'moment'
import type { OssCountryRegistration } from 'types'
import { OSS_REGISTRATION_STATE } from '../constants/oss'

export function isOssRegistrationValidToDate(registration: OssCountryRegistration, date: MomentInput): boolean {
	return (
		isOssRegistrationRegistred(registration) &&
		!!registration.dateFrom &&
		moment(date).isBetween(registration.dateFrom, registration.dateTo || new Date(), 'day', '[]')
	)
}

export function isOssRegistrationRegistred(registration: OssCountryRegistration): boolean {
	return OSS_REGISTRATION_STATE.registered === registration.registrationState
}
