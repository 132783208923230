/* @flow*/

import { createSelector } from 'reselect'
import { isEmpty } from 'lodash-es'
import { getPagerIndexes } from 'utils'
import type { State, Template, PagerProps, FormFieldErrorsMap } from 'types'
import type { TemplateItem } from 'modules/accounting-document/reducers/templates'
import { NEW_TEMPLATE_NAME } from 'trivi-constants'

function getTemplateItem(state: State, templateId: string): ?TemplateItem {
	return (
		state.accountingDocument.templates.templateList.find((item: ?TemplateItem) => {
			return item && item.template && item.template.id ? item.template.id + '' === templateId + '' : null
		}) || null
	)
}

export function getCurrentTemplateItems(state: State): Array<?TemplateItem> {
	const pagerProps: PagerProps = getPagerProps(state)
	const { indexFrom, indexTo } = getPagerIndexes(pagerProps)
	let templateItems: Array<?TemplateItem> = []
	for (let i = indexFrom; i <= indexTo; i++) {
		const item: ?TemplateItem = state.accountingDocument.templates.templateList[i] || null
		templateItems.push(item)
	}
	return templateItems
}

export function getCurrentTemplates(state: State): Array<?Template> {
	const currentTemplateItems: Array<?TemplateItem> = getCurrentTemplateItems(state)
	const currentTemplates: Array<?Template> = currentTemplateItems.map((item: ?TemplateItem) => {
		const template: ?Template = (item && item.template) || null
		modifyNewTemplateHack(template) //TODO hack
		return template
	})
	return currentTemplates
}

export function getCurrentTemplatesLoading(state: State): boolean {
	const currentTemplateItems: Array<?TemplateItem> = getCurrentTemplateItems(state)
	let loading: boolean = false
	currentTemplateItems.forEach((item: ?TemplateItem) => {
		if (item && item.templateLoading && !item.template) {
			loading = true
		}
	})
	return loading
}

function modifyNewTemplateHack(template: ?Template): void {
	if (template && template.name && template.name === NEW_TEMPLATE_NAME) {
		template.name = undefined
	}
}

export function getTemplate(state: State, templateId: string): ?Template {
	const templateItem = getTemplateItem(state, templateId)
	const template: ?Template = (templateItem && templateItem.template) || null
	modifyNewTemplateHack(template) //TODO hack
	return template
}

export function getTemplateIndex(state: State, templateId: string): number {
	return (
		state.accountingDocument.templates.templateList.findIndex((item: ?TemplateItem) => {
			return item && item.template && item.template.id === templateId
		}) || -1
	)
}

export function getTemplateLoading(state: State, templateId: string): boolean {
	const templateItem = getTemplateItem(state, templateId)
	//console.log('getTemplateLoading', {templateItem}) TODO fix
	return (templateItem && templateItem.templateLoading) || false
}

export function getTemplateUpdating(state: State, templateId: string): boolean {
	const templateItem = getTemplateItem(state, templateId)
	return (templateItem && templateItem.templateUpdating) || false
}

export function isSomeTemplateCreating(state: State): boolean {
	return !isEmpty(state.accountingDocument.templates.creatingTemplates)
}

export function getPagerProps(state: State): PagerProps {
	return state.accountingDocument.templates.pagerProps
}

export function getDeleteTemplateOnEditingExit(state: State): boolean {
	return state.accountingDocument.templates.deleteTemplateOnEditingExit
}

export const getTemplateErrors = createSelector(getTemplateItem, (item: ?TemplateItem): ?FormFieldErrorsMap => {
	return item && item.errors
})
