/* @flow */

import { connect } from 'react-redux'
import type {
	AccountingDocumentBankAccount,
	AccountingDocumentReduxAction,
	Dispatch,
	State,
	FormFieldErrorContext,
} from 'types'
import { addAccountingDocumentBankAccount, removeAccountingDocumentBankAccount } from '../../actions'
import { getAccountingDocumentBankAccounts, getAccountingDocumentLoading } from 'modules/accounting-document/selectors'
import BankAccounts from 'modules/accounting-document/containers/invoice-elements/bank-accounts'

type OwnProps = {|
	accountingDocumentId: string,
	direction: 'issued' | 'received',
	readonly?: boolean,
	formFieldErrorContext?: FormFieldErrorContext,
|}

const mapStateToProps = (state: State, ownProps: OwnProps) => {
	return {
		loading: getAccountingDocumentLoading(state, ownProps.accountingDocumentId),
		bankAccounts: getAccountingDocumentBankAccounts(state, ownProps.accountingDocumentId),
	}
}

const mapDispatchToProps = (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
	return {
		onBankAccountAdd: (accountingDocumentId: string, bankAccount: AccountingDocumentBankAccount) => {
			dispatch(addAccountingDocumentBankAccount(accountingDocumentId, bankAccount))
		},
		onBankAccountRemove: (accountingDocumentId: string, bankAccount: AccountingDocumentBankAccount) => {
			dispatch(removeAccountingDocumentBankAccount(accountingDocumentId, bankAccount))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BankAccounts)
