/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { Component, type Node } from 'react'
import memoize from 'memoize-one'
import Select from 'react-select'
import Creatable from 'react-select/lib/Creatable'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { autoTestId, type AutoTestProps } from 'utils/tests/autotest'
import Tooltip from 'components/tooltip'
import DropdownIndicator from './dropdown-indicator'
import ClearIndicator from './clear-indicator'
import Option from './option'
import { colors } from 'variables'

export type OptionType = { value: ?string, label: ?string }
export type OptionsType = Array<OptionType>
export type ValueType = ?OptionType | ?OptionsType
export type GroupType = {
	label: ?string,
	options: OptionsType,
}

export type ActionTypes =
	| 'clear'
	| 'create-option'
	| 'deselect-option'
	| 'pop-value'
	| 'remove-value'
	| 'select-option'
	| 'set-value'
export type ActionMeta = { action: ActionTypes }

export type FormatOptionLabelContext = 'menu' | 'value'
export type FormatOptionLabelMeta = {
	context: FormatOptionLabelContext,
	inputValue: string,
	selectValue: ValueType,
}

type Props = {|
	...AutoTestProps,
	...WithTranslateProps,
	options: OptionsType,
	error?: Node,
	label?: string,
	autoFocus?: boolean,
	compact?: boolean,
	inline?: boolean,
	autoWidth?: boolean,
	disabled?: boolean,
	placeholder?: string,
	value?: ?string,
	defaultValue?: ?string,
	defaultInputValue?: ?string,
	isClearable?: boolean,
	isSearchable?: boolean,
	isCreatable?: boolean,
	isMulti?: boolean,
	portal?: boolean,
	getOptionLabel?: OptionType => string,
	hideIndicator?: boolean,
	tabIndex?: number,
	maxLength?: number,
	formatOptionLabel?: (OptionType, FormatOptionLabelMeta) => Node,
	onChange: (ValueType, ActionMeta) => void,
|}

class SelectNext extends Component<Props> {
	components = { DropdownIndicator: !this.props.hideIndicator ? DropdownIndicator : null, ClearIndicator, Option }
	select = null

	getNoOptionsMessage = () => this.props.t('application.noItems')

	formatCreateLabel = (value: string) => {
		return this.props.t('application.createOption', { value })
	}

	findOptionByValue = memoize((options: OptionsType, value: ?string | Array<string>) => {
		if (value !== null && value !== undefined && Array.isArray(value)) {
			return options.filter(
				(option: OptionType) =>
					value !== null &&
					value !== undefined &&
					option.value !== null &&
					option.value !== undefined &&
					value.includes(option.value),
			)
		} else {
			const found = options.find((option: OptionType) => option.value === value)
			return found ? found : value ? { value: value, label: value } : null
		}
	})

	onFocus = () => {
		this.forceUpdate()
	}

	getStyles = memoize(
		(
			compact?: boolean,
			inline?: boolean,
			autoWidth?: boolean,
			isSearchable?: boolean,
			isClearable?: boolean,
			disabled?: boolean,
			isInvalid?: boolean,
			isMulti?: boolean,
			isEmpty?: boolean,
		) => {
			const normalTheme = {
				invalid: {
					backgroundColor: colors.redFaded4,
					borderColor: colors.red,
					'&:hover': {
						borderColor: colors.red,
					},
				},
				disabled: {
					backgroundColor: '#f9f9f9',
					borderColor: colors.grayLight200,
				},
				focused: {
					backgroundColor: colors.blue100Faded40,
					borderColor: colors.blue,
					'&:hover': {
						borderColor: colors.blue,
					},
				},
				normal: {
					backgroundColor: colors.white,
					borderColor: colors.gray400,
					'&:hover': {
						borderColor: colors.blackFaded40,
					},
				},
			}

			const inlineTheme = {
				invalid: {
					backgroundColor: colors.redFaded4,
					borderColor: colors.red,
					'&:hover': {
						borderColor: colors.red,
					},
				},
				disabled: {
					backgroundColor: colors.transparent,
					borderColor: colors.transparent,
				},
				focused: {
					backgroundColor: isEmpty ? 'transparent' : colors.blue100Faded40,
					borderColor: isEmpty ? 'transparent' : colors.blue,
					'&:hover': {
						backgroundColor: isEmpty ? 'transparent' : colors.blue100Faded40,
						borderColor: isEmpty ? 'transparent' : colors.blue,
					},
				},
				normal: {
					backgroundColor: colors.transparent,
					borderColor: colors.transparent,
					'&:hover': {
						borderColor: isEmpty ? 'transparent' : colors.blueFaded40,
						backgroundColor: isEmpty ? 'transparent' : colors.blue100Faded40,
					},
				},
			}
			const theme = inline ? inlineTheme : normalTheme

			return {
				root: {
					position: 'relative',
					display: inline && autoWidth ? 'inline-flex' : 'block',
					maxWidth: '100%',
					'&:hover': {
						'.dropdown-indicator__arrow': {
							svg: {
								fill: inline && isEmpty ? `${colors.blue} !important` : undefined,
							},
						},
					},
				},
				tooltip: {
					maxWidth: '100%',
				},
				label: {
					display: 'block',
					userSelect: 'none',
					color: colors.black,
					whiteSpace: 'nowrap',
					fontSize: 14,
					paddingBottom: inline ? 0 : 7,
					lineHeight: inline ? '24px' : '17px',
					cursor: 'pointer',
				},
				indicatorSeparator: () => ({ display: 'none' }),
				indicatorsContainer: (provided: Object) => ({
					...provided,
					marginRight: inline ? 0 : compact ? 2 : 8,
				}),
				dropdownIndicator: (provided: Object) => ({
					...provided,
					display: provided.display,
					padding: compact || inline ? 0 : provided.padding,
					alignItems: 'center',
					cursor: 'pointer',
					height: inline ? 22 : provided.height,
					width: inline ? 22 : provided.width,
				}),
				clearIndicator: (provided: Object) => ({
					...provided,
					marginRight: compact ? 0 : 4,
				}),
				multiValueRemove: (provided: Object) => ({
					...provided,
					cursor: 'default',
				}),
				control: (provided: Object, state: Object) => ({
					...provided,
					...(isInvalid
						? theme.invalid
						: state.isDisabled
						? theme.disabled
						: state.isFocused
						? theme.focused
						: theme.normal),
					minHeight: inline ? 22 : compact ? 32 : 46,
					height: compact && !isMulti ? 32 : provided.height,
					borderRadius: compact || inline ? 2 : 3,
					boxShadow: 'none',
					fontSize: 14,
					cursor: isSearchable ? 'text' : 'pointer',
					'.select__single-value': {
						color:
							state.isFocused && state.menuIsOpen
								? colors.grey
								: disabled && !inline
								? colors.disabledText
								: colors.black,
					},
				}),
				valueContainer: (provided: Object) => ({
					...provided,
					paddingTop: 1,
					paddingBottom: 1,
					paddingLeft: inline && isEmpty && !isSearchable ? 0 : inline ? 1 : compact ? 8 : 15,
					paddingRight: inline && isEmpty && !isSearchable ? 0 : inline ? 1 : compact ? 8 : 15,
					flexDirection: autoWidth ? 'row-reverse' : provided.flexDirection,
				}),
				placeholder: (provided: Object, state: Object) => ({
					...provided,
					color: colors.blackFaded40,
					position: autoWidth && !state.hasValue ? 'static' : provided.position,
					transform: autoWidth && !state.hasValue ? 'none' : provided.transform,
					marginLeft: autoWidth && isSearchable ? -4 : provided.marginLeft,
					lineHeight: '20px',
				}),
				input: (provided: Object) => ({
					...provided,
					fontSize: 14,
					color: colors.black,
					padding: inline ? 0 : provided.padding,
					margin: inline ? '0 2px' : provided.margin,
					height: inline ? 20 : provided.height,
					lineHeight: inline ? '20px' : provided.lineHeight,
					div: {
						overflow: 'hidden !important',
						verticalAlign: 'baseline',
					},
					input: {
						fontFamily: 'inherit',
					},
				}),
				singleValue: (provided: Object) => ({
					...provided,
					position: autoWidth ? 'static' : provided.position,
					transform: autoWidth ? 'none' : provided.transform,
					marginLeft: autoWidth && isSearchable ? -3 : provided.marginLeft,
					marginRight: autoWidth && isSearchable ? 5 : provided.marginRight,
					maxWidth: autoWidth && isSearchable ? provided.maxWidth : 'none',
					textOverflow: autoWidth ? 'clip' : 'ellipsis',
					lineHeight: inline ? '20px' : provided.lineHeight,
				}),
				menuPortal: (provided: Object) => ({
					...provided,
					zIndex: 2000,
				}),
				menu: (provided: Object) => ({
					...provided,
					borderWidth: 1,
					borderStyle: 'solid',
					borderColor: colors.gray400,
					borderRadius: compact || inline ? 2 : 3,
					boxShadow: `${colors.blackFaded8} 4px 4px 0`,
					width: 'max-content',
					minWidth: autoWidth ? 0 : provided.width,
					whiteSpace: autoWidth ? 'nowrap' : provided.whiteSpace,
					overflow: 'hidden',
					maxWidth: 480,
					zIndex: 2000,
				}),
				menuList: (provided: Object) => ({
					...provided,
					paddingTop: 3,
					paddingBottom: 3,
				}),
				noOptionsMessage: (provided: Object) => ({
					...provided,
					fontSize: 14,
					color: colors.grey,
				}),
			}
		},
	)

	onInputChange = (inputValue: any) => {
		if (this.props.maxLength == null) {
			return inputValue
		}
		if (typeof inputValue !== 'string') {
			return inputValue
		}
		return inputValue.length <= this.props.maxLength ? inputValue : inputValue.substr(0, this.props.maxLength)
	}

	onLabelClick = () => void (this.select && this.select.focus && this.select.focus())

	bindSelect = (element: ?HTMLElement) => {
		this.select = element
	}

	isEmpty = () => {
		return !this.props.value && (!this.props.placeholder || !this.props.placeholder.length)
	}

	render() {
		const styles = this.getStyles(
			this.props.compact,
			this.props.inline,
			this.props.autoWidth,
			this.props.isSearchable,
			this.props.isClearable,
			this.props.disabled,
			!!this.props.error,
			this.props.isMulti,
			this.isEmpty(),
		)
		const SelectComponent = this.props.isCreatable ? Creatable : Select

		return (
			<div css={styles.root} {...autoTestId(this.props.autoTestId)}>
				{this.props.label && (
					<label onClick={this.onLabelClick} style={styles.label}>
						{this.props.label}
					</label>
				)}
				<Tooltip
					inline={!!this.props.inline && !!this.props.autoWidth}
					label={!this.props.disabled ? this.props.error : ''}
					wrapperStyle={styles.tooltip}
				>
					<SelectComponent
						ref={this.bindSelect}
						onInputChange={this.onInputChange}
						isSearchable={!!this.props.isSearchable}
						isClearable={this.props.isClearable}
						isMulti={this.props.isMulti}
						options={this.props.options}
						isDisabled={this.props.disabled}
						autoFocus={this.props.autoFocus}
						placeholder={this.props.placeholder || ''}
						onChange={this.props.onChange}
						onFocus={this.onFocus}
						noOptionsMessage={this.getNoOptionsMessage}
						formatCreateLabel={this.formatCreateLabel}
						components={this.components}
						defaultInputValue={this.props.defaultInputValue}
						value={this.findOptionByValue(this.props.options, this.props.value)}
						formatOptionLabel={this.props.formatOptionLabel}
						getOptionLabel={this.props.getOptionLabel}
						menuPortalTarget={this.props.portal ? document.getElementById('render-to-layer') : null}
						closeMenuOnScroll={this.props.portal}
						tabIndex={this.props.tabIndex}
						menuShouldScrollIntoView
						classNamePrefix={'select'}
						menuShouldBlockScroll
						openMenuOnFocus
						styles={styles}
					/>
				</Tooltip>
			</div>
		)
	}
}

export default withTranslate(SelectNext)
