// @flow

import type { VatRate, AccountingDocumentVatRatesAction } from 'types'
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'trivi-constants'

export type AccountingDocumentVatRatesState = { [string]: Array<VatRate> }

export type State = {
	vatRates: ?Array<VatRate>,
	accountingDocumentVatRates: AccountingDocumentVatRatesState,
}

export const initialState: State = {
	vatRates: EMPTY_ARRAY,
	accountingDocumentVatRates: EMPTY_OBJECT,
}

function vatRateReducer(state: State = initialState, action: AccountingDocumentVatRatesAction): State {
	switch (action.type) {
		case 'LOAD_ALL_VAT_RATES_FINISH':
			return {
				...state,
				vatRates: action.payload,
			}

		case 'FINISH_LOAD_ACCOUNTING_DOCUMENT_VATRATES': {
			return {
				...state,
				accountingDocumentVatRates: {
					...state.accountingDocumentVatRates,
					[action.accountingDocumentId]: action.vatRates,
				},
			}
		}

		default:
			return state
	}
}

export default vatRateReducer
