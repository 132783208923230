/* @flow*/

import memoize from 'memoize-one'
import { uniqWith } from 'lodash-es'
import { isCountryEu } from 'modules/common/selectors'
import {
	getCurrentOrganizationDomesticCountry,
	getOrganizationProjects,
	getOrganizationBranches,
} from 'modules/organization/selectors'
import { createSelector } from 'reselect'
import type {
	AccountingDocument,
	AccountingDocumentAttachment,
	AccountingDocumentConnection,
	AccountingDocumentContact,
	AccountingDocumentLineItem,
	AccountingDocumentScan,
	AccountingDocumentTotals,
	AccountingDocumentVatRecapInfo,
	CountryVariantSpecific,
	PaymentReminder,
	State,
	AccountingDocumentLanguage,
	PrintLanguage,
	AccountingDocumentBankAccount,
	GreenboxSuggestionResponse,
	PortalLanguage,
	AccountingDocumentIssues,
	ActivityResponse,
	Enum,
	EnumItem,
	CashRegisterInventories,
	Contact,
	ContactBankAccount,
	CrpdphBankAccount,
	BankAccount,
	FormFieldErrorsMap,
	Base64Image,
	OssMode,
	OssCountryRegistration,
} from 'types'
import type {
	AccountingDocumentItem,
	PublicAccountingDocumentItem,
} from 'modules/accounting-document/reducers/accounting-documents'
import {
	BankAccounts_AccountingDocumentBankAccounts,
	AccountingDocument_AccountingDocumentVatRecapInfo,
	Number_AccountingDocumentType,
	AccountingDocument_Totals,
} from 'types/convertor'
import { AccountingDocumentLanguage_PrintLanguage, getAppLanguage, PortalLanguage_PrintLanguage } from 'locales'
import { currentUserIsInternal, getMyPrintLanguage, getMyProfile } from 'modules/user/selectors'
import Sentry from 'utils/sentry'
import { getContact } from 'modules/address-book/selectors'
import { EMPTY_ACCOUNTING_DOCUMENT_TOTALS } from 'modules/accounting-document/constants'
import { EMPTY_ARRAY } from 'trivi-constants'
import { getAccDocCashbotId as getAccDocCashbotIdPure } from 'modules/accounting-document/domain/accounting-document'
import { getExtensionFromFilename } from 'components/file-icon/file-icon-logic'
import { isOssRegistrationValidToDate } from '../../settings/domain/oss'
import { getOrganizationSettingsOss } from '../../settings/selectors'
import { AccountingDocument_isIssued } from 'types/operations'
import moment from 'moment'

export * from './templates'
export * from './sequences'
export * from './vat-rates'

export function getAccountingDocumentItem(state: State, accountingDocumentId: string): ?AccountingDocumentItem {
	return state.accountingDocument.accountingDocuments.items[accountingDocumentId]
}

export function getCalculatingLineItemIds(state: State, accountingDocumentId: string): Array<string> {
	const item = state.accountingDocument.accountingDocuments.items[accountingDocumentId]
	return (item && item.calculatingLineItemIds) || EMPTY_ARRAY
}

export const getAccountingDocumentLoading: (state: State, accountingDocumentId: string) => boolean = createSelector(
	getAccountingDocumentItem,
	(item: ?AccountingDocumentItem) => {
		return (item && item.loading) || false
	},
)

export const getAccountingDocumentPossibleStates: (
	state: State,
	accountingDocumentId: string,
) => ?Array<string> = createSelector(getAccountingDocumentItem, (item: ?AccountingDocumentItem) => {
	return (item && item.possibleStates) || null
})

const processExtractionTemplate = memoize(
	(accountingDocument: AccountingDocument, string: ?string): AccountingDocument => {
		return ({ ...accountingDocument, ...JSON.parse(string || '') }: AccountingDocument)
	},
)

export function getAccountingDocument(state: State, accountingDocumentId: string): ?AccountingDocument {
	const accountingDocument = getAccountingDocumentRaw(state, accountingDocumentId)
	if (accountingDocument) {
		if (isAccountingDocumentUnknownExtracted(accountingDocument)) {
			return processExtractionTemplate(accountingDocument, accountingDocument.__extractingTemplate)
		} else {
			return accountingDocument
		}
	}
}

export function getPublicAccountingDocumentItem(state: State, uniqueId: string): ?PublicAccountingDocumentItem {
	return (
		state.accountingDocument &&
		state.accountingDocument.accountingDocuments &&
		state.accountingDocument.accountingDocuments.publicItems &&
		state.accountingDocument.accountingDocuments.publicItems[uniqueId]
	)
}

export const getPublicAccountingDocumentId: (state: State, uniqueId: string) => ?string = createSelector(
	getPublicAccountingDocumentItem,
	(item: ?PublicAccountingDocumentItem) => {
		return item && item.id
	},
)

export function getPublicAccountingDocument(state: State, uniqueId: string): ?AccountingDocument {
	const item = getPublicAccountingDocumentItem(state, uniqueId)
	const id = item && item.id
	if (!id) return null
	return id ? getAccountingDocument(state, id) : null
}

export const getPublicAccountingDocumentLogo: (state: State, uniqueId: string) => ?Base64Image = createSelector(
	getPublicAccountingDocumentItem,
	(item: ?PublicAccountingDocumentItem) => item && item.logo,
)

export const getPublicAccountingDocumentColor: (state: State, uniqueId: string) => ?string = createSelector(
	getPublicAccountingDocumentItem,
	(item: ?PublicAccountingDocumentItem) =>
		item && item.printing && item.printing.appearance && item.printing.appearance.baseDecorationColor,
)

export const getPublicAccountingDocumentQr: (state: State, uniqueId: string) => ?Base64Image = createSelector(
	getPublicAccountingDocumentItem,
	(item: ?PublicAccountingDocumentItem) => item && item.qr,
)

export function getAccountingDocumentRaw(state: State, accountingDocumentId: string): ?AccountingDocument {
	const item = state.accountingDocument.accountingDocuments.items[accountingDocumentId]
	return item && item.data
}

export function getDataGridAccountingDocument(state: State, accountingDocumentId: string): ?AccountingDocument {
	return (state.dataGrid.data.accountingDocuments || {})[accountingDocumentId]
}

export function isAccountingDocumentUnknownExtracted(accountingDocument: AccountingDocument): boolean {
	return (
		!!accountingDocument.__extractingTemplate && 'unknown' === Number_AccountingDocumentType(accountingDocument.type)
	)
}

export function isAccountingDocumentVatFree(state: State, accountingDocumentId: string): boolean {
	const doc: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
	return doc && doc.vatPaymentMode ? parseInt(doc.vatPaymentMode) === 2 : false
}

export function getAccountingDocumentLineItems(
	state: State,
	accountingDocumentId: string,
): Array<AccountingDocumentLineItem> {
	const accountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
	return (accountingDocument && accountingDocument.lineItems) || []
}

export function getAccountingDocumentLineItem(
	state: State,
	accountingDocumentId: string,
	lineItemId: string,
): ?AccountingDocumentLineItem {
	const lineItems: Array<AccountingDocumentLineItem> = getAccountingDocumentLineItems(state, accountingDocumentId)
	return lineItems.find((lineItem: AccountingDocumentLineItem) => {
		return lineItem.id === lineItemId
	})
}

export function getAccountingDocumentVatRecapInfo(
	state: State,
	accountingDocumentId: string,
): ?AccountingDocumentVatRecapInfo {
	const accountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
	return (
		(accountingDocument &&
			AccountingDocument_AccountingDocumentVatRecapInfo(
				accountingDocument.vatRecapCalculationMode,
				accountingDocument.vatRecap,
				accountingDocument.total,
				accountingDocument.totalVatExcl,
			)) ||
		undefined
	)
}

export function getAccountingDocumentVatRecapInfoLoading(state: State, accountingDocumentId: string): boolean {
	const item = state.accountingDocument.accountingDocuments.items[accountingDocumentId]
	return (item && item.vatRecapInfoLoading) || false
}

export function getAccountingDocumentTotals(state: State, accountingDocumentId: string): AccountingDocumentTotals {
	const accountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
	if (accountingDocument) {
		return AccountingDocument_Totals(accountingDocument)
	} else {
		return EMPTY_ACCOUNTING_DOCUMENT_TOTALS
	}
}

export function getAccountingDocumentCountryVariantSpecific(
	state: State,
	accountingDocumentId: string,
): ?CountryVariantSpecific {
	const accountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
	return (accountingDocument && accountingDocument.countryVariantSpecific) || null
}

export function getAccountingDocumentBookingDate(state: State, accountingDocumentId: string): ?string {
	const accountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
	if (accountingDocument && accountingDocument.bookingDate) {
		return accountingDocument.bookingDate
	}
	return null
}

export function getAccountingDocumentIssueDate(state: State, accountingDocumentId: string): ?Date {
	const accountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
	return accountingDocument && accountingDocument.issueDate ? new Date(accountingDocument.issueDate) : null
}

export function getAccountingDocumentTaxDate(state: State, accountingDocumentId: string): ?Date {
	const accountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
	return accountingDocument && accountingDocument.taxDate ? new Date(accountingDocument.taxDate) : null
}

export function getAccountingDocumentTaxReturnMinDate(state: State, accountingDocumentId: string): ?string {
	const accountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
	const issueDate = accountingDocument && accountingDocument.issueDate ? accountingDocument.issueDate : null
	const taxDate = accountingDocument && accountingDocument.taxDate ? accountingDocument.taxDate : null
	return issueDate && taxDate
		? new Date(issueDate).getTime() > new Date(taxDate).getTime()
			? issueDate
			: taxDate
		: null
}

export function getAccountingDocumentTaxReturnDate(state: State, accountingDocumentId: string): ?Date {
	const accountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
	if (accountingDocument && accountingDocument.taxReturnDate) {
		return new Date(accountingDocument.taxReturnDate)
	}
	return null
}

export function getAccountingDocumentVatCountryType(state: State, accountingDocumentId: string): ?number {
	const accountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
	return (accountingDocument && accountingDocument.vatCountryType) || null
}

export function getAccountingDocumentExchRate(state: State, accountingDocumentId: string): ?number {
	const accountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
	if (accountingDocument && accountingDocument.exchRate !== undefined && accountingDocument.exchRate !== null) {
		return accountingDocument.exchRate
	}
	return null
}

export function getAccountingDocumentExchRateDefault(state: State, accountingDocumentId: string): ?number {
	const accountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
	if (
		accountingDocument &&
		accountingDocument.exchRateDefault !== undefined &&
		accountingDocument.exchRateDefault !== null
	) {
		return accountingDocument.exchRateDefault
	}
	return null
}

export function getAccountingDocumentVatExchRate(state: State, accountingDocumentId: string): ?number {
	const accountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
	if (accountingDocument && accountingDocument.vatExchRate !== undefined && accountingDocument.vatExchRate !== null) {
		return accountingDocument.vatExchRate
	}
	return null
}

export function getAccountingDocumentSadExchRate(state: State, accountingDocumentId: string): ?number {
	const accountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
	if (accountingDocument && accountingDocument.sadExchRate !== undefined && accountingDocument.sadExchRate !== null) {
		return accountingDocument.sadExchRate
	}
	return null
}

export function getAccountingDocumentCurrency(state: State, accountingDocumentId: string): ?string {
	const accountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
	return (accountingDocument && accountingDocument.currency) || null
}

export function getAccountingDocumentLanguage(state: State, accountingDocumentId: string): ?AccountingDocumentLanguage {
	let accountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
	if (!accountingDocument) {
		accountingDocument = getDataGridAccountingDocument(state, accountingDocumentId)
	}
	return (accountingDocument && accountingDocument.language) || null
}

export function getAccountingDocumentPaymentType(state: State, accountingDocumentId: string): ?number {
	const accountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
	if (accountingDocument && accountingDocument.paymentType !== undefined && accountingDocument.paymentType !== null) {
		return accountingDocument.paymentType
	}
	return null
}

export function getAccountingDocumentScans(state: State, accountingDocumentId: string): ?Array<AccountingDocumentScan> {
	const accountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
	return accountingDocument && accountingDocument.scans && accountingDocument.scans.length
		? accountingDocument.scans
		: null
}

export function getAccountingDocumentTotal(state: State, accountingDocumentId: string): ?number {
	const accountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
	if (accountingDocument && accountingDocument.total !== undefined && accountingDocument.total !== null) {
		return accountingDocument.total
	}
	return null
}

export function getAccountingDocumentVatRecapCalculationMode(state: State, accountingDocumentId: string): ?number {
	const accountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
	if (
		accountingDocument &&
		accountingDocument.vatRecapCalculationMode !== undefined &&
		accountingDocument.vatRecapCalculationMode !== null
	) {
		return accountingDocument.vatRecapCalculationMode
	}
	return null
}

export function getAccountingDocumentPaymentReminders(
	state: State,
	accountingDocumentId: string,
): ?Array<PaymentReminder> {
	const item = state.accountingDocument.accountingDocuments.items[accountingDocumentId]
	return item && item.paymentReminders
}

export function getAccountingDocumentRoundingTypeMode(state: State, accountingDocumentId: string): ?number {
	const accountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
	if (accountingDocument && accountingDocument.roundingType !== undefined && accountingDocument.roundingType !== null) {
		return accountingDocument.roundingType
	}
	return null
}

export function getAccountingDocumentDirection(state: State, accountingDocumentId: string): ?(0 | 1) {
	const accountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
	if (accountingDocument) {
		return accountingDocument.direction
	} else {
		return null
	}
}

export function getAccountingDocumentType(state: State, accountingDocumentId: string): ?number {
	const accountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
	return accountingDocument && accountingDocument.type
}

export function getAccountingDocumentProcessingState(state: State, accountingDocumentId: string): ?(0 | 1 | 2 | 3) {
	const accountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
	if (accountingDocument) {
		return accountingDocument.processingState
	} else {
		return null
	}
}

export function getAccountingDocumentConnections(
	state: State,
	accountingDocumentId: string,
): ?Array<AccountingDocumentConnection> {
	const item = state.accountingDocument.accountingDocuments.items[accountingDocumentId]
	return item && item.connections
}

export function getAccountingDocumentConnection(
	state: State,
	accountingDocumentId: string,
	connectedAccountingDocumentId: string,
): ?AccountingDocumentConnection {
	const connections: Array<AccountingDocumentConnection> =
		getAccountingDocumentConnections(state, accountingDocumentId) || []
	return (
		connections.find((c: AccountingDocumentConnection) => {
			return c.connectedAccountingDocumentId === connectedAccountingDocumentId
		}) || null
	)
}

export function getAccountingDocumentAttachments(
	state: State,
	accountingDocumentId: string,
): ?Array<AccountingDocumentAttachment> {
	const item = state.accountingDocument.accountingDocuments.items[accountingDocumentId]
	return item && item.attachments
}

export const getAccountingDocumentContact: (
	state: State,
	accountingDocumentId: string,
) => ?AccountingDocumentContact = createSelector(getAccountingDocument, (accountingDocument: ?AccountingDocument) => {
	return (accountingDocument && accountingDocument.contact) || null
})

export function getDefaultDuePeriod(): number {
	// TODO z nastavení mojí organizace?
	return 14
}

export function getAccountingDocumentsExporting(state: State): boolean {
	return state.accountingDocument.accountingDocuments.exporting
}

export function getAccountingDocumentPrintLanguage(state: State, accountingDocumentId: string): PrintLanguage {
	const sentryLogMessage = 'getAccountingDocumentPrintLanguage'

	// Use primarily document language
	const docLang: ?AccountingDocumentLanguage = getAccountingDocumentLanguage(state, accountingDocumentId)
	if (docLang) {
		const printLang: PrintLanguage = AccountingDocumentLanguage_PrintLanguage(docLang)
		Sentry.logMessage(sentryLogMessage, { source: 'AccountingDocument', docLang, printLang })
		return printLang
	}

	// Then user language
	const myPrintLang: ?PrintLanguage = getMyPrintLanguage(state)
	if (myPrintLang) {
		Sentry.logMessage(sentryLogMessage, { source: 'UserProfile', myPrintLang })
		return myPrintLang
	}

	// App language as the last option
	const portalLang: PortalLanguage = getAppLanguage()
	const printLang: PrintLanguage = PortalLanguage_PrintLanguage(portalLang)
	Sentry.logMessage(sentryLogMessage, { source: 'app-language', portalLang, printLang })

	return printLang
}

export function getUniqAccountingDocumentBankAccounts(
	accounts: Array<AccountingDocumentBankAccount>,
): Array<AccountingDocumentBankAccount> {
	return uniqWith(
		accounts,
		(a: AccountingDocumentBankAccount, b: AccountingDocumentBankAccount) =>
			a.bankCode === b.bankCode && a.number === b.number,
	)
}

export const getBankAccounts = (state: State) => state.bank.bankAccounts.data

export const getIssuedAccountingDocumentBankAccounts: (
	state: State,
) => Array<AccountingDocumentBankAccount> = createSelector(getBankAccounts, (accounts: Array<BankAccount>) => {
	return uniqWith(
		BankAccounts_AccountingDocumentBankAccounts(accounts),
		(a: AccountingDocumentBankAccount, b: AccountingDocumentBankAccount) =>
			a.bankAccountId === b.bankAccountId && a.bankCode === b.bankCode && a.number === b.number,
	)
})

export const getAccountingDocumentAvailableBankAccounts = createSelector(
	[
		(state: State, props: Object) => props.accountingDocumentId,
		(state: State, props: Object) => {
			getAccountingDocumentContact(state, props.accountingDocumentId)
		},
		(state: State, props: Object) => {
			const docContact = getAccountingDocumentContact(state, props.accountingDocumentId)
			return docContact && docContact.contactId ? getContact(state, docContact.contactId) : null
		},
		(state: State) => state.common.countryVariant.cz,
	],
	(
		accountingDocumentId: string,
		docContact: ?AccountingDocumentContact,
		contact: ?Contact,
		crpdphResult: any,
	): Array<AccountingDocumentBankAccount> => {
		const bankAccountsFromContact: Array<AccountingDocumentBankAccount> = ((contact && contact.bankAccounts) || []).map(
			(a: ContactBankAccount) => ({
				number: a.accountNo,
				bankCode: a.accountCode,
				iban: a.accountIban,
				swift: a.accountSwift,
			}),
		)

		const registeredBankAccounts: ?Array<CrpdphBankAccount> =
			docContact && docContact.companyRegNo && crpdphResult[docContact.companyRegNo]
				? crpdphResult[docContact.companyRegNo].registeredBankAccounts
				: null

		const bankAccountsFromCrpdph: Array<AccountingDocumentBankAccount> = (registeredBankAccounts || [])
			.filter(
				(a: CrpdphBankAccount) =>
					bankAccountsFromContact.findIndex(
						(item: AccountingDocumentBankAccount) => item.bankCode === a.accountCode && item.number === a.accountNumber,
					) === -1,
			)
			.map((a: CrpdphBankAccount) => ({
				number: a.accountNumber,
				bankCode: a.accountCode,
			}))

		return getUniqAccountingDocumentBankAccounts([...bankAccountsFromCrpdph, ...bankAccountsFromContact])
	},
)

export function getAccountingDocumentBankAccounts(
	state: State,
	accountingDocumentId: string,
): Array<AccountingDocumentBankAccount> {
	const item = state.accountingDocument && state.accountingDocument.accountingDocuments.items[accountingDocumentId]
	return (item && item.data && item.data.bankAccounts && item.data.bankAccounts) || []
}

export function getAccountingDocumentBankAccount(
	state: State,
	accountingDocumentId: string,
	bankAccountLocalId: string,
): ?AccountingDocumentBankAccount {
	const bankAccounts = getAccountingDocumentBankAccounts(state, accountingDocumentId)
	return bankAccounts && bankAccounts.find((item: AccountingDocumentBankAccount) => item.localId === bankAccountLocalId)
}

export function isAccountingDocumentCreating(state: State): boolean {
	return state.accountingDocument.accountingDocuments.creating
}

export function getAccountingDocumentNotesCount(state: State, accountingDocumentId: string): number {
	const item = state.accountingDocument && state.accountingDocument.accountingDocuments.items[accountingDocumentId]
	return (
		(item &&
			item.data &&
			item.data.notes &&
			item.data.notes.filter((note: any) => note.uniqueId && note.text).length) ||
		0
	)
}

export function getAccountingDocumentExtractingNotesCount(state: State, accountingDocumentId: string): number {
	const item = state.accountingDocument && state.accountingDocument.accountingDocuments.items[accountingDocumentId]
	return (
		(item && item.data && item.data._extracting && item.data._extracting.notes && item.data._extracting.notes.length) ||
		0
	)
}

export function getAccDocGreenboxSuggestion(state: State, accDocId: string): ?GreenboxSuggestionResponse {
	const item = state.accountingDocument && state.accountingDocument.accountingDocuments.items[accDocId]
	return item && item.greenboxSuggestion
}

export function getAccountingDocumentIssues(state: State, accDocId: string): ?AccountingDocumentIssues {
	const item = state.accountingDocument && state.accountingDocument.accountingDocuments.items[accDocId]
	return item && item.issues
}

export function getAccountingDocumentActivitiesLoadingFlag(state: State, accDocId: string): boolean {
	const item = state.accountingDocument && state.accountingDocument.accountingDocuments.items[accDocId]
	return item.activities.loading
}

export function getAccountingDocumentActivities(state: State, accDocId: string): Array<ActivityResponse> {
	const item = state.accountingDocument && state.accountingDocument.accountingDocuments.items[accDocId]
	return item.activities.activities
}

export function getAccountingDocumentCreditNoteDelayedVatFlag(state: State, accDocId: string): boolean {
	const item = state.accountingDocument && state.accountingDocument.accountingDocuments.items[accDocId]
	return (item && item.data && item.data.creditNoteDelayedVat) || false
}

export function getAccountingDocumentBranch(state: State, accountingDocument: AccountingDocument): ?EnumItem {
	const branches = getOrganizationBranches(state)
	return branches && branches.find((branch: EnumItem) => branch.key === accountingDocument.preferredBranch)
}

export function getAccountingDocumentProject(state: State, accountingDocument: AccountingDocument): ?EnumItem {
	const projects: ?Enum = getOrganizationProjects(state)
	return projects && projects.find((project: EnumItem) => project.key === accountingDocument.preferredProject)
}

export function getVatCountryTypeByCountry(state: State, country: ?string): ?number {
	if (getCurrentOrganizationDomesticCountry(state) === country) {
		return 1 // 1 - Domestic
	} else if (isCountryEu(state, country)) {
		return 2 // 2 - EU
	} else if (country) {
		return 3 // 3 - Foreign
	}
}

export function getCashRegisterInventoriesById(state: State, cashRegisterId: string): ?CashRegisterInventories {
	return (
		state.cashRegister.inventories &&
		state.cashRegister.inventories[cashRegisterId] &&
		state.cashRegister.inventories[cashRegisterId].data
	)
}

export function getPreProcessingAccountingDocumentPreview(state: State): boolean {
	const me = getMyProfile(state)

	return (
		!currentUserIsInternal(state) &&
		!!(me && me.settings && me.settings.displayAccountingDocumentPreviewBeforeProcessing)
	)
}

export function getAccDocCashbotId(state: State, accDocId: string): ?number {
	const accDoc: ?AccountingDocument = getAccountingDocument(state, accDocId)
	return getAccDocCashbotIdPure(accDoc)
}

export function getAccDocPdfUrl(state: State, accDocId: string): ?string {
	const accDocItem: ?AccountingDocumentItem = getAccountingDocumentItem(state, accDocId)
	return (accDocItem && accDocItem.pdfUrl) || null
}

export function getAccDocFileIds(state: State, accDocId: string, type: ?string): ?Array<string> {
	const accDoc: ?AccountingDocument = getAccountingDocument(state, accDocId)
	if (!type) return null

	return (
		accDoc &&
		accDoc.scans &&
		accDoc.scans.reduce((result: Array<string>, scan: AccountingDocumentScan): Array<string> => {
			return scan.filename && scan.filename.includes(`.${type || ''}`) && scan.fileId
				? [...result, scan.fileId]
				: result
		}, [])
	)
}

export function getReceivedAccDocType(state: State, accDocId: string): ?string {
	const accDoc: ?AccountingDocument = getAccountingDocument(state, accDocId)
	if (!accDoc) return null

	const types = getAccDocTypes(accDoc) || []
	return types.includes('pdf') ? 'pdf' : types[0]
}

export function getAccDocTypes(accDoc: AccountingDocument): ?Array<?string> {
	return accDoc.scans && accDoc.scans.map((scan: AccountingDocumentScan) => getExtensionFromFilename(scan.filename))
}

export function getAccountingDocumentErrors(state: State, accDocId: string): ?FormFieldErrorsMap {
	const item = state.accountingDocument && state.accountingDocument.accountingDocuments.items[accDocId]
	return item && item.errors
}

export function getAccountingDocumentState(state: State, accountingDocumentId: string): ?string {
	const accountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
	return accountingDocument && accountingDocument.state
}

export function getDefaultLineItems(state: State): Array<AccountingDocumentLineItem> | null {
	return (
		state.accountingDocument &&
		state.accountingDocument.accountingDocuments &&
		state.accountingDocument.accountingDocuments.defaultLineItems
	)
}

export function getAccountingDocumentOssValue(state: State, accountingDocumentId: string): ?OssMode {
	const accountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
	if (
		accountingDocument &&
		accountingDocument.settings &&
		accountingDocument.settings.oss &&
		accountingDocument.settings.oss.currentValue
	) {
		return accountingDocument.settings.oss.currentValue
	}
	return 'none'
}

export function getAccountingDocumentOssMode(state: State, accountingDocumentId: string): ?OssMode {
	const accountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
	if (
		accountingDocument &&
		accountingDocument.settings &&
		accountingDocument.settings.oss &&
		accountingDocument.settings.oss.forcedValue
	) {
		return accountingDocument.settings.oss.forcedValue
	}
	return null
}

export function isAccountingDocumentOssEnabled(state: State, accountingDocumentId: string): boolean {
	const doc: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
	const contact = getAccountingDocumentContact(state, accountingDocumentId)
	const issued = doc && AccountingDocument_isIssued(doc)
	const oss = getOrganizationSettingsOss(state)

	if (!doc || !doc.taxDate || !contact || !contact.country || !oss) return false
	if (!issued || doc.cashRegisterId) return false

	const ossCountry = oss[contact.country]
	if (!ossCountry || !ossCountry.registrations) return false

	const taxDate = moment(doc.taxDate)

	return ossCountry.registrations.reduce((result: boolean, registration: OssCountryRegistration) => {
		return result || isOssRegistrationValidToDate(registration, taxDate)
	}, false)
}

export function getAccountingDocumentNumberingDate(state: State, accountingDocumentId: string): Date | null {
	const doc: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
	if (!doc) return null
	const automaticAccountingDocumentNumberingBy =
		state.settings.organizationSettings && state.settings.organizationSettings.automaticAccountingDocumentNumberingBy
	if (1 === automaticAccountingDocumentNumberingBy && doc.issueDate) return new Date(doc.issueDate)
	if (2 === automaticAccountingDocumentNumberingBy && doc.taxDate) return new Date(doc.taxDate)
	return null
}
