//@flow
/* eslint-disable prettier/prettier */

import type { AppSection, UserType } from 'types'

type GtmVariable =
	| "section"
	| "pageType"
	| "userType"
	| "userID"
	| "organizationID"
	| "event"
	| "mainAccountantId"
	| "payrollAccountantId"
	| "userLanguage";

// edit here to change values that are sent to GTM
const GTM_SECTION_VALUES = {
	DASHBOARD:                  'dashboard',
	INTERNAL_DASHBOARD:         'internalDashboard',
	ACCOUNTING_DOCUMENTS:       'accountingDocuments',
	ACCOUNTING_DOCUMENT_DETAIL: 'accountingDocumentDetail',
	ACCOUNTING_DOCUMENT_EDIT:   'accountingDocumentEdit',
	CASH_REGISTER:              'cashRegister',
	BANK:                       'bank',
	DOCUMENTS:                  'documents',
	ADDRESS_BOOK:               'addressBook',
	TASKS:                      'tasks',
	SETTINGS:                   'settings',
	LOGIN:                      'login',
	REGISTER:                   'register',
	FEATURES:                   'features',
	OTHER:                      'other',
}

const GTM_USER_TYPE_VALUES = {
	CLIENT:   'client',
	INTERNAL: 'internal',
	DEMO:     'demo',
}
const GTM_UNKNOWN = 'unknown'
// ---------------

function AppSection_GtmSection(appSection: AppSection): string {
	return (
		{
			dashboard:                GTM_SECTION_VALUES.DASHBOARD,
			accountingDocumentDetail: GTM_SECTION_VALUES.ACCOUNTING_DOCUMENT_DETAIL,
			accountingDocumentEdit:   GTM_SECTION_VALUES.ACCOUNTING_DOCUMENT_EDIT,
			accountingDocuments:      GTM_SECTION_VALUES.ACCOUNTING_DOCUMENTS,
			cashRegister:             GTM_SECTION_VALUES.CASH_REGISTER,
			bank:                     GTM_SECTION_VALUES.BANK,
			documents:                GTM_SECTION_VALUES.DOCUMENTS,
			addressBook:              GTM_SECTION_VALUES.ADDRESS_BOOK,
			tasks:                    GTM_SECTION_VALUES.TASKS,
			settings:                 GTM_SECTION_VALUES.SETTINGS,
			internalDashboard:        GTM_SECTION_VALUES.INTERNAL_DASHBOARD,
			features:                 GTM_SECTION_VALUES.FEATURES,
			login:                    GTM_SECTION_VALUES.LOGIN,
			register:                 GTM_SECTION_VALUES.REGISTER,
			other:                    GTM_SECTION_VALUES.OTHER,
		}[appSection] ||            GTM_UNKNOWN
	)
}

function AppUserType_GtmUserType(userType: UserType): string {
	return (
		{
			client:    GTM_USER_TYPE_VALUES.CLIENT,
			internal:  GTM_USER_TYPE_VALUES.INTERNAL,
			demo:      GTM_USER_TYPE_VALUES.DEMO,
		}[userType] || GTM_UNKNOWN
	)
}

function _pushToLayer(variableName: GtmVariable, value: string) {
	window.dataLayer.push({
		[variableName]: value,
	})
}

function _pushEventToLayer(value: string) {
	_pushToLayer('event', value)
}

function changeAppSection(appSection: AppSection) {
	_pushToLayer('section', AppSection_GtmSection(appSection))
}

function changeUserId(userId: ?string) {
	const gtmKey = 'userID'
	if (userId != null) {
		_pushToLayer(gtmKey, userId)
	} else {
		_pushToLayer(gtmKey, GTM_UNKNOWN)
		_pushEventToLayer('userIdRemoved')
	}
	_pushEventToLayer('userIdChanged')
}

function changeUserType(userType: ?UserType) {
	const gtmKey = 'userType'
	if (userType != null) {
		_pushToLayer(gtmKey, AppUserType_GtmUserType(userType))
	} else {
		_pushToLayer(gtmKey, GTM_UNKNOWN)
		_pushEventToLayer('userTypeRemoved')
	}
	_pushEventToLayer('userTypeChanged')
}

function changeLanguage(language: string) {
	const gtmKey = 'userLanguage'
	_pushToLayer(gtmKey, language)
	_pushEventToLayer('userLanguageChanged')
}

function changeOrganizationId(organizationId: ?string) {
	const gtmKey = 'organizationID'
	if (organizationId != null) {
		_pushToLayer(gtmKey, organizationId)
	} else {
		_pushToLayer(gtmKey, GTM_UNKNOWN)
		_pushEventToLayer('organizationRemoved')
	}
	_pushEventToLayer('organizationChanged')
}

function submitDemoContactForm() {
	_pushEventToLayer('submitDemoContactForm')
}

function changeMainAccountantId(mainAccountantId: ?string) {
	const gtmKey = 'mainAccountantId'
	if (mainAccountantId != null) {
		_pushToLayer(gtmKey, mainAccountantId)
	}
	else {
		_pushToLayer(gtmKey, GTM_UNKNOWN)
		_pushEventToLayer('mainAccountantIdRemoved')
	}
	_pushEventToLayer('mainAccountantIdChanged')
}

function changePayrollAccountantId(payrollAccountantId: ?string) {
	const gtmKey = 'payrollAccountantId'
	if (payrollAccountantId != null) {
		_pushToLayer(gtmKey, payrollAccountantId)
	} else {
		_pushToLayer(gtmKey, GTM_UNKNOWN)
		_pushEventToLayer('payrollAccountantIdRemoved')
	}
	_pushEventToLayer('payrollAccountantIdChanged')
}

function setUserInfoReady() {
	_pushEventToLayer('userInfoReady')
}

export default {
	changeAppSection,
	changeUserId,
	changeUserType,
	changeOrganizationId,
	submitDemoContactForm,
	changeMainAccountantId,
	changePayrollAccountantId,
	setUserInfoReady,
	changeLanguage,
}
