/* @flow */

import {
	cashRegisters as cashRegistersApi,
	cashRegister as cashRegisterApi,
	cashRegisterEet as cashRegisterEetApi,
	cashRegisterSettings as cashRegisterSettingsApi,
	paymentSearch as paymentSearchApi,
	cashRegisterBalances as cashRegisterBalancesApi,
	cashRegisterInventories as cashRegisterInventoriesApi,
	cashBookDownload as cashBookDownloadApi,
} from 'modules/common/models/api-model'
import type { CashRegisterBalancesGetParams } from 'modules/common/models/api-model'
import type {
	Dispatch,
	Filter,
	CashRegister,
	CashRegisterBalance,
	CashRegistersSearchResult,
	CashRegisterInventory,
	CashRegisterInventories,
	CashRegisterAction,
	CashRegisterSettings,
	OrganizationCountrySpecificSettingsCzEet,
	DownloadToken,
} from 'types'
import { formatDateToIsoString, formatToIsoDateString } from 'utils/formatters'
import { notFoundRoute } from 'modules/navigation/routing/routes'
import { push } from 'react-router-redux'
import { API_NOT_FOUND_CODE } from 'trivi-constants'
import { buildPublicUrl } from 'lib/apiHandler'

export function loadCashRegisters() {
	return async (dispatch: Dispatch<CashRegisterAction>) => {
		dispatch({
			type: 'START_LOAD_CASH_REGISTERS',
		})
		try {
			const result: CashRegistersSearchResult = await cashRegistersApi.get({})
			const cashRegisters = (Array.isArray(result.cashRegisters) && result.cashRegisters) || []
			return dispatch({
				type: 'FINISH_LOAD_CASH_REGISTERS',
				cashRegisters: cashRegisters,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_LOAD_CASH_REGISTERS',
				cashRegisters: [],
				serverError: error,
			})
		}
	}
}

export function loadCashRegister(id: string) {
	return async (dispatch: Dispatch<CashRegisterAction>) => {
		dispatch({
			type: 'START_LOAD_CASH_REGISTER',
			id,
		})
		try {
			const result = await cashRegisterApi.get({ cashRegisterId: id })
			dispatch({
				type: 'FINISH_LOAD_CASH_REGISTER',
				id,
				cashRegister: result,
			})
		} catch (error) {
			await dispatch({
				type: 'FINISH_LOAD_CASH_REGISTER',
				id,
				serverError: error,
			})
			if (error.code === API_NOT_FOUND_CODE) {
				dispatch(push(notFoundRoute()))
			}
		}
	}
}

export function changeCashRegisterBalancesView(id: string, view: string) {
	return {
		type: 'CHANGE_CASH_REGISTER_BALANCES_VIEW',
		id: id,
		view: view,
	}
}

export function updateCashRegister(id: string, cashRegister: CashRegister) {
	return async (dispatch: Dispatch<CashRegisterAction>): Promise<CashRegisterAction> => {
		dispatch({
			type: 'START_UPDATE_CASH_REGISTER',
			id,
			cashRegister,
		})
		try {
			const updatedCashRegister = await cashRegisterApi.put(
				{ cashRegisterId: id },
				{
					name: cashRegister.name,
					currency: cashRegister.currency,
					initialAmount: cashRegister.initialAmount,
					isActive: true,
					ownerUserId: cashRegister.ownerUserId,
					countryVariantSpecific: cashRegister.countryVariantSpecific,
				},
			)
			return dispatch({
				type: 'FINISH_UPDATE_CASH_REGISTER',
				id,
				cashRegister: updatedCashRegister,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_UPDATE_CASH_REGISTER',
				id,
				validationError: error,
			})
		}
	}
}

export function updateCashRegisterSettings(id: string, cashRegisterSettings: CashRegisterSettings) {
	return async (dispatch: Dispatch<CashRegisterAction>): Promise<CashRegisterAction> => {
		dispatch({
			type: 'START_UPDATE_CASH_REGISTER_SETTINGS',
			id,
			cashRegisterSettings,
		})
		try {
			const updatedCashRegisterSettings = await cashRegisterSettingsApi.put(
				{ cashRegisterId: id },
				cashRegisterSettings,
			)
			return dispatch({
				type: 'FINISH_UPDATE_CASH_REGISTER_SETTINGS',
				id,
				cashRegisterSettings: updatedCashRegisterSettings,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_UPDATE_CASH_REGISTER_SETTINGS',
				id,
				validationError: error,
			})
		}
	}
}

export function updateCashRegisterEet(id: string, eet: OrganizationCountrySpecificSettingsCzEet) {
	return async (dispatch: Dispatch<CashRegisterAction>): Promise<CashRegisterAction> => {
		dispatch({
			type: 'START_UPDATE_CASH_REGISTER_EET',
			id,
			eet,
		})
		try {
			const updatedEet = await cashRegisterEetApi.put({ cashRegisterId: id }, eet)
			return dispatch({
				type: 'FINISH_UPDATE_CASH_REGISTER_EET',
				id,
				eet: updatedEet,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_UPDATE_CASH_REGISTER_EET',
				id,
				validationError: error,
			})
		}
	}
}

export function createCashRegister(cashRegister: CashRegister) {
	return async (dispatch: Dispatch<CashRegisterAction>): Promise<CashRegisterAction> => {
		dispatch({
			type: 'START_CREATE_CASH_REGISTER',
			cashRegister,
		})
		try {
			const newCashRegister: CashRegister = await cashRegistersApi.post({
				name: cashRegister.name,
				currency: cashRegister.currency,
				initialAmount: cashRegister.initialAmount,
				isActive: true,
				ownerUserId: cashRegister.ownerUserId,
				countryVariantSpecific: cashRegister.countryVariantSpecific,
			})
			return dispatch({
				type: 'FINISH_CREATE_CASH_REGISTER',
				cashRegister: newCashRegister,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_CREATE_CASH_REGISTER',
				validationError: error,
			})
		}
	}
}

export function loadPayments(cashRegisterId: string, filter: ?Filter) {
	return async (dispatch: Dispatch<CashRegisterAction>) => {
		dispatch({
			type: 'START_LOAD_CASH_REGISTER_PAYMENTS',
			cashRegisterId,
		})
		try {
			const payments = await paymentSearchApi.post({
				filters: [
					...(filter || []),
					{
						field: 'cashRegisterId',
						value: cashRegisterId,
					},
				],
			})
			dispatch({
				type: 'FINISH_LOAD_CASH_REGISTER_PAYMENTS',
				cashRegisterId,
				payments: payments.payments || [],
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_LOAD_CASH_REGISTER_PAYMENTS',
				cashRegisterId,
				payments: [],
				serverError: error,
			})
		}
	}
}

export function loadCashRegisterBalances(
	cashRegisterId: string,
	dateFrom: Date,
	dateTo: Date = new Date(),
	grouping: 'daily' | 'weekly' | 'monthly' | 'yearly' | 'sum' = 'daily',
) {
	return async (dispatch: Dispatch<CashRegisterAction>) => {
		const dateFromString = formatDateToIsoString(dateFrom)
		const dateToString = formatDateToIsoString(
			new Date(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate() + 1, 0, 0, -1),
		)
		dispatch({
			type: 'START_LOAD_CASH_REGISTERS_BALANCES',
			cashRegisterId,
			grouping: grouping,
			dateFrom: dateFromString,
			dateTo: dateToString,
		})
		try {
			let params: CashRegisterBalancesGetParams = {
				cashRegisterId,
				dateFrom: dateFromString,
				dateTo: dateToString,
				grouping,
			}

			const balances: Array<CashRegisterBalance> = await cashRegisterBalancesApi.get(params)
			dispatch({
				type: 'FINISH_LOAD_CASH_REGISTERS_BALANCES',
				cashRegisterId,
				grouping: grouping,
				dateFrom: dateFromString,
				dateTo: dateToString,
				balances: balances || [],
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_LOAD_CASH_REGISTERS_BALANCES',
				cashRegisterId,
				grouping: grouping,
				dateFrom: dateFromString,
				dateTo: dateToString,
				balances: [],
				serverError: error,
			})
		}
	}
}

export function loadBalance(cashRegisterId: string, date: Date) {
	return loadCashRegisterBalances(cashRegisterId, new Date(1990, 0, 1), date, 'sum')
}

export function loadInventories(cashRegisterId: string) {
	return async (dispatch: Dispatch<CashRegisterAction>) => {
		dispatch({
			type: 'START_LOAD_CASH_REGISTER_INVENTORIES',
			cashRegisterId: cashRegisterId,
		})
		try {
			const inventories: CashRegisterInventories = await cashRegisterInventoriesApi.get({
				cashRegisterId: cashRegisterId,
			})
			dispatch({
				type: 'FINISH_LOAD_CASH_REGISTER_INVENTORIES',
				cashRegisterId,
				inventories: inventories,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_LOAD_CASH_REGISTER_INVENTORIES',
				cashRegisterId,
				serverError: error,
			})
		}
	}
}

export function changeMakeInventory(date: Date) {
	return {
		type: 'CHANGE_MAKE_INVENTORY',
		date: date,
	}
}

export function makeInventory(cashRegisterId: string, inventory: CashRegisterInventory) {
	return async (dispatch: Dispatch<CashRegisterAction>): Promise<CashRegisterAction> => {
		dispatch({
			type: 'START_MAKE_INVENTORY',
			inventory: inventory,
		})
		try {
			const result: CashRegisterInventory = await cashRegisterInventoriesApi.post(
				{
					cashRegisterId: cashRegisterId,
				},
				inventory,
			)
			return dispatch({
				type: 'FINISH_MAKE_INVENTORY',
				inventory: result,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_MAKE_INVENTORY',
				serverError: error,
			})
		}
	}
}

export function downloadCashBook(cashRegisterId: string, from: Date, to: Date) {
	return async (dispatch: Dispatch<CashRegisterAction>): Promise<CashRegisterAction> => {
		dispatch({
			type: 'START_DOWNLOADING_CASHBOOK',
		})
		try {
			const downloadToken: DownloadToken = await cashBookDownloadApi.post({
				cashRegisterId,
				from: formatToIsoDateString(from),
				to: formatToIsoDateString(to),
			})
			if (downloadToken.token) {
				const url = buildPublicUrl(`{organizationId}/downloads/tokens/${downloadToken.token}`)
				window.location.href = url
			}
			return dispatch({
				type: 'FINISH_DOWNLOADING_CASHBOOK',
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_DOWNLOADING_CASHBOOK',
				serverError,
			})
		}
	}
}
