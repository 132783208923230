/* @flow */
/** @jsx jsx */

import { PureComponent } from 'react'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import type {
	AccountingDocumentBankAccount,
	BankAccount as BankAccountType,
	Contact,
	FormFieldErrorMessage,
} from 'types'
import AddBankAccount from 'modules/accounting-document/components/invoice-elements/add-bank-account'
import BankAccount from './bank-account'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { emptyAccountingDocumentBankAccount } from 'types/empty'
import { AccountingDocumentBankAccount_equalsValues } from 'types/operations'
import { getRemainingBankAccounts, getTooltipErrorLabel, hasErrors } from 'helpers'
import loader from 'assets/images/loader-bank-account.svg'
import { EMPTY_ARRAY } from 'trivi-constants'
import { colors } from 'variables'
import Tooltip from 'components/tooltip'

type Direction = 'issued' | 'received'

export type Props = {|
	...WithTranslateProps,
	accountingDocumentId: string,
	direction: Direction,
	readonly?: boolean,
	loading: boolean,
	bankAccounts: ?Array<AccountingDocumentBankAccount>,
	availableBankAccounts: Array<AccountingDocumentBankAccount>,
	organizationBankAccounts: Array<BankAccountType>,
	paymentType: ?number,
	accountingDocumentContact: ?Contact,
	accountingDocumentContactId: ?string,
	loadContact: (id: string) => void,
	onBankAccountRemove: (accountingDocumentId: string, bankAccount: AccountingDocumentBankAccount) => void,
	onBankAccountAdd: (accountingDocumentId: string, bankAccount: AccountingDocumentBankAccount) => void,
	onAddBankAccountToContact: (bankAccount: AccountingDocumentBankAccount, contactId: string) => void,
	errors: ?Array<FormFieldErrorMessage>,
	removeError: () => void,
|}

type State = {|
	bankAccounts: Array<AccountingDocumentBankAccount>,
	accountsMenuOpen: boolean,
|}

class BankAccounts extends PureComponent<Props, State> {
	state: State = {
		bankAccounts: this.props.bankAccounts || [],
		accountsMenuOpen: false,
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.bankAccounts !== nextProps.bankAccounts) {
			this.setState({ bankAccounts: nextProps.bankAccounts || EMPTY_ARRAY })
		}
		if (
			!this.isIssued() &&
			this.props.accountingDocumentContact !== nextProps.accountingDocumentContact &&
			nextProps.accountingDocumentContact &&
			(!nextProps.accountingDocumentContact.bankAccounts || !nextProps.accountingDocumentContact.bankAccounts.length)
		) {
			this.onBankAccountCreate()
		}
	}

	isIssued = () => {
		return this.props.direction === 'issued'
	}

	hasAnyAccounts = () => {
		return this.props.bankAccounts && this.props.bankAccounts.length > 0
	}

	openAccountsMenu = () => {
		this.setState({ accountsMenuOpen: true })
	}

	closeAccountsMenu = () => {
		this.setState({ accountsMenuOpen: false })
	}

	onBankAccountCreate = (bankAccount?: AccountingDocumentBankAccount) => {
		this.props.removeError()
		this.props.onBankAccountAdd(this.props.accountingDocumentId, bankAccount || emptyAccountingDocumentBankAccount())
	}

	onBankAccountRemove = (bankAccount: AccountingDocumentBankAccount) => {
		this.props.removeError()
		this.props.onBankAccountRemove(this.props.accountingDocumentId, bankAccount)
	}

	onBankAccountAdd = (bankAccount: AccountingDocumentBankAccount) => {
		this.props.removeError()
		this.props.onBankAccountAdd(this.props.accountingDocumentId, bankAccount)
	}

	onAddBankAccountToContact = (bankAccount: AccountingDocumentBankAccount) => {
		this.props.accountingDocumentContactId &&
			this.props.onAddBankAccountToContact(bankAccount, this.props.accountingDocumentContactId)
	}

	isBankAccountSaved = (
		availableBankAccounts: Array<AccountingDocumentBankAccount>,
		bankAccount: AccountingDocumentBankAccount,
	) => {
		return (
			availableBankAccounts.findIndex((ba: AccountingDocumentBankAccount) => {
				return AccountingDocumentBankAccount_equalsValues(ba, bankAccount)
			}) > -1
		)
	}

	renderSelectAccount = () => {
		return (
			<AddBankAccount
				accountingDocumentId={this.props.accountingDocumentId}
				direction={this.props.direction}
				readonly={this.props.readonly}
				bankAccounts={this.props.bankAccounts || EMPTY_ARRAY}
				availableBankAccounts={this.props.availableBankAccounts}
				onBankAccountAttach={this.onBankAccountAdd}
				onBankAccountCreate={this.onBankAccountCreate}
				buttonText={this.props.t('invoice.selectBankAccount')}
				accountingDocumentContact={this.props.accountingDocumentContact}
				accountingDocumentContactId={this.props.accountingDocumentContactId}
				loadContact={this.props.loadContact}
				organizationBankAccounts={this.props.organizationBankAccounts}
			/>
		)
	}

	renderHeader() {
		const headlineKey =
			this.props.paymentType === 1 ? (this.isIssued() ? 'sendPaymentToAccount' : 'accountNumber') : 'accountNumber'
		return (
			<div css={styles.header}>
				{this.props.t(`invoice.${headlineKey}`, { count: this.state.bankAccounts.length || 1 })}
			</div>
		)
	}

	renderLoader() {
		return (
			<div css={styles.root}>
				<img src={loader} css={styles.loader} />
			</div>
		)
	}

	renderBankAccounts = memoize(
		(
			bankAccounts: Array<AccountingDocumentBankAccount>,
			availableBankAccounts: Array<AccountingDocumentBankAccount>,
			remainingBankAccounts: Array<AccountingDocumentBankAccount>,
			accountingDocumentContactId: ?string,
			direction: Direction,
			readonly: ?boolean,
		) => {
			return bankAccounts.map((bankAccount: AccountingDocumentBankAccount, index: number) => {
				return (
					<BankAccount
						key={bankAccount.localId || bankAccount.bankAccountId || index}
						direction={direction}
						readonly={!!readonly}
						bankAccount={bankAccount}
						remainingBankAccounts={remainingBankAccounts}
						canBeSaved={!!accountingDocumentContactId && !this.isBankAccountSaved(availableBankAccounts, bankAccount)}
						onBankAccountRemove={this.onBankAccountRemove}
						onBankAccountAdd={this.onBankAccountAdd}
						onAddBankAccountToContact={this.onAddBankAccountToContact}
					/>
				)
			})
		},
	)

	render() {
		const { loading, readonly, availableBankAccounts, bankAccounts, errors, t } = this.props

		if (loading) return this.renderLoader()
		if (!this.isIssued() && !readonly && !this.hasAnyAccounts()) this.onBankAccountCreate()

		const remainingBankAccounts = getRemainingBankAccounts(availableBankAccounts, bankAccounts)
		const selectBankAccount = !readonly && !this.hasAnyAccounts() && this.isIssued() && this.renderSelectAccount()
		const areAnyErrors = hasErrors(errors)

		return (
			<div css={areAnyErrors && styles.error}>
				<div css={styles.root}>
					<div>
						{this.renderHeader()}
						<Tooltip label={getTooltipErrorLabel(errors, t)} disabled={!areAnyErrors}>
							{this.renderBankAccounts(
								this.state.bankAccounts,
								this.props.availableBankAccounts,
								remainingBankAccounts,
								this.props.accountingDocumentContactId,
								this.props.direction,
								this.props.readonly,
							)}
							{selectBankAccount}
						</Tooltip>
					</div>
				</div>
			</div>
		)
	}
}

const styles = {
	root: {
		position: 'relative',
		padding: '9px 12px',
	},
	error: {
		background: colors.redFaded4,
		borderTopLeftRadius: 3,
		borderBottomLeftRadius: 3,
		boxShadow: `0 0 0 2px ${colors.red}`,
		position: 'relative',
		zIndex: 1,
		height: '100%',
	},
	header: {
		fontSize: 10,
		color: colors.blackFaded80,
		marginBottom: 5,
	},
	loader: {
		display: 'block',
		width: 240,
		height: 'auto',
		marginTop: 5,
	},
}

export default withTranslate(BankAccounts)
