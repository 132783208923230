/* @flow */

import React, { Component, type Node } from 'react'
import memoize from 'memoize-one'
import type { AutoTestProps } from 'utils/tests/autotest'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import TextField from 'components/TextField'
import Tooltip from 'components/tooltip'
import { passAutoTestId } from 'utils/tests/autotest'

const MAX_LENGTH = 14

export type Props = {|
	id?: string,
	precision: number,
	value: ?number,
	minValue?: number,
	maxValue?: number,
	clientError?: Node,
	labelText?: string,
	disabled?: boolean,
	autoWidth?: boolean,
	onChange?: (SyntheticInputEvent<HTMLInputElement>, ?number) => void,
	onFocus?: (SyntheticInputEvent<HTMLInputElement>) => void,
	onBlur?: (SyntheticInputEvent<HTMLInputElement>) => void,
	hintText?: string,
	right?: boolean,
	inline?: boolean,
	fullWidth?: boolean,
	tabIndex?: number,
	compact?: boolean,
	formatFn?: (value: ?number) => number | string,
	name?: string,
	autoFocus?: boolean,
	minWidth?: number,
	onKeyEnter?: () => void,
	onKeyEscape?: () => void,
	...AutoTestProps,
|}

type State = {|
	isLetterWarningVisible: boolean,
	showFormatted: boolean,
	value: ?number | ?string,
	clientError?: Node,
|}

type ComponentProps = {| ...Props, ...WithTranslateProps |}

class NumberInput extends Component<ComponentProps, State> {
	letterWarningTimeout: ?TimeoutID = null

	static defaultProps = {
		precision: 0,
	}

	constructor(props: ComponentProps) {
		super(props)
		this.state = {
			isLetterWarningVisible: false,
			showFormatted: true,
			value: props.value,
		}
	}

	showLetterWarning() {
		this.setState(
			{
				isLetterWarningVisible: true,
			},
			() => {
				this.letterWarningTimeout && clearTimeout(this.letterWarningTimeout)
				this.letterWarningTimeout = setTimeout(() => {
					this.setState({
						isLetterWarningVisible: false,
					})
				}, 1000)
			},
		)
	}

	UNSAFE_componentWillReceiveProps(newProps: ComponentProps) {
		this.setState({ value: newProps.value })
	}

	validate = (value: ?number): boolean => {
		if (value) {
			if (this.props.minValue !== undefined && value < this.props.minValue) {
				this.setState({ clientError: this.props.t('clientError.minValueIs', { value: this.props.minValue }) })
				return false
			}
			if (this.props.maxValue !== undefined && value > this.props.maxValue) {
				this.setState({ clientError: this.props.t('clientError.maxValueIs', { value: this.props.maxValue }) })
				return false
			}
		}
		this.setState({ clientError: null })
		return true
	}

	onRawChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
		let val = event.target.value
		let valToParse = val
		const lastChar = val.charAt(val.length - 1)

		//first minus
		if (lastChar === '-' && val.length === 1) {
			return this.setState({ value: val })
		}

		if (valToParse) {
			valToParse = val.replace(',', '.')
		}

		const result: number = parseFloat(valToParse)
		const value: ?number = isNaN(result) ? null : result

		if (isNaN(lastChar)) {
			const tmp = val.substring(0, val.length - 1)
			if ((lastChar !== '.' && lastChar !== ',') || tmp.indexOf('.') >= 0 || tmp.indexOf(',') >= 0) {
				val = tmp
			}
		}
		this.setState({ value: val })

		if (
			lastChar === '.' ||
			lastChar === ',' ||
			(lastChar === '0' && (val.indexOf('.') >= 0 || val.indexOf(',') >= 0))
		) {
			return
		}

		if (lastChar.match(/[a-zA-Z]/)) this.showLetterWarning()

		if (this.validate(value)) {
			this.props.onChange && this.props.onChange(event, value)
		}
	}

	onRawBlur = (event: SyntheticInputEvent<HTMLInputElement>) => {
		this.setState({ showFormatted: true })
		this.props.onBlur && this.props.onBlur(event)

		if (this.state.clientError) {
			this.setState({ value: this.props.value }, () => {
				this.validate(this.props.value)
			})
		}
	}

	onFormattedFocus = (event: SyntheticInputEvent<HTMLInputElement>) => {
		this.setState({ showFormatted: false }, () => {
			this.refs.rawInput.getWrappedInstance().focus()
		})
		this.props.onFocus && this.props.onFocus(event)
	}

	focus() {
		this.refs.formattedInput.getWrappedInstance().focus()
	}

	render() {
		const styles = this.getStyles(this.props.inline)
		const rawValue =
			this.state.value !== null && this.state.value !== undefined ? this.state.value.toString().replace('.', ',') : ''
		const formattedValue = this.props.formatFn
			? this.props.formatFn(this.props.value)
			: this.props.value !== null && this.props.value !== undefined
			? this.props.value.toString()
			: ''

		return (
			<Tooltip
				label={this.props.t('application.validation.useOnlyNumbers')}
				visible={this.state.isLetterWarningVisible}
				inline={this.props.inline}
				id={this.props.name}
				{...passAutoTestId(this.props.autoTestId, 'tooltip')}
			>
				<TextField
					ref="rawInput"
					containerStyle={this.state.showFormatted ? styles.hidden : styles.normal}
					inputStyle={styles.input}
					onChange={this.onRawChange}
					onBlur={this.onRawBlur}
					value={rawValue}
					hintText={this.props.hintText}
					labelText={this.props.labelText}
					right={this.props.right}
					inline={this.props.inline}
					fullWidth={this.props.fullWidth}
					autoWidth={this.props.autoWidth}
					disabled={this.props.disabled}
					tabIndex={this.state.showFormatted ? -1 : this.props.tabIndex}
					compact={this.props.compact}
					name={this.props.name}
					style={styles.textField}
					onKeyEnter={this.props.onKeyEnter}
					onKeyEscape={this.props.onKeyEscape}
					autoFocus={this.props.autoFocus}
					clientError={this.props.clientError || this.state.clientError}
					minWidth={this.props.minWidth}
					maxLength={MAX_LENGTH}
					{...passAutoTestId(this.props.autoTestId, 'input-raw')}
				/>
				<TextField
					id={this.props.id}
					ref="formattedInput"
					containerStyle={this.state.showFormatted ? styles.normal : styles.hidden}
					inputStyle={styles.input}
					onFocus={this.onFormattedFocus}
					value={formattedValue}
					hintText={this.props.hintText}
					labelText={this.props.labelText}
					right={this.props.right}
					inline={this.props.inline}
					fullWidth={this.props.fullWidth}
					autoWidth={this.props.autoWidth}
					disabled={this.props.disabled}
					compact={this.props.compact}
					tabIndex={this.state.showFormatted ? this.props.tabIndex : -1}
					onKeyEnter={this.props.onKeyEnter}
					onKeyEscape={this.props.onKeyEscape}
					autoFocus={this.props.autoFocus}
					style={styles.textField}
					clientError={this.props.clientError || this.state.clientError}
					minWidth={this.props.minWidth}
					maxLength={MAX_LENGTH}
					{...passAutoTestId(this.props.autoTestId, 'input-formatted')}
				/>
			</Tooltip>
		)
	}

	getStyles = memoize((inline?: boolean) => ({
		normal: {
			display: 'inline-block',
			marginLeft: 'auto',
			marginRight: 'auto',
			zIndex: 1,
		},
		hidden: {
			opacity: 0,
			pointerEvents: 'none',
			position: 'fixed',
			top: 0,
			left: 0,
			userSelect: 'none',
			tabIndex: -1,
			zIndex: 0,
		},
		textField: {
			lineHeight: '24px',
			height: inline ? 24 : 'auto',
		},
		input: {
			color: 'inherit',
		},
	}))
}

export default withTranslate(NumberInput, { withRef: true })
