/* @flow */

/*
Zobrazování tlačítka pro zapnutí automatického počítání rekapitulace
*/
export function isVatRecapResetButtonVisible(params: {
	isManual: boolean, // Je na dokladu zapnutá manuální rekapitulace?
	isVatFree: boolean, // Je doklad s nebo bez DPH?
	isReadonly: boolean, // Je doklad zobrazený v módu editace?
	isUserInternal: boolean, // Je uživatel interní?
}): boolean {
	const { isManual, isReadonly, isUserInternal, isVatFree } = params

	// Zobrazovat resetovací tlačítko:
	const isResetButtonVisible =
		// - pouze při zapnuté ruční rekapitulaci
		isManual &&
		// - jen při editaci faktury
		!isReadonly &&
		// - pro standardního uživatele pouze u dokladů s DPH (interní uživatel ho vidí i u neplátcovských, aby mohl opravit případnou nesrovnalost)
		(isUserInternal || !isVatFree)

	return isResetButtonVisible
}
