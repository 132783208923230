// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._21AFZHqD0MBf0cY9lYjCQe {\n\tmargin-bottom: 10px;\n\tline-height: 24px;\n}\n\n._3lhDfy6ucMv-54n8caXSRd {\n\tmargin-bottom: 5px;\n\tmargin-top: 0;\n}\n", "",{"version":3,"sources":["webpack://./modules/accounting-document/components/invoice-elements/printing-note.css"],"names":[],"mappings":"AAAA;CACC,mBAAA;CACA,iBAAA;AACD;;AAEA;CACC,kBAAA;CACA,aAAA;AACD","sourcesContent":[".root {\n\tmargin-bottom: 10px;\n\tline-height: 24px;\n}\n\n.headline {\n\tmargin-bottom: 5px;\n\tmargin-top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_21AFZHqD0MBf0cY9lYjCQe",
	"headline": "_3lhDfy6ucMv-54n8caXSRd"
};
export default ___CSS_LOADER_EXPORT___;
