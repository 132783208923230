/* @flow */

import { fetchJson } from './http'
import type { NotificationType } from 'types'

export type MethodOptions = {
	timeout?: number,
	sendNotification?: (notification: NotificationType) => void,
	customOrganizationId?: string,
}

type MethodSettings = {
	method: string,
	headers?: {
		'Content-Type'?: string,
		[string]: string,
	},
	params?: { [string]: string },
	options?: MethodOptions,
}

export type ResourceFn = (routeParams: ?{ [string]: any }, payload: ?{ [string]: any }) => Promise<any>
export type ResourceFns = { [string]: ResourceFn }

function buildResourceFn(
	methodSettings: MethodSettings,
	urlTemplate: string,
	refreshTokenFn: ?() => Promise<?string>,
	options: MethodOptions = {},
): ResourceFn {
	return (
		routeParams: ?{ [string]: string },
		payload: ?{
			file?: File,
			[string]: string,
		},
	) => {
		const params: { [string]: string } = {
			...routeParams,
			...methodSettings.params,
		}
		const queryStringParams: { [string]: string } = { ...routeParams }
		let resolvedUrl: string = urlTemplate
		let queryString: string = '?'

		const matches: ?Array<string> = urlTemplate.match(/\{([^}]+)\}/g)
		if (matches) {
			matches.forEach((val: string) => {
				const paramKey: string = val.substring(1, val.length - 1)
				resolvedUrl = resolvedUrl.replace(val, params[paramKey] == null ? '' : params[paramKey])
				delete queryStringParams[paramKey]
			})
		}
		resolvedUrl = resolvedUrl.replace(/\/*$/, '') // removed trailing slashes if any

		Object.keys(queryStringParams).forEach((key: string) => {
			queryString += key + '=' + encodeURIComponent(queryStringParams[key]) + '&'
		})

		return fetchJson(
			{
				url: resolvedUrl + queryString.substr(0, queryString.length - 1),
				method: methodSettings.method,
				headers: methodSettings.headers,
			},
			payload,
			options.timeout,
			refreshTokenFn,
			options.sendNotification,
		)
	}
}

function buildResources(
	methods: ?{ [string]: MethodSettings },
	urlTemplate: string,
	params: ?{ [string]: string },
	options?: MethodOptions,
	headers: ?{ [string]: string },
	refreshTokenFn: ?() => Promise<?string>,
): ResourceFns {
	const defaultMethods: { [string]: MethodSettings } = {
		get: { method: 'GET' },
		query: { method: 'GET' },
		post: { method: 'POST' },
		put: { method: 'PUT' },
		patch: { method: 'PATCH' },
		delete: { method: 'DELETE' },
	}

	const resources: { [string]: ResourceFn } = {}

	const allMethods: { [string]: MethodSettings } = Object.assign({}, defaultMethods, methods)
	Object.getOwnPropertyNames(allMethods).forEach((key: string) => {
		resources[key] = buildResourceFn(
			{ ...allMethods[key], headers: headers || {}, params: params || {}, options: options || {} },
			urlTemplate,
			refreshTokenFn,
			options,
		)
	})

	return resources
}

const httpResource = (
	urlTemplate: string,
	params: ?{ [string]: string },
	headers: ?{ [string]: string },
	options?: MethodOptions,
	refreshTokenFn: ?() => Promise<?string>,
	methods: ?{ [string]: MethodSettings },
): ResourceFns => buildResources(methods, urlTemplate, params, options, headers, refreshTokenFn)

export default httpResource
