/* @flow */
/** @jsx jsx */

import { PureComponent } from 'react'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import { autoTestId, type AutoTestProps } from 'utils/tests/autotest'
import { colors } from 'variables'

type Props = {|
	...AutoTestProps,
	checked: boolean,
	disabled?: boolean,
	inline?: boolean,
	label?: string,
	showMinusIcon?: boolean,
	onCheck: (SyntheticInputEvent<HTMLInputElement>, boolean) => void,
|}

class Checkbox extends PureComponent<Props> {
	getStyles = memoize((props: Props) => {
		const { disabled, inline, label, showMinusIcon, checked } = props

		return {
			root: {
				display: inline ? 'inline-block' : 'block',
				lineHeight: inline ? '24px' : 'initial',
				margin: inline ? '0 20px 0 0px' : 0,
				opacity: disabled ? 0.6 : 1,
				width: 'auto',
				'&:hover .border': {
					borderColor: colors.blue,
				},
			},
			switchRoot: {
				cursor: props.disabled ? 'default' : 'pointer',
				position: 'relative',
				overflow: 'visible',
				display: 'table',
				height: 'auto',
				width: '100%',
				transition: 'all 200ms ease-in-out',
			},
			controls: {
				width: '100%',
				height: '100%',
				display: 'flex',
				justifyContent: 'flex-start',
				alignItems: 'center',
				alignContent: 'center',
			},
			icon: {
				transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1)',
				position: 'relative',
				display: 'block',
				flexShrink: 0,
				marginLeft: 0,
				height: 24,
				width: 24,
				marginRight: label ? 6 : 0,
			},
			boxStyles: {
				display: 'block',
				position: 'absolute',
				opacity: checked ? 0 : 1,
				transition: 'all 150ms cubic-bezier(0.23, 1, 0.32, 1), opacity 500ms cubic-bezier(0.23, 1, 0.32, 1) 150ms',
				top: 4,
				left: 4,
				width: 16,
				height: 16,
				borderRadius: 3,
				border: `1px solid ${checked ? colors.blue : '#979797'}`,
				backgroundColor: colors.transparent,
			},
			checkStyles: {
				position: 'absolute',
				transitionOrigin: '50% 50%',
				display: 'block',
				top: 4,
				left: 4,
				width: 16,
				height: 16,
				borderRadius: 3,
				border: `1px solid ${checked ? colors.blue : '#979797'}`,
				backgroundColor: colors.blue,
				borderColor: colors.blue,
				fill: checked ? colors.disabled : 'inherit',
				opacity: checked ? 1 : 0,
				transform: checked ? 'scale(1)' : 'scale(0)',
				transition: checked
					? 'opacity 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, transform 800ms cubic-bezier(0.23, 1, 0.32, 1) 0ms'
					: 'opacity 350ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, transform 0ms cubic-bezier(0.23, 1, 0.32, 1) 350ms',
			},
			label: {
				float: 'left',
				position: 'relative',
				display: 'block',
				width: 'calc(100% - 60px)',
				lineHeight: '24px',
				color: disabled ? colors.disabled : colors.black,
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
			},
			iconCheck: {
				position: 'absolute',
				left: showMinusIcon ? 2 : 4,
				top: showMinusIcon ? 6 : 0,
				width: showMinusIcon ? 10 : 6,
				height: showMinusIcon ? '0' : 11,
				borderRight: showMinusIcon ? 'none' : '2px solid #fff',
				borderBottom: '2px solid #fff',
				transform: showMinusIcon ? 'none' : 'rotate(45deg)',
			},
			input: {
				position: 'absolute',
				cursor: 'inherit',
				pointerEvents: 'all',
				opacity: 0,
				width: '100%',
				height: '100%',
				zIndex: 2,
				left: 0,
				boxSizing: 'border-box',
				padding: 0,
				margin: 0,
			},
		}
	})

	handleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
		const isInputChecked = this.refs.checkbox.checked
		this.props.onCheck && this.props.onCheck(event, isInputChecked)
	}

	handleClick = (event: SyntheticMouseEvent<HTMLInputElement>) => {
		event.stopPropagation()
	}

	render() {
		const styles = this.getStyles(this.props)

		return (
			<div css={styles.root} {...autoTestId(this.props.autoTestId)}>
				<div style={styles.switchRoot} onClick={this.handleClick} {...autoTestId(this.props.autoTestId)}>
					<input
						onClick={this.handleClick}
						ref="checkbox"
						type="checkbox"
						style={styles.input}
						disabled={this.props.disabled}
						onChange={this.handleChange}
						checked={this.props.checked}
					/>
					<div style={styles.controls}>
						<div style={styles.icon}>
							<div>
								<div css={styles.boxStyles} className="border" />
								<div css={styles.checkStyles}>
									<div css={styles.iconCheck} />
								</div>
							</div>
						</div>
						{this.props.label && <label style={styles.label}>{this.props.label}</label>}
					</div>
				</div>
			</div>
		)
	}
}

export default Checkbox
