// @flow
import { createSelector } from 'reselect'
import type {
	AccountingDocumentDefaults,
	AccountingDocumentDirection,
	AccountingDocumentType,
	State,
	AccountingDocumentCategory,
	OrganizationSettingsTaxPaymentHistory,
	Signatures,
	Base64Image,
	OrganizationContractState,
	OrganizationSettingsOpen,
	OrganizationSettingsOssCountries,
} from 'types'

export const getAccountingDocumentDefaults = (
	state: State,
	direction: AccountingDocumentDirection,
	type?: ?AccountingDocumentType,
): ?AccountingDocumentDefaults => {
	return (
		(state.settings.accountingDocumentDefaults[direction] &&
			state.settings.accountingDocumentDefaults[direction][type || '']) ||
		null
	)
}

export function getAccountingDocumentCategories(state: State): ?Array<AccountingDocumentCategory> {
	return state.settings.accountingDocumentCategories
}

export function getTaxPaymentHistory(state: State): ?Array<OrganizationSettingsTaxPaymentHistory> {
	return state.settings.taxPaymentHistory
}

export function getCurrentOrganizationRegNo(state: State): ?string {
	return state.settings.organizationSettings && state.settings.organizationSettings.regNo
}

export function getCurrentOrganizationLogo(state: State): ?Base64Image {
	return state.settings.organizationLogo
}

export function getSignatureList(state: State): ?Signatures {
	return state.settings.signatures.list || null
}

export function getPayerTaxType(state: State): ?number {
	return state.settings.organizationSettings && state.settings.organizationSettings.payerTaxType
}

export function getUseOnlyOneSequenceSetPerCashRegisterFlag(state: State): ?boolean {
	return (
		state.settings.organizationSettingsOpen &&
		state.settings.organizationSettingsOpen.useOnlyOneSequenceSetPerCashRegister
	)
}

export function getIsOrganizationVatFree(state: State): boolean {
	const { organizationSettingsOpen } = state.settings
	return organizationSettingsOpen ? organizationSettingsOpen.payerTaxType === 1 : false
}

export function getCurrentOrganizationContractState(state: State): ?OrganizationContractState {
	return state.settings.contractState
}

export function getCurrentOrganizationNoteExtraction(state: State): ?string {
	return state.settings.notes ? state.settings.notes.extraction : undefined
}

function getOrganizationSettingsOpen(state: State): ?OrganizationSettingsOpen {
	return state.settings.organizationSettingsOpen
}

export const organizationSettingsOpenSelector = createSelector(
	getOrganizationSettingsOpen,
	(organizationSettingsOpen: ?OrganizationSettingsOpen) => organizationSettingsOpen,
)

export function getOrganizationSettingsOss(state: State): ?OrganizationSettingsOssCountries {
	return state.settings.oss
}
