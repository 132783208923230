/* @flow */
/* DO NOT EDIT - GENERATED FILE */

import * as t from './swagger-model'

import { handler, privateApiHandler, type MethodOptions } from 'lib/apiHandler'
export type AccountingDocumentsGetParams = {|
	page?: number,
	pageSize?: number,
	scope?: string,
|}
export type AccountingDocumentsPostParams = {|
	createContact?: boolean,
|}
export const accountingDocuments = {
	/**
	 * (TP-142) List accounting documents
	 */
	get: async function(
		params: AccountingDocumentsGetParams,
		options?: MethodOptions,
	): Promise<t.AccountingDocumentSearchResult> {
		return handler('accountingdocuments', options).get(params, null)
	},
	/**
	 * (TP-103) Create accounting document
	 */
	post: async function(body: t.AccountingDocument, options?: MethodOptions): Promise<t.AccountingDocument> {
		return handler('accountingdocuments', options).post(null, body)
	},
}
export type AccountingDocumentParams = {|
	accountingDocumentId: string,
|}
export type AccountingDocumentGetParams = {| ...AccountingDocumentParams |}
export type AccountingDocumentPutParams = {| ...AccountingDocumentParams |}
export type AccountingDocumentPatchParams = {| ...AccountingDocumentParams |}
export type AccountingDocumentDeleteParams = {| ...AccountingDocumentParams |}
export const accountingDocument = {
	/**
	 * (TP-146) Get accounting document
	 */
	get: async function(params: AccountingDocumentGetParams, options?: MethodOptions): Promise<t.AccountingDocument> {
		return handler('accountingdocuments/{accountingDocumentId}', options).get(params, null)
	},
	/**
	 * (TP-140) Update accounting document
	 */
	put: async function(
		params: AccountingDocumentPutParams,
		body: t.AccountingDocument,
		options?: MethodOptions,
	): Promise<t.AccountingDocument> {
		return handler('accountingdocuments/{accountingDocumentId}', options).put(params, body)
	},
	/**
	 * (TP-2450) Patch accounting document
	 */
	patch: async function(
		params: AccountingDocumentPatchParams,
		body: t.AccountingDocument,
		options?: MethodOptions,
	): Promise<t.AccountingDocument> {
		return handler('accountingdocuments/unknown/{accountingDocumentId}', options).patch(params, body)
	},
	/**
	 * (TP-180) Remove accounting document
	 */
	delete: async function(params: AccountingDocumentDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('accountingdocuments/{accountingDocumentId}', options).delete(params, null)
	},
}

export type PublicAccountingDocumentGetParams = {|
	uniqueId: string,
|}
export const publicAccountingDocument = {
	get: async function(params: PublicAccountingDocumentGetParams): Promise<t.PublicAccountingDocumentResponse> {
		return privateApiHandler('accountingdocuments/uniqueid/{uniqueId}').get(params, null)
	},
}

export type AccountingDocumentConnnectionsParams = {|
	accountingDocumentId: string,
|}
export type AccountingDocumentConnnectionsGetParams = {| ...AccountingDocumentConnnectionsParams |}
export type AccountingDocumentConnnectionsGetResponse = Array<t.AccountingDocumentConnection>

export type AccountingDocumentConnnectionsGetResponseTemp = any
export type AccountingDocumentConnnectionsPostParams = {| ...AccountingDocumentConnnectionsParams |}
export const accountingDocumentConnnections = {
	/**
	 * (TP-182) List connections
	 */
	get: async function(
		params: AccountingDocumentConnnectionsGetParams,
		options?: MethodOptions,
	): Promise<AccountingDocumentConnnectionsGetResponse> {
		return handler('accountingdocuments/{accountingDocumentId}/connections', options).get(params, null)
	},
	/**
	 * (TP-160) Connect accounting document
	 */
	post: async function(
		params: AccountingDocumentConnnectionsPostParams,
		body: t.AccountingDocumentConnection,
		options?: MethodOptions,
	): Promise<t.AccountingDocument> {
		return handler('accountingdocuments/{accountingDocumentId}/connections', options).post(params, body)
	},
}
export type AccountingDocumentConnnectionParams = {|
	accountingDocumentId: string,
	connectedAccountingDocumentId: string,
|}
export type AccountingDocumentConnnectionPutParams = {| ...AccountingDocumentConnnectionParams |}
export type AccountingDocumentConnnectionDeleteParams = {| ...AccountingDocumentConnnectionParams |}
export const accountingDocumentConnnection = {
	/**
	 * (TP-161) Change connection amount
	 */
	put: async function(
		params: AccountingDocumentConnnectionPutParams,
		body: t.AccountingDocumentConnection,
		options?: MethodOptions,
	): Promise<t.AccountingDocument> {
		return handler(
			'accountingdocuments/{accountingDocumentId}/connections/{connectedAccountingDocumentId}',
			options,
		).put(params, body)
	},
	/**
	 * (TP-175) Disconnect accounting document
	 */
	delete: async function(params: AccountingDocumentConnnectionDeleteParams, options?: MethodOptions): Promise<void> {
		return handler(
			'accountingdocuments/{accountingDocumentId}/connections/{connectedAccountingDocumentId}',
			options,
		).delete(params, null)
	},
}
export type AccountingDocumentPaymentsParams = {|
	accountingDocumentId: string,
|}
export type AccountingDocumentPaymentsGetParams = {| ...AccountingDocumentPaymentsParams |}
export type AccountingDocumentPaymentsPutParams = {| ...AccountingDocumentPaymentsParams |}
export const accountingDocumentPayments = {
	/**
	 * (TP-178) List payments
	 */
	get: async function(
		params: AccountingDocumentPaymentsGetParams,
		options?: MethodOptions,
	): Promise<t.AccountingDocumentPaymentInfo> {
		return handler('accountingdocuments/{accountingDocumentId}/payments', options).get(params, null)
	},
	/**
	 * (TP-499) Update payment info
	 */
	put: async function(
		params: AccountingDocumentPaymentsPutParams,
		body: t.AccountingDocumentPaymentInfo,
		options?: MethodOptions,
	): Promise<t.AccountingDocumentPaymentInfo> {
		return handler('accountingdocuments/{accountingDocumentId}/payments', options).put(params, body)
	},
}
export type AccountingDocumentAttachmentsParams = {|
	accountingDocumentId: string,
|}
export type AccountingDocumentAttachmentsGetParams = {| ...AccountingDocumentAttachmentsParams |}
export type AccountingDocumentAttachmentsGetResponse = Array<t.AccountingDocumentAttachment>

export type AccountingDocumentAttachmentsGetResponseTemp = any
export type AccountingDocumentAttachmentsPostParams = {| ...AccountingDocumentAttachmentsParams |}
export const accountingDocumentAttachments = {
	/**
	 * (TP-167) List document attachments
	 */
	get: async function(
		params: AccountingDocumentAttachmentsGetParams,
		options?: MethodOptions,
	): Promise<AccountingDocumentAttachmentsGetResponse> {
		return handler('accountingdocuments/{accountingDocumentId}/attachments', options).get(params, null)
	},
	/**
	 * (TP-162) Attach document attachment
	 */
	post: async function(
		params: AccountingDocumentAttachmentsPostParams,
		body: t.AccountingDocumentAttachment,
		options?: MethodOptions,
	): Promise<t.AccountingDocumentAttachment> {
		return handler('accountingdocuments/{accountingDocumentId}/attachments', options).post(params, body)
	},
}
export type AccountingDocumentAttachmentDocumentParams = {|
	accountingDocumentId: string,
	documentId: string,
|}
export type AccountingDocumentAttachmentDocumentDeleteParams = {| ...AccountingDocumentAttachmentDocumentParams |}
export const accountingDocumentAttachmentDocument = {
	/**
	 * (TP-168) Remove document attachment
	 */
	delete: async function(
		params: AccountingDocumentAttachmentDocumentDeleteParams,
		options?: MethodOptions,
	): Promise<void> {
		return handler('accountingdocuments/{accountingDocumentId}/attachments/{documentId}', options).delete(params, null)
	},
}
export type AccountingDocumentScansParams = {|
	accountingDocumentId: string,
|}
export type AccountingDocumentScansGetParams = {| ...AccountingDocumentScansParams |}
export type AccountingDocumentScansGetResponse = Array<t.AccountingDocumentScan>

export type AccountingDocumentScansGetResponseTemp = any
export type AccountingDocumentScansPostParams = {| ...AccountingDocumentScansParams |}
export const accountingDocumentScans = {
	/**
	 * (TP-170) List scanned attachments
	 */
	get: async function(
		params: AccountingDocumentScansGetParams,
		options?: MethodOptions,
	): Promise<AccountingDocumentScansGetResponse> {
		return handler('accountingdocuments/{accountingDocumentId}/scans', options).get(params, null)
	},
	/**
	 * (TP-163) Attach scan
	 */
	post: async function(params: AccountingDocumentScansPostParams, body: {}, options?: MethodOptions): Promise<void> {
		return handler('accountingdocuments/{accountingDocumentId}/scans', options, {
			'Content-Type': 'multipart/related',
		}).post(params, body)
	},
}
export type AccountingDocumentScanParams = {|
	accountingDocumentId: string,
	guid: string,
|}
export type AccountingDocumentScanDeleteParams = {| ...AccountingDocumentScanParams |}
export const accountingDocumentScan = {
	/**
	 * (TP-171) Remove scanned attachment
	 */
	delete: async function(params: AccountingDocumentScanDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('accountingdocuments/{accountingDocumentId}/scans/{guid}', options).delete(params, null)
	},
}

export type AccountingDocumentScanMoveToBanksParams = {|
	accountingDocumentId: string,
|}
export type AccountingDocumentScanMoveToBanksPostParams = {| ...AccountingDocumentScanMoveToBanksParams |}
export const AccountingDocumentScanMoveToBanks = {
	post: async function(
		params: AccountingDocumentScanMoveToBanksParams,
		body: {},
		options?: MethodOptions,
	): Promise<void> {
		return handler('accountingdocuments/{accountingDocumentId}/scans/moveto/bankaccount/statements', options, {}).post(
			params,
			body,
		)
	},
}

export type AccountingDocumentScanMoveToDocumentsParams = {|
	accountingDocumentId: string,
|}
export type AccountingDocumentScanMoveToDocumentsPostParams = {| ...AccountingDocumentScanMoveToDocumentsParams |}
export const AccountingDocumentScanMoveToDocuments = {
	post: async function(
		params: AccountingDocumentScanMoveToDocumentsParams,
		body: {},
		options?: MethodOptions,
	): Promise<void> {
		return handler('accountingdocuments/{accountingDocumentId}/scans/moveto/documents', options, {}).post(params, body)
	},
}

export type AccountingDocumentStateParams = {|
	accountingDocumentId: string,
|}
export type AccountingDocumentStatePutParams = {| ...AccountingDocumentStateParams |}
export const accountingDocumentState = {
	/**
	 * (TP-159) Change state
	 */
	put: async function(
		params: AccountingDocumentStatePutParams,
		body: t.AccountingDocumentStateRequest,
		options?: MethodOptions,
	): Promise<void> {
		return handler('accountingdocuments/{accountingDocumentId}/state', options).put(params, body)
	},
}
export type AccountingDocumentValidateParams = {|
	accountingDocumentId: string,
|}
export type AccountingDocumentValidatePutParams = {| ...AccountingDocumentValidateParams |}
export const accountingDocumentValidate = {
	/**
	 * (TP-2616*) Validate state change
	 */
	put: async function(
		params: AccountingDocumentValidatePutParams,
		body: t.AccountingDocumentStateRequest,
		options?: MethodOptions,
	): Promise<void> {
		return handler('accountingdocuments/{accountingDocumentId}/state/validate', options).put(params, body)
	},
}
export type AccountingDocumentNextStatesParams = {|
	accountingDocumentId: string,
|}
export type AccountingDocumentNextStatesGetParams = {| ...AccountingDocumentNextStatesParams |}
export const accountingDocumentNextStates = {
	/**
	 * (TP-158) List possible states
	 */
	get: async function(
		params: AccountingDocumentNextStatesGetParams,
		options?: MethodOptions,
	): Promise<t.AccountingDocumentStateInfo> {
		return handler('accountingdocuments/{accountingDocumentId}/nextstates', options).get(params, null)
	},
}
export type CountryVariantSpecificParams = {|
	accountingDocumentId: string,
|}
export type CountryVariantSpecificPutParams = {| ...CountryVariantSpecificParams |}
export type CountryVariantSpecificPutResponse = any

export type CountryVariantSpecificPutResponseTemp = any
export const countryVariantSpecific = {
	/**
	 * (TP-456) Update country variant specific settings
	 */
	put: async function(
		params: CountryVariantSpecificPutParams,
		body: any,
		options?: MethodOptions,
	): Promise<CountryVariantSpecificPutResponse> {
		return handler('accountingdocuments/{accountingDocumentId}/countryvariantspecific/operation', options).put(
			params,
			body,
		)
	},
}
export type AccountingDocumentLineItemsParams = {|
	accountingDocumentId: string,
|}
export type AccountingDocumentLineItemsGetParams = {| ...AccountingDocumentLineItemsParams |}
export type AccountingDocumentLineItemsGetResponse = Array<t.AccountingDocumentLineItem>

export type AccountingDocumentLineItemsGetResponseTemp = any
export type AccountingDocumentLineItemsPostParams = {| ...AccountingDocumentLineItemsParams |}
export const accountingDocumentLineItems = {
	/**
	 * (TP-172) List line items
	 */
	get: async function(
		params: AccountingDocumentLineItemsGetParams,
		options?: MethodOptions,
	): Promise<AccountingDocumentLineItemsGetResponse> {
		return handler('accountingdocuments/{accountingDocumentId}/lineitems', options).get(params, null)
	},
	/**
	 * (TP-143) Add line item
	 */
	post: async function(
		params: AccountingDocumentLineItemsPostParams,
		body: t.AccountingDocumentLineItem,
		options?: MethodOptions,
	): Promise<t.AccountingDocumentLineItem> {
		return handler('accountingdocuments/{accountingDocumentId}/lineitems', options).post(params, body)
	},
}
export type AccountingDocumentBankAccountsParams = {|
	accountingDocumentId: string,
|}
export type AccountingDocumentBankAccountsGetParams = {| ...AccountingDocumentBankAccountsParams |}
export type AccountingDocumentBankAccountsGetResponse = Array<t.AccountingDocumentBankAccount>

export type AccountingDocumentBankAccountsGetResponseTemp = any
export type AccountingDocumentBankAccountsPostParams = {| ...AccountingDocumentBankAccountsParams |}
export const accountingDocumentBankAccounts = {
	/**
	 * (TP-177) List bank accounts
	 */
	get: async function(
		params: AccountingDocumentBankAccountsGetParams,
		options?: MethodOptions,
	): Promise<AccountingDocumentBankAccountsGetResponse> {
		return handler('accountingdocuments/{accountingDocumentId}/bankaccounts', options).get(params, null)
	},
	/**
	 * (TP-148) Attach bank account
	 */
	post: async function(
		params: AccountingDocumentBankAccountsPostParams,
		body: t.AccountingDocumentBankAccount,
		options?: MethodOptions,
	): Promise<t.AccountingDocumentBankAccount> {
		return handler('accountingdocuments/{accountingDocumentId}/bankaccounts', options).post(params, body)
	},
}
export type AccountingDocumentBankAccountParams = {|
	accountingDocumentId: string,
	localBankAccountId: string,
|}
export type AccountingDocumentBankAccountPutParams = {| ...AccountingDocumentBankAccountParams |}
export type AccountingDocumentBankAccountDeleteParams = {| ...AccountingDocumentBankAccountParams |}
export const accountingDocumentBankAccount = {
	/**
	 * Update attached bank account
	 */
	put: async function(
		params: AccountingDocumentBankAccountPutParams,
		body: t.AccountingDocumentBankAccount,
		options?: MethodOptions,
	): Promise<t.AccountingDocumentBankAccount> {
		return handler('accountingdocuments/{accountingDocumentId}/bankaccounts/{localBankAccountId}', options).put(
			params,
			body,
		)
	},
	/**
	 * (TP-186) Dettach bank account
	 */
	delete: async function(params: AccountingDocumentBankAccountDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('accountingdocuments/{accountingDocumentId}/bankaccounts/{localBankAccountId}', options).delete(
			params,
			null,
		)
	},
}
export type AccountingDocumentDraftPostParams = {|
	createContact?: boolean,
|}
export const accountingDocumentDraft = {
	/**
	 * (TP-138) Create draft
	 */
	post: async function(
		params: AccountingDocumentDraftPostParams,
		body: t.AccountingDocumentCreateRequest,
		options?: MethodOptions,
	): Promise<t.AccountingDocument> {
		return handler('accountingdocuments/drafts', options).post(params, body)
	},
}
export type AccountingDocumentHeaderParams = {|
	accountingDocumentId: string,
|}
export type AccountingDocumentHeaderGetParams = {| ...AccountingDocumentHeaderParams |}
export type AccountingDocumentHeaderPutParams = {| ...AccountingDocumentHeaderParams |}
export type AccountingDocumentHeaderPatchParams = {| ...AccountingDocumentHeaderParams |}
export const accountingDocumentHeader = {
	/**
	 * (TP-179) Get header
	 */
	get: async function(
		params: AccountingDocumentHeaderGetParams,
		options?: MethodOptions,
	): Promise<t.AccountingDocumentHeader> {
		return handler('accountingdocuments/{accountingDocumentId}/header', options).get(params, null)
	},
	/**
	 * (TP-139) Update header
	 */
	put: async function(
		params: AccountingDocumentHeaderPutParams,
		body: t.AccountingDocumentHeader,
		options?: MethodOptions,
	): Promise<t.AccountingDocumentHeader> {
		return handler('accountingdocuments/{accountingDocumentId}/header', options).put(params, body)
	},
	/**
	 * (TP-2329) Patch header
	 */
	patch: async function(
		params: AccountingDocumentHeaderPatchParams,
		body: t.AccountingDocumentHeader,
		options?: MethodOptions,
	): Promise<t.AccountingDocumentHeader> {
		return handler('accountingdocuments/{accountingDocumentId}/header', options).patch(params, body)
	},
}
export type AccountingDocumentLineItemParams = {|
	accountingDocumentId: string,
	lineItemId: string,
|}
export type AccountingDocumentLineItemPutParams = {| ...AccountingDocumentLineItemParams |}
export type AccountingDocumentLineItemDeleteParams = {| ...AccountingDocumentLineItemParams |}
export const accountingDocumentLineItem = {
	/**
	 * (TP-175) Update line item
	 */
	put: async function(
		params: AccountingDocumentLineItemPutParams,
		body: t.AccountingDocumentLineItem,
		options?: MethodOptions,
	): Promise<t.AccountingDocumentLineItem> {
		return handler('accountingdocuments/{accountingDocumentId}/lineitems/{lineItemId}', options).put(params, body)
	},
	/**
	 * (TP-144) Remove line item
	 */
	delete: async function(params: AccountingDocumentLineItemDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('accountingdocuments/{accountingDocumentId}/lineitems/{lineItemId}', options).delete(params, null)
	},
}
export type AccountingDocumentContactParams = {|
	accountingDocumentId: string,
|}
export type AccountingDocumentContactPutParams = {|
	createContact?: boolean,
	removeVendorBankAccounts?: boolean,
	...AccountingDocumentContactParams,
|}
export type AccountingDocumentContactDeleteParams = {|
	removeVendorBankAccounts?: boolean,
	...AccountingDocumentContactParams,
|}
export const accountingDocumentContact = {
	/**
	 * (TP-184) Assign contact
	 */
	put: async function(
		params: AccountingDocumentContactPutParams,
		body: t.AccountingDocumentContact,
		options?: MethodOptions,
	): Promise<t.AccountingDocumentContact> {
		return handler('accountingdocuments/{accountingDocumentId}/contact', options).put(params, body)
	},
	/**
	 * (TP-982) Unassign contact
	 */
	delete: async function(params: AccountingDocumentContactDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('accountingdocuments/{accountingDocumentId}/contact', options).delete(params, null)
	},
}
export type AccountingDocumentSequenceParams = {|
	accountingDocumentId: string,
|}
export type AccountingDocumentSequencePutParams = {| ...AccountingDocumentSequenceParams |}
export type AccountingDocumentSequencePutResponse = {|
	sequenceId?: string,
	sequenceFormat?: string,
	accountingDocumentNoExample?: string,
|}

export type AccountingDocumentSequencePutResponseTemp = any
export const accountingDocumentSequence = {
	/**
	 * (TP-229) Assign sequence
	 */
	put: async function(
		params: AccountingDocumentSequencePutParams,
		body: any,
		options?: MethodOptions,
	): Promise<AccountingDocumentSequencePutResponse> {
		return handler('accountingdocuments/{accountingDocumentId}/sequence', options).put(params, body)
	},
}
export type VatRecapParams = {|
	accountingDocumentId: string,
|}
export type VatRecapGetParams = {| ...VatRecapParams |}
export type VatRecapPutParams = {| ...VatRecapParams |}
export const vatRecap = {
	/**
	 * (TP-511) Get VAT recap
	 */
	get: async function(params: VatRecapGetParams, options?: MethodOptions): Promise<t.AccountingDocumentVatRecapInfo> {
		return handler('accountingdocuments/{accountingDocumentId}/vatrecap', options).get(params, null)
	},
	/**
	 * (TP-274) Update VAT recap
	 */
	put: async function(
		params: VatRecapPutParams,
		body: t.AccountingDocumentVatRecapInfo,
		options?: MethodOptions,
	): Promise<t.AccountingDocumentVatRecapInfo> {
		return handler('accountingdocuments/{accountingDocumentId}/vatrecap', options).put(params, body)
	},
}
export type AccountingDocumentQrCodeParams = {|
	accountingDocumentId: string,
|}
export type AccountingDocumentQrCodeGetParams = {| ...AccountingDocumentQrCodeParams |}
export type AccountingDocumentQrCodeGetResponse = any

export type AccountingDocumentQrCodeGetResponseTemp = any
export const accountingDocumentQrCode = {
	/**
	 * (TP-455) Generate QR code
	 */
	get: async function(
		params: AccountingDocumentQrCodeGetParams,
		options?: MethodOptions,
	): Promise<AccountingDocumentQrCodeGetResponse> {
		return handler('accountingdocuments/{accountingDocumentId}/qr', options).get(params, null)
	},
}
export type AccountingDocumentIssuesParams = {|
	accountingDocumentId: string,
|}
export type AccountingDocumentIssuesGetParams = {| ...AccountingDocumentIssuesParams |}
export const accountingDocumentIssues = {
	/**
	 * (TP-578) Get issues
	 */
	get: async function(
		params: AccountingDocumentIssuesGetParams,
		options?: MethodOptions,
	): Promise<t.AccountingDocumentIssues> {
		return handler('accountingdocuments/{accountingDocumentId}/issues', options).get(params, null)
	},
}
export type CreditNoteParams = {|
	accountingDocumentId: string,
|}
export type CreditNotePostParams = {| ...CreditNoteParams |}
export const creditNote = {
	/**
	 * (TP-705) Confirm credit note
	 */
	post: async function(
		params: CreditNotePostParams,
		body: t.ConfirmCreditNoteRequest,
		options?: MethodOptions,
	): Promise<void> {
		return handler('accountingdocuments/{accountingDocumentId}/confirmcreditnote', options).post(params, body)
	},
}
export type AccountingDocumentsSendParams = {|
	accountingDocumentId: string,
|}
export type AccountingDocumentsSendPostParams = {| ...AccountingDocumentsSendParams |}
export const accountingDocumentsSend = {
	/**
	 * (TP-809 *) Send via Email
	 */
	post: async function(
		params: AccountingDocumentsSendPostParams,
		body: t.SendAccountingDocument,
		options?: MethodOptions,
	): Promise<void> {
		return handler('accountingdocuments/{accountingDocumentId}/send', options).post(params, body)
	},
}
export type AccountingDocumentPaymentRemindersParams = {|
	accountingDocumentId: string,
|}
export type AccountingDocumentPaymentRemindersGetParams = {| ...AccountingDocumentPaymentRemindersParams |}
export type AccountingDocumentPaymentRemindersGetResponse = Array<t.PaymentReminder>

export type AccountingDocumentPaymentRemindersGetResponseTemp = any
export type AccountingDocumentPaymentRemindersPostParams = {| ...AccountingDocumentPaymentRemindersParams |}
export const accountingDocumentPaymentReminders = {
	/**
	 * (TP-811 *) List payment reminders
	 */
	get: async function(
		params: AccountingDocumentPaymentRemindersGetParams,
		options?: MethodOptions,
	): Promise<AccountingDocumentPaymentRemindersGetResponse> {
		return handler('accountingdocuments/{accountingDocumentId}/reminders', options).get(params, null)
	},
	/**
	 * (TP-811 *) Create payment reminder
	 */
	post: async function(
		params: AccountingDocumentPaymentRemindersPostParams,
		body: t.PaymentReminder,
		options?: MethodOptions,
	): Promise<t.PaymentReminder> {
		return handler('accountingdocuments/{accountingDocumentId}/reminders', options).post(params, body)
	},
}
export type AccountingDocumentPaymentReminderParams = {|
	accountingDocumentId: string,
	reminderId: string,
|}
export type AccountingDocumentPaymentReminderPutParams = {| ...AccountingDocumentPaymentReminderParams |}
export type AccountingDocumentPaymentReminderDeleteParams = {| ...AccountingDocumentPaymentReminderParams |}
export const accountingDocumentPaymentReminder = {
	/**
	 * (TP-811 *) Update payment reminder
	 */
	put: async function(
		params: AccountingDocumentPaymentReminderPutParams,
		body: t.PaymentReminder,
		options?: MethodOptions,
	): Promise<t.PaymentReminder> {
		return handler('accountingdocuments/{accountingDocumentId}/reminders/{reminderId}', options).put(params, body)
	},
	/**
	 * (TP-811 *) Delete payment reminder
	 */
	delete: async function(
		params: AccountingDocumentPaymentReminderDeleteParams,
		options?: MethodOptions,
	): Promise<void> {
		return handler('accountingdocuments/{accountingDocumentId}/reminders/{reminderId}', options).delete(params, null)
	},
}
export type AccountingDocumentStateToProcessedParams = {|
	accountingDocumentId: string,
|}
export type AccountingDocumentStateToProcessedPutParams = {| ...AccountingDocumentStateToProcessedParams |}
export const accountingDocumentStateToProcessed = {
	/**
	 * (TP-1905 *) Set Accounting Document Processing State
	 */
	put: async function(
		params: AccountingDocumentStateToProcessedPutParams,
		body: t.AccountingDocumentProcessingStateRequest,
		options?: MethodOptions,
	): Promise<t.AccountingDocumentProcessingStateRequest> {
		return handler('accountingdocuments/{accountingDocumentId}/processingstate', options).put(params, body)
	},
}

export type AccountingDocumentExtractingStateParams = {|
	accountingDocumentId: string,
|}
export type AccountingDocumentExtractingStatePutParams = {| ...AccountingDocumentExtractingStateParams |}
export const accountingDocumentExtractingState = {
	/**
	 * (TP-2536 *) Set Accounting Document Extracting State
	 */
	put: async function(
		params: AccountingDocumentExtractingStatePutParams,
		body: t.ExtractingState,
		options?: MethodOptions,
	): Promise<t.ExtractingState> {
		return handler('accountingdocuments/{accountingDocumentId}/extractingstate', options).put(params, body)
	},
}

export type AccountingDocumentExtractingNoteParams = {|
	accountingDocumentId: string,
|}
export type AccountingDocumentExtractingNoteBody = {| notes: Array<t.AccountingDocumentNote> |}
export type AccountingDocumentExtractingNotePutParams = {| ...AccountingDocumentExtractingNoteParams |}
export const accountingDocumentExtractingNote = {
	/**
	 * (TP-2560 *) Set Accounting Document Extracting Note
	 */
	put: async function(
		params: AccountingDocumentExtractingNotePutParams,
		body: AccountingDocumentExtractingNoteBody,
		options?: MethodOptions,
	): Promise<AccountingDocumentExtractingNoteBody> {
		return handler('accountingdocuments/{accountingDocumentId}/extracting/notes', options).put(params, body)
	},
}

export type PaymentOrdersParams = {|
	accountingDocumentId: string,
|}
export type PaymentOrdersGetParams = {| ...PaymentOrdersParams |}
export const paymentOrders = {
	/**
	 * (TP-1963 *) Get Accounting Document Payment Orders
	 */
	get: async function(
		params: PaymentOrdersGetParams,
		options?: MethodOptions,
	): Promise<t.AccountingDocumentPaymentOrdersResponse> {
		return handler('accountingdocuments/{accountingDocumentId}/paymentorders', options).get(params, null)
	},
}
export type AccountingDocumentActivitiesParams = {|
	accountingDocumentId: string,
|}
export type AccountingDocumentActivitiesGetParams = {| ...AccountingDocumentActivitiesParams |}
export const accountingDocumentActivities = {
	/**
	 * (TP-1975 *) Get activities for Accounting Document
	 */
	get: async function(
		params: AccountingDocumentActivitiesGetParams,
		options?: MethodOptions,
	): Promise<t.ActivitiesResponse> {
		return handler('accountingdocuments/{accountingDocumentId}/activities', options).get(params, null)
	},
}

export type AccountingDocumentVatRatesParams = {|
	accountingDocumentId: string,
|}
export type AccountingDocumentVatRatesGetParams = {| ...AccountingDocumentVatRatesParams |}
export const accountingDocumentVatRates = {
	/**
	 * (TP-3145 *) Get available vat rates
	 */
	get: async function(
		params: AccountingDocumentVatRatesGetParams,
		options?: MethodOptions,
	): Promise<t.VatRatesResponse> {
		return handler('accountingdocuments/{accountingDocumentId}/lookups/vatrates', options).get(params, null)
	},
}

export type AccountingDocumentBulkActionsPostParams = {||}
export const accountingDocumentBulkActions = {
	/**
	 * (TP-507*) Bulk operation on accounting documents
	 */
	post: async function(body: t.BulkOperationRequest, options?: MethodOptions): Promise<t.BulkOperationResult> {
		return handler('bulk/accountingdocuments', options).post(null, body)
	},
}
export type DocumentsBulkPostParams = {||}
export const documentsBulk = {
	/**
	 * (TP-1459*) Move Document items to directory
	 */
	post: async function(body: t.BulkOperationRequest, options?: MethodOptions): Promise<t.BulkOperationResult> {
		return handler('bulk/documents', options).post(null, body)
	},
}
export type BankAccountsGetParams = {|
	page?: number,
	pageSize?: number,
|}
export type BankAccountsPostParams = {||}
export const bankAccounts = {
	/**
	 * (TP-305) List bank accounts
	 */
	get: async function(params: BankAccountsGetParams, options?: MethodOptions): Promise<t.BankAccountSearchResult> {
		return handler('bankaccounts', options).get(params, null)
	},
	/**
	 * (TP-306) Create bank account
	 */
	post: async function(body: t.BankAccount, options?: MethodOptions): Promise<t.BankAccount> {
		return handler('bankaccounts', options).post(null, body)
	},
}
export type BankAccountParams = {|
	bankAccountId: string,
|}
export type BankAccountGetParams = {| ...BankAccountParams |}
export type BankAccountPutParams = {| ...BankAccountParams |}
export type BankAccountDeleteParams = {| ...BankAccountParams |}
export type BankAccountPatchParams = {| ...BankAccountParams |}
export const bankAccount = {
	/**
	 * (TP-314) Get bank account
	 */
	get: async function(params: BankAccountGetParams, options?: MethodOptions): Promise<t.BankAccount> {
		return handler('bankaccounts/{bankAccountId}', options).get(params, null)
	},
	/**
	 * (TP-315) Update bank account
	 */
	put: async function(
		params: BankAccountPutParams,
		body: t.BankAccount,
		options?: MethodOptions,
	): Promise<t.BankAccount> {
		return handler('bankaccounts/{bankAccountId}', options).put(params, body)
	},
	/**
	 * (TP-316) Remove bank account
	 */
	delete: async function(params: BankAccountDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('bankaccounts/{bankAccountId}', options).delete(params, null)
	},
	/**
	 * (TP-2617*) Update bank account
	 */
	patch: async function(
		params: BankAccountPatchParams,
		body: t.BankAccountRequest,
		options?: MethodOptions,
	): Promise<t.BankAccount> {
		return handler('bankaccounts/{bankAccountId}', options).patch(params, body)
	},
}
export type BankAccountStatementsParams = {|
	bankAccountId: string,
|}
export type BankAccountStatementsGetParams = {|
	page?: number,
	pageSize?: number,
	processingState?: number,
	...BankAccountStatementsParams,
|}
export type BankAccountStatementsPostParams = {| ...BankAccountStatementsParams |}
export type StatementPostBody = {|
	file?: File,
	...t.Statement,
|}

export const bankAccountStatements = {
	/**
	 * (TP-317) List bank statements
	 */
	get: async function(
		params: BankAccountStatementsGetParams,
		options?: MethodOptions,
	): Promise<t.StatementSearchResult> {
		return handler('bankaccounts/{bankAccountId}/statements', options).get(params, null)
	},
	/**
	 * (TP-318) Upload bank statement
	 */
	post: async function(
		params: BankAccountStatementsPostParams,
		body: StatementPostBody,
		options?: MethodOptions,
	): Promise<t.Statement> {
		return handler('bankaccounts/{bankAccountId}/statements', options, { 'Content-Type': 'multipart/related' }).post(
			params,
			body,
		)
	},
}
export type BankAccountStatementParams = {|
	bankAccountId: string,
	statementId: string,
|}
export type BankAccountStatementDeleteParams = {| ...BankAccountStatementParams |}
export const bankAccountStatement = {
	/**
	 * (TP-319) Remove bank statement
	 */
	delete: async function(params: BankAccountStatementDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('bankaccounts/{bankAccountId}/statements/{statementId}', options).delete(params, null)
	},
}
export type BankAccountStatementProcessingParams = {|
	bankAccountId: string,
	statementId: string,
|}
export type BankAccountStatementProcessingPostParams = {| ...BankAccountStatementProcessingParams |}
export type BankAccountStatementProcessingPostResponse = any

export type BankAccountStatementProcessingPostResponseTemp = any
export const bankAccountStatementProcessing = {
	/**
	 * (TP-320) Process bank statement
	 */
	post: async function(
		params: BankAccountStatementProcessingPostParams,
		body: any,
		options?: MethodOptions,
	): Promise<BankAccountStatementProcessingPostResponse> {
		return handler('bankaccounts/{bankAccountId}/statements/{statementId}/processing', options).post(params, body)
	},
}
export type BankAccountPaymentOrdersParams = {|
	bankAccountId: string,
|}
export type BankAccountPaymentOrdersGetParams = {|
	page?: number,
	pageSize?: number,
	...BankAccountPaymentOrdersParams,
|}
export type BankAccountPaymentOrdersPostParams = {| ...BankAccountPaymentOrdersParams |}
export const bankAccountPaymentOrders = {
	/**
	 * (TP-321) List payment orders
	 */
	get: async function(
		params: BankAccountPaymentOrdersGetParams,
		options?: MethodOptions,
	): Promise<t.PaymentOrderSearchResult> {
		return handler('bankaccounts/{bankAccountId}/paymentorders', options).get(params, null)
	},
	/**
	 * (TP-322) Create payment order
	 */
	post: async function(
		params: BankAccountPaymentOrdersPostParams,
		body: t.PaymentOrder,
		options?: MethodOptions,
	): Promise<void> {
		return handler('bankaccounts/{bankAccountId}/paymentorders', options).post(params, body)
	},
}
export type BankAccountPaymentOrderParams = {|
	bankAccountId: string,
	paymentOrderId: string,
|}
export type BankAccountPaymentOrderDeleteParams = {| ...BankAccountPaymentOrderParams |}
export const bankAccountPaymentOrder = {
	/**
	 * (TP-593*) Remove payment order
	 */
	delete: async function(params: BankAccountPaymentOrderDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('bankaccounts/{bankAccountId}/paymentorders/{paymentOrderId}', options).delete(params, null)
	},
}
export type CashRegistersGetParams = {|
	includeDeleted?: boolean,
	page?: number,
	pageSize?: number,
|}
export type CashRegistersPostParams = {||}
export const cashRegisters = {
	/**
	 * (TP-187) List cash registers
	 */
	get: async function(params: CashRegistersGetParams, options?: MethodOptions): Promise<t.CashRegistersSearchResult> {
		return handler('cashregisters', options).get(params, null)
	},
	/**
	 * (TP-189) Create cash register
	 */
	post: async function(body: t.CashRegister, options?: MethodOptions): Promise<t.CashRegister> {
		return handler('cashregisters', options).post(null, body)
	},
}
export type CashRegisterParams = {|
	cashRegisterId: string,
|}
export type CashRegisterGetParams = {| ...CashRegisterParams |}
export type CashRegisterPutParams = {| ...CashRegisterParams |}
export type CashRegisterDeleteParams = {| ...CashRegisterParams |}
export const cashRegister = {
	/**
	 * (TP-188) Get cash register
	 */
	get: async function(params: CashRegisterGetParams, options?: MethodOptions): Promise<t.CashRegister> {
		return handler('cashregisters/{cashRegisterId}', options).get(params, null)
	},
	/**
	 * (TP-191) Update cash register
	 */
	put: async function(
		params: CashRegisterPutParams,
		body: t.CashRegister,
		options?: MethodOptions,
	): Promise<t.CashRegister> {
		return handler('cashregisters/{cashRegisterId}', options).put(params, body)
	},
	/**
	 * (TP-190) Remove cash register
	 */
	delete: async function(params: CashRegisterDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('cashregisters/{cashRegisterId}', options).delete(params, null)
	},
}

export type CashRegisterSettingsParams = {|
	cashRegisterId: string,
|}

export type CashRegisterSettingsPutParams = {| ...CashRegisterSettingsParams |}
export const cashRegisterSettings = {
	put: async function(
		params: CashRegisterSettingsPutParams,
		body: t.CashRegisterSettings,
		options?: MethodOptions,
	): Promise<t.CashRegisterSettings> {
		return handler('cashregisters/{cashRegisterId}/settings', options).put(params, body)
	},
}

export type CashRegisterEetParams = {|
	cashRegisterId: string,
|}

export type CashRegisterEetPutParams = {| ...CashRegisterEetParams |}
export const cashRegisterEet = {
	put: async function(
		params: CashRegisterEetPutParams,
		body: t.OrganizationCountrySpecificSettingsCzEet,
		options?: MethodOptions,
	): Promise<t.OrganizationCountrySpecificSettingsCzEet> {
		return handler('cashregisters/{cashRegisterId}/countryvariantspecific/cz/eet', options).put(params, body)
	},
}

export type CashRegisterInventoriesParams = {|
	cashRegisterId: string,
|}
export type CashRegisterInventoriesGetParams = {| ...CashRegisterInventoriesParams |}
export type CashRegisterInventoriesGetResponse = Array<t.CashRegisterInventory>

export type CashRegisterInventoriesGetResponseTemp = any
export type CashRegisterInventoriesPostParams = {| ...CashRegisterInventoriesParams |}
export const cashRegisterInventories = {
	/**
	 * (TP-192) List inventories
	 */
	get: async function(
		params: CashRegisterInventoriesGetParams,
		options?: MethodOptions,
	): Promise<CashRegisterInventoriesGetResponse> {
		return handler('cashregisters/{cashRegisterId}/inventories', options).get(params, null)
	},
	/**
	 * (TP-214) Create inventory
	 */
	post: async function(
		params: CashRegisterInventoriesPostParams,
		body: t.CashRegisterInventory,
		options?: MethodOptions,
	): Promise<t.CashRegisterInventory> {
		return handler('cashregisters/{cashRegisterId}/inventories', options).post(params, body)
	},
}
export type CashRegisterBalancesParams = {|
	cashRegisterId: string,
|}
export type CashRegisterBalancesGetParams = {|
	dateFrom: string,
	dateTo: string,
	grouping: 'daily' | 'weekly' | 'monthly' | 'yearly' | 'sum',
	...CashRegisterBalancesParams,
|}
export type CashRegisterBalancesGetResponse = Array<t.CashRegisterBalance>

export type CashRegisterBalancesGetResponseTemp = any
export const cashRegisterBalances = {
	/**
	 * (TP-239) Aggregate balance
	 */
	get: async function(
		params: CashRegisterBalancesGetParams,
		options?: MethodOptions,
	): Promise<CashRegisterBalancesGetResponse> {
		return handler('cashregisters/{cashRegisterId}/balances', options).get(params, null)
	},
}
export type AccountingDocumentCategoriesGetParams = {||}
export type AccountingDocumentCategoriesGetResponse = Array<t.AccountingDocumentCategory>

export type AccountingDocumentCategoriesGetResponseTemp = any
export type AccountingDocumentCategoriesPostParams = {||}
export const accountingDocumentCategories = {
	/**
	 * (TP-1305 *) Get Accounting Document Categories
	 */
	get: async function(
		params: AccountingDocumentCategoriesGetParams,
		options?: MethodOptions,
	): Promise<AccountingDocumentCategoriesGetResponse> {
		return handler('accountingdocumentcategories', options).get(params, null)
	},
	/**
	 * (TP-1305 *) Create Accounting Document Category
	 */
	post: async function(
		body: t.AccountingDocumentCategory,
		options?: MethodOptions,
	): Promise<t.AccountingDocumentCategory> {
		return handler('accountingdocumentcategories', options).post(null, body)
	},
}
export type AccountingDocumentCategoryParams = {|
	accountingDocumentCategoryId: string,
|}
export type AccountingDocumentCategoryPutParams = {| ...AccountingDocumentCategoryParams |}
export type AccountingDocumentCategoryDeleteParams = {| ...AccountingDocumentCategoryParams |}
export const accountingDocumentCategory = {
	/**
	 * (TP-1305 *) Update Accounting Document Category
	 */
	put: async function(
		params: AccountingDocumentCategoryPutParams,
		body: t.AccountingDocumentCategoryUpdateRequest,
		options?: MethodOptions,
	): Promise<t.AccountingDocumentCategory> {
		return handler('accountingdocumentcategories/{accountingDocumentCategoryId}', options).put(params, body)
	},
	/**
	 * (TP-1305 *) Delete Accounting Document Category
	 */
	delete: async function(params: AccountingDocumentCategoryDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('accountingdocumentcategories/{accountingDocumentCategoryId}', options).delete(params, null)
	},
}
export type ContactsGetParams = {|
	page?: number,
	pageSize?: number,
|}
export type ContactsPostParams = {||}
export const contacts = {
	/**
	 * (TP-427) List contacts
	 */
	get: async function(params: ContactsGetParams, options?: MethodOptions): Promise<t.ContactsSearchResult> {
		return handler('contacts', options).get(params, null)
	},
	/**
	 * (TP-287) Create contact
	 */
	post: async function(body: t.Contact, options?: MethodOptions): Promise<t.Contact> {
		return handler('contacts', options).post(null, body)
	},
}
export type ContactParams = {|
	contactId: string,
|}
export type ContactGetParams = {| ...ContactParams |}
export type ContactPutParams = {| ...ContactParams |}
export type ContactDeleteParams = {| ...ContactParams |}
export const contact = {
	/**
	 * (TP-288) Get contact
	 */
	get: async function(params: ContactGetParams, options?: MethodOptions): Promise<t.Contact> {
		return handler('contacts/{contactId}', options).get(params, null)
	},
	/**
	 * (TP-289) Update contact
	 */
	put: async function(params: ContactPutParams, body: t.Contact, options?: MethodOptions): Promise<t.Contact> {
		return handler('contacts/{contactId}', options).put(params, body)
	},
	/**
	 * (TP-352*) Remove contact
	 */
	delete: async function(params: ContactDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('contacts/{contactId}', options).delete(params, null)
	},
}
export type DocumentsRootGetParams = {|
	page?: number,
	pageSize?: number,
	sortingDirection?: 'ASC' | 'DESC',
	type?: number,
|}
export const documentsRoot = {
	/**
	 * (TP-215/TP-281) List documents and folders in root
	 */
	get: async function(params: DocumentsRootGetParams, options?: MethodOptions): Promise<t.DocumentsSearchResult> {
		return handler('documents', options).get(params, null)
	},
}
export type DocumentsParams = {|
	documentItemUniqueId: string,
|}
export type DocumentsGetParams = {|
	page?: number,
	pageSize?: number,
	sortingDirection?: 'ASC' | 'DESC',
	type?: number,
	...DocumentsParams,
|}
export type DocumentsPostParams = {| ...DocumentsParams |}
export type DocumentItemPostBody = {|
	file?: File,
	...t.DocumentItem,
|}

export type DocumentsPutParams = {| ...DocumentsParams |}
export type DocumentsDeleteParams = {| ...DocumentsParams |}
export const documents = {
	/**
	 * (TP-215/TP-281) List documents and folders
	 */
	get: async function(params: DocumentsGetParams, options?: MethodOptions): Promise<t.DocumentsSearchResult> {
		return handler('documents/{documentItemUniqueId}', options).get(params, null)
	},
	/**
	 * (TP-205/TP-282) Upload document or create folder
	 */
	post: async function(
		params: DocumentsPostParams,
		body: DocumentItemPostBody,
		options?: MethodOptions,
	): Promise<t.DocumentItem> {
		return handler('documents/{documentItemUniqueId}', options, { 'Content-Type': 'multipart/related' }).post(
			params,
			body,
		)
	},
	/**
	 * (TP-207/TP-283) Change metadata for document or folder
	 */
	put: async function(
		params: DocumentsPutParams,
		body: t.DocumentItem,
		options?: MethodOptions,
	): Promise<t.DocumentItem> {
		return handler('documents/{documentItemUniqueId}', options).put(params, body)
	},
	/**
	 * (TP-206/TP-284) Remove document or folder
	 */
	delete: async function(params: DocumentsDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('documents/{documentItemUniqueId}', options).delete(params, null)
	},
}
export type EmailTemplatesGetParams = {||}
export type EmailTemplatesGetResponse = Array<t.EmailTemplate>

export type EmailTemplatesGetResponseTemp = any
export type EmailTemplatesPostParams = {||}
export const emailTemplates = {
	/**
	 * (TP-780*) Get Email Templates
	 */
	get: async function(params: EmailTemplatesGetParams, options?: MethodOptions): Promise<EmailTemplatesGetResponse> {
		return handler('emailtemplates', options).get(params, null)
	},
	/**
	 * (TP-780*) Create Email Template
	 */
	post: async function(body: t.EmailTemplate, options?: MethodOptions): Promise<t.EmailTemplate> {
		return handler('emailtemplates', options).post(null, body)
	},
}
export type EmailTemplateParams = {|
	emailTemplateId: string,
|}
export type EmailTemplateGetParams = {| ...EmailTemplateParams |}
export type EmailTemplatePutParams = {| ...EmailTemplateParams |}
export type EmailTemplateDeleteParams = {| ...EmailTemplateParams |}
export const emailTemplate = {
	/**
	 * (TP-780*) Get Email Template
	 */
	get: async function(params: EmailTemplateGetParams, options?: MethodOptions): Promise<t.EmailTemplate> {
		return handler('emailtemplates/{emailTemplateId}', options).get(params, null)
	},
	/**
	 * (TP-780*) Update Email Template
	 */
	put: async function(
		params: EmailTemplatePutParams,
		body: t.EmailTemplate,
		options?: MethodOptions,
	): Promise<t.EmailTemplate> {
		return handler('emailtemplates/{emailTemplateId}', options).put(params, body)
	},
	/**
	 * (TP-780*) Delete Email Template
	 */
	delete: async function(params: EmailTemplateDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('emailtemplates/{emailTemplateId}', options).delete(params, null)
	},
}
export type FinancialAccountsGetParams = {|
	accountingDocumentType?: number,
	accountingDocumentDirection?: number,
|}
export type FinancialAccountsGetResponse = Array<t.FinancialAccount>

export type FinancialAccountsGetResponseTemp = any
export const financialAccounts = {
	/**
	 * (TP-234) List financial accounts
	 */
	get: async function(
		params: FinancialAccountsGetParams,
		options?: MethodOptions,
	): Promise<FinancialAccountsGetResponse> {
		return handler('financialaccounts', options).get(params, null)
	},
}
export type FinancialAccountParams = {|
	finAccountNumber: string,
|}
export type FinancialAccountPostParams = {| ...FinancialAccountParams |}
export const financialAccount = {
	/**
	 * (TP-764*) Create sub financial account
	 */
	post: async function(
		params: FinancialAccountPostParams,
		body: t.FinancialAccount,
		options?: MethodOptions,
	): Promise<t.FinancialAccount> {
		return handler('financialaccounts/{finAccountNumber}', options).post(params, body)
	},
}
export type GroupsGetParams = {||}
export type GroupsGetResponse = Array<t.Group>

export type GroupsGetResponseTemp = any
export type GroupsPostParams = {||}
export const groups = {
	/**
	 * (TP-730) Get groups
	 */
	get: async function(params: GroupsGetParams, options?: MethodOptions): Promise<GroupsGetResponse> {
		return handler('groups', options).get(params, null)
	},
	/**
	 * (TP-731) Create group
	 */
	post: async function(body: t.Group, options?: MethodOptions): Promise<t.Group> {
		return handler('groups', options).post(null, body)
	},
}
export type GroupParams = {|
	groupId: string,
|}
export type GroupGetParams = {| ...GroupParams |}
export type GroupPutParams = {| ...GroupParams |}
export type GroupDeleteParams = {| ...GroupParams |}
export const group = {
	/**
	 * (TP-732) Get group
	 */
	get: async function(params: GroupGetParams, options?: MethodOptions): Promise<t.Group> {
		return handler('groups/{groupId}', options).get(params, null)
	},
	/**
	 * (TP-733) Update group
	 */
	put: async function(params: GroupPutParams, body: t.Group, options?: MethodOptions): Promise<t.Group> {
		return handler('groups/{groupId}', options).put(params, body)
	},
	/**
	 * (TP-736) Remove group
	 */
	delete: async function(params: GroupDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('groups/{groupId}', options).delete(params, null)
	},
}
export type GroupUserParams = {|
	groupId: string,
|}
export type GroupUserPostParams = {| ...GroupUserParams |}
export const groupUser = {
	/**
	 * (TP-749) Assign user to group
	 */
	post: async function(params: GroupUserPostParams, body: t.GroupUser, options?: MethodOptions): Promise<t.GroupUser> {
		return handler('groups/{groupId}/users', options).post(params, body)
	},
}
export type GroupUserRemoveParams = {|
	groupId: string,
	userId: string,
|}
export type GroupUserRemoveDeleteParams = {| ...GroupUserRemoveParams |}
export const groupUserRemove = {
	/**
	 * (TP-763*) Remove user from group
	 */
	delete: async function(params: GroupUserRemoveDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('groups/{groupId}/users/{userId}', options).delete(params, null)
	},
}
export type VatRatesGetParams = {|
	countryCode: string,
	date: string,
	vatCountryType: number,
	accountingDocumentDirection: number,
	financialAccount?: string,
|}
export type VatRatesGetResponse = Array<t.VatRate>

export type VatRatesGetResponseTemp = any
export const vatRates = {
	/**
	 * (TP-235) List VAT rates
	 */
	get: async function(params: VatRatesGetParams, options?: MethodOptions): Promise<VatRatesGetResponse> {
		return handler('vatrates', options).get(params, null)
	},
}
export type VatRatesAllGetParams = {||}
export type VatRatesAllGetResponse = Array<t.VatRate>

export type VatRatesAllGetResponseTemp = any
export const vatRatesAll = {
	/**
	 * (TP-2213 *) List all VAT rates
	 */
	get: async function(params: VatRatesAllGetParams, options?: MethodOptions): Promise<VatRatesAllGetResponse> {
		return handler('vatrates/all', options).get(params, null)
	},
}
export type BranchesGetParams = {||}
export type BranchesGetResponse = Array<t.Enumeration>

export type BranchesGetResponseTemp = any
export const branches = {
	/**
	 * (TP-404) List branches
	 */
	get: async function(params: BranchesGetParams, options?: MethodOptions): Promise<BranchesGetResponse> {
		return handler('branches', options).get(params, null)
	},
}
export type ProjectsGetParams = {||}
export type ProjectsGetResponse = Array<t.Enumeration>

export type ProjectsGetResponseTemp = any
export const projects = {
	/**
	 * (TP-405) List projects
	 */
	get: async function(params: ProjectsGetParams, options?: MethodOptions): Promise<ProjectsGetResponse> {
		return handler('projects', options).get(params, null)
	},
}
export type CurrenciesGetParams = {|
	usedInOrg?: boolean,
|}
export type CurrenciesGetResponse = Array<t.Currency>

export type CurrenciesGetResponseTemp = any
export const currencies = {
	/**
	 * (TP-299) List currencies
	 */
	get: async function(params: CurrenciesGetParams, options?: MethodOptions): Promise<CurrenciesGetResponse> {
		return handler('currencies', options).get(params, null)
	},
}
export type CountriesGetParams = {||}
export type CountriesGetResponse = Array<t.Country>

export type CountriesGetResponseTemp = any
export const countries = {
	/**
	 * (TP-323) List countries
	 */
	get: async function(params: CountriesGetParams, options?: MethodOptions): Promise<CountriesGetResponse> {
		return handler('countries', options).get(params, null)
	},
}
export type BanksGetParams = {||}
export type BanksGetResponse = Array<t.Bank>

export type BanksGetResponseTemp = any
export const banks = {
	/**
	 * (TP-301) List banks
	 */
	get: async function(params: BanksGetParams, options?: MethodOptions): Promise<BanksGetResponse> {
		return handler('banks', options).get(params, null)
	},
}
export type CompanyTypesGetParams = {||}
export type CompanyTypesGetResponse = Array<t.CompanyType>

export type CompanyTypesGetResponseTemp = any
export const companyTypes = {
	/**
	 * (TP-870 *) List company types
	 */
	get: async function(params: CompanyTypesGetParams, options?: MethodOptions): Promise<CompanyTypesGetResponse> {
		return privateApiHandler('countryvariantspecific/cz/companytypes', options).get(params, null)
	},
}
export type SecurityCredentialsParams = {|
	organizationId?: string,
|}
export type SecurityCredentialsGetParams = {| ...SecurityCredentialsParams |}
export const securityCredentials = {
	/**
	 * (TP- *) Return authorization credentials
	 */
	get: async function(
		params: SecurityCredentialsGetParams,
		options?: MethodOptions,
	): Promise<t.SecurityCredentialsResponse> {
		return handler('{organizationId}/security/credentials', options).get(params, null)
	},
}
export type OauthLinkPostParams = {||}
export const oauthLink = {
	/**
	 * (TP-2825 *) Generate link for OAuth authentication
	 */
	post: async function(body: t.OAuthLinkRequest, options?: MethodOptions): Promise<t.OAuthLinkResponse> {
		return handler('oauth/link/generate', options).post(null, body)
	},
}
export type OauthLinkRemovePostParams = {||}
export const oauthLinkRemove = {
	/**
	 * (TP-2870 *) Remove OAuth authentication
	 */
	post: async function(body: t.OAuthTokenRemoveRequest, options?: MethodOptions): Promise<void> {
		return handler('oauth/tokens/remove', options).post(null, body)
	},
}
export type PaymentRemindersGetParams = {||}
export type PaymentRemindersGetResponse = Array<t.PaymentReminder>

export type PaymentRemindersGetResponseTemp = any
export type PaymentRemindersPostParams = {||}
export const paymentReminders = {
	/**
	 * (TP-782*) Get payment reminders
	 */
	get: async function(
		params: PaymentRemindersGetParams,
		options?: MethodOptions,
	): Promise<PaymentRemindersGetResponse> {
		return handler('reminders/payments', options).get(params, null)
	},
	/**
	 * (TP-782*) Create payment reminder
	 */
	post: async function(body: t.PaymentReminder, options?: MethodOptions): Promise<t.PaymentReminder> {
		return handler('reminders/payments', options).post(null, body)
	},
}
export type PaymentReminderParams = {|
	paymentReminderId: string,
|}
export type PaymentReminderGetParams = {| ...PaymentReminderParams |}
export type PaymentReminderPutParams = {| ...PaymentReminderParams |}
export type PaymentReminderDeleteParams = {| ...PaymentReminderParams |}
export const paymentReminder = {
	/**
	 * (TP-782*) Get payment reminder
	 */
	get: async function(params: PaymentReminderGetParams, options?: MethodOptions): Promise<t.PaymentReminder> {
		return handler('reminders/payments/{paymentReminderId}', options).get(params, null)
	},
	/**
	 * (TP-782*) Update payment reminder
	 */
	put: async function(
		params: PaymentReminderPutParams,
		body: t.PaymentReminder,
		options?: MethodOptions,
	): Promise<t.PaymentReminder> {
		return handler('reminders/payments/{paymentReminderId}', options).put(params, body)
	},
	/**
	 * (TP-782*) Delete payment reminder
	 */
	delete: async function(params: PaymentReminderDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('reminders/payments/{paymentReminderId}', options).delete(params, null)
	},
}
export type ReportsGetParams = {|
	type?: number,
	pageSize?: number,
	page?: number,
|}
export type ReportsPostParams = {||}
export type ReportsPostResponse = {|
	type: string,
|}

export type ReportsPostResponseTemp = any
export const reports = {
	/**
	 * (TP-382) List generic reports
	 */
	get: async function(params: ReportsGetParams, options?: MethodOptions): Promise<t.GenericReportsSearchResult> {
		return handler('reports/generic', options).get(params, null)
	},
	/**
	 * (TP-380) Request generic report
	 */
	post: async function(body: t.GenericReportRequest, options?: MethodOptions): Promise<ReportsPostResponse> {
		return handler('reports/generic', options).post(null, body)
	},
}
export type ReportParams = {|
	reportId: string,
|}
export type ReportGetParams = {| ...ReportParams |}
export const report = {
	/**
	 * Get generic report
	 */
	get: async function(params: ReportGetParams, options?: MethodOptions): Promise<t.GenericReport> {
		return handler('reports/generic/{reportId}', options).get(params, null)
	},
}
export type AccountingDocumentReportsPostParams = {||}
export type AccountingDocumentReportsPostResponse = Array<t.AccountingDocumentsReport>

export type AccountingDocumentReportsPostResponseTemp = any
export const accountingDocumentReports = {
	/**
	 * (TP-383) Get aggregated accounting documents report
	 */
	post: async function(
		body: t.AccountingDocumentReportRequest,
		options?: MethodOptions,
	): Promise<AccountingDocumentReportsPostResponse> {
		return handler('reports/accountingdocuments', options).post(null, body)
	},
}
export type AccountingDocumentAggregationReportsPostParams = {||}
export const accountingDocumentAggregationReports = {
	/**
	 * (TP-1856*) Get aggregations for Accounting Documents
	 */
	post: async function(
		body: t.AccountingDocumentReportAggregationRequest,
		options?: MethodOptions,
	): Promise<t.AccountingDocumentReportAggregationResponse> {
		return handler('reports/accountingdocuments/aggregations', options).post(null, body)
	},
}
export type AccountingDocumentSearchPostParams = {|
	scope?: string,
|}
export const accountingDocumentSearch = {
	/**
	 * (TP-193) Search for Accounting Documents
	 */
	post: async function(
		params: AccountingDocumentSearchPostParams,
		body: t.SearchRequestGeneric,
		options?: MethodOptions,
	): Promise<t.AccountingDocumentSearchResult> {
		return handler('search/accountingdocuments', options).post(params, body)
	},
}
export type PaymentSearchPostParams = {||}
export const paymentSearch = {
	/**
	 * (TP-228) Search for Payments
	 */
	post: async function(body: t.SearchRequestGeneric, options?: MethodOptions): Promise<t.PaymentsSearchResult> {
		return handler('search/payments', options).post(null, body)
	},
}
export type ContactsSearchPostParams = {||}
export const contactsSearch = {
	/**
	 * (TP-285) Search for Contacts
	 */
	post: async function(body: t.SearchRequestGeneric, options?: MethodOptions): Promise<t.ContactsSearchResult> {
		return handler('search/contacts', options).post(null, body)
	},
}
export type CzAresPostParams = {||}
export const czAres = {
	/**
	 * (TP-309) Search for contact in the Czech ARES database
	 */
	post: async function(body: any, options?: MethodOptions): Promise<t.AresContactsSearchResult> {
		return privateApiHandler('search/countryvariantspecific/cz/ares', options).post(null, body)
	},
}
export type DocumentsSearchPostParams = {||}
export const documentsSearch = {
	/**
	 * (TP-286) Search for Documents
	 */
	post: async function(body: t.SearchRequestGeneric, options?: MethodOptions): Promise<t.DocumentsSearchResult> {
		return handler('search/documents', options).post(null, body)
	},
}
export type BankAccountPaymentOrdersSearchPostParams = {||}
export const bankAccountPaymentOrdersSearch = {
	/**
	 * (TP-307) Search for Payment Orders
	 */
	post: async function(body: t.SearchRequestGeneric, options?: MethodOptions): Promise<t.PaymentOrderSearchResult> {
		return handler('search/paymentorders', options).post(null, body)
	},
}
export type BankAccountStatementsSearchPostParams = {||}
export const bankAccountStatementsSearch = {
	/**
	 * (TP-308) Search for Bank Statements
	 */
	post: async function(body: t.SearchRequestGeneric, options?: MethodOptions): Promise<t.StatementSearchResult> {
		return handler('search/bankstatements', options).post(null, body)
	},
}
export type TasksSearchPostParams = {||}
export const tasksSearch = {
	/**
	 * (TP-364) Search for Tasks
	 */
	post: async function(body: t.SearchRequestGeneric, options?: MethodOptions): Promise<t.TasksSearchResult> {
		return privateApiHandler('search/tasks', options).post(null, body)
	},
}
export type TagsSearchPostParams = {||}
export const tagsSearch = {
	/**
	 * (TP-779*) Search for Tags
	 */
	post: async function(body: t.SearchRequestGeneric, options?: MethodOptions): Promise<t.TagsSearchResult> {
		return handler('search/tags', options).post(null, body)
	},
}
export type CrpdphPostParams = {||}
export const crpdph = {
	/**
	 * (TP-820*) Search for contact in the Czech CRPDPH database
	 */
	post: async function(body: any, options?: MethodOptions): Promise<t.CrpdphSearchResult> {
		return privateApiHandler('search/countryvariantspecific/cz/crpdph', options).post(null, body)
	},
}
export type FinancialAccountsSearchPostParams = {||}
export const financialAccountsSearch = {
	/**
	 * (TP-845) Search for Financial Accounts
	 */
	post: async function(
		body: t.SearchRequestGeneric,
		options?: MethodOptions,
	): Promise<t.FinancialAccountsSearchResult> {
		return handler('search/financialaccounts', options).post(null, body)
	},
}
export type SequencesGetParams = {|
	accountingDocumentType?: number,
	accountingDocumentDirection?: number,
	includeDisabled?: boolean,
	includeInvalidated?: boolean,
|}
export type SequencesGetResponse = Array<t.Sequence>

export type SequencesGetResponseTemp = any
export type SequencesPostParams = {||}
export const sequences = {
	/**
	 * (TP-236) List non-Cash Register sequences
	 */
	get: async function(params: SequencesGetParams, options?: MethodOptions): Promise<SequencesGetResponse> {
		return handler('sequences', options).get(params, null)
	},
	/**
	 * (TP-459) Create non-Cash Register sequence
	 */
	post: async function(body: t.Sequence, options?: MethodOptions): Promise<t.Sequence> {
		return handler('sequences', options).post(null, body)
	},
}
export type SequenceParams = {|
	sequenceId: string,
|}
export type SequenceGetParams = {| ...SequenceParams |}
export type SequencePutParams = {| ...SequenceParams |}
export type SequenceDeleteParams = {| ...SequenceParams |}
export const sequence = {
	/**
	 * (TP-2410) Get non-Cash Register sequence
	 */
	get: async function(params: SequenceGetParams, options?: MethodOptions): Promise<t.Sequence> {
		return handler('sequences/{sequenceId}', options).get(params, null)
	},
	/**
	 * (TP-460) Update non-Cash Register sequence
	 */
	put: async function(params: SequencePutParams, body: t.Sequence, options?: MethodOptions): Promise<t.Sequence> {
		return handler('sequences/{sequenceId}', options).put(params, body)
	},
	/**
	 * (TP-461) Remove non-Cash Register sequence
	 */
	delete: async function(params: SequenceDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('sequences/{sequenceId}', options).delete(params, null)
	},
}
export type LastGeneratedSequenceNumberParams = {|
	sequenceId: string,
	year: number,
|}
export type LastGeneratedSequenceNumberPutParams = {| ...LastGeneratedSequenceNumberParams |}
export const lastGeneratedSequenceNumber = {
	/**
	 * (TP-890 *) Set non-Cash Register sequence's last generated number
	 */
	put: async function(
		params: LastGeneratedSequenceNumberPutParams,
		body: any,
		options?: MethodOptions,
	): Promise<t.Sequence> {
		return handler('sequences/{sequenceId}/lastGeneratedNumbers/{year}', options).put(params, body)
	},
}
export type CashRegisterSequencesParams = {|
	cashRegisterId: string,
|}
export type CashRegisterSequencesGetParams = {|
	accountingDocumentType?: number,
	accountingDocumentDirection?: number,
	includeDisabled?: boolean,
	includeInvalidated?: boolean,
	...CashRegisterSequencesParams,
|}
export type CashRegisterSequencesGetResponse = Array<t.Sequence>

export type CashRegisterSequencesGetResponseTemp = any
export type CashRegisterSequencesPostParams = {| ...CashRegisterSequencesParams |}
export const cashRegisterSequences = {
	/**
	 * (TP-236) List Cash Register sequences
	 */
	get: async function(
		params: CashRegisterSequencesGetParams,
		options?: MethodOptions,
	): Promise<CashRegisterSequencesGetResponse> {
		return handler('cashregisters/{cashRegisterId}/sequences', options).get(params, null)
	},
	/**
	 * (TP-459) Create Cash Register sequence
	 */
	post: async function(
		params: CashRegisterSequencesPostParams,
		body: t.Sequence,
		options?: MethodOptions,
	): Promise<t.Sequence> {
		return handler('cashregisters/{cashRegisterId}/sequences', options).post(params, body)
	},
}
export type CashRegisterSequenceParams = {|
	cashRegisterId: string,
	sequenceId: string,
|}
export type CashRegisterSequenceGetParams = {| ...CashRegisterSequenceParams |}
export type CashRegisterSequencePutParams = {| ...CashRegisterSequenceParams |}
export type CashRegisterSequenceDeleteParams = {| ...CashRegisterSequenceParams |}
export const cashRegisterSequence = {
	/**
	 * (TP-2410) Get Cash Register sequence
	 */
	get: async function(params: CashRegisterSequenceGetParams, options?: MethodOptions): Promise<t.Sequence> {
		return handler('cashregisters/{cashRegisterId}/sequences/{sequenceId}', options).get(params, null)
	},
	/**
	 * (TP-460) Update Cash Register sequence
	 */
	put: async function(
		params: CashRegisterSequencePutParams,
		body: t.Sequence,
		options?: MethodOptions,
	): Promise<t.Sequence> {
		return handler('cashregisters/{cashRegisterId}/sequences/{sequenceId}', options).put(params, body)
	},
	/**
	 * (TP-461) Remove Cash Register sequence
	 */
	delete: async function(params: CashRegisterSequenceDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('cashregisters/{cashRegisterId}/sequences/{sequenceId}', options).delete(params, null)
	},
}
export type LastGeneratedCashRegisterSequenceNumberParams = {|
	cashRegisterId: number,
	sequenceId: string,
	year: number,
|}
export type LastGeneratedCashRegisterSequenceNumberPutParams = {| ...LastGeneratedCashRegisterSequenceNumberParams |}
export const lastGeneratedCashRegisterSequenceNumber = {
	/**
	 * (TP-890 *) Set Cash Register sequence's last generated number
	 */
	put: async function(
		params: LastGeneratedCashRegisterSequenceNumberPutParams,
		body: any,
		options?: MethodOptions,
	): Promise<t.Sequence> {
		return handler('cashregisters/{cashRegisterId}/sequences/{sequenceId}/lastGeneratedNumbers/{year}', options).put(
			params,
			body,
		)
	},
}
export type AllSequencesGetParams = {|
	includeDisabled?: boolean,
	includeInvalidated?: boolean,
|}
export type AllSequencesGetResponse = Array<t.Sequence>

export type AllSequencesGetResponseTemp = any
export const allSequences = {
	/**
	 * (TP-2199*) List all Cash Register sequences (both non-Cash Register and Cash Register)
	 */
	get: async function(params: AllSequencesGetParams, options?: MethodOptions): Promise<AllSequencesGetResponse> {
		return handler('sequences/all', options).get(params, null)
	},
}
export type TagsGetParams = {||}
export type TagsPostParams = {||}
export const tags = {
	/**
	 * (TP-738*) List tags
	 */
	get: async function(params: TagsGetParams, options?: MethodOptions): Promise<t.TagsSearchResult> {
		return handler('tags', options).get(params, null)
	},
	/**
	 * (TP-737*) Create tag
	 */
	post: async function(body: t.Tag, options?: MethodOptions): Promise<t.Tag> {
		return handler('tags', options).post(null, body)
	},
}
export type TagParams = {|
	tagId: string,
|}
export type TagPutParams = {| ...TagParams |}
export type TagDeleteParams = {| ...TagParams |}
export const tag = {
	/**
	 * (TP-739*) Update tag
	 */
	put: async function(params: TagPutParams, body: t.Tag, options?: MethodOptions): Promise<t.Tag> {
		return handler('tags/{tagId}', options).put(params, body)
	},
	/**
	 * (TP-741*) Remove tag
	 */
	delete: async function(params: TagDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('tags/{tagId}', options).delete(params, null)
	},
}
export type TasksGetParams = {|
	page?: number,
	pageSize?: number,
	authorGuid?: string,
	assigneeId?: string,
|}
export type TasksPostParams = {||}
export const tasks = {
	/**
	 * (TP-348) List tasks
	 */
	get: async function(params: TasksGetParams, options?: MethodOptions): Promise<t.TasksSearchResult> {
		return privateApiHandler('tasks', options).get(params, null)
	},
	/**
	 * (TP-349) Create task
	 */
	post: async function(body: t.Task, options?: MethodOptions): Promise<t.Task> {
		return privateApiHandler('tasks', options).post(null, body)
	},
}
export type TaskParams = {|
	taskId: number,
|}
export type TaskGetParams = {| ...TaskParams |}
export type TaskPutParams = {| ...TaskParams |}
export type TaskDeleteParams = {| ...TaskParams |}
export type TaskPatchParams = {| ...TaskParams |}
export const task = {
	/**
	 * (TP-384) Get task
	 */
	get: async function(params: TaskGetParams, options?: MethodOptions): Promise<t.Task> {
		return privateApiHandler('tasks/{taskId}', options).get(params, null)
	},
	/**
	 * (TP-350) Update task
	 */
	put: async function(params: TaskPutParams, body: t.Task, options?: MethodOptions): Promise<t.Task> {
		return privateApiHandler('tasks/{taskId}', options).put(params, body)
	},
	/**
	 * (TP-351) Remove task
	 */
	delete: async function(params: TaskDeleteParams, options?: MethodOptions): Promise<void> {
		return privateApiHandler('tasks/{taskId}', options).delete(params, null)
	},
	/**
	 * (TP-414) Update selected fields
	 */
	patch: async function(params: TaskPatchParams, body: any, options?: MethodOptions): Promise<t.Task> {
		return privateApiHandler('tasks/{taskId}', options).patch(params, body)
	},
}
export type TemplatesGetParams = {|
	page?: number,
	pageSize?: number,
|}
export type TemplatesPostParams = {||}
export const templates = {
	/**
	 * (TP-398) List templates
	 */
	get: async function(params: TemplatesGetParams, options?: MethodOptions): Promise<t.TemplatesSearchResult> {
		return handler('templates', options).get(params, null)
	},
	/**
	 * (TP-243) Create template
	 */
	post: async function(body: t.Template, options?: MethodOptions): Promise<t.Template> {
		return handler('templates', options).post(null, body)
	},
}
export type TemplateParams = {|
	templateId: string,
|}
export type TemplateGetParams = {| ...TemplateParams |}
export type TemplatePutParams = {| ...TemplateParams |}
export type TemplateDeleteParams = {| ...TemplateParams |}
export type TemplateDeleteResponse = any

export type TemplateDeleteResponseTemp = any
export const template = {
	/**
	 * (TP-399) Get template
	 */
	get: async function(params: TemplateGetParams, options?: MethodOptions): Promise<t.Template> {
		return handler('templates/{templateId}', options).get(params, null)
	},
	/**
	 * (TP-400) Update template
	 */
	put: async function(params: TemplatePutParams, body: t.Template, options?: MethodOptions): Promise<t.Template> {
		return handler('templates/{templateId}', options).put(params, body)
	},
	/**
	 * (TP-401) Remove template
	 */
	delete: async function(params: TemplateDeleteParams, options?: MethodOptions): Promise<TemplateDeleteResponse> {
		return handler('templates/{templateId}', options).delete(params, null)
	},
}
export type TemplateSchedulerParams = {|
	templateId: string,
|}
export type TemplateSchedulerPutParams = {| ...TemplateSchedulerParams |}
export type TemplateSchedulerDeleteParams = {| ...TemplateSchedulerParams |}
export type TemplateSchedulerPatchParams = {| ...TemplateSchedulerParams |}
export const templateScheduler = {
	/**
	 * (TP-291) Set scheduler
	 */
	put: async function(
		params: TemplateSchedulerPutParams,
		body: t.TemplateScheduler,
		options?: MethodOptions,
	): Promise<t.TemplateScheduler> {
		return handler('templates/{templateId}/scheduler', options).put(params, body)
	},
	/**
	 * (TP-403) Remove scheduler
	 */
	delete: async function(params: TemplateSchedulerDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('templates/{templateId}/scheduler', options).delete(params, null)
	},
	/**
	 * (TP-2559) Update scheduler
	 */
	patch: async function(
		params: TemplateSchedulerPatchParams,
		body: t.TemplateScheduler,
		options?: MethodOptions,
	): Promise<t.TemplateScheduler> {
		return handler('templates/{templateId}/scheduler', options).patch(params, body)
	},
}
export type TemplateDraftParams = {|
	templateId: string,
|}
export type TemplateDraftPostParams = {| ...TemplateDraftParams |}
export const templateDraft = {
	/**
	 * (TP-435) Create draft accounting document from template
	 */
	post: async function(params: TemplateDraftPostParams, options?: MethodOptions): Promise<t.AccountingDocument> {
		return handler('templates/{templateId}/accountingdocuments', options).post(params, null)
	},
}
export type AccountingDocumentsScansParams = {|
	organizationId?: string,
|}
export type AccountingDocumentsScansPostParams = {| ...AccountingDocumentsScansParams |}
export type AccountingDocumentsScansPostResponse = Array<t.AccountingDocumentFromScanResponse>

export type AccountingDocumentsScansPostResponseTemp = any
export const accountingDocumentsScans = {
	/**
	 * (TP-353) Creates a new accounting document with scans
	 */
	post: async function(
		params: AccountingDocumentsScansPostParams,
		body: any,
		options?: MethodOptions,
	): Promise<AccountingDocumentsScansPostResponse> {
		return handler('{organizationId}/accountingdocuments/scans', options).post(params, body)
	},
}
export type AccountingDocumentStatsParams = {|
	organizationId?: string,
|}
export type AccountingDocumentStatsGetParams = {|
	direction: number,
	...AccountingDocumentStatsParams,
|}
export type AccountingDocumentStatsGetResponse = {|
	afterDueDate?: number,
	sum?: number,
	waitingForApproval?: number,
|}

export type AccountingDocumentStatsGetResponseTemp = any
export const accountingDocumentStats = {
	/**
	 * (TP-342) Gets accounting document statistics
	 */
	get: async function(
		params: AccountingDocumentStatsGetParams,
		options?: MethodOptions,
	): Promise<AccountingDocumentStatsGetResponse> {
		return handler('{organizationId}/accountingdocuments/stats', options).get(params, null)
	},
}
export type AccountingDocumentActionsParams = {|
	organizationId?: string,
|}
export type AccountingDocumentActionsPostParams = {| ...AccountingDocumentActionsParams |}
export const accountingDocumentActions = {
	/**
	 * (TP-434) Gets a list of available actions
	 */
	post: async function(
		params: AccountingDocumentActionsPostParams,
		body: t.GetAvailableActionsRequest,
		options?: MethodOptions,
	): Promise<t.GetAvailableActionsResponse> {
		return handler('{organizationId}/accountingdocuments/actions', options).post(params, body)
	},
}
export type AccountingDocumentAcknowledgeParams = {|
	organizationId?: string,
	accountingDocumentId: string,
|}
export type AccountingDocumentAcknowledgePostParams = {| ...AccountingDocumentAcknowledgeParams |}
export const accountingDocumentAcknowledge = {
	/**
	 * (TP-389) Assign a type and/or direction to the accounting document
	 */
	post: async function(
		params: AccountingDocumentAcknowledgePostParams,
		body: t.AcknowledgeRequest,
		options?: MethodOptions,
	): Promise<t.AcknowledgeResponse> {
		return handler('{organizationId}/accountingdocuments/{accountingDocumentId}/acknowledge', options).post(
			params,
			body,
		)
	},
}
export type PrintRequestParams = {|
	organizationId?: string,
	accountingDocumentId: string,
|}
export type PrintRequestPostParams = {| ...PrintRequestParams |}
export type PrintRequestPostResponse = {|
	token?: string,
|}

export type PrintRequestPostResponseTemp = any
export const printRequest = {
	/**
	 * (TP-604) Create PDF download request
	 */
	post: async function(params: PrintRequestPostParams, options?: MethodOptions): Promise<PrintRequestPostResponse> {
		return handler('{organizationId}/accountingdocuments/{accountingDocumentId}/pdf/downloadrequest', options).post(
			params,
			null,
		)
	},
}
export type AccountingDocumentsExportRequestParams = {|
	organizationId?: string,
|}
export type AccountingDocumentsExportRequestPostParams = {| ...AccountingDocumentsExportRequestParams |}
export const accountingDocumentsExportRequest = {
	/**
	 * (TP-908 *) Create XLSX export download request for Accounting Documents list
	 */
	post: async function(
		params: AccountingDocumentsExportRequestPostParams,
		body: t.AccountingDocumentSearchExport,
		options?: MethodOptions,
	): Promise<t.DownloadToken> {
		return handler('{organizationId}/search/accountingdocuments/xlsx/downloadrequest', options).post(params, body)
	},
}
export type ForceProcessingParams = {|
	organizationId?: string,
	accountingDocumentId: string,
|}
export type ForceProcessingPostParams = {| ...ForceProcessingParams |}
export const forceProcessing = {
	/**
	 * (TP-594) Force processing
	 */
	post: async function(params: ForceProcessingPostParams, options?: MethodOptions): Promise<void> {
		return handler('{organizationId}/accountingdocuments/{accountingDocumentId}/process', options).post(params, null)
	},
}
export type AccountingDocumentsToExtractCountGetParams = {||}
export const accountingDocumentsToExtractCount = {
	/**
	 * Get number of accounting documents to be extracted
	 */
	get: async function(
		params: AccountingDocumentsToExtractCountGetParams,
		options?: MethodOptions,
	): Promise<t.AccountingDocumentExtractsCountResponse> {
		return privateApiHandler('accountingdocuments/extracts/count', options).get(params, null)
	},
}
export type AccountingDocumentExtractsPostParams = {||}
export const accountingDocumentExtracts = {
	/**
	 * (TP-1262) Fetch next Accounting Document to be extracted
	 */
	post: async function(
		body: t.AccountingDocumentExtractsFetchRequest,
		options?: MethodOptions,
	): Promise<t.AccountingDocumentExtractsFetchResponse> {
		return privateApiHandler('accountingdocuments/extracts/fetch', options).post(null, body)
	},
}
export type AccountingDocumentExtractingTemplateParams = {|
	organizationId?: string,
	accountingDocumentId: string,
|}
export type AccountingDocumentExtractingTemplatePutParams = {| ...AccountingDocumentExtractingTemplateParams |}
export const accountingDocumentExtractingTemplate = {
	/**
	 * Store accounting document extracting template
	 */
	put: async function(
		params: AccountingDocumentExtractingTemplatePutParams,
		body: t.AccountingDocumentExtractingTemplate,
		options?: MethodOptions,
	): Promise<void> {
		return handler('{organizationId}/accountingdocuments/{accountingDocumentId}/extractingtemplate', options).put(
			params,
			body,
		)
	},
}
export type AccDocintegrationsParams = {|
	organizationId?: string,
	accountingDocumentId: string,
|}
export type AccDocintegrationsPostParams = {| ...AccDocintegrationsParams |}
export const accDocintegrations = {
	/**
	 * (TP-2832 *) Adds new value to Accounting Document's integration settings
	 */
	post: async function(
		params: AccDocintegrationsPostParams,
		body: t.AccountingDocumentIntegrationRequest,
		options?: MethodOptions,
	): Promise<t.AccountingDocumentIntegrationsResponse> {
		return handler('{organizationId}/accountingDocuments/{accountingDocumentId}/integrations', options).post(
			params,
			body,
		)
	},
}
export type BankAccountStatementOptionsParams = {|
	organizationId?: string,
	bankAccountId: string,
|}
export type BankAccountStatementOptionsGetParams = {| ...BankAccountStatementOptionsParams |}
export type BankAccountStatementOptionsPutParams = {| ...BankAccountStatementOptionsParams |}
export const bankAccountStatementOptions = {
	/**
	 * Get Bank Statement Download Options
	 */
	get: async function(
		params: BankAccountStatementOptionsGetParams,
		options?: MethodOptions,
	): Promise<t.BankStatementDownloadOptionsResponse> {
		return handler('{organizationId}/bankaccounts/{bankAccountId}/statements/options', options).get(params, null)
	},
	/**
	 * Set Bank Statement download options
	 */
	put: async function(
		params: BankAccountStatementOptionsPutParams,
		body: t.BankStatementDownloadOptionsRequest,
		options?: MethodOptions,
	): Promise<t.BankStatementDownloadOptionsResponse> {
		return handler('{organizationId}/bankaccounts/{bankAccountId}/statements/options', options).put(params, body)
	},
}
export type BankAccountStatementOptionsFormatParams = {|
	organizationId?: string,
	bankAccountId: string,
	format: 'gpc' | 'pdf',
|}
export type BankAccountStatementOptionsFormatGetParams = {| ...BankAccountStatementOptionsFormatParams |}
export type BankAccountStatementOptionsFormatPutParams = {| ...BankAccountStatementOptionsFormatParams |}
export const bankAccountStatementOptionsFormat = {
	/**
	 * Get Bank Statement Download Options
	 */
	get: async function(
		params: BankAccountStatementOptionsFormatGetParams,
		options?: MethodOptions,
	): Promise<t.BankStatementDownloadOptionsResponse> {
		return handler('{organizationId}/bankaccounts/{bankAccountId}/statements/options/{format}', options).get(
			params,
			null,
		)
	},
	/**
	 * Set Bank Statement download options
	 */
	put: async function(
		params: BankAccountStatementOptionsFormatPutParams,
		body: t.BankStatementDownloadOptionsRequest,
		options?: MethodOptions,
	): Promise<t.BankStatementDownloadOptionsResponse> {
		return handler('{organizationId}/bankaccounts/{bankAccountId}/statements/options/{format}', options).put(
			params,
			body,
		)
	},
}
export type BillingReportsParams = {|
	periodYear: number,
	periodMonth: number,
|}
export type BillingReportsGetParams = {| ...BillingReportsParams |}
export const billingReports = {
	/**
	 * (TP-1815*) Get global report for all Organizations
	 */
	get: async function(
		params: BillingReportsGetParams,
		options?: MethodOptions,
	): Promise<t.BillingReportGlobalResponse> {
		return privateApiHandler('billing/reports/global/periods/{periodYear}/{periodMonth}', options).get(params, null)
	},
}
export type CashBookDownloadParams = {|
	organizationId?: string,
	cashRegisterId: string,
|}
export type CashBookDownloadPostParams = {|
	from: string,
	to: string,
	...CashBookDownloadParams,
|}
export const cashBookDownload = {
	/**
	 * Create PDF cashbook download request
	 */
	post: async function(params: CashBookDownloadPostParams, options?: MethodOptions): Promise<t.DownloadToken> {
		return handler('{organizationId}/cashregisters/{cashRegisterId}/cashbook/pdf/downloadrequest', options).post(
			params,
			null,
		)
	},
}
export type ChatAccessTokenParams = {|
	organizationId?: string,
|}
export type ChatAccessTokenGetParams = {|
	device: string,
	...ChatAccessTokenParams,
|}
export const chatAccessToken = {
	/**
	 * Create chat access token
	 */
	get: async function(params: ChatAccessTokenGetParams, options?: MethodOptions): Promise<t.ChatAccessTokenResponse> {
		return handler('{organizationId}/chat/users/me/accesstoken', options).get(params, null)
	},
}
export type ChatRegisterMeParams = {|
	organizationId?: string,
|}
export type ChatRegisterMePutParams = {| ...ChatRegisterMeParams |}
export const chatRegisterMe = {
	/**
	 * Creates or updates user's registration in chat service
	 */
	put: async function(params: ChatRegisterMePutParams, options?: MethodOptions): Promise<void> {
		return handler('{organizationId}/chat/users/me/registration', options).put(params, null)
	},
}
export type ChatRegisterUserParams = {|
	organizationId?: string,
	userId: string,
|}
export type ChatRegisterUserPutParams = {| ...ChatRegisterUserParams |}
export const chatRegisterUser = {
	/**
	 * Creates or updates user's registration in chat service
	 */
	put: async function(params: ChatRegisterUserPutParams, options?: MethodOptions): Promise<void> {
		return handler('{organizationId}/chat/users/{userId}/registration', options).put(params, null)
	},
}
export type ChatUsersInfoPostParams = {||}
export const chatUsersInfo = {
	/**
	 * (TP-1066) Get chat users info
	 */
	post: async function(body: t.ChatUsersInfoRequest, options?: MethodOptions): Promise<t.ChatUsersInfoResponse> {
		return privateApiHandler('chat/users/info', options).post(null, body)
	},
}
export type DownloadExportParams = {|
	organizationId?: string,
	token: string,
|}
export type DownloadExportGetParams = {| ...DownloadExportParams |}
export type DownloadExportGetResponse = any

export type DownloadExportGetResponseTemp = any
export const downloadExport = {
	/**
	 * (TP-908 *) Download export
	 */
	get: async function(params: DownloadExportGetParams, options?: MethodOptions): Promise<DownloadExportGetResponse> {
		return handler('{organizationId}/downloads/tokens/{token}', options).get(params, null)
	},
}
export type EnumsParams = {|
	organizationId?: string,
|}
export type EnumsGetParams = {|
	type:  //eslint-disable-line flowtype/space-after-type-colon
		| 'accountingDocumentCategories'
		| 'accountingDocumentTypes'
		| 'accountingDocumentDirections'
		| 'paymentTypes'
		| 'reverseChargeCodes'
		| 'bankStatementState'
		| 'naceCodes'
		| 'taxOffices'
		| 'legalForms',
	originCountry?: string,
	...EnumsParams,
|}
export type EnumsGetResponse = Array<t.Enumeration>

export type EnumsGetResponseTemp = any
export const enums = {
	/**
	 * (TP-233) Gets an enum
	 */
	get: async function(params: EnumsGetParams, options?: MethodOptions): Promise<EnumsGetResponse> {
		return handler('{organizationId}/enums', options).get(params, null)
	},
}
export type BankStatementTypesParams = {|
	organizationId?: string,
	bankId: string,
|}
export type BankStatementTypesGetParams = {| ...BankStatementTypesParams |}
export type BankStatementTypesGetResponse = Array<t.BankStatementType>

export type BankStatementTypesGetResponseTemp = any
export const bankStatementTypes = {
	/**
	 * (TP-303) Gets a list of statement types
	 */
	get: async function(
		params: BankStatementTypesGetParams,
		options?: MethodOptions,
	): Promise<BankStatementTypesGetResponse> {
		return handler('{organizationId}/banks/{bankId}/statementtypes', options).get(params, null)
	},
}
export type BankPaymentOrderTypesParams = {|
	organizationId?: string,
	bankId: string,
|}
export type BankPaymentOrderTypesGetParams = {| ...BankPaymentOrderTypesParams |}
export type BankPaymentOrderTypesGetResponse = Array<t.BankPaymentOrderType>

export type BankPaymentOrderTypesGetResponseTemp = any
export const bankPaymentOrderTypes = {
	/**
	 * (TP-304) Gets a list of payment order types
	 */
	get: async function(
		params: BankPaymentOrderTypesGetParams,
		options?: MethodOptions,
	): Promise<BankPaymentOrderTypesGetResponse> {
		return handler('{organizationId}/banks/{bankId}/paymentordertypes', options).get(params, null)
	},
}
export type GreenboxAccountingDocumentFinAccountsParams = {|
	organizationId?: string,
	accountingDocumentId: string,
|}
export type GreenboxAccountingDocumentFinAccountsPostParams = {| ...GreenboxAccountingDocumentFinAccountsParams |}
export const greenboxAccountingDocumentFinAccounts = {
	/**
	 * Get Financial Account suggestions
	 */
	post: async function(
		params: GreenboxAccountingDocumentFinAccountsPostParams,
		body: t.GreenboxSuggestionRequest,
		options?: MethodOptions,
	): Promise<t.GreenboxSuggestionResponse> {
		return handler(
			'{organizationId}/greenbox/accountingdocuments/{accountingDocumentId}/suggest/financialaccounts',
			options,
		).post(params, body)
	},
}
export type AccountingDocumentImportsParams = {|
	organizationId?: string,
|}
export type AccountingDocumentImportsPostParams = {| ...AccountingDocumentImportsParams |}
export const accountingDocumentImports = {
	/**
	 * Create import
	 */
	post: async function(
		params: AccountingDocumentImportsPostParams,
		body: t.Import,
		options?: MethodOptions,
	): Promise<t.Import> {
		return handler('{organizationId}/accountingdocuments/imports', options).post(params, body)
	},
}
export type AccountingDocumentImportParams = {|
	organizationId?: string,
	importId: string,
|}
export type AccountingDocumentImportGetParams = {| ...AccountingDocumentImportParams |}
export type AccountingDocumentImportPutParams = {| ...AccountingDocumentImportParams |}
export type AccountingDocumentImportDeleteParams = {| ...AccountingDocumentImportParams |}
export const accountingDocumentImport = {
	/**
	 * Get import
	 */
	get: async function(params: AccountingDocumentImportGetParams, options?: MethodOptions): Promise<t.Import> {
		return handler('{organizationId}/accountingdocuments/imports/{importId}', options).get(params, null)
	},
	/**
	 * Update import
	 */
	put: async function(
		params: AccountingDocumentImportPutParams,
		body: t.Import,
		options?: MethodOptions,
	): Promise<t.Import> {
		return handler('{organizationId}/accountingdocuments/imports/{importId}', options).put(params, body)
	},
	/**
	 * Delete import
	 */
	delete: async function(params: AccountingDocumentImportDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('{organizationId}/accountingdocuments/imports/{importId}', options).delete(params, null)
	},
}
export type AccountingDocumentImportProcessParams = {|
	organizationId?: string,
	importId: string,
|}
export type AccountingDocumentImportProcessPostParams = {| ...AccountingDocumentImportProcessParams |}
export const accountingDocumentImportProcess = {
	/**
	 * Process import
	 */
	post: async function(params: AccountingDocumentImportProcessPostParams, options?: MethodOptions): Promise<t.Import> {
		return handler('{organizationId}/accountingdocuments/imports/{importId}/process', options).post(params, null)
	},
}
export type LogsGetParams = {|
	level?: string,
	organizationId?: number,
|}
export type LogsGetResponse = {|
	message?: string,
	level?: string,
	time?: string,
	exception?: string,
|}

export type LogsGetResponseTemp = any
export type LogsPostParams = {||}
export type LogsPostResponse = any

export type LogsPostResponseTemp = any
export const logs = {
	/**
	 * (TP-254) Gets a list of last 500 logs
	 */
	get: async function(params: LogsGetParams, options?: MethodOptions): Promise<LogsGetResponse> {
		return privateApiHandler('logs', options).get(params, null)
	},
	/**
	 * (TP-590) Create UI log
	 */
	post: async function(body: any, options?: MethodOptions): Promise<LogsPostResponse> {
		return privateApiHandler('logs', options).post(null, body)
	},
}
export type BootstrapGetParams = {|
	organizationId?: number,
|}
export const bootstrap = {
	/**
	 * Bootstrap
	 */
	get: async function(params: BootstrapGetParams, options?: MethodOptions): Promise<t.BootstrapResponse> {
		return privateApiHandler('bootstrap', options).get(params, null)
	},
}
export type InvitationsParams = {|
	organizationId?: string,
|}
export type InvitationsPostParams = {| ...InvitationsParams |}
export const invitations = {
	/**
	 * Send Customer Reference Program email
	 */
	post: async function(
		params: InvitationsPostParams,
		body: t.ReferenceProgramSendEmailRequest,
		options?: MethodOptions,
	): Promise<t.ReferenceProgramSendEmailResponse> {
		return handler('{organizationId}/referenceprogram/email/send', options).post(params, body)
	},
}
export type ContactMePostParams = {||}
export const contactMe = {
	/**
	 * (TP-2093*) Send ContactMe request
	 */
	post: async function(body: t.ContactMeRequest, options?: MethodOptions): Promise<void> {
		return privateApiHandler('misc/contactme', options).post(null, body)
	},
}
export type OrganizationParams = {|
	organizationId?: string,
|}
export type OrganizationGetParams = {| ...OrganizationParams |}
export type OrganizationDeleteParams = {| ...OrganizationParams |}
export const organization = {
	/**
	 * (TP-241) Gets information on current organization
	 */
	get: async function(params: OrganizationGetParams, options?: MethodOptions): Promise<t.Organization> {
		return handler('{organizationId}', options).get(params, null)
	},
	/**
	 * (TP-1812*) Delete organization
	 */
	delete: async function(params: OrganizationDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('{organizationId}', options).delete(params, null)
	},
}
export type SignaturesParams = {|
	organizationId?: string,
|}
export type SignaturesGetParams = {| ...SignaturesParams |}
export type SignaturesGetResponse = Array<{|
	id?: string,
	fileId?: string,
|}>

export type SignaturesGetResponseTemp = any
export type SignaturesPostParams = {| ...SignaturesParams |}
export type SignaturesPostResponse = {|
	id?: string,
|}

export type SignaturesPostResponseTemp = any
export const signatures = {
	/**
	 * (TP-519) List signatures
	 */
	get: async function(params: SignaturesGetParams, options?: MethodOptions): Promise<SignaturesGetResponse> {
		return handler('{organizationId}/settings/signatures', options).get(params, null)
	},
	/**
	 * (TP-518) Upload signature
	 */
	post: async function(
		params: SignaturesPostParams,
		body: {},
		options?: MethodOptions,
	): Promise<SignaturesPostResponse> {
		return handler('{organizationId}/settings/signatures', options, { 'Content-Type': 'multipart/form-data' }).post(
			params,
			body,
		)
	},
}
export type SignatureDeleteParams = {|
	organizationId?: string,
	signatureId: string,
|}
export type SignatureDeleteDeleteParams = {| ...SignatureDeleteParams |}
export const signatureDelete = {
	/**
	 * (TP-520) Remove signature
	 */
	delete: async function(params: SignatureDeleteDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('{organizationId}/settings/signatures/{signatureId}', options).delete(params, null)
	},
}
export type OrganizationLogoParams = {|
	organizationId?: string,
|}
export type OrganizationLogoGetParams = {| ...OrganizationLogoParams |}
export type OrganizationLogoGetResponse = any

export type OrganizationLogoGetResponseTemp = any
export type OrganizationLogoPutParams = {| ...OrganizationLogoParams |}
export type OrganizationLogoDeleteParams = {| ...OrganizationLogoParams |}
export const organizationLogo = {
	/**
	 * (TP-517) Get organization logo data
	 */
	get: async function(
		params: OrganizationLogoGetParams,
		options?: MethodOptions,
	): Promise<OrganizationLogoGetResponse> {
		return handler('{organizationId}/settings/logo', options).get(params, null)
	},
	/**
	 * (TP-515) Change organization logo
	 */
	put: async function(params: OrganizationLogoPutParams, body: {}, options?: MethodOptions): Promise<void> {
		return handler('{organizationId}/settings/logo', options, { 'Content-Type': 'multipart/form-data' }).put(
			params,
			body,
		)
	},
	/**
	 * (TP-516) Remove organization logo
	 */
	delete: async function(params: OrganizationLogoDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('{organizationId}/settings/logo', options).delete(params, null)
	},
}
export type OrganizationSettingsParams = {|
	organizationId?: string,
|}
export type OrganizationSettingsGetParams = {| ...OrganizationSettingsParams |}
export type OrganizationSettingsPatchParams = {| ...OrganizationSettingsParams |}
export const organizationSettings = {
	/**
	 * Get organization settings
	 */
	get: async function(params: OrganizationSettingsGetParams, options?: MethodOptions): Promise<void> {
		return handler('{organizationId}/settings', options).get(params, null)
	},
	/**
	 * Update settings
	 */
	patch: async function(
		params: OrganizationSettingsPatchParams,
		body: t.OrganizationSettings,
		options?: MethodOptions,
	): Promise<t.OrganizationSettings> {
		return handler('{organizationId}/settings', options).patch(params, body)
	},
}
export const organizationNoteExtraction = {
	/**
	 * Get organization note extraction
	 */
	get: async function(params: OrganizationSettingsGetParams, options?: MethodOptions): Promise<void> {
		return handler('{organizationId}/settings/internal/notes/extraction', options).get(params, null)
	},
	/**
	 * Update settings
	 */
	put: async function(
		params: OrganizationContractStatePatchParams,
		body: t.OrganizationNoteExtraction,
		options?: MethodOptions,
	): Promise<t.OrganizationNoteExtraction> {
		return handler('{organizationId}/settings/internal/notes/extraction', options).put(params, body)
	},
}
export type OrganizationsStatsPostParams = {||}
export const organizationsStats = {
	/**
	 * (TP-554) Filter organization statistics
	 */
	post: async function(
		body: t.SearchRequestGeneric,
		options?: MethodOptions,
	): Promise<t.OrganizationStatisticsSearchResult> {
		return privateApiHandler('organizations/stats', options).post(null, body)
	},
}
export type OrganizationsSearchPostParams = {||}
export const organizationsSearch = {
	/**
	 * (TP-496) Search for Organizations
	 */
	post: async function(body: t.SearchRequestGeneric, options?: MethodOptions): Promise<t.OrganizationsSearchResult> {
		return privateApiHandler('search/organizations', options).post(null, body)
	},
}
export type OrganizationApiAppsParams = {|
	organizationId?: string,
|}
export type OrganizationApiAppsGetParams = {| ...OrganizationApiAppsParams |}
export type OrganizationApiAppsPostParams = {| ...OrganizationApiAppsParams |}
export const organizationApiApps = {
	/**
	 * (TP-748*) List API apps
	 */
	get: async function(params: OrganizationApiAppsGetParams, options?: MethodOptions): Promise<t.ApiAppsSearchResult> {
		return handler('{organizationId}/settings/apiapps', options).get(params, null)
	},
	/**
	 * (TP-717*) Create new API app
	 */
	post: async function(
		params: OrganizationApiAppsPostParams,
		body: t.ApiApp,
		options?: MethodOptions,
	): Promise<t.ApiApp> {
		return handler('{organizationId}/settings/apiapps', options).post(params, body)
	},
}
export type OrganizationApiAppParams = {|
	organizationId?: number,
	apiAppId: number,
|}
export type OrganizationApiAppPutParams = {| ...OrganizationApiAppParams |}
export type OrganizationApiAppDeleteParams = {| ...OrganizationApiAppParams |}
export const organizationApiApp = {
	/**
	 * (TP-719*) Update API app
	 */
	put: async function(params: OrganizationApiAppPutParams, body: t.ApiApp, options?: MethodOptions): Promise<t.ApiApp> {
		return handler('{organizationId}/settings/apiapps/{apiAppId}', options).put(params, body)
	},
	/**
	 * (TP-720*) Remove API app
	 */
	delete: async function(params: OrganizationApiAppDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('{organizationId}/settings/apiapps/{apiAppId}', options).delete(params, null)
	},
}
export type OrganizationAddressParams = {|
	organizationId?: number,
	addressType: number,
|}
export type OrganizationAddressPutParams = {| ...OrganizationAddressParams |}
export type OrganizationAddressDeleteParams = {| ...OrganizationAddressParams |}
export type OrganizationAddressPatchParams = {| ...OrganizationAddressParams |}
export const organizationAddress = {
	/**
	 * (TP-790*) Set organization address
	 */
	put: async function(
		params: OrganizationAddressPutParams,
		body: t.OrganizationAddress,
		options?: MethodOptions,
	): Promise<t.OrganizationAddress> {
		return handler('{organizationId}/addresstypes/{addressType}/address', options).put(params, body)
	},
	/**
	 * (TP-968*) Removes organization address
	 */
	delete: async function(params: OrganizationAddressDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('{organizationId}/addresstypes/{addressType}/address', options).delete(params, null)
	},
	/**
	 * (TP-2624*) Update organization address
	 */
	patch: async function(
		params: OrganizationAddressPatchParams,
		body: t.OrganizationAddress,
		options?: MethodOptions,
	): Promise<t.OrganizationAddress> {
		return handler('{organizationId}/addresstypes/{addressType}/address', options).patch(params, body)
	},
}
export type OrganizationCreatorsParams = {|
	organizationId?: number,
|}
export type OrganizationCreatorsGetParams = {| ...OrganizationCreatorsParams |}
export type OrganizationCreatorsGetResponse = Array<t.OrganizationCreator>

export type OrganizationCreatorsGetResponseTemp = any
export type OrganizationCreatorsPostParams = {| ...OrganizationCreatorsParams |}
export const organizationCreators = {
	/**
	 * (TP-800*) Get organization creators
	 */
	get: async function(
		params: OrganizationCreatorsGetParams,
		options?: MethodOptions,
	): Promise<OrganizationCreatorsGetResponse> {
		return handler('{organizationId}/settings/creators', options).get(params, null)
	},
	/**
	 * (TP-800*) Create organization creator
	 */
	post: async function(
		params: OrganizationCreatorsPostParams,
		body: t.OrganizationCreator,
		options?: MethodOptions,
	): Promise<t.OrganizationCreator> {
		return handler('{organizationId}/settings/creators', options).post(params, body)
	},
}
export type OrganizationCreatorParams = {|
	organizationId?: string,
	organizationCreatorId: string,
|}
export type OrganizationCreatorGetParams = {| ...OrganizationCreatorParams |}
export type OrganizationCreatorPutParams = {| ...OrganizationCreatorParams |}
export type OrganizationCreatorDeleteParams = {| ...OrganizationCreatorParams |}
export const organizationCreator = {
	/**
	 * (TP-800*) Get organization creator
	 */
	get: async function(params: OrganizationCreatorGetParams, options?: MethodOptions): Promise<t.OrganizationCreator> {
		return handler('{organizationId}/settings/creators/{organizationCreatorId}', options).get(params, null)
	},
	/**
	 * (TP-800*) Update organization creator
	 */
	put: async function(
		params: OrganizationCreatorPutParams,
		body: t.OrganizationCreator,
		options?: MethodOptions,
	): Promise<t.OrganizationCreator> {
		return handler('{organizationId}/settings/creators/{organizationCreatorId}', options).put(params, body)
	},
	/**
	 * (TP-800*) Remove organization creator
	 */
	delete: async function(params: OrganizationCreatorDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('{organizationId}/settings/creators/{organizationCreatorId}', options).delete(params, null)
	},
}
export type OrganizationSignAuthorizedPersonsParams = {|
	organizationId?: number,
|}
export type OrganizationSignAuthorizedPersonsGetParams = {| ...OrganizationSignAuthorizedPersonsParams |}
export type OrganizationSignAuthorizedPersonsGetResponse = Array<t.OrganizationPersonAuthorizedToSign>

export type OrganizationSignAuthorizedPersonsGetResponseTemp = any
export type OrganizationSignAuthorizedPersonsPostParams = {| ...OrganizationSignAuthorizedPersonsParams |}
export const organizationSignAuthorizedPersons = {
	/**
	 * (TP-800*) Get organization persons authorized to sign
	 */
	get: async function(
		params: OrganizationSignAuthorizedPersonsGetParams,
		options?: MethodOptions,
	): Promise<OrganizationSignAuthorizedPersonsGetResponse> {
		return handler('{organizationId}/settings/personsauthorizedtosign', options).get(params, null)
	},
	/**
	 * (TP-800*) Create organization person authorized to sign
	 */
	post: async function(
		params: OrganizationSignAuthorizedPersonsPostParams,
		body: t.OrganizationPersonAuthorizedToSign,
		options?: MethodOptions,
	): Promise<t.OrganizationPersonAuthorizedToSign> {
		return handler('{organizationId}/settings/personsauthorizedtosign', options).post(params, body)
	},
}
export type OrganizationSignAuthorizedPersonParams = {|
	organizationId?: string,
	personAuthorizedToSignId: string,
|}
export type OrganizationSignAuthorizedPersonGetParams = {| ...OrganizationSignAuthorizedPersonParams |}
export type OrganizationSignAuthorizedPersonPutParams = {| ...OrganizationSignAuthorizedPersonParams |}
export type OrganizationSignAuthorizedPersonDeleteParams = {| ...OrganizationSignAuthorizedPersonParams |}
export const organizationSignAuthorizedPerson = {
	/**
	 * (TP-800*) Get organization person authorized to sign
	 */
	get: async function(
		params: OrganizationSignAuthorizedPersonGetParams,
		options?: MethodOptions,
	): Promise<t.OrganizationPersonAuthorizedToSign> {
		return handler('{organizationId}/settings/personsauthorizedtosign/{personAuthorizedToSignId}', options).get(
			params,
			null,
		)
	},
	/**
	 * (TP-800*) Update organization person authorized to sign
	 */
	put: async function(
		params: OrganizationSignAuthorizedPersonPutParams,
		body: t.OrganizationPersonAuthorizedToSign,
		options?: MethodOptions,
	): Promise<t.OrganizationPersonAuthorizedToSign> {
		return handler('{organizationId}/settings/personsauthorizedtosign/{personAuthorizedToSignId}', options).put(
			params,
			body,
		)
	},
	/**
	 * (TP-800*) Delete person authorized to sign
	 */
	delete: async function(params: OrganizationSignAuthorizedPersonDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('{organizationId}/settings/personsauthorizedtosign/{personAuthorizedToSignId}', options).delete(
			params,
			null,
		)
	},
}
export type OrganizationTaxRepresentativesParams = {|
	organizationId?: string,
|}
export type OrganizationTaxRepresentativesGetParams = {| ...OrganizationTaxRepresentativesParams |}
export type OrganizationTaxRepresentativesGetResponse = Array<t.OrganizationTaxRepresentative>

export type OrganizationTaxRepresentativesGetResponseTemp = any
export type OrganizationTaxRepresentativesPostParams = {| ...OrganizationTaxRepresentativesParams |}
export const organizationTaxRepresentatives = {
	/**
	 * (TP-800*) Get organization tax representatives
	 */
	get: async function(
		params: OrganizationTaxRepresentativesGetParams,
		options?: MethodOptions,
	): Promise<OrganizationTaxRepresentativesGetResponse> {
		return handler('{organizationId}/settings/taxrepresentatives', options).get(params, null)
	},
	/**
	 * (TP-800*) Create organization tax representative
	 */
	post: async function(
		params: OrganizationTaxRepresentativesPostParams,
		body: t.OrganizationTaxRepresentative,
		options?: MethodOptions,
	): Promise<t.OrganizationTaxRepresentative> {
		return handler('{organizationId}/settings/taxrepresentatives', options).post(params, body)
	},
}
export type OrganizationTaxRepresentativeParams = {|
	organizationId?: string,
	taxRepresentativeId: string,
|}
export type OrganizationTaxRepresentativeGetParams = {| ...OrganizationTaxRepresentativeParams |}
export type OrganizationTaxRepresentativePutParams = {| ...OrganizationTaxRepresentativeParams |}
export type OrganizationTaxRepresentativeDeleteParams = {| ...OrganizationTaxRepresentativeParams |}
export const organizationTaxRepresentative = {
	/**
	 * (TP-800*) Get organization tax representative
	 */
	get: async function(
		params: OrganizationTaxRepresentativeGetParams,
		options?: MethodOptions,
	): Promise<t.OrganizationTaxRepresentative> {
		return handler('{organizationId}/settings/taxrepresentatives/{taxRepresentativeId}', options).get(params, null)
	},
	/**
	 * (TP-800*) Update organization tax representative
	 */
	put: async function(
		params: OrganizationTaxRepresentativePutParams,
		body: t.OrganizationTaxRepresentative,
		options?: MethodOptions,
	): Promise<t.OrganizationTaxRepresentative> {
		return handler('{organizationId}/settings/taxrepresentatives/{taxRepresentativeId}', options).put(params, body)
	},
	/**
	 * (TP-800*) Remove organization tax representative
	 */
	delete: async function(params: OrganizationTaxRepresentativeDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('{organizationId}/settings/taxrepresentatives/{taxRepresentativeId}', options).delete(params, null)
	},
}
export type AccountingDocumentDefaultsParams = {|
	organizationId?: string,
	accountingDocumentTypeId: string,
	accountingDocumentDirectionId: string,
|}
export type AccountingDocumentDefaultsGetParams = {| ...AccountingDocumentDefaultsParams |}
export type AccountingDocumentDefaultsPatchParams = {| ...AccountingDocumentDefaultsParams |}
export const accountingDocumentDefaults = {
	/**
	 * (TP-832*) Get default AccountingDocument settings
	 */
	get: async function(
		params: AccountingDocumentDefaultsGetParams,
		options?: MethodOptions,
	): Promise<t.AccountingDocumentDefaults> {
		return handler(
			'{organizationId}/settings/defaults/accountingdocuments/{accountingDocumentTypeId}/{accountingDocumentDirectionId}',
			options,
		).get(params, null)
	},
	/**
	 * (TP-832*) Update default AccountingDocument settings
	 */
	patch: async function(
		params: AccountingDocumentDefaultsPatchParams,
		body: t.AccountingDocumentDefaults,
		options?: MethodOptions,
	): Promise<t.AccountingDocumentDefaults> {
		return handler(
			'{organizationId}/settings/defaults/accountingdocuments/{accountingDocumentTypeId}/{accountingDocumentDirectionId}',
			options,
		).patch(params, body)
	},
}
export type ProcessOrganizationParams = {|
	organizationId?: string,
|}
export type ProcessOrganizationPostParams = {| ...ProcessOrganizationParams |}
export const processOrganization = {
	/**
	 * Process organization
	 */
	post: async function(params: ProcessOrganizationPostParams, options?: MethodOptions): Promise<void> {
		return handler('{organizationId}/process', options).post(params, null)
	},
}
export type ProcessOrganizationIssuesParams = {|
	organizationId?: string,
|}
export type ProcessOrganizationIssuesGetParams = {| ...ProcessOrganizationIssuesParams |}
export const processOrganizationIssues = {
	/**
	 * Get organization pre-process issues
	 */
	get: async function(
		params: ProcessOrganizationIssuesGetParams,
		options?: MethodOptions,
	): Promise<t.OrganizationProcessIssues> {
		return handler('{organizationId}/process/issues', options).get(params, null)
	},
}
export type InboundEmailsParams = {|
	organizationId?: string,
|}
export type InboundEmailsGetParams = {| ...InboundEmailsParams |}
export const inboundEmails = {
	/**
	 * (TP-) Gets list of organization inbound email addresses
	 */
	get: async function(params: InboundEmailsGetParams, options?: MethodOptions): Promise<t.EmailListResponse> {
		return handler('{organizationId}/inbound/emails', options).get(params, null)
	},
}
export type TaxPaymentHistoryParams = {|
	organizationId?: string,
|}
export type TaxPaymentHistoryGetParams = {| ...TaxPaymentHistoryParams |}
export type TaxPaymentHistoryGetResponse = Array<t.OrganizationSettingsTaxPaymentHistory>

export type TaxPaymentHistoryGetResponseTemp = any
export const taxPaymentHistory = {
	/**
	 * (TP-1369 *) Get Tax Payment Settings history
	 */
	get: async function(
		params: TaxPaymentHistoryGetParams,
		options?: MethodOptions,
	): Promise<TaxPaymentHistoryGetResponse> {
		return handler('{organizationId}/settings/taxpayment/history', options).get(params, null)
	},
}

export type OssHistoryGetResponse = t.OrganizationSettingsCountryVariantSpecificXeuOss

export const ossHistory = {
	get: async function(options?: MethodOptions): Promise<OssHistoryGetResponse> {
		return handler('{organizationId}/settings/countryvariantspecific/xeu/oss', options).get(null, null)
	},
}

export type OrganizationSettingsOpenParams = {|
	organizationId?: string,
|}
export type OrganizationSettingsOpenGetParams = {| ...OrganizationSettingsOpenParams |}
export const organizationSettingsOpen = {
	/**
	 * (TP-1466 *) Get open organization settings
	 */
	get: async function(
		params: OrganizationSettingsOpenGetParams,
		options?: MethodOptions,
	): Promise<t.OrganizationSettingsOpen> {
		return handler('{organizationId}/settings/open', options).get(params, null)
	},
}
export type FeaturesParams = {|
	organizationId?: string,
|}
export type FeaturesGetParams = {| ...FeaturesParams |}
export type FeaturesPatchParams = {| ...FeaturesParams |}
export const organizationSettingsFeatures = {
	/**
	 * (TP-2830 *) Get Organizations' features settings
	 */
	get: async function(
		params: FeaturesGetParams,
		options?: MethodOptions,
	): Promise<t.OrganizationSettingsInternalFeaturesResponse> {
		return handler('{organizationId}/settings/internal/features', options).get(params, null)
	},
	/**
	 * (TP-2828 *) Update Organization's features settings
	 */
	patch: async function(
		params: FeaturesPatchParams,
		body: t.OrganizationSettingsInternalFeaturesRequest,
		options?: MethodOptions,
	): Promise<t.OrganizationSettingsInternalFeaturesResponse> {
		return handler('{organizationId}/settings/internal/features', options).patch(params, body)
	},
}
export type OrganizationContractStateParams = {|
	organizationId?: string,
|}
export type OrganizationContractStateGetParams = {| ...OrganizationContractStateParams |}
export type OrganizationContractStatePatchParams = {| ...OrganizationContractStateParams |}
export const organizationContractState = {
	/**
	 * Get organization contract settings
	 */
	get: async function(
		params: OrganizationContractStateGetParams,
		options?: MethodOptions,
	): Promise<t.OrganizationContractState> {
		return handler('{organizationId}/settings/contractstate', options).get(params, null)
	},
	/**
	 * Update organization contract settings
	 */
	patch: async function(
		params: OrganizationContractStatePatchParams,
		body: t.OrganizationContractStateRequest,
		options?: MethodOptions,
	): Promise<t.OrganizationContractState> {
		return handler('{organizationId}/settings/contractstate', options).patch(params, body)
	},
}
export type UnmatchedPaymentsParams = {|
	organizationId?: string,
|}
export type UnmatchedPaymentsGetParams = {| ...UnmatchedPaymentsParams |}
export type UnmatchedPaymentsGetResponse = Array<t.UnmatchedPayment>

export type UnmatchedPaymentsGetResponseTemp = any
export const unmatchedPayments = {
	/**
	 * Get list of unmatched payments
	 */
	get: async function(
		params: UnmatchedPaymentsGetParams,
		options?: MethodOptions,
	): Promise<UnmatchedPaymentsGetResponse> {
		return handler('{organizationId}/payments/unmatched', options).get(params, null)
	},
}
export type UnmatchedPaymentsBulkParams = {|
	organizationId?: string,
	unmatchedPaymentId: string,
|}
export type UnmatchedPaymentsBulkPostParams = {| ...UnmatchedPaymentsBulkParams |}
export const unmatchedPaymentsBulk = {
	/**
	 * Add Accounting Documents and/or Financial Accounts to be matched with unmatched payment
	 */
	post: async function(
		params: UnmatchedPaymentsBulkPostParams,
		body: t.UnmatchedPaymentMatchesRequestBulk,
		options?: MethodOptions,
	): Promise<t.UnmatchedPaymentMatches> {
		return handler('{organizationId}/payments/unmatched/{unmatchedPaymentId}/matches/bulk', options).post(params, body)
	},
}
export type UnmatchedPaymentParams = {|
	organizationId?: string,
	unmatchedPaymentId: string,
|}
export type UnmatchedPaymentGetParams = {| ...UnmatchedPaymentParams |}
export type UnmatchedPaymentPatchParams = {| ...UnmatchedPaymentParams |}
export const unmatchedPayment = {
	/**
	 * (TP-1493) Get unmatched payment
	 */
	get: async function(params: UnmatchedPaymentGetParams, options?: MethodOptions): Promise<t.UnmatchedPayment> {
		return handler('{organizationId}/payments/unmatched/{unmatchedPaymentId}', options).get(params, null)
	},
	/**
	 * Update Unmatched payment
	 */
	patch: async function(
		params: UnmatchedPaymentPatchParams,
		body: t.UnmatchedPayment,
		options?: MethodOptions,
	): Promise<t.UnmatchedPayment> {
		return handler('{organizationId}/payments/unmatched/{unmatchedPaymentId}', options).patch(params, body)
	},
}
export type OrganizationReportParams = {|
	organizationId?: string,
|}
export type OrganizationReportGetParams = {| ...OrganizationReportParams |}
export const organizationReport = {
	/**
	 * (TP-1334) Get Organization Report
	 */
	get: async function(params: OrganizationReportGetParams, options?: MethodOptions): Promise<t.OrganizationReport> {
		return handler('{organizationId}/reports/organization', options).get(params, null)
	},
}

export type OrganizationCardsParams = {|
	organizationId?: string,
|}
export type OrganizationCardsGetParams = {|
	...OrganizationCardsParams,
	language: string,
	includeCurrent?: boolean,
	startDate?: string,
	numberOfDatesToFuture?: number,
	numberOfDatesToPast?: number,
|}
export const organizationCards = {
	get: async function(params: OrganizationCardsGetParams, options?: MethodOptions): Promise<t.CardsResponse> {
		return handler('{organizationId}/cards', options).get(params, null)
	},
}

export type OrganizationCardCompletionParams = {|
	organizationId?: string,
	cardId?: string,
|}
export type OrganizationCardCompletionPutParams = {|
	...OrganizationCardCompletionParams,
|}
export const organizationCardCompletion = {
	put: async function(
		params: OrganizationCardCompletionPutParams,
		body: t.CardCompletionRequest,
		options?: MethodOptions,
	): Promise<t.CardCompletionResponse> {
		return handler('{organizationId}/cards/{cardId}/completion', options).put(params, body)
	},
}

export type ScanFactoryAccountingDocumentMetadataParams = {|
	organizationId?: string,
	accountingDocumentId: string,
|}
export type ScanFactoryAccountingDocumentMetadataGetParams = {| ...ScanFactoryAccountingDocumentMetadataParams |}
export const scanFactoryAccountingDocumentMetadata = {
	/**
	 * Gets Scan Factory Metadata
	 */
	get: async function(
		params: ScanFactoryAccountingDocumentMetadataGetParams,
		options?: MethodOptions,
	): Promise<t.ScanFactoryAccountingDocumentScanOutput> {
		return handler('{organizationId}/scanfactory/accountingdocuments/{accountingDocumentId}/metadata', options).get(
			params,
			null,
		)
	},
}
export type ScanFactoryAccountingDocumentImageParams = {|
	organizationId?: string,
	accountingDocumentId: string,
	imageLocation: string,
	imageId: string,
|}
export type ScanFactoryAccountingDocumentImageGetParams = {| ...ScanFactoryAccountingDocumentImageParams |}
export type ScanFactoryAccountingDocumentImageGetResponse = any

export type ScanFactoryAccountingDocumentImageGetResponseTemp = any
export const scanFactoryAccountingDocumentImage = {
	/**
	 * Gets Scan Factory Images
	 */
	get: async function(
		params: ScanFactoryAccountingDocumentImageGetParams,
		options?: MethodOptions,
	): Promise<ScanFactoryAccountingDocumentImageGetResponse> {
		return handler(
			'{organizationId}/scanfactory/accountingdocuments/{accountingDocumentId}/images/{imageLocation}/{imageId}',
			options,
		).get(params, null)
	},
}
export type ScanFactoryAccountingDocumentStatsParams = {|
	organizationId?: string,
	accountingDocumentId: string,
|}
export type ScanFactoryAccountingDocumentStatsPostParams = {| ...ScanFactoryAccountingDocumentStatsParams |}
export const scanFactoryAccountingDocumentStats = {
	/**
	 * (TP-1398 *) Saves extracting statistics
	 */
	post: async function(
		params: ScanFactoryAccountingDocumentStatsPostParams,
		body: t.AccountingDocumentExtractionStats,
		options?: MethodOptions,
	): Promise<void> {
		return handler('{organizationId}/scanfactory/accountingdocuments/{accountingDocumentId}/stats', options).post(
			params,
			body,
		)
	},
}
export type UnmatchedPaymentsSearchParams = {|
	organizationId?: string,
|}
export type UnmatchedPaymentsSearchPostParams = {| ...UnmatchedPaymentsSearchParams |}
export const unmatchedPaymentsSearch = {
	/**
	 * Search for Unmatched Payments
	 */
	post: async function(
		// TODO: Na backend se odesilal spatny payload, bude vyreseno v tasku https://triviit.atlassian.net/browse/TWU-2523
		// params: UnmatchedPaymentsSearchPostParams,
		body: t.UnmatchedPaymentsSearchRequest,
		options?: MethodOptions,
	): Promise<t.UnmatchedPaymentsSearchResponse> {
		// HOFIX: https://triviit.atlassian.net/browse/TWU-2533 - na základě hotfixu výše vznikl tento problém
		// $FlowFixMe
		if ('undefined' === typeof body.sortingField) {
			// $FlowFixMe
			body.sortingField = 'id'
		}
		// $FlowFixMe
		if ('undefined' === typeof body.sortingDirection) {
			// $FlowFixMe
			body.sortingDirection = 'DESC'
		}
		// END HOTFIX

		return handler('{organizationId}/search/payments/unmatched', options).post({}, body)
	},
}
export type StickyNotesGetParams = {||}
export type StickyNotesGetResponse = Array<t.StickyNote>

export type StickyNotesGetResponseTemp = any
export type StickyNotesPostParams = {||}
export const stickyNotes = {
	/**
	 * List Sticky Notes
	 */
	get: async function(params: StickyNotesGetParams, options?: MethodOptions): Promise<StickyNotesGetResponse> {
		return privateApiHandler('stickynotes', options).get(params, null)
	},
	/**
	 * Create Sticky Note
	 */
	post: async function(body: t.StickyNote, options?: MethodOptions): Promise<t.StickyNote> {
		return privateApiHandler('stickynotes', options).post(null, body)
	},
}
export type StickyNoteParams = {|
	stickyNoteId: string,
|}
export type StickyNotePutParams = {| ...StickyNoteParams |}
export type StickyNoteDeleteParams = {| ...StickyNoteParams |}
export const stickyNote = {
	/**
	 * Update Sticky Note
	 */
	put: async function(params: StickyNotePutParams, body: t.StickyNote, options?: MethodOptions): Promise<t.StickyNote> {
		return privateApiHandler('stickynotes/{stickyNoteId}', options).put(params, body)
	},
	/**
	 * Delete Sticky Note
	 */
	delete: async function(params: StickyNoteDeleteParams, options?: MethodOptions): Promise<void> {
		return privateApiHandler('stickynotes/{stickyNoteId}', options).delete(params, null)
	},
}
export type FeatureFlagsGetParams = {||}
export const featureFlags = {
	/**
	 * Get feature flags
	 */
	get: async function(params: FeatureFlagsGetParams, options?: MethodOptions): Promise<t.FeatureFlagsResponse> {
		return handler('system/features/flags', options).get(params, null)
	},
}
export type UploadsParams = {|
	organizationId?: string,
|}
export type UploadsPostParams = {| ...UploadsParams |}
export type UploadsPostResponse = {|
	id?: string,
|}

export type UploadsPostResponseTemp = any
export const uploads = {
	/**
	 * (TP-231) Uploads a new file
	 */
	post: async function(params: UploadsPostParams, body: {}, options?: MethodOptions): Promise<UploadsPostResponse> {
		return handler('{organizationId}/uploads', options, { 'Content-Type': 'multipart/form-data' }).post(params, body)
	},
}
export type UploadParams = {|
	organizationId?: string,
	fileId: string,
|}
export type UploadGetParams = {| ...UploadParams |}
export type UploadGetResponse = any

export type UploadGetResponseTemp = any
export const upload = {
	/**
	 * (TP-232) Gets a file
	 */
	get: async function(params: UploadGetParams, options?: MethodOptions): Promise<UploadGetResponse> {
		return handler('{organizationId}/uploads/{fileId}', options).get(params, null)
	},
}
export type InternalUploadsPostParams = {||}
export type InternalUploadsPostResponse = {|
	id?: string,
|}

export type InternalUploadsPostResponseTemp = any
export const internalUploads = {
	/**
	 * (TP-564) Upload new internal file
	 */
	post: async function(body: {}, options?: MethodOptions): Promise<InternalUploadsPostResponse> {
		return privateApiHandler('uploads', options, { 'Content-Type': 'multipart/form-data' }).post(null, body)
	},
}
export type DownloadRequestParams = {|
	fileId: string,
|}
export type DownloadRequestPostParams = {| ...DownloadRequestParams |}
export type DownloadRequestPostResponse = {|
	token?: string,
|}

export type DownloadRequestPostResponseTemp = any
export const downloadRequest = {
	/**
	 * (TP-588*) Create file download request
	 */
	post: async function(
		params: DownloadRequestPostParams,
		options?: MethodOptions,
	): Promise<DownloadRequestPostResponse> {
		return privateApiHandler('uploads/{fileId}/downloadrequest', options).post(params, null)
	},
}
export type MeGetParams = {||}
export type MePatchParams = {||}
export const me = {
	/**
	 * (TP-290) Gets the current user profile
	 */
	get: async function(params: MeGetParams, options?: MethodOptions): Promise<t.UserProfile> {
		return privateApiHandler('users/me', options).get(params, null)
	},
	/**
	 * (TP-941 *) Updates the current user profile
	 */
	patch: async function(body: t.UserProfile, options?: MethodOptions): Promise<t.UserProfile> {
		return privateApiHandler('users/me', options).patch(null, body)
	},
}
export type FavoritesSectionParams = {|
	section: string,
|}
export type FavoritesSectionGetParams = {| ...FavoritesSectionParams |}
export type FavoritesSectionGetResponse = Array<string>

export type FavoritesSectionGetResponseTemp = any
export const favoritesSection = {
	/**
	 * (TP-451) Gets a list of keys in the given section
	 */
	get: async function(
		params: FavoritesSectionGetParams,
		options?: MethodOptions,
	): Promise<FavoritesSectionGetResponse> {
		return privateApiHandler('users/me/favorites/{section}', options).get(params, null)
	},
}
export type FavoritesParams = {|
	section: string,
	key: string,
|}
export type FavoritesPutParams = {| ...FavoritesParams |}
export type FavoritesDeleteParams = {| ...FavoritesParams |}
export const favorites = {
	/**
	 * (TP-452) Creates or updates a favorite item
	 */
	put: async function(params: FavoritesPutParams, options?: MethodOptions): Promise<void> {
		return privateApiHandler('users/me/favorites/{section}/{key}', options).put(params, null)
	},
	/**
	 * (TP-453) Removes a section by its section and key
	 */
	delete: async function(params: FavoritesDeleteParams, options?: MethodOptions): Promise<void> {
		return privateApiHandler('users/me/favorites/{section}/{key}', options).delete(params, null)
	},
}
export type OrganizationsGetParams = {||}
export type OrganizationsGetResponse = Array<t.OrganizationMember>

export type OrganizationsGetResponseTemp = any
export type OrganizationsPostParams = {||}
export const organizations = {
	/**
	 * (TP-340) Gets a list of my organizations
	 */
	get: async function(params: OrganizationsGetParams, options?: MethodOptions): Promise<OrganizationsGetResponse> {
		return privateApiHandler('users/me/organizations', options).get(params, null)
	},
	/**
	 * (TP-933 *) Creates a new organization
	 */
	post: async function(body: t.OrganizationCreateRequest, options?: MethodOptions): Promise<t.Organization> {
		return privateApiHandler('users/me/organizations', options).post(null, body)
	},
}
export type UsersParams = {|
	organizationId?: string,
|}
export type UsersGetParams = {| ...UsersParams |}
export type UsersGetResponse = Array<t.UserProfile>

export type UsersGetResponseTemp = any
export const users = {
	/**
	 * (TP-563) Gets a list of users
	 */
	get: async function(params: UsersGetParams, options?: MethodOptions): Promise<UsersGetResponse> {
		return handler('{organizationId}/users', options).get(params, null)
	},
}
export type InternalUsersGetParams = {||}
export type InternalUsersGetResponse = Array<t.InternalUser>

export type InternalUsersGetResponseTemp = any
export type InternalUsersPostParams = {||}
export const internalUsers = {
	/**
	 * (TP-562*) List internal users
	 */
	get: async function(params: InternalUsersGetParams, options?: MethodOptions): Promise<InternalUsersGetResponse> {
		return privateApiHandler('internalusers', options).get(params, null)
	},
	/**
	 * (TP-613*) Create internal user
	 */
	post: async function(body: t.InternalUser, options?: MethodOptions): Promise<t.InternalUser> {
		return privateApiHandler('internalusers', options).post(null, body)
	},
}
export type InternalUserParams = {|
	userId: string,
|}
export type InternalUserPutParams = {| ...InternalUserParams |}
export type InternalUserDeleteParams = {| ...InternalUserParams |}
export const internalUser = {
	/**
	 * (TP-615) Update internal user
	 */
	put: async function(
		params: InternalUserPutParams,
		body: t.InternalUser,
		options?: MethodOptions,
	): Promise<t.InternalUser> {
		return privateApiHandler('internalusers/{userId}', options).put(params, body)
	},
	/**
	 * (TP-614) Remove internal user
	 */
	delete: async function(params: InternalUserDeleteParams, options?: MethodOptions): Promise<void> {
		return privateApiHandler('internalusers/{userId}', options).delete(params, null)
	},
}
export type ResetPasswordPostParams = {||}
export type ResetPasswordPostResponse = {|
	email?: string,
|}

export type ResetPasswordPostResponseTemp = any
export type ResetPasswordPutParams = {||}
export type ResetPasswordPutResponse = any

export type ResetPasswordPutResponseTemp = any
export const resetPassword = {
	/**
	 * (TP-573) Create password reset request
	 */
	post: async function(body: any, options?: MethodOptions): Promise<ResetPasswordPostResponse> {
		return privateApiHandler('users/resetpassword', options).post(null, body)
	},
	/**
	 * (TP-574) Update password on previous reset password request
	 */
	put: async function(body: any, options?: MethodOptions): Promise<ResetPasswordPutResponse> {
		return privateApiHandler('users/resetpassword', options).put(null, body)
	},
}
export type UserInvitationsParams = {|
	organizationId?: string,
|}
export type UserInvitationsGetParams = {| ...UserInvitationsParams |}
export type UserInvitationsGetResponse = Array<t.Invitation>

export type UserInvitationsGetResponseTemp = any
export type UserInvitationsPostParams = {| ...UserInvitationsParams |}
export const userInvitations = {
	/**
	 * (TP-1702*) Get invitations to application/organization
	 */
	get: async function(params: UserInvitationsGetParams, options?: MethodOptions): Promise<UserInvitationsGetResponse> {
		return handler('{organizationId}/users/invitations', options).get(params, null)
	},
	/**
	 * (TP-757*) Send invitation to application
	 */
	post: async function(
		params: UserInvitationsPostParams,
		body: t.InvitationRequest,
		options?: MethodOptions,
	): Promise<void> {
		return handler('{organizationId}/users/invitations', options).post(params, body)
	},
}
export type InternalUserMembershipParams = {|
	organizationId?: number,
	userId: number,
|}
export type InternalUserMembershipPutParams = {| ...InternalUserMembershipParams |}
export type InternalUserMembershipDeleteParams = {| ...InternalUserMembershipParams |}
export const internalUserMembership = {
	/**
	 * (TP-788*) Create/update internal user organization membership
	 */
	put: async function(
		params: InternalUserMembershipPutParams,
		body: t.InternalMembership,
		options?: MethodOptions,
	): Promise<t.InternalMembership> {
		return handler('{organizationId}/internalusers/{userId}/membership', options).put(params, body)
	},
	/**
	 * (TP-789*) Unassign internal user from organization
	 */
	delete: async function(params: InternalUserMembershipDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('{organizationId}/internalusers/{userId}/membership', options).delete(params, null)
	},
}
export type SignUpPostParams = {||}
export const signUp = {
	/**
	 * (TP-932 *) Sign up user
	 */
	post: async function(body: t.SignUpRequest, options?: MethodOptions): Promise<void> {
		return privateApiHandler('users/signup', options).post(null, body)
	},
}
export type ProcessInvitationParams = {|
	token: string,
|}
export type ProcessInvitationPutParams = {| ...ProcessInvitationParams |}
export const processInvitation = {
	/**
	 * (TP-970 *) Process invitation
	 */
	put: async function(
		params: ProcessInvitationPutParams,
		body: t.InvitationProcessRequest,
		options?: MethodOptions,
	): Promise<t.InvitationProcessResponse> {
		return privateApiHandler('users/invitations/{token}', options).put(params, body)
	},
}
export type InternalUsersSignupPostParams = {||}
export type InternalUsersSignupPostResponse = any

export type InternalUsersSignupPostResponseTemp = any
export const internalUsersSignup = {
	/**
	 * (TP-934 *) Sign up internal user
	 */
	post: async function(body: t.SignUpRequest, options?: MethodOptions): Promise<InternalUsersSignupPostResponse> {
		return privateApiHandler('internalusers/signup', options).post(null, body)
	},
}
export type MyAvatarGetParams = {|
	width?: number,
	height?: number,
|}
export type MyAvatarGetResponse = any

export type MyAvatarGetResponseTemp = any
export type MyAvatarPutParams = {||}
export type MyAvatarDeleteParams = {||}
export const myAvatar = {
	/**
	 * (TP-942 *) Gets the current user avatar
	 */
	get: async function(params: MyAvatarGetParams, options?: MethodOptions): Promise<MyAvatarGetResponse> {
		return privateApiHandler('users/me/avatar', options).get(params, null)
	},
	/**
	 * (TP-942 *) Sets the current user avatar
	 */
	put: async function(body: {}, options?: MethodOptions): Promise<void> {
		return privateApiHandler('users/me/avatar', options, { 'Content-Type': 'multipart/form-data' }).put(null, body)
	},
	/**
	 * (TP-942 *) Deletes the current user avatar
	 */
	delete: async function(options?: MethodOptions): Promise<void> {
		return privateApiHandler('users/me/avatar', options).delete(null, null)
	},
}
export type MyAvatarOriginalGetParams = {|
	width?: number,
	height?: number,
|}
export type MyAvatarOriginalGetResponse = any

export type MyAvatarOriginalGetResponseTemp = any
export const myAvatarOriginal = {
	/**
	 * (TP-1009 *) Gets the current user avatar in original form (before cropping)
	 */
	get: async function(
		params: MyAvatarOriginalGetParams,
		options?: MethodOptions,
	): Promise<MyAvatarOriginalGetResponse> {
		return privateApiHandler('users/me/avatar/original', options).get(params, null)
	},
}
export type MyAvatarCropPutParams = {||}
export const myAvatarCrop = {
	/**
	 * (TP-942 *) Crops original of the current user avatar
	 */
	put: async function(body: t.CropRequest, options?: MethodOptions): Promise<void> {
		return privateApiHandler('users/me/avatar/crop', options).put(null, body)
	},
}
export type UserPasswordPostParams = {||}
export const userPassword = {
	/**
	 * (TP-945 *) Sets the current user password
	 */
	post: async function(body: t.SetPasswordRequest, options?: MethodOptions): Promise<void> {
		return privateApiHandler('users/me/password', options).post(null, body)
	},
}
export type TermsGetParams = {||}
export type TermsPutParams = {||}
export const terms = {
	/**
	 * Get Terms waiting for Agreement
	 */
	get: async function(params: TermsGetParams, options?: MethodOptions): Promise<t.TermsItemResponse> {
		return privateApiHandler('users/me/terms/agree', options).get(params, null)
	},
	/**
	 * Agree with Terms and Conditions
	 */
	put: async function(body: t.TermsAgreeRequest, options?: MethodOptions): Promise<void> {
		return privateApiHandler('users/me/terms/agree', options).put(null, body)
	},
}
export type UserInvitationsByUserParams = {|
	organizationId?: string,
	userId: string,
|}
export type UserInvitationsByUserDeleteParams = {| ...UserInvitationsByUserParams |}
export const userInvitationsByUser = {
	/**
	 * Delete invitations by user
	 */
	delete: async function(params: UserInvitationsByUserDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('{organizationId}/users/invitations/users/{userId}', options).delete(params, null)
	},
}
export type UserInvitationsByEmailParams = {|
	organizationId?: string,
	email: string,
|}
export type UserInvitationsByEmailDeleteParams = {| ...UserInvitationsByEmailParams |}
export const userInvitationsByEmail = {
	/**
	 * Delete invitations by email
	 */
	delete: async function(params: UserInvitationsByEmailDeleteParams, options?: MethodOptions): Promise<void> {
		return handler('{organizationId}/users/invitations/emails/{email}', options).delete(params, null)
	},
}

export const accountingDocumentLineItemsSearch = {
	post: async function(
		body: t.AccountingDocumentLineItemsSearchRequest,
		options?: MethodOptions,
	): Promise<t.AccountingDocumentLineItemsSearchResult> {
		return handler('search/accountingdocuments/lineitems', options).post(null, body)
	},
}
