/* @flow */

import { connect } from 'react-redux'
import type { AccountingDocument, AccountingDocumentPrintingInfo, State } from 'types'
import { getAccountingDocument } from 'modules/accounting-document/selectors'
import { updateAccountingDocumentPrinting } from 'modules/accounting-document/actions'
import PrintingNote from 'modules/accounting-document/components/invoice-elements/printing-note'

type OwnProps = {|
	accountingDocumentId: string,
	readonly?: boolean,
|}

type StateProps = {|
	printing: ?AccountingDocumentPrintingInfo,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const doc: ?AccountingDocument = getAccountingDocument(state, ownProps.accountingDocumentId)

	return {
		printing: doc && doc.printing,
	}
}

type DispatchProps = {|
	changePrinting: (printing: ?AccountingDocumentPrintingInfo) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<>, ownProps: OwnProps): DispatchProps => {
	return {
		changePrinting: (printing: ?AccountingDocumentPrintingInfo) => {
			dispatch(updateAccountingDocumentPrinting(ownProps.accountingDocumentId, printing))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintingNote)
