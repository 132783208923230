/* @flow */
/** @jsx jsx */

import { Component, createRef } from 'react'
import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { type AutoTestProps } from 'utils/tests/autotest'
import type { FormFieldErrorMessage, FormFieldErrorContext } from 'types'
import { formatToMoney } from 'utils/formatters'
import NumberInput, { type Props as CurrencyInputProps } from 'components/number-input'
import { getTooltipErrorLabel } from 'helpers'

export type Props = {|
	...CurrencyInputProps,
	...AutoTestProps,
	...WithTranslateProps,
	symbol?: ?string,
	errors?: ?Array<FormFieldErrorMessage>,
	formFieldErrorContext?: FormFieldErrorContext,
	removeError?: () => void,
|}

class CurrencyInput extends Component<Props> {
	inputRef = createRef()

	static defaultProps = {
		precision: 2,
		symbol: null,
	}

	focus() {
		this.inputRef.current && this.inputRef.current.getWrappedInstance().focus()
		this.props.removeError && this.props.removeError()
	}

	formatFn = (value: ?number) =>
		formatToMoney(value, {
			currency: this.props.symbol,
			minimumFractionDigits: this.props.precision,
		})

	render() {
		const { symbol, errors, removeError, t, formFieldErrorContext, ...props } = this.props

		return (
			<NumberInput
				{...props}
				clientError={getTooltipErrorLabel(errors, t)}
				formatFn={this.formatFn}
				ref={this.inputRef}
			/>
		)
	}
}

export default withTranslate(CurrencyInput)
