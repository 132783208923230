/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import type { AccountingDocumentContact } from 'types'
import { autoTestId, type AutoTestProps } from 'utils/tests/autotest'
import { withTranslate, type WithTranslateProps } from 'wrappers'

export type Props = {|
	contact: ?AccountingDocumentContact,
	countryName: ?string,
	isMe?: boolean,
	showLoader?: boolean,
	public?: boolean,
	...AutoTestProps,
|}

type TextFieldName =
	| 'firstname'
	| 'lastname'
	| 'companyRegNo'
	| 'companyName'
	| 'taxId'
	| 'street'
	| 'email'
	| 'zipCode'
	| 'city'
	| 'country'

type ComponentProps = {|
	...Props,
	...WithTranslateProps,
|}

class ContactDetail extends Component<ComponentProps> {
	renderField(field: TextFieldName) {
		const value = this.props.contact && this.props.contact[field]
		return value ? (
			<span css={styles.field} {...autoTestId(field)}>
				{value}
			</span>
		) : null
	}

	renderLeftTextFields() {
		const { contact, t, isMe } = this.props
		const showName = contact && contact.firstname
		const showLastname = contact && contact.lastname
		const showCountry = !isMe || (isMe && contact && contact.country)
		const showEmail = isMe && contact && contact.email

		return (
			<div style={isMe ? styles.fields : styles.column}>
				<div css={styles.fieldsInner}>
					{this.renderField('companyName')}
					{showName || showLastname ? <br /> : null}
					{showName && this.renderField('firstname')}
					{showLastname && this.renderField('lastname')}
					<br />
					{this.renderField('street')}
					<br />
					{this.renderField('zipCode')}
					{this.renderField('city')}
					<br />
					{showCountry && (
						<span css={styles.field} {...autoTestId('countryName')}>
							{this.props.countryName}
						</span>
					)}
					<br />
					{isMe && (
						<div>
							<br />
							{this.props.contact && this.props.contact.companyRegNo && (
								<div css={styles.field}>{t('invoice.contact.companyRegNoLabel')}:</div>
							)}
							{this.renderField('companyRegNo')}
							<br />
							{this.props.contact && this.props.contact.taxId && (
								<div css={styles.field}>{t('invoice.contact.taxIdLabel')}:</div>
							)}
							{this.renderField('taxId')}
						</div>
					)}
					{showEmail && <div css={styles.addons}>{this.renderField('email')}</div>}
				</div>
			</div>
		)
	}

	renderRightTextFields() {
		const { t } = this.props
		return (
			<div css={styles.column}>
				{this.props.contact && this.props.contact.companyRegNo && (
					<div css={styles.field}>{t('invoice.contact.companyRegNoLabel')}:</div>
				)}
				{this.renderField('companyRegNo')}
				<br />
				{this.props.contact && this.props.contact.taxId && (
					<div css={styles.field}>{t('invoice.contact.taxIdLabel')}:</div>
				)}
				{this.renderField('taxId')}
				<br />
				<div>{this.renderField('email')}</div>
			</div>
		)
	}

	renderLoader() {
		if (!this.props.contact) {
			return (
				<div css={styles.placeholders}>
					<div>
						<div css={styles.placeholder} style={{ width: '11%' }} />
						<div css={styles.placeholder} style={{ width: '20%' }} />
					</div>
					<div>
						<div css={styles.placeholder} style={{ width: '13%' }} />
					</div>
					<div css={styles.placeholder} style={{ width: '10%' }} />
				</div>
			)
		}
	}

	render() {
		if (!this.props.contact) return this.props.showLoader ? this.renderLoader() : null

		return (
			<div css={styles.detail}>
				{this.renderLeftTextFields()}
				{!this.props.isMe && this.renderRightTextFields()}
			</div>
		)
	}
}

const styles = {
	fields: {
		padding: '0 15px 0 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignContent: 'center',
		alignItems: 'center',
		marginLeft: -15,
		marginRight: -15,
	},
	fieldsInner: {
		flex: '1 0 50%',
	},
	field: {
		display: 'inline-block',
		marginRight: 3,
		overflow: 'hidden',
		textOverflow: 'hidden',
		wordBreak: 'break-all',
	},
	detail: {
		display: 'flex',
	},
	column: {
		flex: '0 0 50%',
		padding: '0 15px',
	},
	addons: {
		marginTop: 10,
	},
	placeholder: {
		backgroundColor: '#CFCFCF',
		display: 'inline-block',
		margin: '1px 2px 1px 0',
		width: '30%',
		height: 8,
	},
	placeholders: {
		padding: '0 15px',
	},
}

export default withTranslate(ContactDetail)
