/* @flow */

import { getAppLocale, i18n } from 'locales'
import moment from 'moment'

export const formatToDateString = (date: string | Date, locales: string = getAppLocale()): string => {
	if (date == null) return ''
	if (typeof date === 'string') date = new Date(Date.parse(date))

	let result = ''

	if (date.toLocaleDateString) {
		result = date.toLocaleDateString(locales)
	} else {
		result = date.getUTCFullYear() + '-' + pad(date.getUTCMonth() + 1) + '-' + pad(date.getUTCDate())
	}

	return result.replace('Invalid Date', '')
}

export const formatToTimeString = (date: string | Date, locales: string = getAppLocale(), options?: {}): string => {
	if (date == null) return ''
	if (typeof date === 'string') date = new Date(Date.parse(date))

	let result = ''

	if (date.toLocaleTimeString) {
		// $FlowFixMe
		result = date.toLocaleTimeString(locales, options)
	} else {
		result = date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
	}

	return result.replace('Invalid Date', '')
}

export const formatToDateTimeString = (date: string | Date, locales: string = getAppLocale()): string => {
	return `${formatToDateString(date, locales)} ${formatToTimeString(date, locales)}`.trim()
}

export function dateIntervalToString(date: Date, referenceDate: Date = new Date()) {
	if (isNaN(date.getTime())) {
		return ''
	}

	const normDate = new Date(date.getFullYear(), date.getMonth(), date.getDate())
	const normReferenceDate = new Date(referenceDate.getFullYear(), referenceDate.getMonth(), referenceDate.getDate())

	const dayDiff = Math.round((normDate - normReferenceDate) / 86400000)

	if (dayDiff <= -28) {
		return i18n.t('utils.dateInterval.monthsAgo', { count: -Math.round(dayDiff / 30) })
	} else if (dayDiff <= -14) {
		return i18n.t('utils.dateInterval.weeksAgo', { count: -Math.round(dayDiff / 7) })
	} else if (dayDiff < 0) {
		return i18n.t('utils.dateInterval.daysAgo', { count: -dayDiff })
	} else if (dayDiff == 0) {
		return i18n.t('utils.dateInterval.today')
	} else if (dayDiff < 14) {
		return i18n.t('utils.dateInterval.inDays', { count: dayDiff })
	} else if (dayDiff < 28) {
		return i18n.t('utils.dateInterval.inWeeks', { count: Math.round(dayDiff / 7) })
	} else {
		return i18n.t('utils.dateInterval.inMonths', { count: Math.round(dayDiff / 30) })
	}
}

export const formatToIsoDateString = (date: Date): string => {
	if (date && !isNaN(date)) {
		return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate())
	}
	throw new Error('Invalid date')
}

// format date to date-time format by ISO8601
export const formatToIsoDateTimeString = (date: Date): string => {
	if (date && !isNaN(date)) {
		return toIsoString(date)
	}
	throw new Error('Invalid date')
}

// format date to full-date format by ISO8601
export const isADate = (date: ?Date): boolean => {
	return !!date && !isNaN(date)
}

// format date to full-date format by ISO8601
export const formatDateToIsoString = (date: Date): string => {
	return toIsoDateString(date)
}

export const convertToDate = (dateString: string): Date => {
	return new Date(Date.parse(dateString))
}

export const getTodayIsoDateString = (): string => {
	return toIsoDateString(new Date())
}

export const getTomorrowIsoDateString = (): string => {
	return toIsoDateString(
		moment()
			.add(1, 'day')
			.toDate(),
	)
}

function toIsoString(date: Date): string {
	return (
		date.getUTCFullYear() +
		'-' +
		pad(date.getUTCMonth() + 1) +
		'-' +
		pad(date.getUTCDate()) +
		'T' +
		pad(date.getUTCHours()) +
		':' +
		pad(date.getUTCMinutes()) +
		':' +
		pad(date.getUTCSeconds()) +
		'.' +
		(date.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5) +
		'Z'
	)
}

export function toDateOnlyIsoString(date: Date): string {
	return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate()) + 'T00:00:00'
}

function toIsoDateString(date: Date) {
	let result: string = date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate())
	return result
}

function pad(number: number) {
	if (number < 10) {
		return '0' + number
	}
	return number
}

const months = [
	'january',
	'february',
	'march',
	'april',
	'may',
	'june',
	'july',
	'august',
	'september',
	'october',
	'november',
	'december',
]
export function getMonthName(monthNo: number, zeroIndexed: boolean = true, translate: boolean = false): string {
	const name = months[monthNo + (zeroIndexed ? 0 : -1)]
	return translate ? i18n.t(`application.months.${name}`) : name
}

const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
export function getDayName(date: Date): string {
	return i18n.t(`application.days.${days[date.getDay()]}`)
}

export function getQuarterName(startMonth: number, zeroIndexed: boolean = true, translate: boolean = false): string {
	const monthNo = startMonth + (zeroIndexed ? 1 : 0)
	let quarter

	if (monthNo <= 3) {
		quarter = 1
	} else if (monthNo <= 6) {
		quarter = 2
	} else if (monthNo <= 9) {
		quarter = 3
	} else {
		quarter = 4
	}

	const name = `Q${quarter}`

	return translate ? i18n.t(`application.quarter.${name}`) : name
}

export function getRangeName(dateFrom: Date, dateTo: Date, translate: boolean = false): string {
	if (isMonthRange(dateFrom, dateTo)) {
		return getMonthName(dateFrom.getMonth(), true, translate)
	} else if (isYearRange(dateFrom, dateTo)) {
		return dateFrom.getFullYear().toString()
	} else if (isQuarterRange(dateFrom, dateTo)) {
		return getQuarterName(dateFrom.getMonth(), true, translate)
	}

	return `${dateFrom.toDateString()} - ${dateTo.toDateString()}`
}

export function isMonthRange(dateFrom: Date, dateTo: Date): boolean {
	return (
		dateFrom.getMonth() === dateTo.getMonth() &&
		1 === dateFrom.getDate() &&
		-1 !== [28, 29, 30, 31].indexOf(dateTo.getDate())
	)
}

export function isYearRange(dateFrom: Date, dateTo: Date): boolean {
	return 0 === dateFrom.getMonth() && 1 === dateFrom.getDate() && 11 === dateTo.getMonth() && 31 === dateTo.getDate()
}

export function isQuarterRange(dateFrom: Date, dateTo: Date): boolean {
	return (
		-1 !== [0, 3, 6, 9].indexOf(dateFrom.getMonth()) &&
		1 === dateFrom.getDate() &&
		dateFrom.getMonth() + 2 === dateTo.getMonth() &&
		-1 !== [28, 29, 30, 31].indexOf(dateTo.getDate())
	)
}

export type Interval = {|
	interval: 'year' | 'month' | 'quarter',
	count: number,
	shift: number,
	initialOffset?: 0 | 1,
|}

export function intervalToDates(interval: Interval, current: Date = new Date()): [Date, Date] {
	const currentMoment = moment(current).utc()
	const dateFrom = currentMoment
		.clone()
		.startOf(interval.interval)
		.add(interval.initialOffset || 0, interval.interval)
		.add(interval.count * interval.shift, interval.interval)
	const dateTo = dateFrom
		.clone()
		.add(interval.count - 1, interval.interval)
		.endOf(interval.interval)
	return [dateFrom.toDate(), dateTo.toDate()]
}

export function getIntervalName(interval: Interval, current: Date = new Date()) {
	const [dateFrom, dateTo] = intervalToDates(interval, current)
	const params = {}
	let name = ''

	if ('month' === interval.interval) {
		if (1 === interval.count && -1 === interval.shift) {
			name = 'month.prev'
		} else if (1 === interval.count && 0 === interval.shift) {
			name = 'month.current'
		} else if (1 === interval.count && 1 === interval.shift) {
			name = 'month.next'
		} else if (1 !== interval.count && -1 === interval.shift) {
			name = 'month.prevRange_interval'
			params.count = interval.count
		} else if (1 !== interval.count && 1 === interval.shift) {
			name = 'month.nextRange_interval'
			params.count = interval.count
		} else if (1 === interval.count) {
			name = 'month.exact'
			params.month = dateFrom.getMonth() + 1
			params.year = dateFrom.getFullYear()
		} else {
			name = 'month.range'
			params.monthFrom = dateFrom.getMonth() + 1
			params.yearFrom = dateFrom.getFullYear()
			params.monthTo = dateTo.getMonth() + 1
			params.yearTo = dateTo.getFullYear()
		}
	} else if ('year' === interval.interval) {
		if (1 === interval.count && -1 === interval.shift) {
			name = 'year.prev'
		} else if (1 === interval.count && 0 === interval.shift) {
			name = 'year.current'
		} else if (1 === interval.count && 1 === interval.shift) {
			name = 'year.next'
		} else if (1 !== interval.count && -1 === interval.shift) {
			name = 'year.prevRange_interval'
			params.count = interval.count
		} else if (1 !== interval.count && 1 === interval.shift) {
			name = 'year.nextRange_interval'
			params.count = interval.count
		} else if (1 === interval.count) {
			name = 'year.exact'
			params.year = dateFrom.getFullYear()
		} else {
			name = 'year.range'
			params.yearFrom = dateFrom.getFullYear()
			params.yearTo = dateTo.getFullYear()
		}
	} else if ('quarter' === interval.interval) {
		if (1 === interval.count && -1 === interval.shift) {
			name = 'quarter.prev'
		} else if (1 === interval.count && 0 === interval.shift) {
			name = 'quarter.current'
		} else if (1 === interval.count && 1 === interval.shift) {
			name = 'quarter.next'
		} else if (1 !== interval.count && -1 === interval.shift) {
			name = 'quarter.prevRange_interval'
			params.count = interval.count
		} else if (1 !== interval.count && 1 === interval.shift) {
			name = 'quarter.nextRange_interval'
			params.count = interval.count
		} else if (1 === interval.count) {
			name = 'quarter.exact'
			params.quarter = getQuarterFromDate(dateFrom)
			params.year = dateFrom.getFullYear()
		} else {
			name = 'quarter.range'
			params.yearFrom = dateFrom.getFullYear()
			params.yearTo = dateTo.getFullYear()
			params.quarterFrom = getQuarterFromDate(dateFrom)
			params.quarterTo = getQuarterFromDate(dateTo)
		}
	} else {
		name = 'range'
		params.dateFrom = dateFrom
		params.dateTo = dateTo
	}

	return { name, params }
}

export function getQuarterFromDate(date: Date): number {
	return Math.floor((date.getUTCMonth() + 3) / 3)
}

export function addOneDayToDate(date: string | Date): Date {
	return moment(date)
		.add(1, 'd')
		.toDate()
}

export function substractOneDayFromDate(date: string | Date): Date {
	return moment(date)
		.subtract(1, 'd')
		.toDate()
}
