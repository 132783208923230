/* @flow */
/** @jsx jsx */

// $FlowFixMe - there is useMemo
import { type Node, useMemo } from 'react'
import { jsx } from '@emotion/core'
import type { VatRate } from 'types'
import SelectNext, { type ValueType } from 'components/select-next'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { OptionsType } from 'components/select-next'
import { type AutoTestProps } from 'utils/tests/autotest'
import { findVatRateById } from '../../domain/vat-rate'
import { EMPTY_ARRAY } from 'trivi-constants'

type Props = {|
	...AutoTestProps,
	...WithTranslateProps,
	value: string,
	error?: ?Node,
	disabled?: boolean,
	tabIndex?: number,
	vatRates: null | Array<VatRate>,
	onChange: (vatRate: VatRate | null) => void,
|}

function VatRateSelect(props: Props) {
	const found = findVatRateById(props.vatRates, props.value)
	const value = found ? found.id || null : null

	const options = useMemo((): OptionsType => {
		return props.vatRates
			? props.vatRates.map((item: VatRate) => {
					return { value: item.id, label: item.text }
			  })
			: EMPTY_ARRAY
	}, [props.vatRates])

	function onChange(value: ValueType) {
		const newVatRateId = value && !Array.isArray(value) ? value.value : null
		const found = findVatRateById(props.vatRates, newVatRateId)
		props.onChange(found || null)
	}

	return (
		<SelectNext
			tabIndex={props.tabIndex}
			disabled={props.disabled}
			autoTestId={props.autoTestId}
			placeholder={props.t('invoice.lineItems.vat')}
			options={options}
			error={props.error}
			onChange={onChange}
			value={value}
			hideIndicator
			isSearchable
			autoWidth
			inline
		/>
	)
}

export default withTranslate(VatRateSelect)
