// @flow

import type {
	AccountingDocumentType,
	AccountingDocumentAssignedType,
	AccountingDocumentAssignedDirection,
	AccountingDocumentFormFieldErrorSubPath,
} from 'types'

export const ACCOUNTING_DOCUMENT_TITLES = {
	issued: {
		invoice: 'accountingDocument.typesLong.invoice',
		advance: 'accountingDocument.typesLong.advance',
		tax_advance: 'accountingDocument.typesLong.taxAdvance',
		credit_note: 'accountingDocument.typesLong.creditNote',
		simplified_invoice: 'accountingDocument.typesLong.simplifiedInvoice',
		cash_receipt: 'accountingDocument.typesLong.cashReceipt_issued',
		cash_transfer: 'accountingDocument.typesLong.cashTransfer_issued',
		cash_uncategorized: 'accountingDocument.typesLong.cashUncategorized',
		employee_advance: '',
		unknown: '',
	},
	received: {
		invoice: 'accountingDocument.typesLong.invoice',
		advance: 'accountingDocument.typesLong.advance',
		tax_advance: 'accountingDocument.typesLong.taxAdvance',
		credit_note: 'accountingDocument.typesLong.creditNote',
		simplified_invoice: 'accountingDocument.typesLong.simplifiedInvoice',
		cash_receipt: 'accountingDocument.typesLong.cashReceipt_received',
		cash_transfer: 'accountingDocument.typesLong.cashTransfer_received',
		cash_uncategorized: 'accountingDocument.typesLong.cashUncategorized',
		employee_advance: 'accountingDocument.typesLong.employeeAdvance',
		unknown: '',
	},
}

export const ACCOUNTING_DOCUMENT_TITLES_NO = {
	issued: {
		invoice: 'accountingDocument.typesLongWithNo.invoice',
		advance: 'accountingDocument.typesLongWithNo.advance',
		tax_advance: 'accountingDocument.typesLongWithNo.taxAdvance',
		credit_note: 'accountingDocument.typesLongWithNo.creditNote',
		simplified_invoice: 'accountingDocument.typesLongWithNo.simplifiedInvoice',
		cash_receipt: 'accountingDocument.typesLongWithNo.cashReceipt_issued',
		cash_transfer: 'accountingDocument.typesLongWithNo.cashTransfer_issued',
		cash_uncategorized: 'accountingDocument.typesLongWithNo.cashUncategorized',
		employee_advance: '',
		unknown: '',
	},
	received: {
		invoice: 'accountingDocument.typesLongWithNo.invoice',
		advance: 'accountingDocument.typesLongWithNo.advance',
		tax_advance: 'accountingDocument.typesLongWithNo.taxAdvance',
		credit_note: 'accountingDocument.typesLongWithNo.creditNote',
		simplified_invoice: 'accountingDocument.typesLongWithNo.simplifiedInvoice',
		cash_receipt: 'accountingDocument.typesLongWithNo.cashReceipt_received',
		cash_transfer: 'accountingDocument.typesLongWithNo.cashTransfer_received',
		cash_uncategorized: 'accountingDocument.typesLongWithNo.cashUncategorized',
		employee_advance: 'accountingDocument.typesLongWithNo.employeeAdvance',
		unknown: '',
	},
}

export const NON_CASH_REGISTER_ACCOUNTING_DOCUMENT_TYPES: Array<AccountingDocumentAssignedType> = [
	'invoice',
	'advance',
	'tax_advance',
	'credit_note',
]

export const CASH_REGISTER_ACCOUNTING_DOCUMENT_TYPES: Array<AccountingDocumentAssignedType> = [
	'simplified_invoice',
	'employee_advance',
	'cash_transfer',
	'cash_uncategorized',
	'cash_receipt',
]

export const ADVANCED_CASH_REGISTER_ACCOUNTING_DOCUMENTS: Array<AccountingDocumentAssignedType> = [
	'employee_advance',
	'cash_transfer',
	'cash_uncategorized',
	'cash_receipt',
]

export const ACCOUNTING_DOCUMENT_ASSIGNED_DIRECTION_TYPES: Array<AccountingDocumentAssignedDirection> = [
	'received',
	'issued',
]

export const AVAILABLE_PAYMENT_TYPES = [
	{ type: 1, name: 'accountingDocument.multiActions.paymentTypes.BankTransfer' },
	{ type: 2, name: 'accountingDocument.multiActions.paymentTypes.Cash' },
	{ type: 3, name: 'accountingDocument.multiActions.paymentTypes.CashOnDelivery' },
	{ type: 4, name: 'accountingDocument.multiActions.paymentTypes.PaymentCard' },
	{ type: 5, name: 'accountingDocument.multiActions.paymentTypes.SetOff' },
	{ type: 6, name: 'accountingDocument.multiActions.paymentTypes.Loan' },
]

export const VAT_RECAP_CALCULATION_MODE_FROM_VAT_EXCLUDED = 0
export const VAT_RECAP_CALCULATION_MODE_FROM_VAT_INCLUDED = 1
export const VAT_RECAP_CALCULATION_MODE_MANUAL = 2

export const VAT_PAYMENT_TYPE_PAYER = 1
export const VAT_PAYMENT_TYPE_FREE = 2

export const UPLOAD_TO_BE_APPROVED = 5
export const UPLOAD_NEED_HELP = 4
export const UPLOAD_APPROVED = 6
export const UPLOAD_REJECTED = 7
export const UPLOAD_LOCKED = 2

export const MAX_DOCUMENT_NUMBER_LENGTH = 20

export const EMPTY_ACCOUNTING_DOCUMENT_TOTALS = {
	total: null,
	totalVatExcl: null,
	roundingAmount: null,
	vatAmount: null,
	amountToPay: null,
}

export const REVERSE_CHARGE_CODES: Array<{ code: string, name: string }> = [
	{ code: '1', name: 'Dodání zlata (§92b)' },
	{ code: '4', name: 'Stavební nebo montážní práce (§92e)' },
	{ code: '5', name: 'Zboží uvedené v příloze č. 5 zákona (§92c)' },
	{ code: '11', name: 'Povolenky na emise (§92f)' },
	{ code: '12', name: 'Obiloviny a technické plodiny' },
	{ code: '13', name: 'Kovy' },
	{ code: '14', name: 'Mobilní telefony' },
	{ code: '15', name: 'Integrované obvody' },
	{ code: '16', name: 'Přenosná zařízení pro automatizované zpracování dat' },
	{ code: '17', name: 'Videoherní konzole' },
	{ code: '3', name: 'Dodání nemovité věci' },
	{ code: '18', name: 'Dodání certifikátu elektřiny' },
	{ code: '19', name: 'Dodání elektřiny soustavami nebo sítěmi obch.' },
	{ code: '20', name: 'Dodání plynu soustavami nebo sítěmi obch.' },
]

export const ACCDOC_ERROR_MAIN_PATHS: Array<AccountingDocumentFormFieldErrorSubPath> = [
	'bankAccounts',
	'contact',
	'creditNoteDescription',
	'headerNo',
	'lineItems',
	'paymentType',
	'upperSettings',
]

export const TEMPLATE_ERROR_MAIN_PATHS: Array<AccountingDocumentFormFieldErrorSubPath> = [
	'bankAccounts',
	'contact',
	'creditNoteDescription',
	'headerNo',
	'lineItems',
	'paymentType',
	'upperSettings',
	'name',
]

export const TEMPLATE_SCHEDULER_ERROR_MAIN_PATHS: Array<AccountingDocumentFormFieldErrorSubPath> = ['start']

// z BE moze napr.: prist path ['contact', ':id', 'country'], ale na FE zobrazujeme chybu pre cely komponent
// a preto pouzivame iba hlavny path ['contact']
export const ACCDOC_ERROR_MAIN_PATHS_ONLY: Array<AccountingDocumentFormFieldErrorSubPath> = ['contact']

// chyby z niektorych path nechceme zobrazit v policku formulara ale ako doteraz v snackbare
export const ACCDOC_ERROR_PATHS_TO_SNACKBAR: Array<AccountingDocumentFormFieldErrorSubPath> = [
	'vatCountryType',
	'total',
]

// z BE pridu pathy, ktore sa nedaju priamo namapovat na pathy vo FE (napr je komponent vnoreny v dalsom komponente)
// napr.: z BE pride iba jednoduchy path ['currency'], ale na FE pouzivame path zacinajucu s 'upperSettings'
export const ACCDOC_ERROR_PATHS_SPECIAL_MAPPING: {
	[AccountingDocumentFormFieldErrorSubPath]: Array<AccountingDocumentFormFieldErrorSubPath>,
} = {
	currency: ['upperSettings', 'currency'],
	exchRate: ['upperSettings', 'exchRate'],
	exchRateDefault: ['upperSettings', 'exchRate'],
	explicitNo: ['headerNo'],
	sequenceId: ['headerNo'],
}

export const ACCDOC_TAG_TYPE = {
	branch: 0, // Středisko
	project: 1, // Zakázka
}

export const ACCDOC_TAG_MAXLENGTH = {
	branch: 10, // Středisko
	project: 20, // Zakázka
}

export const ACC_DOC_FILTER_NAME = 'accountingDocument'

export const DEFAULT_LIST_VIEW = 'all'
export const STANDARD_LIST_VIEWS = ['errors', 'all', 'drafts']

export const OSS_MODES = [null, 'none', 'oss'] // 'ioss' is not implemented yet

export const ACC_DOC_TYPES: { [key: AccountingDocumentType]: number } = {
	invoice: 0,
	advance: 1,
	tax_advance: 2,
	credit_note: 3,
	cash_receipt: 7,
	simplified_invoice: 8,
	unknown: 9,
	employee_advance: 10,
	cash_transfer: 11,
	cash_uncategorized: 12,
}
