/* @flow */
/** @jsx jsx */

import { PureComponent } from 'react'
import { jsx } from '@emotion/core'
import { formatToMoney } from 'utils/formatters'

export type Props = {|
	currency: ?string,
	amount: ?number,
|}

class AmountDisplay extends PureComponent<Props> {
	render() {
		return (
			<span css={styles.root}>
				{formatToMoney(this.props.amount || 0, {
					currency: this.props.currency,
				})}
			</span>
		)
	}
}

const styles = {
	root: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
}

export default AmountDisplay
