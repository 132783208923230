/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import CircularProgress from 'components/circular-progress'

type Props = {
	isFocused: boolean,
}

export default function LoadingIndicator(props: Props) {
	return props.isFocused ? (
		<div css={style.root}>
			<CircularProgress size={14} />
		</div>
	) : null
}

const style = {
	root: {
		width: 20,
		position: 'absolute',
		right: -24,
		top: 0,
		bottom: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		pointerEvents: 'none',
	},
}
