/* @flow */

import deepEqual from 'deep-equal'
import type {
	AccountingDocument,
	AccountingDocumentAttachment,
	AccountingDocumentBankAccount,
	AccountingDocumentConnection,
	AccountingDocumentContact,
	AccountingDocumentHeader,
	AccountingDocumentIssues,
	AccountingDocumentLineItem,
	AccountingDocumentPaymentInfo,
	AccountingDocumentReduxAction,
	DataGridNextAction,
	AccountingDocumentVatRecapInfo,
	CountryVariantSpecific,
	IssueAction,
	PaymentReminder,
	ReduxStateUpdater,
	GreenboxSuggestionResponse,
	CountryVariantRequest,
	ActivityResponse,
	AccountingDocumentLanguage,
	AccountingDocumentExtractingState,
	AccountingDocumentNote,
	AccountingDocumentIntegrationsResponse,
	PublicAccountingDocumentPrintingSettings,
	FormFieldErrorsMap,
	Base64Image,
} from 'types'
import { union as arrayUnion, without as arrayWithout, isEqual } from 'lodash-es'
import {
	mergeAccountingDocumentHeaderToAccountingDocument,
	mergeAccountingDocumentToAccountingDocumentHeader,
	merge_AccountingDocumentVatRecapInfo_to_AccountingDocument,
} from 'types/operations'
import type { AccountingDocumentQrCodeGetResponse } from 'modules/common/models/api-model'
import { emptyAccountingDocument } from 'types/empty'
import { updateReduxState } from 'utils/redux'
import { EMPTY_ARRAY } from 'trivi-constants'

export type AccountingDocumentItem = {|
	loading: boolean,
	data: ?AccountingDocument,
	header: ?AccountingDocumentHeader,
	attachments: ?Array<AccountingDocumentAttachment>,
	attachmentsLoading: boolean,
	connections: ?Array<AccountingDocumentConnection>,
	connectionsLoading: boolean,
	calculatingLineItemIds: Array<string>,
	vatRecapInfoLoading: boolean,
	issues: ?AccountingDocumentIssues,
	storing: boolean,
	lineItemsProcessing: boolean,
	paymentReminders: ?Array<PaymentReminder>,
	qrCode: ?AccountingDocumentQrCodeGetResponse,
	pdfUrl: ?string,
	addingLineItem: boolean,
	possibleStates: ?Array<string>,
	greenboxSuggestion: ?GreenboxSuggestionResponse,
	cvsBeforeUpdate?: any,
	activities: {
		activities: Array<ActivityResponse>,
		loading: boolean,
	},
	errors?: ?FormFieldErrorsMap,
|}

export type PublicAccountingDocumentItem = {|
	id: string,
	qr?: Base64Image,
	logo?: Base64Image,
	printing?: ?PublicAccountingDocumentPrintingSettings,
|}

export type State = {
	items: {
		[accountingDocumentId: string]: AccountingDocumentItem,
	},
	publicItems: {
		[uniqueId: string]: PublicAccountingDocumentItem,
	},
	defaultLineItems: Array<AccountingDocumentLineItem> | null,
	lastCreatedDocument: ?AccountingDocument,
	exporting: boolean,
	sending: boolean,
	creating: boolean,
}

export const initialState: State = {
	items: {},
	publicItems: {},
	printing: null,
	lastCreatedDocument: null,
	exporting: false,
	sending: false,
	creating: false,
	defaultLineItems: null,
}

const getEmptyAccountingDocumentItem = (): AccountingDocumentItem => {
	return {
		loading: false,
		data: null,
		header: null,
		attachments: null,
		attachmentsLoading: false,
		connections: null,
		connectionsLoading: false,
		calculatingLineItemIds: EMPTY_ARRAY,
		vatRecapInfoLoading: false,
		issues: null,
		storing: false,
		lineItemsProcessing: false,
		paymentReminders: null,
		qrCode: null,
		pdfUrl: null,
		addingLineItem: false,
		possibleStates: null,
		greenboxSuggestion: null,
		activities: {
			activities: [],
			loading: false,
		},
	}
}

const setGreenboxSuggestion = (
	accDocId: string,
	greenboxSuggestion: ?GreenboxSuggestionResponse,
): ReduxStateUpdater<State> => {
	return (state: State): State => {
		const item: AccountingDocumentItem = state.items[accDocId] || getEmptyAccountingDocumentItem()
		if (isEqual(greenboxSuggestion, item.greenboxSuggestion)) return state

		const items = {
			...state.items,
			[accDocId]: { ...item, greenboxSuggestion },
		}
		return { ...state, items }
	}
}

const setAccountingDocumentLoading = (
	accountingDocumentId: string,
	loading: boolean,
	state: State,
	storing: boolean = false,
): State => {
	const id: string = accountingDocumentId
	const item: AccountingDocumentItem = state.items[id] || getEmptyAccountingDocumentItem()
	const items = { ...state.items, [id]: { ...item, loading, storing } }
	return { ...state, items }
}

const setAccountingDocumentData = (
	accountingDocumentId: string,
	data: ?AccountingDocument,
	state: State,
	storing: boolean = false,
): State => {
	const id: string = accountingDocumentId
	const item: AccountingDocumentItem = state.items[id] || getEmptyAccountingDocumentItem()
	const header = mergeAccountingDocumentToAccountingDocumentHeader(data, item.header)
	const items = { ...state.items, [id]: { ...item, data, header, storing } }
	return { ...state, items }
}

const setAccountingDocumentHeader = (
	accountingDocumentId: string,
	header: ?AccountingDocumentHeader,
	state: State,
	storing: boolean = false,
): State => {
	const id: string = accountingDocumentId
	const item: AccountingDocumentItem = state.items[id] || getEmptyAccountingDocumentItem()
	const data = mergeAccountingDocumentHeaderToAccountingDocument(header, item.data)
	const items = { ...state.items, [id]: { ...item, header, data, storing } }
	return { ...state, items }
}

const setAccountingDocumentLanguage = (
	accountingDocumentId: string,
	language: ?AccountingDocumentLanguage,
	state: State,
	storing: boolean = false,
): State => {
	const id: string = accountingDocumentId
	const item: AccountingDocumentItem = state.items[id] || getEmptyAccountingDocumentItem()
	const header = { ...item.header }
	if (language) header.language = language
	return setAccountingDocumentHeader(accountingDocumentId, header, state, storing)
}

const setAccountingDocumentAttachments = (
	accountingDocumentId: string,
	attachments: ?Array<AccountingDocumentAttachment>,
	state: State,
): State => {
	const id: string = accountingDocumentId
	const item: AccountingDocumentItem = state.items[id] || getEmptyAccountingDocumentItem()
	const items = { ...state.items, [id]: { ...item, attachments, storing: false } }
	return { ...state, items }
}

const setAccountingDocumentConnections = (
	accountingDocumentId: string,
	connections: Array<AccountingDocumentConnection>,
	state: State,
): State => {
	const id: string = accountingDocumentId
	const item: AccountingDocumentItem = state.items[id] || getEmptyAccountingDocumentItem()
	const items = { ...state.items, [id]: { ...item, connections } }
	return { ...state, items }
}

const setAccountingDocumentConnectionsLoading = (
	accountingDocumentId: string,
	connectionsLoading: boolean,
	state: State,
): State => {
	const id: string = accountingDocumentId
	const item: AccountingDocumentItem = state.items[id] || getEmptyAccountingDocumentItem()
	const items = { ...state.items, [id]: { ...item, connectionsLoading } }
	return { ...state, items }
}

const addAccountingDocumentConnection = (
	accountingDocumentId: string,
	connection: AccountingDocumentConnection,
	state: State,
	storing: boolean = false,
): State => {
	let items = { ...state.items }
	if (items[accountingDocumentId] && Array.isArray(items[accountingDocumentId].connections)) {
		const item = { ...items[accountingDocumentId] }
		const connections = [...item.connections]
		const existingIndex = connections.findIndex((c: AccountingDocumentConnection) => {
			return c.connectedAccountingDocumentId === connection.connectedAccountingDocumentId
		})
		if (existingIndex > -1) {
			connections[existingIndex] = connection
		} else {
			connections.push(connection)
		}
		item.connections = connections
		items[accountingDocumentId] = item
	}

	if (items[accountingDocumentId]) {
		items[accountingDocumentId].storing = storing
	}

	return { ...state, items }
}

const removeAccountingDocumentConnection = (
	accountingDocumentId: string,
	connection: AccountingDocumentConnection,
	state: State,
	storing: boolean = false,
): State => {
	let items = { ...state.items }
	if (items[accountingDocumentId] && Array.isArray(items[accountingDocumentId].connections)) {
		items[accountingDocumentId].connections = items[accountingDocumentId].connections.filter(
			(c: AccountingDocumentConnection) => {
				return c.connectedAccountingDocumentId !== connection.connectedAccountingDocumentId
			},
		)
	}
	if (items[accountingDocumentId]) {
		items[accountingDocumentId].storing = storing
	}
	return { ...state, items }
}

const setAccountingDocumentContact = (
	accountingDocumentId: string,
	contact: ?AccountingDocumentContact,
	state: State,
	storing: boolean = false,
): State => {
	const id: string = accountingDocumentId
	const item: AccountingDocumentItem = state.items[id] || getEmptyAccountingDocumentItem()

	const data = { ...item.data }
	if (contact) {
		data.contact = { ...contact }
	} else {
		delete data.contact
	}

	return {
		...state,
		items: {
			...state.items,
			[id]: {
				...item,
				data,
				storing,
			},
		},
	}
}

const setVatRecapInfo = (
	accountingDocumentId: string,
	vatRecapInfo: AccountingDocumentVatRecapInfo,
	state: State,
	storing: boolean = false,
): State => {
	const id: string = accountingDocumentId
	const item: AccountingDocumentItem = state.items[id] || getEmptyAccountingDocumentItem()
	const newData: AccountingDocument = merge_AccountingDocumentVatRecapInfo_to_AccountingDocument(
		vatRecapInfo,
		item.data,
	)
	const newHeader: AccountingDocumentHeader = mergeAccountingDocumentToAccountingDocumentHeader(newData)

	const items = {
		...state.items,
		[id]: {
			...item,
			data: newData,
			header: newHeader,
			storing,
		},
	}
	return { ...state, items }
}

const setCountryVariantSpecific = (
	accountingDocumentId: string,
	countryVariantSpecific: CountryVariantSpecific,
	state: State,
	storing: boolean = false,
): State => {
	const id: string = accountingDocumentId
	const item: AccountingDocumentItem = state.items[id] || getEmptyAccountingDocumentItem()
	let data: AccountingDocument = {
		...emptyAccountingDocument(),
		...item.data,
		countryVariantSpecific: countryVariantSpecific,
	}
	const items = { ...state.items, [id]: { ...item, data, storing, cvsBeforeUpdate: null } }
	return { ...state, items }
}

const cvsFallbackAfterError = (accountingDocumentId: string, state: State) => {
	const item: AccountingDocumentItem = state.items[accountingDocumentId] || getEmptyAccountingDocumentItem()
	const cvsBeforeUpdate = item.cvsBeforeUpdate ? item.cvsBeforeUpdate : undefined
	let data: AccountingDocument = {
		...emptyAccountingDocument(),
		...item.data,
		countryVariantSpecific: cvsBeforeUpdate,
	}
	const items = { ...state.items, [accountingDocumentId]: { ...item, data, cvsBeforeUpdate: null, storing: false } }
	return { ...state, items }
}

const cvsOptimisticUpdate = (
	accountingDocumentId: string,
	cvs: CountryVariantRequest,
	state: State,
	storing: boolean = true,
) => {
	const id: string = accountingDocumentId
	const item: AccountingDocumentItem = state.items[id] || getEmptyAccountingDocumentItem()
	const cvsBeforeUpdate = item.data && item.data.countryVariantSpecific ? item.data.countryVariantSpecific : null
	const operation: any = cvs.cz && cvs.cz.eet && cvs.cz.eet.operation ? cvs.cz.eet.operation : null
	let data: AccountingDocument = {
		...emptyAccountingDocument(),
		...item.data,
		countryVariantSpecific: { cz: { eet: { operation } } },
	}
	const items = { ...state.items, [id]: { ...item, data, storing, cvsBeforeUpdate } }
	return { ...state, items }
}

const setVatRecapInfoLoading = (accountingDocumentId: string, vatRecapInfoLoading: boolean, state: State): State => {
	const id: string = accountingDocumentId
	const item: AccountingDocumentItem = state.items[id] || getEmptyAccountingDocumentItem()
	const items = { ...state.items, [id]: { ...item, vatRecapInfoLoading } }
	return { ...state, items }
}

const setAccountingDocumentLineItem = (
	accountingDocumentId: string,
	lineItem: AccountingDocumentLineItem,
	state: State,
	storing: boolean = false,
): State => {
	const id: string = accountingDocumentId
	const item: AccountingDocumentItem = state.items[id] || getEmptyAccountingDocumentItem()
	let data: AccountingDocument = Object.assign(emptyAccountingDocument(), item.data)
	let lineItems: Array<AccountingDocumentLineItem> = data.lineItems || EMPTY_ARRAY
	const foundIndex = lineItems.findIndex((i: AccountingDocumentLineItem) => {
		return i.id === lineItem.id
	})
	if (foundIndex > -1) {
		lineItems[foundIndex] = Object.seal({ ...lineItems[foundIndex], ...lineItem })
	} else {
		lineItems.push(lineItem)
	}
	data.lineItems = !deepEqual(data.lineItems, lineItems) ? lineItems : data.lineItems
	const items = { ...state.items, [id]: { ...item, data, storing } }
	return { ...state, items }
}

const setAccountingDocumentLineItemCalculating = (
	accountingDocumentId: string,
	lineItemId: string,
	isCalculating: boolean,
	state: State,
): State => {
	const id: string = accountingDocumentId
	const item: AccountingDocumentItem = state.items[id] || getEmptyAccountingDocumentItem()
	const computedCalculatingLineItemIds: Array<string> = isCalculating
		? arrayUnion(item.calculatingLineItemIds, [lineItemId])
		: arrayWithout(item.calculatingLineItemIds, lineItemId)

	const calculatingLineItemIds = deepEqual(item.calculatingLineItemIds, computedCalculatingLineItemIds)
		? item.calculatingLineItemIds
		: computedCalculatingLineItemIds

	const items = { ...state.items, [id]: { ...item, calculatingLineItemIds } }
	return { ...state, items }
}

const addAccountingDocumentBankAccount = (
	accountingDocumentId: string,
	bankAccount: AccountingDocumentBankAccount,
	state: State,
	storing: boolean = false,
): State => {
	let items = state.items
	if (items[accountingDocumentId]) {
		let item: AccountingDocumentItem = items[accountingDocumentId]
		const bankAccounts: ?Array<AccountingDocumentBankAccount> =
			item.data && item.data.bankAccounts && item.data.bankAccounts
		const existingIndex: ?number =
			bankAccounts &&
			bankAccounts.findIndex((b: AccountingDocumentBankAccount) => {
				return b.localId === bankAccount.localId
			})
		const emptyIndex: ?number =
			bankAccounts &&
			bankAccounts.findIndex((b: AccountingDocumentBankAccount) => {
				return b.localId === undefined
			})

		let newBankAccounts: ?Array<AccountingDocumentBankAccount> = null
		let index: ?number = null

		if (existingIndex != null && existingIndex > -1) {
			index = existingIndex
		} else if (emptyIndex != null && emptyIndex > -1) {
			index = emptyIndex
		}

		if (index != null) {
			newBankAccounts =
				bankAccounts &&
				bankAccounts.map((account: AccountingDocumentBankAccount, i: number) => {
					return i === index ? bankAccount : account
				})
		} else {
			newBankAccounts = [...((item.data && item.data.bankAccounts) || []), bankAccount]
		}

		return setAccountingDocumentItemProps(state, accountingDocumentId, { storing }, { bankAccounts: newBankAccounts })
	}
	return state
}

const removeAccountingDocumentBankAccount = (
	accountingDocumentId: string,
	bankAccount: AccountingDocumentBankAccount,
	state: State,
	storing: boolean = false,
): State => {
	let items = state.items

	if (items[accountingDocumentId]) {
		let item: AccountingDocumentItem = items[accountingDocumentId]
		const newBankAccounts: ?Array<AccountingDocumentBankAccount> = ((item.data && item.data.bankAccounts) || []).filter(
			(b: AccountingDocumentBankAccount) => b.localId !== bankAccount.localId,
		)
		return setAccountingDocumentItemProps(state, accountingDocumentId, { storing }, { bankAccounts: newBankAccounts })
	}

	return state
}

const removeAccountingDocumentAllBankAccounts = (state: State, accountingDocumentId: string): State => {
	const id: string = accountingDocumentId
	const item: AccountingDocumentItem = state.items[id] || getEmptyAccountingDocumentItem()
	const items = {
		...state.items,
		[id]: {
			...item,
			data: {
				...state.items[id].data,
				bankAccounts: [],
			},
		},
	}

	return { ...state, items }
}

const setAccountingDocumentActivitiesLoading = (state: State, id: string, loading: boolean): State => {
	const item: AccountingDocumentItem = state.items[id]
	return {
		...state,
		items: {
			...state.items,
			[id]: {
				...item,
				activities: {
					...item.activities,
					loading,
				},
			},
		},
	}
}

const setAccountingDocumentActivities = (state: State, id: string, activities: Array<ActivityResponse>) => {
	const item: AccountingDocumentItem = state.items[id]
	return {
		...state,
		items: {
			...state.items,
			[id]: {
				...item,
				activities: {
					loading: false,
					activities,
				},
			},
		},
	}
}

export default (
	state: State = initialState,
	action: AccountingDocumentReduxAction | IssueAction | DataGridNextAction,
): State => {
	switch (action.type) {
		case 'FETCH_ACCOUNTING_DOCUMENT_ACTIVITIES_START': {
			return setAccountingDocumentActivitiesLoading(state, action.accountingDocumentId, true)
		}

		case 'FETCH_ACCOUNTING_DOCUMENT_ACTIVITIES_FINISH': {
			if (!action.serverError && action.activities) {
				return setAccountingDocumentActivities(state, action.accountingDocumentId, action.activities || [])
			}
			return setAccountingDocumentActivitiesLoading(state, action.accountingDocumentId, false)
		}
		case 'REMOVE_ACCOUNTING_DOCUMENT_ACTIVITIES': {
			return setAccountingDocumentActivities(state, action.accountingDocumentId, [])
		}

		case 'PREPARE_CREATE_ACCOUNTING_DOCUMENT': {
			return {
				...state,
				creating: true,
			}
		}

		case 'FINISH_CREATE_ACCOUNTING_DOCUMENT': {
			let newState = {
				...state,
			}

			if (action.result) {
				newState = setAccountingDocumentData(
					action.result.id || '',
					action.result,
					setAccountingDocumentLoading(action.result.id || '', false, state),
				)
				newState.lastCreatedDocument = action.result
			}

			newState = {
				...newState,
				creating: false,
			}

			return newState
		}

		case 'FINISH_REMOVE_ACCOUNTING_DOCUMENT': {
			let newState = { ...state }

			if (action.success) {
				delete newState.items[action.accountingDocumentId]
			}

			return newState
		}

		case 'START_LOAD_ACCOUNTING_DOCUMENT': {
			return setAccountingDocumentLoading(action.accountingDocumentId, true, state)
		}

		case 'FINISH_LOAD_ACCOUNTING_DOCUMENT': {
			return setAccountingDocumentData(
				action.accountingDocumentId,
				action.accountingDocument,
				setAccountingDocumentLoading(action.accountingDocumentId, false, state),
			)
		}

		case 'FINISH_LOAD_PUBLIC_ACCOUNTING_DOCUMENT': {
			if (!action.accountingDocument || !action.accountingDocument.id || !action.uniqueId) return state
			const id = action.accountingDocument.id

			const oldItems = (state && state.items) || {}
			const item: AccountingDocumentItem = (oldItems && oldItems[action.uniqueId]) || getEmptyAccountingDocumentItem()
			const header = mergeAccountingDocumentToAccountingDocumentHeader(action.accountingDocument, item.header)
			const items = {
				...oldItems,
				[id || '']: { ...item, data: action.accountingDocument, header },
			}

			return {
				...state,
				items,
				publicItems: {
					...state.publicItems,
					[action.uniqueId]: {
						...state.publicItems[action.uniqueId || ''],
						printing: action.printingSettings,
						id,
					},
				},
			}
		}

		case 'FINISH_LOAD_PUBLIC_ACCOUNTING_DOCUMENT_LOGO': {
			if (!action.binary || !action.uniqueId) return state

			return {
				...state,
				publicItems: {
					...state.publicItems,
					[action.uniqueId]: {
						...state.publicItems[action.uniqueId || ''],
						logo: action.binary,
					},
				},
			}
		}

		case 'START_LOAD_ACCOUNTING_DOCUMENT_HEADER': {
			return setAccountingDocumentLoading(action.accountingDocumentId, true, state)
		}

		case 'FINISH_LOAD_ACCOUNTING_DOCUMENT_HEADER': {
			const notLoadingState = setAccountingDocumentLoading(action.accountingDocumentId, false, state)
			if (action.success) {
				return setAccountingDocumentHeader(
					action.accountingDocumentId,
					action.accountingDocumentHeader,
					notLoadingState,
				)
			} else {
				return notLoadingState
			}
		}

		case 'START_UPDATE_ACCOUNTING_DOCUMENT_HEADER': {
			return setAccountingDocumentHeader(action.accountingDocumentId, action.newHeader, state, true)
		}

		case 'FINISH_UPDATE_ACCOUNTING_DOCUMENT_HEADER': {
			if (action.success) {
				return setAccountingDocumentHeader(action.accountingDocumentId, action.confirmedHeader, state)
			} else {
				return setAccountingDocumentHeader(action.accountingDocumentId, action.oldHeader, state)
			}
		}

		case 'START_UPDATE_ACCOUNTING_DOCUMENT_LANGUAGE': {
			return setAccountingDocumentLanguage(action.accountingDocumentId, action.newLanguage, state, true)
		}

		case 'FINISH_UPDATE_ACCOUNTING_DOCUMENT_LANGUAGE': {
			if (action.success) {
				return setAccountingDocumentLanguage(action.accountingDocumentId, action.confirmedLanguage, state)
			} else {
				return setAccountingDocumentLanguage(action.accountingDocumentId, action.oldLanguage, state)
			}
		}

		case 'START_LOAD_ACCOUNTING_DOCUMENT_ATTACHMENTS': {
			return setAccountingDocumentLoading(action.accountingDocumentId, true, state)
		}

		case 'FINISH_LOAD_ACCOUNTING_DOCUMENT_ATTACHMENTS': {
			return setAccountingDocumentAttachments(
				action.accountingDocumentId,
				action.attachments,
				setAccountingDocumentLoading(action.accountingDocumentId, false, state),
			)
		}

		case 'FINISH_DELETE_ACCOUNTING_DOCUMENT_SCAN':
		case 'FINISH_LOAD_ACCOUNTING_DOCUMENT_SCANS': {
			if (action.scans) {
				const id: string = action.accountingDocumentId
				const item: AccountingDocumentItem = state.items[id] || getEmptyAccountingDocumentItem()
				const items = {
					...state.items,
					[id]: {
						...item,
						data: {
							...(item.data || {}),
							scans: action.scans,
						},
					},
				}
				return { ...state, items }
			}
			return state
		}

		case 'FINISH_ATTACH_ACCOUNTING_DOCUMENT_ATTACHMENT': {
			const id: string = action.accountingDocumentId
			const attachments: Array<AccountingDocumentAttachment> = (state.items[id] && state.items[id].attachments) || []
			return setAccountingDocumentAttachments(
				action.accountingDocumentId,
				attachments.concat(action.attachment || []),
				state,
			)
		}

		case 'START_REMOVE_ACCOUNTING_DOCUMENT_ATTACHMENT': {
			return {
				...state,
				items: {
					...state.items,
					[action.accountingDocumentId]: {
						...state.items[action.accountingDocumentId],
						storing: true,
					},
				},
			}
		}

		case 'FINISH_REMOVE_ACCOUNTING_DOCUMENT_ATTACHMENT': {
			const id: string = action.accountingDocumentId
			const success: boolean = action.success
			const attachment: AccountingDocumentAttachment = action.attachment
			const currentAttachments: Array<AccountingDocumentAttachment> =
				(state.items[id] && state.items[id].attachments) || []
			if (!success) {
				// attachment wasnt deleted
				return {
					...state,
					items: {
						...state.items,
						[action.accountingDocumentId]: {
							...state.items[action.accountingDocumentId],
							storing: false,
						},
					},
				}
			} else {
				// attachment was deleted, remove it from list
				const newAttachments: Array<AccountingDocumentAttachment> = currentAttachments.filter(
					(att: AccountingDocumentAttachment) => {
						return att.documentUniqueId !== attachment.documentUniqueId
					},
				)
				return setAccountingDocumentAttachments(action.accountingDocumentId, newAttachments, state)
			}
		}

		case 'START_ADD_ACCOUNTING_DOCUMENT_LINE_ITEM': {
			return {
				...state,
				items: {
					...state.items,
					[action.accountingDocumentId]: {
						...state.items[action.accountingDocumentId],
						lineItemsProcessing: true,
						addingLineItem: true,
						storing: true,
					},
				},
			}
		}

		case 'FINISH_ADD_ACCOUNTING_DOCUMENT_LINE_ITEM': {
			const accountingDocumentId: string = action.accountingDocumentId
			const lineItem: ?AccountingDocumentLineItem = action.lineItem
			const accountingDocumentItemA: AccountingDocumentItem =
				state.items[accountingDocumentId] || getEmptyAccountingDocumentItem()
			const accountingDocument: ?AccountingDocument =
				state.items[accountingDocumentId] && state.items[accountingDocumentId].data

			let lineItems: Array<AccountingDocumentLineItem> = (accountingDocument && accountingDocument.lineItems) || []
			if (lineItem) {
				lineItems = [...lineItems, lineItem]
			}

			const items = {
				...state.items,
				[accountingDocumentId]: {
					...accountingDocumentItemA,
					loading: false,
					storing: false,
					lineItemsProcessing: false,
					addingLineItem: false,
					data: accountingDocument ? { ...accountingDocument, lineItems } : null,
				},
			}
			return { ...state, items }
		}

		case 'START_UPDATE_ACCOUNTING_DOCUMENT_LINE_ITEM': {
			return setAccountingDocumentLineItem(
				action.accountingDocumentId,
				action.newLineItem,
				setAccountingDocumentLineItemCalculating(
					action.accountingDocumentId,
					action.newLineItem.id || '',
					action.autocalc,
					state,
				),
				true,
			)
		}

		case 'FINISH_UPDATE_ACCOUNTING_DOCUMENT_LINE_ITEM': {
			const lineItem: ?AccountingDocumentLineItem = action.success ? action.confirmedLineItem : action.oldLineItem
			if (!lineItem) {
				return state
			}
			return setAccountingDocumentLineItem(
				action.accountingDocumentId,
				lineItem,
				setAccountingDocumentLineItemCalculating(action.accountingDocumentId, lineItem.id || '', false, state),
			)
		}

		case 'FINISH_LOAD_DEFAULT_ACCOUNTING_DOCUMENT_LINE_ITEMS': {
			return { ...state, defaultLineItems: action.lineItems }
		}

		case 'FINISH_LOAD_ACCOUNTING_DOCUMENT_LINE_ITEMS': {
			return {
				...state,
				items: {
					...state.items,
					[action.accountingDocumentId]: {
						...state.items[action.accountingDocumentId],
						data: {
							...state.items[action.accountingDocumentId].data,
							lineItems: action.lineItems,
						},
					},
				},
			}
		}

		case 'START_REMOVE_ACCOUNTING_DOCUMENT_LINE_ITEM': {
			return {
				...state,
				items: {
					...state.items,
					[action.accountingDocumentId]: {
						...state.items[action.accountingDocumentId],
						data: {
							...state.items[action.accountingDocumentId].data,
							lineItems: action.newLineItems,
						},
						storing: true,
					},
				},
			}
		}

		case 'FINISH_REMOVE_ACCOUNTING_DOCUMENT_LINE_ITEM': {
			const accountingDocumentId: string = action.accountingDocumentId
			const accountingDocumentItemA: AccountingDocumentItem =
				state.items[accountingDocumentId] || getEmptyAccountingDocumentItem()
			const accountingDocument: ?AccountingDocument =
				state.items[accountingDocumentId] && state.items[accountingDocumentId].data
			const lineItems: Array<AccountingDocumentLineItem> = action.serverError
				? action.oldLineItems
				: action.newLineItems
			return {
				...state,
				items: {
					...state.items,
					[accountingDocumentId]: {
						...accountingDocumentItemA,
						loading: false,
						storing: false,
						data: accountingDocument ? { ...accountingDocument, lineItems } : null,
					},
				},
			}
		}

		case 'START_UPDATE_ACCOUNTING_DOCUMENT_CONTACT': {
			//optimistic update
			return setAccountingDocumentContact(action.accountingDocumentId, action.newContact, state, true)
		}

		case 'FINISH_UPDATE_ACCOUNTING_DOCUMENT_CONTACT': {
			if (action.success) {
				return setAccountingDocumentContact(action.accountingDocumentId, action.confirmedContact, state)
			} else {
				return setAccountingDocumentContact(action.accountingDocumentId, action.oldContact, state)
			}
		}

		case 'START_ADD_ACCOUNTING_DOCUMENT_BANK_ACCOUNT': {
			// optimistic update
			return addAccountingDocumentBankAccount(action.accountingDocumentId, action.newBankAccount, state, true)
		}

		case 'FINISH_ADD_ACCOUNTING_DOCUMENT_BANK_ACCOUNT': {
			if (action.success && action.confirmedBankAccount) {
				return addAccountingDocumentBankAccount(action.accountingDocumentId, action.confirmedBankAccount, state)
			} else {
				if (action.oldBankAccount) {
					return addAccountingDocumentBankAccount(action.accountingDocumentId, action.oldBankAccount, state)
				} else {
					return removeAccountingDocumentBankAccount(action.accountingDocumentId, action.newBankAccount, state)
				}
			}
		}

		case 'START_REMOVE_ACCOUNTING_DOCUMENT_BANK_ACCOUNT': {
			// optimistic update
			return removeAccountingDocumentBankAccount(action.accountingDocumentId, action.bankAccount, state, true)
		}

		case 'FINISH_REMOVE_ACCOUNTING_DOCUMENT_BANK_ACCOUNT': {
			if (action.success) {
				return removeAccountingDocumentBankAccount(action.accountingDocumentId, action.bankAccount, state)
			} else {
				return addAccountingDocumentBankAccount(action.accountingDocumentId, action.bankAccount, state)
			}
		}

		case 'START_LOAD_ACCOUNTING_DOCUMENT_VAT_RECAP_INFO': {
			return setVatRecapInfoLoading(action.accountingDocumentId, true, state)
		}

		case 'FINISH_LOAD_ACCOUNTING_DOCUMENT_VAT_RECAP_INFO': {
			let resultState = setVatRecapInfoLoading(action.accountingDocumentId, false, state)
			if (action.success && action.loadedVatRecapInfo) {
				resultState = setVatRecapInfo(action.accountingDocumentId, action.loadedVatRecapInfo, resultState)
			}
			return resultState
		}

		case 'START_UPDATE_ACCOUNTING_DOCUMENT_VAT_RECAP_INFO': {
			// optimistic update
			return setVatRecapInfo(action.accountingDocumentId, action.newVatRecapInfo, state, true)
		}

		case 'FINISH_UPDATE_ACCOUNTING_DOCUMENT_VAT_RECAP_INFO': {
			if (action.success && action.confirmedVatRecapInfo) {
				return setVatRecapInfo(action.accountingDocumentId, action.confirmedVatRecapInfo, state)
			} else if (action.oldVatRecapInfo !== undefined && action.oldVatRecapInfo !== null) {
				return setVatRecapInfo(action.accountingDocumentId, action.oldVatRecapInfo, state)
			} else {
				return state
			}
		}

		case 'START_COUNTRY_VARIANT_UPDATE': {
			return cvsOptimisticUpdate(action.accountingDocumentId, action.countryVariantRequest, state, true)
		}
		case 'FINISH_COUNTRY_VARIANT_UPDATE': {
			if (!action.serverError && action.countryVariantResponse) {
				return setCountryVariantSpecific(action.accountingDocumentId, action.countryVariantResponse, state)
			} else {
				return cvsFallbackAfterError(action.accountingDocumentId, state)
			}
		}

		case 'START_LOAD_ACCOUNTING_DOCUMENT_CONNECTIONS': {
			return setAccountingDocumentConnectionsLoading(action.accountingDocumentId, true, state)
		}

		case 'FINISH_LOAD_ACCOUNTING_DOCUMENT_CONNECTIONS': {
			let resultState: State = setAccountingDocumentConnectionsLoading(action.accountingDocumentId, false, state)
			if (action.success) {
				resultState = setAccountingDocumentConnections(action.accountingDocumentId, action.connections, resultState)
			}
			return resultState
		}

		case 'START_CONNECT_ACCOUNTING_DOCUMENT_CONNECTION': {
			// optimistic update
			return addAccountingDocumentConnection(action.accountingDocumentId, action.newConnection, state, true)
		}

		case 'FINISH_CONNECT_ACCOUNTING_DOCUMENT_CONNECTION': {
			if (action.success) {
				return setAccountingDocumentData(action.accountingDocumentId, action.confirmedAccountingDocument, state)
			} else {
				return removeAccountingDocumentConnection(action.accountingDocumentId, action.newConnection, state)
			}
		}

		case 'START_UPDATE_ACCOUNTING_DOCUMENT_CONNECTION': {
			// optimistic update
			return addAccountingDocumentConnection(action.accountingDocumentId, action.newConnection, state, true)
		}

		case 'FINISH_UPDATE_ACCOUNTING_DOCUMENT_CONNECTION': {
			if (action.success) {
				return setAccountingDocumentData(action.accountingDocumentId, action.confirmedAccountingDocument, state)
			} else if (action.oldConnection) {
				return addAccountingDocumentConnection(action.accountingDocumentId, action.oldConnection, state)
			} else {
				return removeAccountingDocumentConnection(action.accountingDocumentId, action.newConnection, state)
			}
		}

		case 'START_DISCONNECT_ACCOUNTING_DOCUMENT_CONNECTION': {
			// optimistic update
			return removeAccountingDocumentConnection(action.accountingDocumentId, action.connection, state, true)
		}

		case 'FINISH_DISCONNECT_ACCOUNTING_DOCUMENT_CONNECTION': {
			if (action.success) {
				return removeAccountingDocumentConnection(action.accountingDocumentId, action.connection, state)
			} else {
				return addAccountingDocumentConnection(action.accountingDocumentId, action.connection, state)
			}
		}

		case 'FINISH_LOAD_ACCOUNTING_DOCUMENT_ISSUES': {
			return setAccountingDocumentItemProps(state, action.accountingDocumentId, {
				issues: action.result,
			})
		}

		case 'START_ACKNOWLEDGE_ACCOUNTING_DOCUMENT': {
			return setAccountingDocumentItemProps(state, action.accountingDocumentId, { storing: true })
		}

		case 'FINISH_ACKNOWLEDGE_ACCOUNTING_DOCUMENT': {
			return setAccountingDocumentItemProps(state, action.accountingDocumentId, { storing: false })
		}

		case 'FINISH_VALIDATE_ACCOUNTING_DOCUMENT': {
			return setAccountingDocumentItemProps(
				state,
				action.accountingDocumentId,
				undefined,
				undefined,
				action.validationErrorAccDoc ? action.validationErrorAccDoc : 'delete',
			)
		}

		case 'START_CHANGE_ACCOUNTING_DOCUMENT_STATE': {
			return setAccountingDocumentItemProps(state, action.accountingDocumentId, { storing: true })
		}

		case 'FINISH_CHANGE_ACCOUNTING_DOCUMENT_STATE': {
			return setAccountingDocumentItemProps(
				state,
				action.accountingDocumentId,
				{ storing: false },
				undefined,
				action.validationErrorAccDoc ? action.validationErrorAccDoc : 'delete',
			)
		}

		case 'START_UPDATE_ACCOUNTING_DOCUMENT_PAYMENT_INFO': {
			return setAccountingDocumentItemProps(state, action.accountingDocumentId, { storing: true })
		}

		case 'FINISH_UPDATE_ACCOUNTING_DOCUMENT_PAYMENT_INFO': {
			return setAccountingDocumentItemProps(
				state,
				action.accountingDocumentId,
				{ storing: false },
				{ payments: action.paymentInfo },
			)
		}

		case 'START_FORCE_PROCESS': {
			const id: string = action.accountingDocumentId.toString()

			return setAccountingDocumentItemProps(
				state,
				id,
				{ storing: true },
				{ processingState: action.newProcessingState },
			)
		}

		case 'FINISH_FORCE_PROCESS': {
			const id: string = action.accountingDocumentId.toString()

			return setAccountingDocumentItemProps(
				state,
				id,
				{ storing: false },
				{
					processingState: action.serverError ? action.oldProcessingState : action.newProcessingState,
				},
			)
		}

		case 'START_SET_ACCOUNTING_DOCUMENT_EXTRACTING_STATE': {
			return setAccountingDocumentItemProps(
				state,
				action.accountingDocumentId,
				{ storing: true },
				{ __extractingState: action.newExtractingState },
			)
		}

		case 'FINISH_SET_ACCOUNTING_DOCUMENT_EXTRACTING_STATE': {
			return setAccountingDocumentItemProps(
				state,
				action.accountingDocumentId,
				{ storing: false },
				{ __extractingState: action.serverError ? action.oldExtractingState : action.newExtractingState },
			)
		}

		case 'START_SET_ACCOUNTING_DOCUMENT_EXTRACTING_NOTE': {
			return setAccountingDocumentItemProps(
				state,
				action.accountingDocumentId,
				{ storing: true },
				{ _extracting: { notes: [action.newExtractingNote] } },
			)
		}

		case 'FINISH_SET_ACCOUNTING_DOCUMENT_EXTRACTING_NOTE': {
			return setAccountingDocumentItemProps(
				state,
				action.accountingDocumentId,
				{ storing: false },
				{ _extracting: { notes: [action.serverError ? action.oldExtractingNote : action.newExtractingNote] } },
			)
		}

		case 'START_REPLACE_LINE_ITEMS': {
			return setAccountingDocumentItemProps(state, action.accountingDocumentId, {
				storing: true,
				lineItemsProcessing: true,
			})
		}

		case 'FINISH_REPLACE_LINE_ITEMS': {
			return setAccountingDocumentItemProps(state, action.accountingDocumentId, {
				storing: false,
				lineItemsProcessing: false,
			})
		}

		case 'START_REPLACE_BANK_ACCOUNTS': {
			return setAccountingDocumentItemProps(state, action.accountingDocumentId, { storing: true })
		}

		case 'FINISH_REPLACE_BANK_ACCOUNTS': {
			return setAccountingDocumentItemProps(state, action.accountingDocumentId, { storing: false })
		}

		case 'FINISH_LOADING_ACCOUNTING_DOCUMENT_PAYMENT_REMINDERS': {
			return setAccountingDocumentItemProps(state, action.accountingDocumentId, {
				paymentReminders: action.paymentReminders,
			})
		}

		case 'FINISH_CREATING_ACCOUNTING_DOCUMENT_PAYMENT_REMINDERS': {
			if (action.paymentReminder) {
				const id: string = action.accountingDocumentId
				const paymentReminders: Array<PaymentReminder> = (state.items[id] && state.items[id].paymentReminders) || []

				const newPaymentReminders: Array<PaymentReminder> = [...paymentReminders, action.paymentReminder]

				return setAccountingDocumentItemProps(state, action.accountingDocumentId, {
					paymentReminders: newPaymentReminders,
				})
			}
			return state
		}

		case 'START_DELETING_ACCOUNTING_DOCUMENT_PAYMENT_REMINER':
		case 'START_UPDATING_ACCOUNTING_DOCUMENT_PAYMENT_REMINER': {
			return setAccountingDocumentItemProps(state, action.accountingDocumentId, {
				paymentReminders: action.newPaymentReminders,
			})
		}

		case 'FINISH_DELETING_ACCOUNTING_DOCUMENT_PAYMENT_REMINER':
		case 'FINISH_UPDATING_ACCOUNTING_DOCUMENT_PAYMENT_REMINER': {
			return setAccountingDocumentItemProps(state, action.accountingDocumentId, {
				paymentReminders: action.serverError ? action.oldPaymentReminders : action.newPaymentReminders,
			})
		}

		case 'START_LOADING_ACCOUNTING_DOCUMENT_PDF': {
			return {
				...state,
				items: {
					...state.items,
					[action.accountingDocumentId]: {
						...state.items[action.accountingDocumentId],
						...{
							pdfUrl: null,
						},
					},
				},
			}
		}

		case 'FINISH_LOADING_ACCOUNTING_DOCUMENT_PDF': {
			return {
				...state,
				items: {
					...state.items,
					[action.accountingDocumentId]: {
						...state.items[action.accountingDocumentId],
						...{
							pdfUrl: action.pdfUrl,
						},
					},
				},
			}
		}

		case 'START_LOADING_ACCOUNTING_DOCUMENT_POSSIBLE_STATES': {
			return setAccountingDocumentItemProps(state, action.accountingDocumentId, {
				loading: true,
			})
		}

		case 'FINISH_LOADING_ACCOUNTING_DOCUMENT_POSSIBLE_STATES': {
			const item: ?AccountingDocumentItem = state.items[action.accountingDocumentId]
			return setAccountingDocumentItemProps(state, action.accountingDocumentId, {
				loading: false,
				possibleStates: !action.serverError ? action.possibleStates : item && item.possibleStates,
			})
		}

		case 'START_EXPORTING_ACCOUNTING_DOCUMENTS': {
			return {
				...state,
				exporting: true,
			}
		}

		case 'FINISH_EXPORTING_ACCOUNTING_DOCUMENTS': {
			return {
				...state,
				exporting: false,
			}
		}

		case 'START_SENDING_ACCOUNTING_DOCUMENT_EMAIL': {
			return {
				...state,
				sending: true,
			}
		}

		case 'FINISH_SENDING_ACCOUNTING_DOCUMENT_EMAIL': {
			return {
				...state,
				sending: false,
			}
		}

		case 'REMOVE_ACCOUNTING_DOCUMENT_BANK_ACCOUNTS_IN_REDUX': {
			return removeAccountingDocumentAllBankAccounts(state, action.accountingDocumentId)
		}

		case 'FINISH_LOADING_GREENBOX_SUGGESTION': {
			const item = state.items[action.accDocId]
			if (item) {
				if (deepEqual(item.greenboxSuggestion, action.greenboxSuggestion)) return state
			}
			return updateReduxState(state, setGreenboxSuggestion(action.accDocId, action.greenboxSuggestion))
		}

		case 'FINISH_UPDATE_ACCDOC_CASHBOT_ID': {
			if (!state.items[action.accDocId] || !state.items[action.accDocId].data || !action.newIntegration) {
				return state
			}
			return setAccountingDocumentItemProps(state, action.accDocId, null, { _integrations: action.newIntegration })
		}

		case 'FINISH_LOAD_GRID_DATA': {
			if (action.dataType === 'accountingDocuments' && !action.serverError && action.data) {
				// $FlowFixMe
				const accDocs: Array<AccountingDocument> = action.data
				let modifiedState = { ...state }
				accDocs.forEach((accDoc: AccountingDocument) => {
					if (accDoc.id) {
						modifiedState = setAccountingDocumentData(accDoc.id, accDoc, modifiedState)
					}
				})
				return modifiedState
			} else {
				return state
			}
		}

		case 'REMOVE_ACCDOC_ERROR': {
			const { accountingDocumentId, errorUniqueId } = action
			return removeError(state, accountingDocumentId, errorUniqueId)
		}

		case 'FINISH_LOADING_ACCOUNTING_DOCUMENT_QR_CODE': {
			return setAccountingDocumentItemProps(state, action.accountingDocumentId, {
				qrCode: action.qrCode,
			})
		}

		case 'FINISH_LOAD_PUBLIC_ACCOUNTING_DOCUMENT_QR_CODE': {
			if (!action.uniqueId) return state

			return {
				...state,
				publicItems: {
					...state.publicItems,
					[action.uniqueId]: {
						...state.publicItems[action.uniqueId || ''],
						qr: action.qrCode,
					},
				},
			}
		}

		default:
			return state
	}
}

type AccountingDocumentReplaceItem = {|
	storing?: boolean,
	loading?: boolean,
	lineItemsProcessing?: boolean,
	issues?: ?AccountingDocumentIssues,
	paymentReminders?: ?Array<PaymentReminder>,
	qrCode?: ?AccountingDocumentQrCodeGetResponse,
	pdfUrl?: string,
	possibleStates?: ?Array<string>,
|}

type AccountingDocumentReplace = {|
	bankAccounts?: ?Array<AccountingDocumentBankAccount>,
	lineItems?: ?Array<AccountingDocumentLineItem>,
	processingState?: ?number,
	payments?: ?AccountingDocumentPaymentInfo,
	__extractingState?: AccountingDocumentExtractingState,
	_extracting?: { notes: Array<?AccountingDocumentNote> },
	_integrations?: AccountingDocumentIntegrationsResponse,
|}

function setAccountingDocumentItemProps(
	state: State,
	id: string,
	item: ?AccountingDocumentReplaceItem,
	data: ?AccountingDocumentReplace,
	errors?: 'delete' | FormFieldErrorsMap,
): State {
	if (!state.items[id]) {
		return state
	}

	let newAccDoc = {
		...state.items[id],
		...item,
		data: !data ? state.items[id].data : { ...state.items[id].data, ...data },
	}
	if (errors === 'delete') {
		delete newAccDoc.errors
	} else if (errors != null) {
		newAccDoc.errors = errors
	}

	return {
		...state,
		items: {
			...state.items,
			[id]: newAccDoc,
		},
	}
}

function removeError(state: State, accDocId: string, errorUniqueId: string) {
	const item = state.items[accDocId]
	let newState = state
	if (item && item.errors && item.errors[errorUniqueId]) {
		const newErrors = { ...item.errors }
		delete newErrors[errorUniqueId]
		newState = setAccountingDocumentItemProps(state, accDocId, undefined, undefined, newErrors)
	}
	return newState
}
