/* @flow */

import { connect } from 'react-redux'
import LineItem from 'modules/accounting-document/components/invoice-elements/line-item'
import { createTag } from 'modules/settings/actions'
import type {
	Dispatch,
	Tag,
	OrganizationAction,
	DataGridNextAction,
	FormFieldErrorContext,
	MultiFormFieldMessages,
	State,
	AccountingDocumentLineItem,
	FinancialAccount,
	GreenboxSuggestionResponse,
	Enum,
	AccountingDocumentReduxAction,
	AccountingDocument,
	VatRate,
} from 'types'
import type { ValidationMessageType, ValidationValueType } from 'wrappers/validate'
import type { Action as ReportingAction } from 'modules/settings/actions/reporting-action-types'
import { makeGetMultiFormFieldErrors } from 'modules/common/selectors'
import { removeError } from 'helpers'
import { accountingDocumentLineItemsSearch as accountingDocumentLineItemsSearchApi } from 'modules/common/models/api-model'
export type OwnProps = {|
	public?: boolean,
	readonly?: boolean,
	value: AccountingDocumentLineItem,
	financialAccounts: Array<FinancialAccount>,
	favoriteFinancialAccountIds: Array<string>,
	favoriteLineItemIds: Array<string>,
	greenboxSuggestion: ?GreenboxSuggestionResponse,
	availableProjects: Enum,
	availableBranches: Enum,
	isCalculating: boolean,
	currency: ?string,
	isCreating?: boolean,
	direction: ?number,
	index: number,
	isAccDocVatFree: boolean,
	validationMessage?: ValidationMessageType,
	validationValue?: ValidationValueType,
	defaultLineItems?: Array<AccountingDocumentLineItem> | null,
	accountingDocumentId?: ?string,
	accountingDocument?: ?AccountingDocument,
	vatRates: null | Array<VatRate>,
	onChange: (value: AccountingDocumentLineItem, autocalc: boolean) => void,
	onRemove: () => void,
	onClone: () => void,
	onExpand: (id: number) => void,
	onExpandClose: (id: number) => void,
	onFinancialAccountFavorite: (no: string, isFavorite: boolean) => void,
	onLineItemFavorite: (id: string, isFavorite: boolean) => void,
	isExpanded: boolean,
	formFieldErrorContext?: FormFieldErrorContext,
|}

type StateProps = {|
	errors: ?MultiFormFieldMessages,
|}

const makeMapStateToProps = () => {
	const getMultiFormFieldErrors = makeGetMultiFormFieldErrors()
	const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
		return {
			errors: ownProps.formFieldErrorContext && getMultiFormFieldErrors(state, ownProps.formFieldErrorContext),
		}
	}

	// $FlowFixMe
	return mapStateToProps
}

type DispatchProps = {|
	createTag: (tag: Tag) => void,
	removeError: (formFieldName: string) => void,
	searchLineItems: (filter?: string) => Promise<Array<AccountingDocumentLineItem>>,
|}

function mapDispatchToProps(
	dispatch: Dispatch<ReportingAction | OrganizationAction | DataGridNextAction | AccountingDocumentReduxAction>,
	ownProps: OwnProps,
): DispatchProps {
	return {
		createTag: (tag: Tag) => {
			dispatch(createTag(tag))
		},
		removeError: (formFieldName: string) => {
			removeError({
				formFieldErrorContext: ownProps.formFieldErrorContext,
				dispatch,
				extraPath: [formFieldName],
			})
		},
		searchLineItems: async (filter?: string) => {
			try {
				const result = await accountingDocumentLineItemsSearchApi.post({
					filters: {
						text: {
							text: filter || '',
						},
					},
				})
				return result
			} catch (error) {
				return []
			}
		},
	}
}

export default connect(makeMapStateToProps, mapDispatchToProps)(LineItem)
