/* @flow */

import type { AccessToken, State, UserAction, NotificationType } from 'types'
import type { ResourceFn, ResourceFns, MethodOptions } from './httpResource'
import { beginTask, endTask } from 'utils/loader'
import { API_REQUESTS_SHOW_LOADER, API_REQUESTS_IGNORE_LOADER } from 'trivi-constants'
import httpResource from './httpResource'
import { refreshToken as refreshTokenAction } from 'modules/user/actions'
import store from 'store'
import { setNotification } from 'modules/common/actions'
import { getAccessToken } from 'utils/local-storage'

export type { MethodOptions }

// it is object for O(1) access
const QUEUE_EXCLUDED_REQUESTS: { [path: string]: boolean } = {
	'{organizationId}/uploads': true,
}

declare var API_HOST: {
	notificationsUrl: string,
	host: string,
	pathPrefix: string,
	privateApiPrefix: string,
	publicApiPrefix: string,
}

export const { host, pathPrefix, privateApiPrefix, publicApiPrefix, notificationsUrl } = API_HOST //global variable defined in webpack.config.js

let refreshingToken: ?Promise<?UserAction> = null
const queue: Array<() => Promise<void>> = []

// this function can transform data returned from API, use only if it is really necessary
function hackDataMiddleware(data: Object, path: string, params: Array<any>): Object { //eslint-disable-line
	//
	// FinancialAccount ID - can be removed when ID will be added to FinancialAccount on API, it is needed for DataGrid
	if (path === 'search/financialaccounts') {
		data &&
			Array.isArray(data.financialAccounts) &&
			data.financialAccounts.forEach((finAcc: Object) => {
				finAcc.id = finAcc.no
			})
	}
	// FinancialAccount ID

	return data
}

function buildUrlTemplate(urlPath: string, pathPrefix: string) {
	const path = publicApiPrefix.match(/{organizationId}/)
		? urlPath.replace(/{organizationId}\//, '').replace(/{organizationId}$/, '')
		: urlPath
	return `${host}${pathPrefix}/${path}`
}

export function buildNotificationsUrl() {
	return `http://${notificationsUrl}/messageHub`
}

export function buildPrivateUrl(urlPath: string) {
	return buildUrlTemplate(urlPath, privateApiPrefix)
}

export function buildPublicUrl(urlPath: string) {
	const templateUrl = buildUrlTemplate(urlPath, publicApiPrefix)
	return templateUrl.replace(/{organizationId}/, getOrganizationId())
}

function getToken(): string {
	return tokenToString(getAccessToken()) || ''
}

export function tokenToString(token: ?AccessToken): ?string {
	if (token && token.access_token && token.token_type) {
		return token.token_type + ' ' + token.access_token
	}
	return null
}

function actionToAccessToken(action: ?UserAction): string {
	let token: ?AccessToken
	let tokenString: ?string

	if (action && action.type === 'REFRESH_TOKEN') {
		token = action.token
	}
	tokenString = tokenToString(token)

	if (tokenString) {
		return tokenString
	} else {
		throw null
	}
}

async function refreshToken(): Promise<?string> {
	const token = getAccessToken()
	const refreshToken = token && token.refresh_token
	let action: ?UserAction

	if (refreshingToken) {
		return actionToAccessToken(await refreshingToken)
	}

	if (refreshToken) {
		refreshingToken = store.dispatch(refreshTokenAction(refreshToken))
		action = await refreshingToken
		refreshingToken = null
		return actionToAccessToken(action)
	} else {
		throw null
	}
}

export function createXHR(path: string, method?: 'POST' | 'PUT' | 'GET' | 'DELETE', isPrivate: boolean = false) {
	method = method == null ? 'POST' : method
	let xhr = new XMLHttpRequest()
	const url: string = !isPrivate ? buildPublicUrl(path) : buildPrivateUrl(path)
	xhr.open(method, url)
	xhr.setRequestHeader('Authorization', getToken())
	return xhr
}

function getOrganizationId(): string {
	const state: State = store.getState()
	return state.user.currentOrganizationId || '0'
}

// This is a temporary solution to queue some requests to minimize race conditions on the server
// Remove it when server is ready
function makeQueuedResourceFns(httpResource: ResourceFns, path: string): ResourceFns {
	if (QUEUE_EXCLUDED_REQUESTS[path]) {
		// some exceptions for queuing
		return httpResource
	}
	const resources = ['post', 'put', 'patch', 'delete']
	resources.forEach((resource: string) => {
		httpResource[resource] = makeQueuedResourceFn(httpResource[resource], path)
	})
	return httpResource
}

function makeQueuedResourceFn(resourceFn: ResourceFn, path: string): ResourceFn {
	// const withLoader: boolean = isRequestWithLoader(path)
	return (...params: Array<any>): Promise<any> => {
		return new Promise((resolve: any => void, reject: any => void) => {
			queue.push(async () => {
				try {
					const data = await resourceFn(...params)
					resolve(hackDataMiddleware(data, path, params))
				} catch (error) {
					reject(error)
				}
				queue.shift()
				if (queue[0]) {
					queue[0]()
				}
			})
			if (queue.length === 1) {
				queue[0]()
			}
		})
	}
}

// Obalí funkce laoderem a dispatchem do storu (pro monitoring aktualne fetchovanych path)
function makeLoaderFns(httpResource: ResourceFns, path: string): ResourceFns {
	const resources = ['post', 'put', 'patch', 'delete', 'get']
	resources.forEach((resource: string) => {
		httpResource[resource] = makeLoaderFn(httpResource[resource], path)
	})
	return httpResource
}

function makeLoaderFn(resourceFn: ResourceFn, path: string): ResourceFn {
	const withLoader: boolean = isRequestWithLoader(path)
	return (...params: Array<any>): Promise<any> => {
		return new Promise((resolve: any => void, reject: any => void) => {
			const fn = async () => {
				const loaderId = 'API_' + path
				try {
					// Show global loader
					withLoader && beginTask(loaderId)
					// ---
					resolve(await resourceFn(...params))
				} catch (error) {
					reject(error)
				} finally {
					setTimeout(() => {
						// Hide global loader
						withLoader && endTask(loaderId)
						// ---
					}, 0)
				}
			}
			// call it imediatelly
			fn()
		})
	}
}

function isRequestWithLoader(path: string): boolean {
	const isWithLoader = API_REQUESTS_SHOW_LOADER.indexOf(path) > -1
	const isIgnored = API_REQUESTS_IGNORE_LOADER.indexOf(path) > -1

	if (isWithLoader && isIgnored) {
		console.warn(//eslint-disable-line
			'Global loader: api path "' +
				path +
				'" is in both show and ignore list, ' +
				'it should be only in one of them. Please change it in trivi-constants/trivi-constants.js.',
		)
	}

	if (!isWithLoader && !isIgnored) {
		console.warn(//eslint-disable-line
			'Global loader: api path "' +
				path +
				'" is missing in global loader lists. ' +
				'Please add it to show or ignore list in trivi-constants/trivi-constants.js.',
		)
	}

	return isWithLoader
}

function sendNotification(notification: NotificationType) {
	store.dispatch(setNotification(notification, 100))
}

export function handler(path: string, options?: MethodOptions, customHeaders: Object = {}): ResourceFns {
	const headers = { ...customHeaders, Authorization: getToken() }
	const resource = httpResource(
		buildUrlTemplate(path, publicApiPrefix),
		{ organizationId: options && options.customOrganizationId ? options.customOrganizationId : getOrganizationId() },
		headers,
		{
			...options,
			sendNotification,
		},
		refreshToken,
	)

	const fns: ResourceFns = makeLoaderFns(resource, path)
	return makeQueuedResourceFns(fns, path)
}

export function privateApiHandler(path: string, options?: MethodOptions, customHeaders: Object = {}): ResourceFns {
	const headers = { ...customHeaders, Authorization: getToken() }
	const resource = httpResource(buildUrlTemplate(path, privateApiPrefix), {}, headers, options, refreshToken)

	const fns: ResourceFns = makeLoaderFns(resource, path)
	return makeQueuedResourceFns(fns, path)
}

export function authApiHandler(path: string, options?: MethodOptions, customHeaders: Object = {}): ResourceFns {
	const headers = { ...customHeaders, Authorization: getToken() }
	return httpResource(buildUrlTemplate(path, pathPrefix), {}, headers, options)
}

export function loadBase64Data(path: string, isPrivate?: boolean, params?: {} = {}): Promise<*> {
	return new Promise((resolve: Function, reject: Function) => {
		try {
			let xhr = createXHR(path, 'GET', isPrivate)
			xhr.responseType = 'arraybuffer'
			xhr.onload = (event: ProgressEvent) => {
				// $FlowFixMe
				if (200 === event.target.status) {
					// $FlowFixMe
					let uInt8Array: Uint8Array = new Uint8Array(event.target.response)
					let i = uInt8Array.length
					let binaryString = new Array(i)
					while (i--) {
						binaryString[i] = String.fromCharCode(uInt8Array[i])
					}
					let data = binaryString.join('')
					let base64 = 'data:image;base64,' + window.btoa(data)
					resolve(base64)
				} else {
					reject(null)
				}
			}
			xhr.send(params)
		} catch (serverError) {
			reject(serverError)
		}
	})
}
