// @flow

import type { AccountingDocumentReportAggregationResponseAggregatedItem, DashboardAction } from 'types'
import { convertToDomesticKey } from '../constants'
import type { BillingSummaryFilter, TimeGranularity } from '../types'

export type State = {
	filter: $Diff<BillingSummaryFilter, { currency: ?string }>,
	data: {
		[currency: string]: {
			[filter: string]: {
				[timeGranularity: TimeGranularity]: Array<AccountingDocumentReportAggregationResponseAggregatedItem>,
			},
		},
	},
}

export const initialState: State = {
	filter: {
		interval: { shift: 0, count: 1, interval: 'year' },
		timeGranularity: 3,
	},
	data: {},
}

export default (state: State = initialState, action: DashboardAction): State => {
	switch (action.type) {
		case 'FINISH_LOAD_BILLING_SUMMARY_REPORT_AGGREGATIONS':
			return action.serverError ? state : finishLoadReportAggregationsReducer(state, action.data, action.filter)
		case 'SET_BILLING_SUMMARY_FILTER':
			return setBillingSummaryFilterReducer(state, action.filter)
	}

	return state
}

function finishLoadReportAggregationsReducer(
	state: State,
	aggregatedData?: Array<AccountingDocumentReportAggregationResponseAggregatedItem>,
	filter: BillingSummaryFilter,
): State {
	const currency = filter.currency || convertToDomesticKey
	const timeGranularity = filter.timeGranularity || 0
	const intervalKey = JSON.stringify(filter.interval)

	return {
		...state,
		data: {
			...(state.data || {}),
			[currency]: {
				...(state.data[currency] || {}),
				[intervalKey]: {
					...((state.data[currency] || {})[intervalKey] || {}),
					[timeGranularity]: aggregatedData,
				},
			},
		},
	}
}

function setBillingSummaryFilterReducer(state: State, filter: BillingSummaryFilter): State {
	const { currency, ...rest } = filter
	return {
		...state,
		filter: rest,
	}
}
